import { StatusFragment, ProductFragment, DoctypeType, StatusType, StatusCategory } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import { make } from 'src/utils/reactives.util';

const {
  hookValue,
  hookWithSelector,
  getValue,
  setValue,
} = make<{
  completed: StatusFragment[];
  notStarted: StatusFragment[];
  started: StatusFragment[];
  canceled: StatusFragment[];
  all: StatusFragment[];
  statusById: Record<string, StatusFragment>;
  defaultNotStarted: StatusFragment | null;
  notStartedId: string | null;
  completedId: string | null;
}>({
  defaultState: {
    completed: [],
    notStarted: [],
    started: [],
    canceled: [],
    all: [],
    statusById: {},
    defaultNotStarted: null,
    notStartedId: null,
    completedId: null,
  },
});

export const setStatuses = (status: ProductFragment['status']) => {
  const completed = nodeToArray(status?.completed);
  const notStarted = nodeToArray(status?.notStarted);
  const started = nodeToArray(status?.started);
  const canceled = nodeToArray(status?.canceled);
  const all = [...completed, ...notStarted, ...started, ...canceled];
  if (isEqual(all, getValue().all)) return;
  setValue({
    completed: sortBy(completed, 'position'),
    notStarted: sortBy(notStarted, 'position'),
    started: sortBy(started, 'position'),
    canceled: sortBy(canceled, 'position'),
    all: sortBy(all, 'position'),
    statusById: keyBy(all, 'id'),
    defaultNotStarted: notStarted.find(s => s.doctypes.edges.some(e => e.node.type === DoctypeType.Feedback)) ?? null,
    notStartedId: notStarted.find(s => s.type === StatusType.ToProcess)?.id ?? null,
    completedId: completed.find(s => s.type === StatusType.Processed)?.id ?? null,
  });
};

export const getStatuses = getValue;
export const useGetStatuses = hookValue;

export const getStatus = (id?: string | null) => {
  if (!id) return undefined;
  return getStatuses().statusById[id];
};

export const useDefaultNotStartedStatus = () => hookWithSelector(state => state.defaultNotStarted);

export const useNotStartedStatusId = () => hookWithSelector(state => state.notStartedId);

export const useCompletedStatusId = () => hookWithSelector(state => state.completedId);

export const useDefaultDocTypeStatus = (docTypeId?: string) => {
  const { notStarted } = useGetStatuses();
  return useMemo(() => {
    if (!docTypeId) return undefined;
    return notStarted.filter(s => s.doctypes.edges.some(e => e.node.id === docTypeId))?.[0];
  }, [docTypeId, notStarted]);
};

export const useStatusByDocTypes = (
  docTypeIds: string[] | undefined,
  filter?: (status: StatusFragment) => boolean,
) => {
  const { all } = useGetStatuses();
  return useMemo(() => all.filter(
    status => (!docTypeIds || docTypeIds.some(id => status.doctypes.edges.some(d => d.node.id === id))) &&
      (!filter || filter(status)),
  ), [all, docTypeIds, filter]);
};

// Filter out statuses that are not relevant for suggestions
export const useSuggestionStatusIds = () => useGetStatuses().all
  .filter(status => status.type !== StatusType.LoopClosed && status.category !== StatusCategory.Canceled)
  .map(status => status.id);

export const getSearchStatusIds = (statusFilter: Record<StatusCategory, boolean>) => {
  const statuses = getStatuses();
  const statusIds: string[] = [];
  if (statusFilter[StatusCategory.NotStarted]) statusIds.push(...statuses.notStarted.map(s => s.id));
  if (statusFilter[StatusCategory.Started]) statusIds.push(...statuses.started.map(s => s.id));
  if (statusFilter[StatusCategory.Completed]) statusIds.push(...statuses.completed.map(s => s.id));
  if (statusFilter[StatusCategory.Canceled]) statusIds.push(...statuses.canceled.map(s => s.id));
  return statusIds;
};
