import { InfoColorType } from '@cycle-app/utilities';
import { useRef, useEffect } from 'react';

import { ProgressBar, Circle, ProgressCircle } from './Progress.styles';
import { getColorTypeByPercentage } from '../../utils/colors.utils';

export { ProgressBar };

type Size = 'L' | 'M' | 'S';

export type ProgressProps = {
  className?: string;
  isNeutral?: boolean;
  percentage?: number;
  state?: 'default' | 'warning' | 'danger';
  type?: 'bar' | 'ring';
  useCycleColor?: boolean;
  size?: Size;
  lineCap?: 'round' | 'butt';
};

export const Progress = ({
  className,
  /**
   * If neutral, color won't be added based on the percentage
   */
  isNeutral = false,
  percentage = 0,
  state,
  type = 'bar',
  size = 'L',
  useCycleColor,
  lineCap = 'round',
}: ProgressProps) => {
  const progressRef = useRef<SVGCircleElement>(null);

  useEffect(() => {
    if (type !== 'ring') return;

    const circle = progressRef.current;

    if (!circle) return;

    const normalizePercentage = percentage / 100;
    const radius = circle.r.baseVal.value;
    const circumference = radius * 2 * Math.PI;
    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    const offset = circumference - normalizePercentage * circumference;
    circle.style.strokeDashoffset = `${offset}`;
  }, [percentage, type]);

  const defaultColorType: InfoColorType | undefined = useCycleColor ? 'default' : undefined;
  const colorType = isNeutral
    ? defaultColorType
    : state || getColorTypeByPercentage(percentage);

  if (type === 'ring') {
    return (
      <svg
        className={className}
        height={getPixelSize(size)}
        width={getPixelSize(size)}
        viewBox="0 0 34 34"
      >
        <Circle
          strokeWidth="4"
          r="14"
          cx="17"
          cy="17"
          $colorType={colorType}
        />
        <ProgressCircle
          ref={progressRef}
          strokeWidth="4"
          r="14"
          cx="17"
          cy="17"
          strokeDasharray="10 20"
          $colorType={colorType}
          $lineCap={lineCap}
        />
      </svg>
    );
  }

  return (
    <ProgressBar
      className={className}
      $percentage={percentage}
      $colorType={colorType}
    />
  );
};

const getPixelSize = (size: Size) => {
  if (size === 'L') return 32;
  if (size === 'M') return 22;
  return 16;
};
