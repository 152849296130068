// Source: https://jotai.org/docs/recipes/atom-with-debounce

import { atom, SetStateAction } from 'jotai';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';

export const atomWithDebounce = <T>(
  initialValue: T,
  delayMilliseconds = INPUT_ONCHANGE_DEBOUNCE,
  shouldDebounceOnReset = false,
) => {
  const prevTimeoutAtom = atom<ReturnType<typeof setTimeout> | undefined>(
    undefined,
  );

  const _currentValueAtom = atom(initialValue);
  const isDebouncingAtom = atom(false);

  const debouncedValueAtom = atom(
    initialValue,
    (get, set, update: SetStateAction<T>) => {
      clearTimeout(get(prevTimeoutAtom));

      const prevValue = get(_currentValueAtom);
      const nextValue =
        typeof update === 'function'
          ? (update as (prev: T) => T)(prevValue)
          : update;

      const onDebounceStart = () => {
        set(_currentValueAtom, nextValue);
        set(isDebouncingAtom, true);
      };

      const onDebounceEnd = () => {
        set(debouncedValueAtom, nextValue);
        set(isDebouncingAtom, false);
      };

      onDebounceStart();

      if (!shouldDebounceOnReset && nextValue === initialValue) {
        onDebounceEnd();
        return;
      }

      const nextTimeoutId = setTimeout(() => {
        onDebounceEnd();
      }, delayMilliseconds);

      // set previous timeout atom in case it needs to get cleared
      set(prevTimeoutAtom, nextTimeoutId);
    },
  );

  return [
    atom((get) => get(_currentValueAtom)),
    debouncedValueAtom,
  ] as const;
};
