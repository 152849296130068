import { CloseIcon } from '@cycle-app/ui/icons';
import { useHotkeys } from '@cycle-app/utilities';
import { nextFrame } from '@cycle-app/utilities/src/utils/async.utils';
import { useAnimate, AnimationPlaybackControls } from 'framer-motion';
import clamp from 'lodash/clamp';
import { useRef, useState, ReactNode } from 'react';

import { RELEASES_PRODUCT_TOUR_SLIDE_WIDTH } from 'src/constants/releases.constants';

import {
  Modal, Carousel, Slides, Slide, Actions, Badge,
  Bullets, Buttons, Bullet, Button, CloseButton,
} from './ProductTourModalContent.styles';

type ProductTourModalContentProps = {
  slides: {
    id: number;
    title?: ReactNode;
    description?: ReactNode;
    image?: ReactNode;
    badge?: string;
    component?: ReactNode;
  }[];
  stop: VoidFunction;
};

export const ProductTourModalContent = ({
  slides, stop,
}: ProductTourModalContentProps) => {
  const animation = useRef<AnimationPlaybackControls>();
  const [scope, animate] = useAnimate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const goTo = async (index: number) => {
    animation.current?.stop();
    await nextFrame();
    animation.current = animate(scope.current, {
      x: -index * RELEASES_PRODUCT_TOUR_SLIDE_WIDTH,
    }, {
      type: 'spring',
      duration: 0.6,
      bounce: 0,
    });
    setCurrentIndex(index);
  };

  const isFirst = currentIndex === 0;
  const isLast = currentIndex === slides.length - 1;

  const goBack = () => !isFirst && goTo(currentIndex - 1);
  const goNext = () => !isLast && goTo(currentIndex + 1);

  useHotkeys('arrowleft', goBack);
  useHotkeys('arrowright', goNext);

  return (
    <Modal hide={stop}>
      <Carousel>
        <Slides
          ref={scope}
          drag="x"
          whileDrag={{
            cursor: 'grabbing',
          }}
          dragConstraints={{
            left: -RELEASES_PRODUCT_TOUR_SLIDE_WIDTH * (slides.length - 1),
            right: 0,
          }}
          dragTransition={{
            power: 0.2,
            timeConstant: 200,
            modifyTarget: target => {
              const index = Math.round(target / RELEASES_PRODUCT_TOUR_SLIDE_WIDTH);
              const clampedIndex = clamp(-index, 0, slides.length - 1);
              setCurrentIndex(clampedIndex);
              return index * RELEASES_PRODUCT_TOUR_SLIDE_WIDTH;
            },
          }}
        >
          {slides.map(s => (
            <Slide key={s.id}>
              {s.component ?? (
                <>
                  <div>
                    {s.image}
                  </div>
                  <div>
                    {s.title && (
                      <h2>
                        <span>
                          {s.title}
                        </span>
                        {s.badge && (
                          <Badge>
                            {s.badge}
                          </Badge>
                        )}
                      </h2>
                    )}
                    {s.description && (
                      <p>
                        {s.description}
                      </p>
                    )}
                  </div>
                </>
              )}
            </Slide>
          ))}
        </Slides>
      </Carousel>

      <Actions>
        <Bullets>
          {slides.map((slide, index) => (
            <Bullet
              key={slide.id}
              $isActive={index === currentIndex}
              onClick={() => goTo(index)}
            />
          ))}
        </Bullets>
        <Buttons>
          <div>
            <Button
              variant="outlined-alt"
              disabled={currentIndex === 0}
              onClick={goBack}
            >
              Back
            </Button>
          </div>

          <div>
            <Button onClick={isLast ? stop : goNext}>
              {isLast ? 'Close' : 'Next'}
            </Button>
          </div>
        </Buttons>
      </Actions>

      <CloseButton onClick={stop}>
        <CloseIcon />
      </CloseButton>
    </Modal>
  );
};
