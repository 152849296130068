import { ActionButton } from '@cycle-app/ui';
import { WarningAltIcon, DownArrowIcon, CloseIcon, RefreshIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { useInterval } from 'src/hooks/useInterval';
import { useGetDownloads } from 'src/reactives/downloads.reactive';
import { formatBytes } from 'src/utils/formatBytes';
import { formatMs } from 'src/utils/formatMs';

import {
  Container, Header, Title, Infos, Content, ProgressBar, Progress, Actions,
} from './DownloadToaster.styles';

interface Props {
  url: string;
  close: VoidFunction;
  retry: VoidFunction;
}

export const DownloadToaster = ({
  url, close, retry,
}: Props) => {
  const result = useGetDownloads()[url];
  const [state, setState] = useState<{
    timeLeft: number;
    loaded: number;
  } | null>(null);

  useInterval(() => {
    if (!result?.startTime || !result?.loaded || !result.total || result.hasError) return;
    const elapsed = Date.now() - result.startTime;
    const speed = result.loaded / elapsed;
    setState({
      loaded: result.loaded,
      timeLeft: (result.total - result.loaded) / speed,
    });
  }, 100);

  if (!result) return null;

  const isComplete = result.loaded === result.total;
  const progress = result.loaded && result.total ? (result.loaded / result.total) * 100 : null;
  const timeLeft = state?.timeLeft ? formatMs(state.timeLeft) : null;
  const total = result.total ? formatBytes(result.total) : null;
  const loaded = state?.loaded ? formatBytes(state.loaded, {
    unit: total?.unit,
    hideUnit: true,
    formatOptions: {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
  }) : null;

  let fileName = result.baseName ? `${result.baseName}` : null;
  const srcFileName = url.split('/').pop();
  const srcExtension = srcFileName?.includes('.') ? srcFileName.split('.').pop() : null;
  if (srcExtension !== null) fileName += `.${srcExtension}`;

  const infos: string[] = [];
  if (result.hasError) {
    infos.push('Failed', 'Try again');
  } else if (isComplete && total !== null) {
    infos.push(`${total}`);
  } else {
    if (total !== null) {
      infos.push(loaded !== null ? `${loaded}/${total}` : `${total}`);
    }
    if (timeLeft !== null) {
      infos.push(`${timeLeft} left`);
    }
  }

  return (
    <Container>
      <Header>
        <Title>
          {result.hasError ? <WarningAltIcon size={16} /> : <DownArrowIcon size={16} />}
          {fileName && (
            <span>
              {fileName}
            </span>
          )}
        </Title>
        <Infos>
          {infos.join(' · ')}
        </Infos>
      </Header>

      <Content>
        <ProgressBar>
          <Progress
            $hasError={result.hasError}
            style={{ width: `${progress}%` }}
          />
        </ProgressBar>
        <Actions>
          <ActionButton
            tooltip={isComplete ? 'Close' : 'Cancel'}
            tooltipPlacement="top"
            onClick={() => {
              result.cancel?.();
              close();
            }}
          >
            <CloseIcon />
          </ActionButton>
          {result.hasError && (
            <ActionButton
              tooltip="Try again"
              tooltipPlacement="top"
              onClick={retry}
            >
              <RefreshIcon />
            </ActionButton>
          )}
        </Actions>
      </Content>
    </Container>
  );
};
