import { PublishDocDocument, DocBaseFragment, AutopilotState } from '@cycle-app/graphql-codegen';
import { getDocSlug } from '@cycle-app/utilities';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { PageId } from 'src/constants/routing.constant';
import { useCustomerDocFromCache } from 'src/hooks/api/cache/cacheCustomerDoc';
import { useRemoveDocImportFromCache } from 'src/hooks/api/cache/cacheDoc';
import { usePublishDocInCache } from 'src/hooks/api/mutations/usePublishDocInCache';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useNavigate } from 'src/hooks/useNavigate';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { hideCreateDocModal, removeDraft } from 'src/reactives/createDoc.reactive';
import { getDocImport, setDocImport } from 'src/reactives/docImport.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { refetchBoardWithConfigQuery } from 'src/utils/boardConfig/boardConfig.util';
import { getDocKey } from 'src/utils/doc.util';
import { isFeedback } from 'src/utils/docType.util';
import { addToaster } from 'src/utils/toasters.utils';

type PublishOptions = {
  withAutopilot?: boolean;
  openDoc?: boolean;
};

export const usePublishDoc = () => {
  const { addCustomerDoc } = useCustomerDocFromCache();
  const { navigate } = useNavigate();
  const [publish, { loading }] = useSafeMutation(PublishDocDocument);
  const publishDocInCache = usePublishDocInCache();
  const removeDocImportFromCache = useRemoveDocImportFromCache();

  const publishDoc = useCallback(async (doc: FullDocWithPublicId | null, {
    openDoc, withAutopilot,
  }: PublishOptions = {}) => {
    if (!doc) return;
    hideCreateDocModal();
    // Do not remove the feedback draft created from the sidebar if we publish from the dropzone
    if (!getDocImport().draftIdOpen) removeDraft(doc.doctype.id);
    setDocImport({ draftIdOpen: null });

    publishDocInCache({
      ...doc,
      // AutopilotState set to unprocessed, to immediately display the "extract quotes" loader in doc item
      autopilotState: withAutopilot ? AutopilotState.Unprocessed : null,
    });

    if (doc.customer) {
      addCustomerDoc({
        doc: {
          ...doc,
          isDraft: false,
        },
      });
    }

    const { data } = await publish({
      variables: {
        docId: doc.id,
        withAutopilot,
      },
      refetchQueries: refetchBoardWithConfigQuery,
      update: (_, { data: publishedData }) => {
        // Remove the doc from those imported with the dropzone
        removeDocImportFromCache(publishedData?.publishDoc);
      },
    });

    if (data?.publishDoc) {
      addToaster({
        title: 'Successfully created',
        message: ({ close }) => (
          <SuccessMessage
            doc={doc}
            close={close}
          />
        ),
      });
    }

    if (openDoc) navigate(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
  }, [addCustomerDoc, navigate, publish, publishDocInCache, removeDocImportFromCache]);

  return {
    publishDoc,
    isPublishingDoc: loading,
  };
};

const SuccessMessage = ({
  doc, close,
}: {
  doc?: DocBaseFragment;
  close: VoidFunction;
}) => {
  const docType = useGetDocType(doc?.doctype.id);
  const product = useProductBase();
  const { getUrl } = useNavigate();
  if (!doc) return null;
  const docUrl = getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) });

  if (isFeedback(docType)) {
    return (
      <Link
        to={docUrl}
        onClick={close}
      >
        {doc.title}
      </Link>
    );
  }

  return (
    <>
      <Link
        to={docUrl}
        onClick={close}
      >
        {getDocKey(product?.key, doc.publicId)}
      </Link>
      {' was successfully created'}
    </>
  );
};
