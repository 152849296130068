import { useFragment } from '@apollo/client';
import { DocCoverFragmentDoc } from '@cycle-app/graphql-codegen';
import { useRef } from 'react';

import DocPanelCover from 'src/app/Main/Board/DocPanel/DocPanelCover/DocPanelCover';
import CoverImageInputFile from 'src/components/CoverImageInputFile/CoverImageInputFile';
import useDocCoverDropzone from 'src/hooks/doc/useCoverDropzone';

import { useQuoteId } from './QuoteContext';

export const QuotePanelCover = () => {
  const docId = useQuoteId();

  const coverUrl = useFragment({
    fragment: DocCoverFragmentDoc,
    from: docId,
  }).data.cover?.url;

  if (!coverUrl) return null;

  return (
    <QuotePanelCoverPresent coverUrl={coverUrl} />
  );
};

export const QuotePanelCoverPresent = ({ coverUrl }: { coverUrl: string }) => {
  const docId = useQuoteId();
  const editable = !coverUrl.startsWith('blob:'); // optimistic data
  const coverInputRef = useRef<HTMLInputElement | null>(null);

  const {
    getRootProps: getDropzonePropsCover,
    isDragActive: isDragActiveFromCover,
    updateDocCover,
  } = useDocCoverDropzone({
    docId,
    disabled: !editable,
  });

  return (
    <>
      <DocPanelCover
        docId={docId}
        coverUrl={coverUrl}
        isDragActive={isDragActiveFromCover}
        getDropzoneProps={getDropzonePropsCover}
        onUpdateCoverClicked={() => coverInputRef.current?.click()}
        className="mx-0! -mt-2! mb-6!"
      />

      {editable && (
        <CoverImageInputFile
          ref={coverInputRef}
          onCoverChanged={file => updateDocCover(file, { optimistic: true })}
        />
      )}
    </>
  );
};
