import { ReleaseTagStyle } from '@cycle-app/graphql-codegen';
import { twJoin } from 'tailwind-merge';

export function ChangelogTag({
  value, color, releaseTagStyle, className,
}: { value: string; color: string; releaseTagStyle?: ReleaseTagStyle; className?: string }) {

  return (
    <div
      className={twJoin(
        'flex select-none items-center gap-2 rounded',
        releaseTagStyle === ReleaseTagStyle.Dot
          ? 'before:size-2 before:rounded-full before:bg-[var(--tag-color)] before:content-[""]'
          : 'bg-[var(--tag-color)] px-2 text-contrast',
        className,
      )}
      style={{
        '--tag-color': color,
        '--contrast-color': 'var(--tag-color)',
        '--contrast-light-opacity': '90%',
        '--contrast-dark-opacity': '80%',
      }}
    >
      {value}
    </div>
  );
}
