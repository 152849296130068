import { TextHighlighter } from '@cycle-app/ui';
import { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

import { CommandActionType } from 'src/types/commandbar.types';

import { Action, ShortcutStyled, Top, TitleWithHighlight, TextWithHighlight } from './CommandAction.styles';

interface CommandActionProps extends HTMLAttributes<HTMLButtonElement> {
  action: CommandActionType;
  selected: boolean;
  hoverDisabled: boolean;
  searchText: string;
  searchTextDebounced: string;
}

export const CommandAction = ({
  action,
  selected,
  hoverDisabled,
  searchText,
  searchTextDebounced,
  ...buttonProps
}:CommandActionProps) => {
  const content = action.renderLabel
    ? action.renderLabel({
      searchText,
      searchTextDebounced,
    })
    : (
      <div>
        <Top>
          {action.icon}
          {action.richTitle
            ? (
              <TitleWithHighlight
                className="highlight"
                dangerouslySetInnerHTML={{ __html: action.richTitle }}
              />
            )
            : (
              <TextHighlighter
                searchWords={[action.filtered ? searchTextDebounced : searchText]}
                textToHighlight={action.label ?? ''}
                className="highlight"
              />
            )}
          {action.postLabel}
          {action.shortcut && (
            <ShortcutStyled
              keys={action.shortcut}
              hideThen
            />
          )}
          {action.followUp && '->'}
        </Top>
        {!!action.richContent && (
          <TextWithHighlight
            className="highlight"
            dangerouslySetInnerHTML={{ __html: action.richContent }}
          />
        )}
      </div>
    );

  if (action.linkTo) {
    return (
      <Action
        id={action.id}
        as={Link}
        to={action.linkTo}
        onClick={action.onClick}
        onSelect={action.onSelect}
        className={selected ? 'selected' : ''}
        onMouseEnter={action.onMouseEnter}
        onMouseLeave={action.onMouseLeave}
        data-value={action.id}
      >
        {content}
      </Action>
    );
  }

  return (
    <Action
      id={action.id}
      className={selected ? 'selected' : ''}
      hoverDisabled={hoverDisabled}
      {...buttonProps}
      data-value={action.id}
    >
      {content}
    </Action>
  );
};
