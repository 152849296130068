import { ActionButton } from '@cycle-app/ui';
import { ResizeImageIcon } from '@cycle-app/ui/icons';
import { ImageSize } from '@cycle-app/utilities';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC, useCallback } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { ImageMenu } from 'src/components/ImageMenu';
import { useEditorContext } from 'src/contexts/editorContext';
import { useTranscript } from 'src/hooks/editor/useTranscript';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useZoomableProps } from 'src/hooks/useZoomableProps';
import { setTranscriptTooltip, useTranscriptTooltip } from 'src/reactives/transcript.reactive';
import { ImageViewAttributes } from 'src/types/editor.types';
import { Layer } from 'src/types/layers.types';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';

import { TranscriptTooltip } from '../Editor/TranscriptTooltip';
import { EditorMediaDragMenu } from '../EditorMediaDragMenu';
import { EditorNodeEmptyState } from '../EditorNodeEmptyState';
import {
  Container,
  ImageContainer,
  Image,
  MenuContent,
  SelectLineItem,
  StyledCheckIcon,
  EditorTranscriptStatus,
} from './EditorImageView.styles';

interface EditorImageViewProps extends NodeViewRendererProps {
  selected: boolean;
  updateAttributes: (p: Partial<ImageViewAttributes>) => void;
}

export const EditorImageView: FC<React.PropsWithChildren<EditorImageViewProps>> = ({
  node,
  selected,
  getPos,
  updateAttributes,
}) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const isInert = useEditorContext(ctx => ctx.isInert);
  const areImageResizable = useEditorContext(ctx => !ctx.isReadOnly && !ctx.disabledFeatures?.includes('image-resizable'));

  const {
    size,
    src,
    title,
    dataId,
  } = node.attrs as ImageViewAttributes;
  const {
    getTranscript, isLoading: isTranscriptLoading, insertTranscript,
  } = useTranscript({
    node,
    getPos,
    dataId,
  });
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  const onDelete = () => deleteNodeRange({
    editor,
    node,
    getPos,
  });
  const onTranscript = useCallback(() => getTranscript({
    src,
    type: 'image',
    insertContent: true,
  }), [getTranscript, src]);

  const getZoomableProps = useZoomableProps();

  const dataToTranscript = useTranscriptTooltip(dataId);

  return (
    <Container
      $isSelected={!isInert && selected && !isTranscriptLoading}
    >
      <ImageContainer
        $size={src ? size : undefined}
      >
        {editor.isEditable && (
          <EditorMediaDragMenu
            onDelete={onDelete}
            onTranscript={onTranscript}
            isTranscriptLoading={isTranscriptLoading}
            src={src}
            title={title}
            type="image"
          />
        )}

        {areImageResizable && src && (
          <ImageMenu forceVisible={isDropdownVisible}>
            <DropdownLayer
              visible={isDropdownVisible}
              layer={Layer.DropdownModalZ2}
              hide={hideDropdown}
              content={(
                <>
                  <SelectLineItem
                    onClick={() => updateAttributes({ size: ImageSize.LARGE })}
                    label="Large"
                    endSlot={size === ImageSize.LARGE ? <StyledCheckIcon /> : undefined}
                  />
                  <SelectLineItem
                    onClick={() => updateAttributes({ size: ImageSize.MEDIUM })}
                    label="Medium"
                    endSlot={size === ImageSize.MEDIUM ? <StyledCheckIcon /> : undefined}
                  />
                  <SelectLineItem
                    onClick={() => updateAttributes({ size: ImageSize.SMALL })}
                    label="Small"
                    endSlot={size === ImageSize.SMALL ? <StyledCheckIcon /> : undefined}
                  />
                </>
              )}
            >
              <MenuContent>
                <ActionButton
                  tooltip="Size"
                  tooltipPlacement="top"
                  onClick={toggleDropdown}
                  forceFocus={isDropdownVisible}
                >
                  <ResizeImageIcon />
                </ActionButton>
              </MenuContent>
            </DropdownLayer>
          </ImageMenu>
        )}
        <TranscriptTooltip
          dataId={dataId}
          transcript={() => {
            if (!dataToTranscript) return;
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            getTranscript(dataToTranscript).then(insertTranscript);
          }}
        >
          {src
            ? (
              <Image
                {...getZoomableProps({
                  src,
                  title,
                })}
              />
            )
            : (
              <EditorNodeEmptyState
                type="image"
                dataId={dataId}
                onCancel={onDelete}
                onUploaded={(fileData) => {
                  updateAttributes({
                    src: fileData.src,
                    title: fileData.title,
                    dataId,
                    width: fileData.width,
                    height: fileData.height,
                  });
                  setTranscriptTooltip(dataId, fileData);
                }}
              />
            )}
        </TranscriptTooltip>
      </ImageContainer>

      {src && isTranscriptLoading && (
        <EditorTranscriptStatus
          $size={size}
          loadingText="Running OCR…"
        />
      )}
    </Container>
  );
};
