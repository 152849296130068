import { ViewType } from '@cycle-app/graphql-codegen';
import { FC, memo, MutableRefObject, useMemo } from 'react';

import { DocItemHandle } from 'src/components/DocItem';
import { useDocsNavigation } from 'src/hooks/doc/useDocsNavigation';
import { useDocsPreview } from 'src/hooks/doc/useDocsPreview';
import { useBoardShortcutListener } from 'src/hooks/shortcuts/useBoardShortcutListener';
import { useDocsBulkSelection } from 'src/hooks/useDocsSelection';
import { useBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { useGetDocIdPreview } from 'src/reactives/docPreview.reactive';
import { useGetLayerDocPanel, useGetLayerDropdown, useGetLayerModalZ2 } from 'src/reactives/layer.reactive';
import { useGetNotifications } from 'src/reactives/notifications.reactive';
import { useGetSidebarResising } from 'src/reactives/sidebar.reactive';
import { Items } from 'src/types/item.types';
import { Layer } from 'src/types/layers.types';

interface Props {
  activeId: string | null;
  viewType: ViewType;
  docIds: Items;
  docItemRefs: MutableRefObject<DocItemHandle[][]>;
}

// It might seem useless to create a component that render null but we want to avoid re-rendering
// BoardContent component (that is heavy in computation) when the hooks re-render
// (especially the one that listen to layers)
const BoardContentListeners: FC<React.PropsWithChildren<Props>> = ({
  activeId,
  docIds,
  viewType,
  docItemRefs,
}) => {
  const { enabled: isDocPreviewEnabled } = useGetDocIdPreview();
  const { draftPosition } = useBoardNewDocPositionState();
  const { visible: isLayerDocPanelVisible } = useGetLayerDocPanel();
  const { visible: isLayerDocModalZ2Visible } = useGetLayerModalZ2();
  const { visible: isLayerDropdownVisible } = useGetLayerDropdown();
  const { isNotifCenterVisible } = useGetNotifications();
  const { resizing } = useGetSidebarResising();

  const groupDocIds = useMemo(() => Object.values(docIds), [docIds]);
  const isDocHoveringEnabled = useMemo(
    () => !activeId && !resizing && !isNotifCenterVisible && !isLayerDocPanelVisible && !isLayerDocModalZ2Visible && !isLayerDropdownVisible,
    [activeId, isLayerDocPanelVisible, isLayerDocModalZ2Visible, isLayerDropdownVisible, isNotifCenterVisible, resizing],
  );

  const isCreatingNewDoc = !!draftPosition;

  useDocsBulkSelection({
    enabled: isDocHoveringEnabled,
    items: docIds,
  });

  useDocsPreview({
    enabled: isDocPreviewEnabled && !isCreatingNewDoc,
    disableOnLayers: [Layer.DocPanel, Layer.Modal, Layer.Commandbar, Layer.ModalZ2],
  });

  useDocsNavigation({
    enabled: () => isDocHoveringEnabled && !isCreatingNewDoc,
    viewType,
    items: groupDocIds,
    docItemRefs,
  });

  useBoardShortcutListener();

  return null;
};

export default memo(BoardContentListeners);
