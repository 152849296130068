import { DoctypeType } from '@cycle-app/graphql-codegen';
import { Icon, CycleLogo } from '@cycle-app/ui';
import { format, parseISO } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { useReducer } from 'react';
import { twJoin } from 'tailwind-merge';

import { SourceIcon } from 'src/components/DocSource';
import { getLabel } from 'src/components/DocSource/DocSource.utils';
import { useDocCreatorFragment, useDocSourceFragment } from 'src/hooks/api/fragments/doc';
import { useDocDoctypeFragment } from 'src/hooks/api/fragments/doctype';
import { history } from 'src/providers';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { getUserLabel } from 'src/utils/users.util';

import { SourceUrlForm } from './SourceForm';

type Props = {
  docId: string;
  hide: () => void;
};

export const SourcePopoverContent = ({
  docId, hide,
}: Props) => {
  const source = useDocSourceFragment(docId)?.source;
  const doctype = useDocDoctypeFragment(docId);
  const [isEditing, toggleEditing] = useReducer(v => !v, false);
  if (!source) return null;
  const isEditable = doctype?.type !== DoctypeType.Insight;
  return (
    <div
      className="relative min-w-80 max-w-96 cursor-auto rounded-[10px] border border-grey-300 bg-white text-primary shadow-z2 dark:border-grey-800 dark:bg-grey-850"
      onClick={e => e.stopPropagation()}
      onWheel={e => e.stopPropagation()}
    >
      <div className="flex flex-col gap-1.5 border-b border-grey-200 p-3 pb-2 dark:border-grey-800">
        <div className="flex items-center gap-1.5">
          <SourceIcon
            source={source}
            size={16}
            defaultIcon={(
              <CycleLogo
                size={16}
                color="blue"
              />
            )}
          />
          <div className="text-header-small font-medium">
            {getLabel(source)}
          </div>
        </div>
        <SourceInfos docId={docId} />
      </div>

      <div className="flex items-center gap-1 p-1">
        <button
          className="btn-tertiary"
          onClick={e => {
            hide();

            if (e.metaKey || source.url.startsWith('mailto:')) {
              window.open(source.url, '_blank');
              return;
            }

            // Internal link
            const url = new URL(source.url);
            if (url.origin === location.origin) {
              history.push(url.pathname);
              return;
            }

            window.open(source.url, '_blank');
          }}
        >
          <Icon
            name="arrow-right-up"
            className="size-3.5"
          />
          Open
        </button>

        <button
          className="btn-tertiary"
          onClick={() => {
            copyToClipboard({
              text: source.url,
              notification: 'Link successfully copied to clipboard',
            });
          }}
        >
          <Icon name="copy" />
          Copy link
        </button>

        {isEditable && (
          <button
            className={twJoin('btn-tertiary', isEditing && 'btn-hover')}
            onClick={toggleEditing}
          >
            <Icon name="edit" />
            Edit link
          </button>
        )}
      </div>

      <AnimatePresence>
        {isEditing && (
          <motion.div
            className="overflow-hidden"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              hidden: {
                opacity: 0,
                height: 0,
              },
              visible: {
                opacity: 1,
                height: 'auto',
              },
            }}
          >
            <SourceUrlForm
              docId={docId}
              defaultValue={source.url}
              hide={toggleEditing}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const SourceInfos = ({ docId }: { docId: string }) => {
  const docCreator = useDocCreatorFragment(docId);
  if (!docCreator) return null;
  const date = format(parseISO(docCreator.createdAt), 'MMMM d, yyyy');
  const creator = getUserLabel(docCreator.creator);
  return (
    <div className="select-text text-[13px] leading-5 text-secondary">
      {`Captured on ${date} · ${creator}`}
    </div>
  );
};
