import { Tag } from '@cycle-app/ui';
import { useRect } from '@cycle-app/utilities';
import { useRef } from 'react';
import { twJoin } from 'tailwind-merge';

import { TooltipClickToCopy } from 'src/components/TooltipClickToCopy';

import { useCustomAttributesItems } from './useCustomAttributesItems';

type Props = {
  companyId: string;
  onOverflow: (overflow: boolean) => void;
};

export const CustomAttributes = ({
  companyId, onOverflow,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [containerRef, overflow] = useRect(rect => {
    const contentWidth = contentRef.current?.getBoundingClientRect().width;
    const state = contentWidth ? rect.width < contentWidth : false;
    onOverflow(state);
    return state;
  });

  const items = useCustomAttributesItems(companyId);

  if (items.length === 0) return null;

  return (
    <div
      ref={containerRef}
      className={twJoin(
        'mt-1.5 flex justify-end overflow-clip',
        overflow && 'mt-0 h-0 opacity-0',
      )}
    >
      <div
        ref={contentRef}
        className="ml-auto flex w-fit items-center justify-end gap-2"
      >
        {items.map(item => (
          <TooltipClickToCopy
            key={item.id}
            title={item.title}
            value={item.value}
          >
            {props => (
              <Tag
                color={item.color}
                {...props}
              >
                {item.value}
              </Tag>
            )}
          </TooltipClickToCopy>
        ))}
      </div>
    </div>
  );
};
