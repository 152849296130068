export const TEXT_TO_REPLACE = 'ship';

export function getSubtitleData(subtitle: string) {
  const shipRegex = new RegExp(`\\b${TEXT_TO_REPLACE}\\b`, 'i');
  const match = subtitle.match(shipRegex);

  return {
    hasShip: match !== null,
    shipIndex: match?.index ?? -1,
  };
}
