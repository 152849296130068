import { CompanyDescriptionDocument, CompanyDescriptionQuery, CompanyDescriptionQueryVariables } from '@cycle-app/graphql-codegen';

import { useSafeAsyncLazyQuery } from '../useSafeAsyncLazyQuery';

export function useFetchCompanyDescription() {
  const [fetch, { loading }] = useSafeAsyncLazyQuery<CompanyDescriptionQuery, CompanyDescriptionQueryVariables>(CompanyDescriptionDocument);

  return {
    fetch: (domain: string) => fetch({
      variables: { domain },
    }),
    isLoading: loading,
  };
}
