import { Skeleton } from '@cycle-app/ui';

import { Container, Header, Content } from './ReleaseItem.styles';

export const ReleaseItemSkeleton = () => (
  <Container>
    <Header>
      <Content>
        <Skeleton
          height={20}
          width={100}
        />
        <Skeleton
          height={20}
          style={{
            maxWidth: 300,
            flex: 2,
          }}
        />
        <div style={{ flex: 1 }} />
        <Skeleton
          height={20}
          width={120}
        />
      </Content>
    </Header>
  </Container>
);
