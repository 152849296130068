import { CreateReleaseNoteTagValueDocument } from '@cycle-app/graphql-codegen';

import { useSafeMutation } from '..';

export const useReleaseNoteTagCreate = () => {
  const [createMutation] = useSafeMutation(CreateReleaseNoteTagValueDocument);

  const createTag = async ({
    changelogId,
    value,
    color,
  }: {
    changelogId: string;
    value: string;
    color: string;
  }) => {
    const result = await createMutation({
      variables: {
        changelogId,
        value,
        color,
      },
      update: (cache, { data }) => {
        const newTag = data?.createReleaseNoteTagValue;
        if (!newTag) return;

        cache.modify({
          id: cache.identify({
            __typename: 'Changelog',
            id: changelogId,
          }),
          fields: {
            releaseNoteTagValues: (existingTags = []) => {
              const newTagRef = cache.identify(newTag);
              if (!newTagRef) return existingTags;
              return [...existingTags, { __ref: newTagRef }];
            },
          },
        });
      },
    });

    return result.data?.createReleaseNoteTagValue;
  };

  return {
    createTag,
  };
};
