import { SubscriptionHookOptions, useSubscription } from '@apollo/client';
import { QuotesDocument, QuotesSubscriptionVariables, QuotesSubscription } from '@cycle-app/graphql-codegen';

type Options =  Omit<SubscriptionHookOptions<QuotesSubscription, QuotesSubscriptionVariables>, 'variables'>;

export const useDocQuotesSubscription = (feedbackId: string, options: Options = {}) => {
  return useSubscription(QuotesDocument, {
    variables: { feedbackId },
    ...options,
  });
};
