import { PageId, SettingsPageId } from 'src/constants/routing.constant';
import { history } from 'src/providers';
import { setSettingsFromUrl } from 'src/reactives/lastView.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { getPermission } from 'src/reactives/permission.reactive';
import { LocationState, RouteParams } from 'src/types/routes.types';
import { getUrl } from 'src/utils/routing.utils';

import { useRouteAccess } from './router/useRouteAccess';

type Options = {
  useLimitationModal?: boolean;
};

// TODO: Remove this hook and import the function directly

/**
 * Navigate to a settings page with permission check and optional limitation modal
 * The current url will be saved if it is not a settings page
 */
export const useNavigateToSettings = (options: Options = { useLimitationModal: false }) => {
  const hasBillingAccess = useRouteAccess(PageId.SettingsBilling);
  return {
    navigate: (
      pageId: SettingsPageId,
      params?: RouteParams,
      state?: LocationState,
      metaKey?: boolean,
    ) => {
      const permission = getPermission();
      const canNavigate =
        pageId === PageId.SettingsBilling && hasBillingAccess ||
        permission.canReadSettings;

      if (permission.isPermissionInit && !canNavigate) {
        if (options.useLimitationModal) setLimitationsModal({ action: 'SETTINGS_READ' });
        return;
      }

      setSettingsFromUrl();

      if (metaKey) {
        window.open(getUrl(pageId, params), '_blank');
      } else {
        history.push(getUrl(pageId, params), state);
      }
    },
  };
};
