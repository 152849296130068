import { Icon } from '@cycle-app/ui';

import { DocAttributes } from 'src/components/DocAttributes';
import { useDocParentFragment } from 'src/hooks/api/fragments/doc';
import { navigateToDocFullPage } from 'src/hooks/useNavigate';

import { DocTagKey } from './DocTagKey';

type Props = {
  parentDocId: string;
  onClickChange: () => void;
  onClickUnlink: () => void;
  hide: () => void;
};

export const ParentPopoverContent = ({
  parentDocId, onClickChange, onClickUnlink, hide,
}: Props) => {
  const parentDoc = useDocParentFragment(parentDocId);
  if (!parentDoc) return null;
  return (
    <div
      className="relative min-w-80 max-w-96 cursor-auto rounded-[10px] border border-grey-300 bg-white text-primary shadow-z2 dark:border-grey-800 dark:bg-grey-850"
      onClick={e => e.stopPropagation()}
      onWheel={e => e.stopPropagation()}
    >
      <div className="flex flex-col gap-2 border-b border-grey-200 p-3 dark:border-grey-800">
        <div className="text-body">
          {parentDoc?.title.trim() || 'Untitled'}
        </div>

        <div className="flex flex-wrap items-center gap-1.5">
          <DocTagKey docId={parentDocId} />
          <DocAttributes
            className="contents!"
            doc={parentDoc}
            showDocId={false}
            showDocType={false}
            showStatus={false}
            showLinear={false}
            showAiState={false}
            showRelease={false}
            context="doc-panel"
            appendTo="parent"
          />
        </div>
      </div>

      <div className="flex items-center gap-1 p-1">
        <button
          className="btn-tertiary"
          onClick={e => {
            hide();
            navigateToDocFullPage(parentDoc, undefined, e.metaKey);
          }}
        >
          <Icon
            name="arrow-right-up"
            className="size-3.5"
          />
          Open
        </button>

        <button
          className="btn-tertiary"
          onClick={() => {
            hide();
            onClickChange();
          }}
        >
          <Icon name="edit" />
          Change
        </button>

        <button
          className="btn-tertiary"
          onClick={() => {
            hide();
            onClickUnlink();
          }}
        >
          <Icon name="unlink" />
          Unlink
        </button>
      </div>
    </div>
  );
};
