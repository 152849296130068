import { Spinner } from '@cycle-app/ui';
import { DownArrowIcon } from '@cycle-app/ui/icons';
import { FC, MouseEvent } from 'react';
import { twJoin } from 'tailwind-merge';

interface Props {
  className?: string;
  count?: number;
  onClick: (e: MouseEvent) => void;
  loading?: boolean;
}

const LoadMore: FC<React.PropsWithChildren<Props>> = ({
  count,
  loading = false,
  className,
  ...buttonProps
}) => (
  <button
    className={twJoin('btn-tertiary btn-sm', className)}
    {...buttonProps}
  >
    {loading ? <Spinner size={12} /> : <DownArrowIcon size={12} />}
    <span>
      {count
        ? `Load ${count} more`
        : 'Load more'}
    </span>
  </button>
);

export default LoadMore;
