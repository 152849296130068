import { lazy, Suspense } from 'react';

import { ChangelogBuilderLoader } from 'src/app/Changelog/ChangelogBuilder';
import { useChangelog } from 'src/hooks/releases/useChangelog';

import { ErrorPage } from '../../ErrorPage';

const ChangelogBuilder = lazy(() => import('src/app/Changelog/ChangelogBuilder').then(module => ({ default: module.ChangelogBuilder })));

export const ChangelogView = () => {
  const {
    changelog, isLoading,
  } = useChangelog();

  if (isLoading) return <ChangelogBuilderLoader />;
  if (!changelog) return <ErrorPage />;

  return (
    <Suspense fallback={<ChangelogBuilderLoader />}>
      <ChangelogBuilder changelog={changelog} />
    </Suspense>
  );
};
