import { useQuery } from '@apollo/client';
import { DoctypeFragment, ProductDoctypesDocument } from '@cycle-app/graphql-codegen/generated';
import { nodeToArray } from '@cycle-app/utilities';
import stubTrue from 'lodash/stubTrue';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { extract } from 'src/types/graphql.types';
import { isCustom, isLinkedToAI, isLinkedToFeedback, isLinkedToReleases } from 'src/utils/docType.util';

export const useDocTypes = (productId?: string) => {
  const {
    data, loading, refetch,
  } = useQuery(ProductDoctypesDocument, {
    skip: !productId,
    variables: {
      productId: productId as string,
    },
  });

  return {
    docTypes: nodeToArray(extract('Product', data?.node)?.doctypes),
    isLoading: loading,
    refetch,
  };
};

export const useFeatureTypes = (filter: (doctype: DoctypeFragment) => boolean = stubTrue) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const query = useQuery(ProductDoctypesDocument, {
    skip: !productId,
    variables: { productId },
  });
  const doctypes = nodeToArray(extract('Product', query.data?.node)?.doctypes);
  return doctypes.filter(doctype => isCustom(doctype) && filter(doctype));
};

export const useFeatureTypesLinkedToFeedback = () => useFeatureTypes(isLinkedToFeedback);
export const useFeatureTypesLinkedToReleases = () => useFeatureTypes(isLinkedToReleases);
export const useFeatureTypesLinkedToAI = () => useFeatureTypes(isLinkedToAI);
