import { BillingPlan } from '@cycle-app/graphql-codegen';
import { CheckCircleIcon } from '@cycle-app/ui/icons';

import { useIntercom } from 'src/hooks/useIntercom';
import { openIntercom } from 'src/utils/intercom.utils';

import {
  Body,
  BodyDefault,
  Content,
  StyledButton,
  StyledLogoLoader,
  SubTitle,
} from './OnboardingStepCallBooked.styles';
import { OnboardingAutologin } from '../OnboardingLayout/OnboardingAutologin';
import { OnboardingFooter } from '../OnboardingLayout/OnboardingFooter';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { Title } from '../OnboardingLayout/OnboardingLayout.styles';

interface Props {
  userId: string;
  productId: string;
  plan: BillingPlan;
}
export const OnboardingStepCallBooked = ({
  userId, productId, plan,
}: Props) => {
  useIntercom();
  return (
    <OnboardingLayout
      hideLogo
      hasLogout
      isContentGrow
      footer={<OnboardingFooter />}
      main={(
        <Content>
          <StyledLogoLoader
            size={100}
            color="blue"
          />
          <Body>
            <BodyDefault initial={{ opacity: 1 }}>
              <Title>
                You&apos;re all set, see you on the other side
              </Title>
              <SubTitle>
                <CheckCircleIcon
                  size={28}
                  style={{ margin: '8px 0' }}
                />
                <div>
                  Your onboarding is booked. Can&apos;t wait to unlock your free trial and show you the good stuff 🤗
                </div>
              </SubTitle>
              <StyledButton
                onClick={() => openIntercom()}
                size="M"
              >
                Any questions? Say hi!
              </StyledButton>
            </BodyDefault>
          </Body>
          <OnboardingAutologin
            userId={userId}
            productId={productId}
            plan={plan}
          />
        </Content>
      )}
    />
  );
};
