import { DocTargetFragment, UpdateInsightQuoteDocument } from '@cycle-app/graphql-codegen';
import { useState } from 'react';

import { DropdownLayerProps } from 'src/components/DropdownLayer';
import { useSafeMutation } from 'src/hooks';
import useAppHotkeys from 'src/hooks/useAppHotkeys';

import { StyledDropdown, StyledCommentEditor, Footer } from './EditQuoteDropdown.styles';

type Props = Omit<DropdownLayerProps, 'content'> & {
  insight: DocTargetFragment;
};

export const EditQuoteDropdown = ({
  insight, hide, ...props
}: Props) => {
  return (
    <StyledDropdown
      withPortal
      withWrapper={false}
      hide={hide}
      content={(
        <Content
          insight={insight}
          hide={hide}
        />
      )}
      {...props}
    />
  );
};

const Content = ({
  insight, hide,
}: {
  insight: DocTargetFragment;
  hide?: VoidFunction;
}) => {
  const [value, setValue] = useState(insight.content ?? '');

  const [updateQuote] = useSafeMutation(UpdateInsightQuoteDocument);

  const submit = () => {
    if (!insight?.doc?.id || !insight.doc?.docSource?.doc?.id) return;
    hide?.();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateQuote({
      variables: {
        targetId: insight.doc.id,
        content: value,
        sourceId: insight.doc.docSource.doc.id,
      },
      optimisticResponse: {
        changeDocLink: {
          ...insight.doc,
          docSource: {
            ...insight.doc.docSource,
            content: value,
          },
        },
      },
      update: (cache, { data }) => {
        if (!data?.changeDocLink?.docSource || !insight) return;
        cache.modify({
          id: cache.identify(insight),
          fields: {
            content: () => data.changeDocLink?.docSource?.content ?? '',
          },
        });
      },
    });
  };

  useAppHotkeys('enter', submit);

  return (
    <>
      <StyledCommentEditor
        autofocus
        content={value}
        onUpdate={(data) => setValue(data.html)}
      />

      <Footer>
        <button
          className="btn-tertiary"
          onClick={hide}
        >
          Cancel
        </button>

        <button
          className="btn-primary"
          onClick={submit}
        >
          Save
        </button>
      </Footer>
    </>
  );
};
