import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { Badge } from '@cycle-app/ui';
import { AddIcon, QuoteIcon } from '@cycle-app/ui/icons';
import { isPresent } from 'ts-is-present';

import LoadMore from 'src/components/LoadMore/LoadMore';
import { QuoteCard } from 'src/components/QuoteCard';
import { useDocInsights } from 'src/hooks';
import { useDocChildren } from 'src/hooks/api/useDoc';
import { Layer } from 'src/types/layers.types';
import { getDocFromCache } from 'src/utils/cache.utils';
import { insightName } from 'src/utils/doc.util';
import { SIZE_ITEMS_HIERARCHY } from 'src/utils/pagination.util';
import { requestInsightCreate } from 'src/utils/requestInsightCreate.utils';

import { DoctypeInfo, DoctypeLabel, AddButton } from './DocHierarchyGroupInsight.styles';
import { OpenInsightCreateModal } from './OpenInsightCreateModal';
import { DocHierarchyInsightsSkeletons } from '../DocHierarchyItem/DocHierarchyInsightsSkeletons';

export type DocHierarchyGroupInsightProps = {
  parentId?: string;
  doctypeChildren: DoctypeFragment;
  onClickItem?: VoidFunction;
  isFeedback?: boolean;
  count: number;
  isReadOnly?: boolean;
};

export const DocHierarchyGroupInsight = ({
  parentId,
  doctypeChildren,
  isFeedback,
  count,
  isReadOnly = false,
}: DocHierarchyGroupInsightProps) => {
  const childrenQuery = useDocChildren({
    doctypeId: doctypeChildren.id,
    docId: parentId,
  }, {
    skip: isFeedback,
  });

  const insightsQuery = useDocInsights(parentId, {
    skip: !isFeedback,
  });

  const isLoading = insightsQuery.isLoading || childrenQuery.loading || childrenQuery.isPaginationLoading;

  const docs = isFeedback
    ? insightsQuery.insights.map(i => i?.doc).filter(isPresent)
    : childrenQuery.docs;

  const parentDoc = parentId && getDocFromCache(parentId);

  const childrenLoading = !childrenQuery.isPaginationLoading && childrenQuery.pageInfo?.hasNextPage;

  return (
    <>
      <div
        key={doctypeChildren.id}
        className="flex flex-col items-stretch gap-4"
      >
        <DoctypeInfo>
          <DoctypeLabel>
            <QuoteIcon />
            {insightName({
              plural: true,
              uppercase: true,
            })}
            <Badge>
              <span>
                {count}
              </span>
            </Badge>
          </DoctypeLabel>

          {parentDoc && !isReadOnly && (
            <OpenInsightCreateModal
              layer={Layer.DropdownModalZ3}
              doc={parentDoc}
              button={props => (
                <AddButton
                  onClick={e => {
                    e.stopPropagation();
                    requestInsightCreate(props?.onClick);
                  }}
                  forceFocus={props.isOpen}
                >
                  <AddIcon size={12} />
                  {`Add ${insightName()}`}
                </AddButton>
              )}
            />
          )}
        </DoctypeInfo>
        {(docs.length || isLoading || childrenLoading) && (
          <div className="flex flex-col gap-2">
            {docs.map(doc => (
              <QuoteCard
                key={doc.id}
                docId={doc.id}
                showCustomer={!isFeedback}
                showFeature={isFeedback}
              />
            ))}

            {isLoading && <DocHierarchyInsightsSkeletons count={Math.min(count, 3)} />}

            {childrenLoading && (
              <LoadMore
                className="justify-start"
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  await childrenQuery.loadMore(childrenQuery.pageInfo?.endCursor ?? '');
                }}
                count={Math.min((count - docs.length), SIZE_ITEMS_HIERARCHY)}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
