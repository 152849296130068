import { SelectOption } from '@cycle-app/ui';
import { TrashIcon, DuplicateIcon, CaretIcon } from '@cycle-app/ui/icons';
import { NodeViewRendererProps, NodeViewContent } from '@tiptap/react';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { useEditorContext } from 'src/contexts/editorContext';
import { CODE_BLOCK_NODE_CLASS_NAME } from 'src/styles';
import { Layer } from 'src/types/layers.types';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';

import { Container, Toolbar, LanguageContainer, Language } from './BlockCodeView.styles';

const LANGUAGES = ['html', 'css', 'php', 'javascript', 'typescript', 'json', 'xml'];

interface BlockCodeViewProps extends NodeViewRendererProps {
  getPos: () => number;
  updateAttributes: (p: { language: string }) => void;
}

const BlockCodeView = ({
  node,
  getPos,
  updateAttributes,
}: BlockCodeViewProps) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const isReadOnly = useEditorContext(ctx => ctx.isReadOnly);

  const options: Array<SelectOption> = [
    {
      label: 'Copy',
      value: 'copy',
      icon: <DuplicateIcon />,
      onSelect: () => navigator.clipboard.writeText(node.content.toString()),
    },
  ];

  if (!isReadOnly) {
    options.push({
      label: 'Delete',
      value: 'delete',
      icon: <TrashIcon />,
      onSelect: () => deleteNodeRange({
        editor,
        node,
        getPos,
      }),
    });
  }

  const languages = LANGUAGES.sort((a, b) => a.localeCompare(b));
  const currentLanguage = (node.attrs.language ?? 'auto') as string;

  return (
    <Container className={CODE_BLOCK_NODE_CLASS_NAME}>
      <Toolbar $isReadOnly={!!isReadOnly}>
        <DotsMenuLayer
          layer={Layer.DropdownModalZ3}
          options={options}
          placement="bottom-end"
        />
      </Toolbar>
      <pre spellCheck={false}>
        <NodeViewContent />
      </pre>
      {!isReadOnly && (
        <LanguageContainer>
          <DropdownSelectLayer
            layer={Layer.DropdownModalZ3}
            selectedValue={currentLanguage}
            options={[
              {
                label: 'Auto',
                value: 'auto',
                onSelect: () => updateAttributes({ language: 'auto' }),
              },
              ...languages.map(lang => ({
                label: lang,
                value: lang,
                onSelect: () => updateAttributes({ language: lang }),
              })),
            ]}
          >
            <Language>
              <span contentEditable={false}>
                {currentLanguage}
              </span>
              <CaretIcon />
            </Language>
          </DropdownSelectLayer>
        </LanguageContainer>
      )}
    </Container>
  );
};

export default BlockCodeView;
