import { ThemeType } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { FC } from 'react';

import slackMessageDark from 'src/assets/slack-message-dark.png';
import slackMessage from 'src/assets/slack-message.png';
import { useGetThemeConfig } from 'src/reactives/theme.reactive';

import { SlackMessageContainer, SlackMessageEmoji } from './SlackMessage.styles';

interface Props {
  className?: string;
  emoji: string;
}

export const SlackMessage: FC<React.PropsWithChildren<Props>> = ({
  className, emoji,
}) => {
  const { colorTheme } = useGetThemeConfig();
  return (
    <SlackMessageContainer className={className}>
      <SlackMessageEmoji>
        <Emoji
          emoji={emoji}
          size={12}
        />
        1
      </SlackMessageEmoji>
      <img
        src={colorTheme === ThemeType.Nightfall ? slackMessageDark : slackMessage}
        alt="slack message"
      />
    </SlackMessageContainer>
  );
};
