import { StatusIcon } from '@cycle-app/ui';
import { useMemo } from 'react';

import { shortcuts, ShortcutStatus } from 'src/constants/shortcuts.constants';
import { useUpdateDocsStatus } from 'src/hooks/doc/useUpdateDocsStatus';
import { useHotkeyListener, UseHotkeyParams } from 'src/hooks/useHotkeyListener';
import { useStatusByDocTypes } from 'src/reactives/productStatus.reactive';
import { getSelection } from 'src/reactives/selection.reactive';

import { StyledSelectPanel } from './EditProperty.styles';

type Props = {
  onChange?: VoidFunction;
  docTypeIds?: string[];
  compatibleStatusIds?: string[];
};

export const EditStatus = ({
  onChange, docTypeIds, compatibleStatusIds,
}: Props) => {
  const statuses = useStatusByDocTypes(docTypeIds);

  const { updateDocsStatus } = useUpdateDocsStatus();

  const statusList = useMemo(() => (
    Object.values(statuses)
      .flat()
      .filter(status => (!compatibleStatusIds || compatibleStatusIds.includes(status.id)))
  ), [statuses, compatibleStatusIds]);

  const options = useMemo(() => {
    const result = [];
    let shortcutIndex = 1;
    for (const status of statusList) {
      const disabled = !status.id || !docTypeIds?.every(id => status.doctypes.edges.some(e => id === e.node.id));
      let shortcut;
      if (!disabled && shortcutIndex < 10) {
        shortcut = shortcuts[ShortcutStatus[`Status${shortcutIndex}` as ShortcutStatus]];
        shortcutIndex += 1;
      }
      result.push({
        label: status.value ?? '',
        value: status.id ?? '',
        icon: (
          <StatusIcon
            category={status.category}
            withBackground
          />
        ),
        disabled,
        shortcut,
      });
    }
    return result;
  }, [docTypeIds, statusList]);

  useHotkeyListener(statusList
    .filter(s => s.id && docTypeIds?.every(id => s.doctypes.edges.some(e => id === e.node.id)))
    .reduce<UseHotkeyParams<ShortcutStatus>>((acc, s, index) => {
    if (index > 8) return acc;
    const key = ShortcutStatus[`Status${index + 1}` as ShortcutStatus];
    acc.shortcuts.push(key);
    acc.callbacks[key] = () => {
      onChange?.();
      return updateDocsStatus(getSelection().selected, s.id);
    };
    return acc;
  },
  {
    callbacks: {},
    shortcuts: [],
  }));

  return (
    <StyledSelectPanel
      hideSearch
      options={options}
      onOptionChange={async option => {
        onChange?.();
        const docIds = getSelection().selected;
        await updateDocsStatus(docIds, option.value, false);
      }}
    />
  );
};
