import { SelectPanel, CustomPropertyInputText, TextType, CheckboxInput } from '@cycle-app/ui';
import { toIsoString } from '@cycle-app/utilities';
import { FC } from 'react';
import { useController, Control } from 'react-hook-form';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { getLastCustomFieldUsed, setLastCustomFieldUsed } from 'src/reactives';
import { CreateDocMentionFormCustomField, CreateDocMentionFormValues } from 'src/types/doc.types';
import { Layer } from 'src/types/layers.types';

import {
  Container, Label, ToggleContainer, InputContainer, Caret, InputContent,
} from './CustomField.styles';

type Definition =
  | 'AttributeDateDefinition'
  | 'AttributeEmailDefinition'
  | 'AttributeNumberDefinition'
  | 'AttributePhoneDefinition'
  | 'AttributeTextDefinition'
  | 'AttributeUrlDefinition';

const inputType: Record<Definition, TextType> = {
  AttributeDateDefinition: 'date',
  AttributeEmailDefinition: 'email',
  AttributeNumberDefinition: 'number',
  AttributePhoneDefinition: 'phone',
  AttributeTextDefinition: 'text',
  AttributeUrlDefinition: 'text',
};

const defaultValue = 'Choose from list';

type Props = {
  control: Control<CreateDocMentionFormValues>;
  field: CreateDocMentionFormCustomField;
};

export const CustomField: FC<React.PropsWithChildren<Props>> = ({
  control, field,
}) => {
  const [isVisible, {
    setFalseCallback, setTrueCallback,
  }] = useOptimizedBooleanState(false);

  const isMultiSelect = field.type === 'AttributeMultiSelectDefinition';
  const isSingleSelect = field.type === 'AttributeSingleSelectDefinition';
  const lastUsedValue = getLastCustomFieldUsed(field.id);

  const {
    field: {
      onChange, value,
    },
  } = useController({
    name: field.id,
    control,
    defaultValue: isSingleSelect
      ? field.options?.find(option => option.node.id === lastUsedValue)?.node.id
      : undefined,
  });

  if (isMultiSelect) return null;

  return (
    <Container>
      <Label>
        {field.name}
      </Label>
      {renderDropdown()}
    </Container>
  );

  function renderDropdown() {
    if (!isMultiSelect && !isSingleSelect) return renderValueContainer();

    const options = field.options?.map(option => ({
      value: option.node.id,
      label: option.node.value,
      selected: isMultiSelect && Array.isArray(field.inheritAttributeValueFromDocContext)
        ? value.map((v: { value: string }) => v.value).includes(option.node.id)
        : false,
    })) ?? [];

    return (
      <DropdownLayer
        layer={Layer.DropdownModalZ1}
        visible={isVisible}
        hide={setFalseCallback}
        placement="bottom-start"
        content={(
          <SelectPanel
            options={options}
            {...isMultiSelect ? {
              isMulti: true,
              onOptionsChange: values => {
                onChange(values);
                /* TODO: Handle the multi-select value when we will support that
                  * property type in the custom fields form
                  */
                // setLastCustomFieldUsed(field.id, values);
              },
            } : {
              onOptionChange: option => {
                onChange(option);
                setFalseCallback();
                setLastCustomFieldUsed(field.id, option.value);
              },
            }}
          />
        )}
      >
        {renderValueContainer()}
      </DropdownLayer>
    );
  }

  function renderValueContainer() {
    switch (field.type) {
      case 'AttributeDateDefinition':
        return (
          <CustomPropertyInputText
            type={inputType[field.type]}
            variant="dropdown"
            hasHelper={false}
            onInputChange={(e) => onChange(toIsoString(e.target.value))}
            values={[value || '']}
            placeholder={`Enter a ${field.name}`}
          />
        );
      case 'AttributeEmailDefinition':
      case 'AttributeNumberDefinition':
      case 'AttributePhoneDefinition':
      case 'AttributeTextDefinition':
      case 'AttributeUrlDefinition':
        return (
          <CustomPropertyInputText
            type={inputType[field.type]}
            variant="dropdown"
            hasHelper={false}
            onInputChange={(e) => onChange(e.target.value)}
            values={[value || '']}
            placeholder={`Enter a ${field.name}`}
          />
        );
      case 'AttributeCheckboxDefinition':
        return (
          <ToggleContainer>
            <CheckboxInput
              id={field.id}
              checked={value || false}
              onChange={() => onChange(!value)}
            />
          </ToggleContainer>
        );
      case 'AttributeMultiSelectDefinition': {
        const [firstValue, ...otherValues] = value;
        let label = value.length ? firstValue.label : null;
        if (label && otherValues) {
          label += ` +${otherValues.length}`;
        }

        return (
          <InputContainer onClick={setTrueCallback}>
            <InputContent>
              {label || defaultValue}
            </InputContent>
            <Caret />
          </InputContainer>
        );
      }
      case 'AttributeSingleSelectDefinition':
        return (
          <InputContainer onClick={setTrueCallback}>
            <InputContent>
              {value
                ? field.options?.find(option => option.node.id === lastUsedValue)?.node.value ?? defaultValue
                : defaultValue}
            </InputContent>
            <Caret />
          </InputContainer>
        );
      default:
        return null;
    }
  }
};
