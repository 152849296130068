import { ActionButton, Button }  from '@cycle-app/ui';
import {
  CloseIcon, AiIcon, TwitterIcon, LinkedinIcon, LinkIcon, CopyIcon, LoopLeftIcon,
} from '@cycle-app/ui/icons';
import { shareSocial } from '@cycle-app/utilities';
import { ReactNode } from 'react';

import closeTheLoopIllustration from './assets/closeTheLoop.jpg';
import generateCommunicationIllustration from './assets/generateCommunication.jpg';
import socialIllustration from './assets/social.jpg';
import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { useChangelog } from '../../hooks/releases/useChangelog';
import { useRelease } from '../../hooks/releases/useRelease';
import { useReleaseNotes } from '../../hooks/releases/useReleaseNotes';
import { useGetReleasesAction, resetReleasesAction } from '../../reactives/releases.reactive';
import { changelogUrl } from '../../utils/changelog.utils';
import { copyToClipboard } from '../../utils/clipboard.utils';
import { PortalModal } from '../PortalModal';

export const ReleaseShareModal = () => {
  const action = useGetReleasesAction();

  if (action.type !== 'shareRelease') return null;

  return <ReleaseShareModalContent releaseId={action.id} />;
};

const ReleaseShareModalContent = ({ releaseId }: { releaseId: string }) => {
  const { release } = useRelease(releaseId);
  const releaseNotes = useReleaseNotes(releaseId, false);
  const { changelog } = useChangelog();
  const productSlug = useWorkspaceContext(ctx => ctx.productSlug);

  if (!release || !release.publicRelease || !changelog) return null;

  const releaseNoteUrl = `${changelogUrl(productSlug, changelog.domain)}/release-${release.publicRelease.id}`;
  const shareReleaseContent =
`We just shipped a new version of the product.

What’s new?
${releaseNotes.list.filter(note => note.title?.trim()).map(note => `- ${note.title}`).join('\n')}

Check it out on our public Changelog.`;

  return (
    <PortalModal
      hide={resetReleasesAction}
      className="w-[640px] space-y-2"
    >
      <div className="flex items-center justify-between gap-2">
        <h2 className="text-sm font-normal text-primary">
          Share the news
        </h2>
        <ActionButton onClick={() => resetReleasesAction()}>
          <CloseIcon className="size-4" />
        </ActionButton>
      </div>

      <ShareCard
        title={(
          <div className="flex items-center gap-2">
            Close the loop
            <ComingSoonBadge />
          </div>
        )}
        description="Close the loop with customers who gave you feedback about the features you released."
        actions={(
          <Button
            disabled
            variant="outlined-alt"
            className="text-sm/6! shadow-xs"
          >
            <LoopLeftIcon />
            Close feedback loops
          </Button>
        )}
        illustration={closeTheLoopIllustration}
      />
      <ShareCard
        title={(
          <div className="flex items-center gap-2">
            Generate communication
            <ComingSoonBadge />
          </div>
        )}
        description="Draft a communication, then copy and paste it into your favorite email tool to let everyone know about your new release."
        actions={(
          <Button
            disabled
            variant="outlined-alt"
            className="text-sm/6! shadow-xs"
          >
            <AiIcon hasGradient />
            {' '}
            Write communication
          </Button>
        )}
        illustration={generateCommunicationIllustration}
      />
      <ShareCard
        title="Social"
        description="Post about your new release on social media with one click."
        actions={(
          <>
            <Button
              variant="outlined-alt"
              className="text-sm/6! shadow-xs"
              onClick={() => {
                window.open(shareSocial({
                  type: 'twitter',
                  text: shareReleaseContent,
                  url: releaseNoteUrl,
                }));
              }}
            >
              <TwitterIcon />
              {' '}
              X (Twitter)
            </Button>
            <Button
              variant="outlined-alt"
              className="text-sm/6! shadow-xs"
              onClick={() => {
                window.open(shareSocial({
                  type: 'linkedin',
                  text: shareReleaseContent,
                  url: releaseNoteUrl,
                }));
              }}
            >
              <LinkedinIcon />
              {' '}
              LinkedIn
            </Button>
          </>
        )}
        illustration={socialIllustration}
      />
      <div className="flex gap-2">
        <Button
          variant="outlined-alt"
          className="grow text-sm/6! shadow-xs"
          onClick={() => copyToClipboard({
            text: releaseNoteUrl,
            showNotification: true,
          })}
        >
          <LinkIcon />
          {' '}
          Copy public release link
        </Button>
        <Button
          variant="outlined-alt"
          className="grow text-sm/6! shadow-xs"
          onClick={() => copyToClipboard({
            text: shareReleaseContent,
            showNotification: true,
          })}
        >
          <CopyIcon />
          {' '}
          Copy release content
        </Button>
      </div>
    </PortalModal>

  );
};

const ShareCard = ({
  title, description, actions, illustration,
}: { title: ReactNode; description: ReactNode; actions: ReactNode; illustration: string }) => {
  return (
    <div className="group rounded-lg border border-grey-200 px-4 py-5 dark:border-grey-850">
      <div className="flex gap-4">
        <div className="h-20 w-24 shrink-0 overflow-clip rounded-lg bg-grey-100">
          <img
            src={illustration}
            className="size-full object-cover grayscale transition-all ease-in-out group-hover:scale-105 group-hover:grayscale-0"
            loading="lazy"
          />
        </div>

        <div>
          <h3 className="text-sm font-medium text-primary">
            {title}
          </h3>
          <p className="mt-2 text-secondary">
            {description}
          </p>
        </div>
      </div>
      <div className="mt-4 flex gap-2">
        {actions}
      </div>
    </div>
  );
};

const ComingSoonBadge = () => {
  return (
    // eslint-disable-next-line max-len
    <div className="rounded-sm bg-grey-100 from-blue-200 to-[#CFF4FF] px-1 text-xs/5 font-normal text-secondary transition-colors group-hover:bg-gradient-to-r group-hover:text-blue-900 dark:bg-grey-850 dark:from-[#334554] dark:to-[#4A3F54] group-hover:dark:text-[#E1F1FF]">
      Coming soon
    </div>
  );
};
