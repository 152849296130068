import { Color, QuoteFragment, UpdateQuoteDocument } from '@cycle-app/graphql-codegen';
import { Tooltip, Icon } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import uniq from 'lodash/uniq';
import { useState, useRef } from 'react';
import { twJoin } from 'tailwind-merge';
import { isPresent } from 'ts-is-present';

import { DocParentPanel } from 'src/components/DocParentDropdown/DocParentPanel';
import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { DropdownLayer } from 'src/components/DropdownLayer';
import { ParentPopover } from 'src/components/ParentPopover/ParentPopover';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useSafeMutation } from 'src/hooks';
import { useTippyOffsetAdapter } from 'src/hooks/useTippyOffsetAdapter';
import { getDocType, getInsightDocType, useInsightParentDocTypeIds } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocResultFromCache } from 'src/utils/cache.utils';

import { ParentText } from './VerifyQuoteModal.styles';

type Props = {
  quote: QuoteFragment;
  className?: string;
};

export const QuoteFeatureDropdown = ({
  quote, className,
}: Props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [updateQuote] = useSafeMutation(UpdateQuoteDocument);

  const parentDocTypeIds = useInsightParentDocTypeIds();
  const offsetAdapter = useTippyOffsetAdapter();

  // The suggested feature type could be unlinked from feedback
  const suggestedParentDoctypeId = quote?.suggestedParentDoctype?.id && parentDocTypeIds.includes(quote.suggestedParentDoctype.id)
    ? quote.suggestedParentDoctype.id
    : null;

  const possibleDoctypeIds = uniq([
    quote?.parent?.doctype.id,
    suggestedParentDoctypeId,
    ...parentDocTypeIds,
  ].filter(isPresent));

  const doctypeName = getDocType(possibleDoctypeIds[0])?.name.toLowerCase() ?? 'feature';

  return (
    <>
      <button
        ref={buttonRef}
        className={twJoin(
          'btn btn-tertiary btn-sm mb-2 w-fit max-w-full gap-1.5 overflow-hidden',
          className,
        )}
        onClick={() => setDropdownVisible(true)}
      >
        {quote.parent ? (
          <>
            <DocTypeIcon
              size={12}
              doctype={getDocType(quote.parent.doctype.id)}
            />
            <ParentText>
              {quote.parent.title}
            </ParentText>
          </>
        ) : (
          <>
            <Icon
              name="plus"
              className="size-3.5 text-[#967ee4]"
            />
            <ParentText $gradient>
              {`Link to recommended ${doctypeName}`}
            </ParentText>
            <AiIcon
              hasGradient
              size={14}
            />
          </>
        )}
      </button>

      {quote.parent ? (
        <ParentPopover
          parentDocId={quote.parent.id}
          reference={buttonRef}
          onClickChange={() => setDropdownVisible(true)}
          onClickUnlink={() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            updateQuote({
              variables: {
                id: quote?.id,
                parentId: null,
              },
              optimisticResponse: {
                updateQuote: {
                  ...quote,
                  parent: null,
                },
              },
            });
          }}
        />
      ) : (
        <Tooltip
          withPortal
          withWrapper={false}
          placement="top"
          content={`Change ${doctypeName}`}
          reference={buttonRef}
        />
      )}

      <DropdownLayer
        withPortal
        withWrapper={false}
        layer={Layer.DropdownModalZ1}
        placement="bottom-start"
        reference={buttonRef}
        visible={dropdownVisible}
        hide={() => setDropdownVisible(false)}
        {...offsetAdapter.tippyProps}
        content={(
          <DropdownContent
            quote={quote}
            possibleDoctypeIds={possibleDoctypeIds}
            onOptionsChange={offsetAdapter.forceUpdate}
            hide={() => setDropdownVisible(false)}
          />
        )}
      />
    </>
  );
};

const DropdownContent = ({
  quote, possibleDoctypeIds, onOptionsChange, hide,
}: {
  quote: QuoteFragment;
  possibleDoctypeIds: string[];
  onOptionsChange: () => void;
  hide: () => void;
}) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [updateQuote] = useSafeMutation(UpdateQuoteDocument);

  const insightDocType = getInsightDocType();
  if (!insightDocType) return null;

  // Possible doctype names separated by commas and "or" for the last one
  const possibleDoctypeNames = possibleDoctypeIds
    .reduce((acc, id, index) => {
      const name = getDocType(id)?.name.toLowerCase();
      if (!name) return acc;
      if (index === 0) return name;
      if (index === possibleDoctypeIds.length - 1) return `${acc} or ${name}`;
      return `${acc}, ${name}`;
    }, '');

  return (
    <DocParentPanel
      productAreaIds={quote.productArea?.id ? [quote.productArea.id] : []}
      docType={insightDocType}
      placeholder={`Link to ${possibleDoctypeNames}`}
      hide={hide}
      onOptionsChange={onOptionsChange}
      onChange={parentId => {
        const docResult = parentId ? getDocResultFromCache(parentId) : null;
        const docType = getDocType(docResult?.doctype.id);
        if (!parentId || !docResult || !docType) return;
        const parentProductArea = nodeToArray(docResult.productAreas)[0];
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        updateQuote({
          variables: {
            id: quote?.id,
            parentId,
            // Quote should always has a product area. update only if parent has product area.
            ...parentProductArea?.id && {
              productAreaId: parentProductArea.id,
            },
          },
          // Only the doctype id is useful in the optimistic response to immediately start the animation
          // when a parent changes category in the verification modal.
          optimisticResponse: {
            updateQuote: {
              ...quote,
              ...parentProductArea && {
                productArea: parentProductArea,
              },
              parent: {
                ...docResult,
                doctype: {
                  id: docType.id,
                  type: docType.type,
                },
                status: null,
                isDraft: false,
                childrenCount: 0,
                insightsCount: 0,
                docTargetsCount: 0,
                docTargetsAiCount: 0,
                quotesCount: 0,
                threadsCount: 0,
                threads: {
                  edges: [],
                },
                aiState: null,
                assignee: null,
                attributes: {
                  edges: [],
                },
                createdAt: new Date().toISOString(),
                creator: {
                  id: '',
                  email: '',
                  role: null,
                  color: Color.A,
                },
                productAreas: {
                  edges: [],
                },
              },
            },
          },
        });
      }}
      showNoneOption={false}
      possibleDoctypeIds={possibleDoctypeIds}
      suggestedParentName={quote?.suggestedParentName}
      searchVariables={{
        doctypeIds: possibleDoctypeIds,
        childDoctypeId: null,
      }}
      searchSuggestionsVariables={{
        productId,
        text: quote.content,
        doctypeIds: possibleDoctypeIds[0],
        childDoctypeId: null,
      }}
      recentSearchVariables={{
        doctypeIds: possibleDoctypeIds[0],
        childDoctypeId: null,
      }}
    />
  );
};
