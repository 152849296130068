export const IllustrationLight = () => (
  <svg
    width="221"
    height="110"
    viewBox="0 0 221 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd_1195_58016)">
      <rect
        x="18"
        y="13"
        width="160"
        height="42"
        rx="8"
        fill="white"
      />
      <rect
        x="17.5"
        y="12.5"
        width="161"
        height="43"
        rx="8.5"
        stroke="black"
        strokeOpacity="0.02"
      />
    </g>
    <rect
      x="35"
      y="30"
      width="126"
      height="8"
      rx="3"
      fill="#EFEFEF"
    />
    <g filter="url(#filter1_dd_1195_58016)">
      <rect
        x="43"
        y="45"
        width="160"
        height="42"
        rx="8"
        fill="white"
      />
      <rect
        x="42.5"
        y="44.5"
        width="161"
        height="43"
        rx="8.5"
        stroke="black"
        strokeOpacity="0.02"
      />
    </g>
    <rect
      x="60"
      y="62"
      width="126"
      height="8"
      rx="3"
      fill="#EFEFEF"
    />
    <defs>
      <filter
        id="filter0_dd_1195_58016"
        x="0"
        y="0"
        width="196"
        height="78"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="8.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1195_58016"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1195_58016"
          result="effect2_dropShadow_1195_58016"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1195_58016"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dd_1195_58016"
        x="25"
        y="32"
        width="196"
        height="78"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="8.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1195_58016"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1195_58016"
          result="effect2_dropShadow_1195_58016"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1195_58016"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
