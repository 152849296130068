import { DocAttributeFragment, CustomAttributeDefinitionFragment } from '@cycle-app/graphql-codegen';
import { Warning, SelectOption, CheckboxInput, CustomPropertyInputText, PropertyInputType } from '@cycle-app/ui';
import { nodeToArray, isUrl } from '@cycle-app/utilities';
import { ChangeEvent } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { AttributeOptionsManager } from 'src/components/AttributeOptionsManager';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useChangeDocAttributeValue } from 'src/hooks/api/mutations/useChangeDocAttributeValue';
import { useRemoveDocAttributeValue } from 'src/hooks/api/mutations/useRemoveDocAttributeValue';
import { useFullDoc } from 'src/hooks/api/useDoc';
import { useCompatibility } from 'src/hooks/useCompatibility';
import {
  customAttributeTypeData,
  getDocAttributeValue,
  getIncompatibleAttributeOptionWarningMessage,
} from 'src/utils/attributes.util';
import { getDocTypeName } from 'src/utils/docType.util';

import { InputContainer, InputTextContainer, Label, Name } from './PropertyDropdownValue.styles';

interface Props {
  docId: string;
  attributeDefinitionId: string;
  hide: VoidFunction;
  onRemoveValue?: (attributeId: string, valueId: string) => void;
  hideIncompatibleValues?: boolean;
}

export const PropertyDropdownValue = ({
  docId,
  attributeDefinitionId,
  hide,
  onRemoveValue,
  hideIncompatibleValues,
}: Props) => {
  const { doc } = useFullDoc({ docId });
  const { removeDocAttributeValue } = useRemoveDocAttributeValue();
  const { changeDocAttributeValue } = useChangeDocAttributeValue();
  const changeDocAttributeValueDebounced = useDebouncedCallback(changeDocAttributeValue, INPUT_ONCHANGE_DEBOUNCE);
  const {
    getCompatibleOptions,
    shouldDisplayWarning,
    isPropertyRequiredToBeVisible,
    canPropertyCreateOption,
  } = useCompatibility();

  const attributeDefinitions: CustomAttributeDefinitionFragment[] = nodeToArray(doc?.doctype?.attributeDefinitions);
  const attributeDefinition = attributeDefinitions.find(a => a.id === attributeDefinitionId);
  const docAttribute = nodeToArray(doc?.attributes).find(a => a.definition.id === attributeDefinitionId);

  const notCompatibleValues = (
    attributeDefinition?.__typename === 'AttributeSingleSelectDefinition' ||
    attributeDefinition?.__typename === 'AttributeMultiSelectDefinition'
  )
    ? nodeToArray(attributeDefinition.values)
      .filter(({ id }) => !getCompatibleOptions(attributeDefinition).map(a => a.node.id).includes(id))
      .map(a => a.id) : [];

  if (!doc || !attributeDefinition) return null;

  const { input: inputType } = customAttributeTypeData[attributeDefinition.__typename];
  const attributeValue = docAttribute ? getDocAttributeValue(docAttribute as DocAttributeFragment) : null;

  const isPropertyRequired = isPropertyRequiredToBeVisible(attributeDefinition);
  const canCreateOption = canPropertyCreateOption(attributeDefinition);
  const warning = !doc.isDraft && shouldDisplayWarning(attributeDefinition)
    ? getIncompatibleAttributeOptionWarningMessage(getDocTypeName(doc.doctype))
    : undefined;
  const hideClearValue = hideIncompatibleValues && isPropertyRequired;

  if (attributeDefinition.__typename === 'AttributeSingleSelectDefinition') {
    const options = nodeToArray(attributeDefinition.values)
      .filter(attribute => (!hideIncompatibleValues || !notCompatibleValues.includes(attribute.id)))
      .map(({
        id,
        value,
      }) => ({
        value: id,
        label: value,
        end: notCompatibleValues.includes(id)
          ? <Warning tooltip={getIncompatibleAttributeOptionWarningMessage(getDocTypeName(doc.doctype))} />
          : undefined,
      }));

    const selectedValue = docAttribute?.__typename === 'DocAttributeSingleSelect' ? docAttribute.selectValue?.id : undefined;

    return (
      <AttributeOptionsManager
        options={options}
        attributeDefinition={attributeDefinition}
        onSelect={async (selectedOption) => {
          hide();
          await onSelectChange(selectedOption);
        }}
        onCreate={canCreateOption ? async (newAttribute) => {
          hide();
          if (typeof newAttribute !== 'string') {
            await onSelectChange({
              label: newAttribute.value,
              value: newAttribute.id,
            });
          }
        } : undefined}
        selectedValue={selectedValue}
        onClearValue={!hideClearValue ? onClear : undefined}
        showWarningOnNoneValue={isPropertyRequired}
        warning={warning}
      />
    );
  }

  if (attributeDefinition.__typename === 'AttributeMultiSelectDefinition') {
    const selectedValuesIds: string[] = docAttribute?.__typename === 'DocAttributeMultiSelect'
      ? docAttribute.selectValues?.map(v => v.id) ?? []
      : [];

    const options: SelectOption[] = nodeToArray(attributeDefinition.values)
      .filter(attribute => (!hideIncompatibleValues || !notCompatibleValues.includes(attribute.id)))
      .map(({
        id,
        value,
      }) => ({
        value: id,
        label: value,
        selected: selectedValuesIds.includes(id),
      }));
    return (
      <AttributeOptionsManager
        isMulti
        options={options}
        attributeDefinition={attributeDefinition}
        onSelect={async (selectedOption) => {
          if (selectedOption.selected) {
            await removeValue({
              label: selectedOption.label,
              value: selectedOption.value,
            });
          } else {
            await onSelectChange(selectedOption);
          }
        }}
        onCreate={canCreateOption ? async (newAttribute) => {
          if (typeof newAttribute !== 'string') {
            await onSelectChange({
              label: newAttribute.value,
              value: newAttribute.id,
            });
          }
        } : undefined}
      />
    );
  }

  if (inputType === 'email' ||
    inputType === 'phone' ||
    inputType === 'number' ||
    inputType === 'date' ||
    inputType === 'url' ||
    inputType === 'text') {
    return (
      <InputTextContainer onClick={e => e.stopPropagation()}>
        <CustomPropertyInputText
          variant="dropdown"
          validate={getValidate(inputType)}
          label={(
            <Label>
              <Name>
                {attributeDefinition.name}
              </Name>
            </Label>
          )}
          type={inputType}
          values={[attributeValue as string]}
          onInputChange={onInputChange}
          warning={warning}
          hasHelper={false}
        />
      </InputTextContainer>
    );
  }

  if (inputType === 'checkbox') {
    return (
      <InputContainer onClick={e => e.stopPropagation()}>
        <CheckboxInput
          id={attributeDefinitionId}
          value="on"
          label={attributeDefinition.name}
          defaultChecked
          onChange={onCheckboxChange}
        />
        {warning && <Warning tooltip={warning} />}
      </InputContainer>
    );
  }

  return null;

  async function onSelectChange(selectedValue: SelectOption) {
    if (!attributeDefinition || !doc) return;

    await changeDocAttributeValue({
      attributeDefinition,
      doc,
      value: selectedValue.value,
      notCompatible: notCompatibleValues.includes(selectedValue.value),
    });
  }

  async function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    if (!attributeDefinition || !doc) return;
    if (e.target.value === '') {
      if (!docAttribute) return;
      await removeValue({
        label: docAttribute.id,
        value: docAttribute.id,
      });
    } else {
      let { value } = e.target;
      if (inputType === 'url' && !isUrl(value)) {
        value = `https://${value}`;
      }
      await changeDocAttributeValueDebounced({
        attributeDefinition,
        doc,
        value,
        notCompatible: !!warning,
      });
    }
  }

  async function onCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
    if (!attributeDefinition || !doc) return;
    hide();
    if (e.target.checked) {
      await changeDocAttributeValue({
        attributeDefinition,
        doc,
        value: e.target.checked ? 'checked' : '',
        notCompatible: !!warning,
      });
    } else {
      await removeDocAttributeValue({
        doc,
        attributeDefinition,
        valueId: attributeDefinition.id,
        notCompatible: isPropertyRequired,
      });
    }
  }

  async function onClear() {
    const selectedValue = docAttribute?.__typename === 'DocAttributeSingleSelect' ? docAttribute.selectValue : undefined;
    if (!selectedValue) return;

    await removeValue({
      value: selectedValue.id,
      label: selectedValue.value,
    });
    hide();
  }

  async function removeValue({
    label, value,
  }: SelectOption) {
    if (!attributeDefinition) return;

    onRemoveValue?.(attributeDefinition.id, label);
    await removeDocAttributeValue({
      doc,
      attributeDefinition,
      valueId: value,
      notCompatible: isPropertyRequired,
    });
  }
};

const getValidate = (inputType: PropertyInputType) => {
  if (inputType === 'url') {
    return (value: string) => (value && !isUrl(value, { strict: false }) ? 'URL format is incorrect' : null);
  }
  return undefined;
};
