import { ActionButton } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const LeftActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledActionButton = styled(ActionButton)`
  padding: 5px;
  ${p => p.disabled && css`
    color: ${p.theme.colors.actionButton.colorDisabled};
  `}
`;

export const Separator = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  height: 16px;
  width: 1px;
  background-color: ${p => p.theme.colors.docPanel.header.breadCrumbSeparatorColor};
`;

export const RightActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  button {
    padding: 5px;
  }
`;
