import { Skeleton } from '@cycle-app/ui';
import { FC, ReactNode } from 'react';
import { useTheme } from 'styled-components';
import { hasPresentKey } from 'ts-is-present';

import { InsightList } from 'src/hooks/insight/useDocInsights.types';
import { insightName } from 'src/utils/doc.util';

import { IllustrationDark } from './IllustrationDark';
import { IllustrationLight } from './IllustrationLight';
import {
  ButtonEmptyWrapper,
  ButtonWrapper,
  Container,
  EmptyStateContainer,
  Paragraph,
  ParagraphSkeleton,
  ScrollableContainer,
  Title,
  TitleSkeleton,
} from './InsightsList.styles';
import { QuoteCard } from '../QuoteCard';

type InsightListProps = {
  children?: ReactNode;
  cursor?: string | null;
  hasNextPage?: boolean;
  insights: InsightList;
  isLoading?: boolean;
  isPaginationLoading?: boolean;
  isParent?: boolean;
  loadMore?: (cursor: string) => void;
  modal?: ReactNode;
  docTargetsAiCount?: number;
};

export const InsightsList: FC<React.PropsWithChildren<InsightListProps>> = ({
  children,
  cursor,
  hasNextPage,
  insights,
  isLoading,
  isPaginationLoading,
  isParent,
  loadMore,
  modal,
  docTargetsAiCount,
}) => {
  const Illustration = useTheme().isDark ? <IllustrationDark /> : <IllustrationLight />;

  if (isLoading) {
    return (
      <Container>
        <EmptyStateContainer>
          {Illustration}
          <TitleSkeleton />
          <ParagraphSkeleton />
        </EmptyStateContainer>
      </Container>
    );
  }

  if (!insights.length) {
    return (
      <Container>
        <EmptyStateContainer>
          {Illustration}
          <Title>
            {(isParent || !docTargetsAiCount) ? `No ${insightName()} yet` : `No user-verified ${insightName()} yet`}
          </Title>
          <Paragraph>
            {insightName({
              plural: true,
              uppercase: true,
            })}
            {' '}
            let you connect feedback to
            <br />
            product delivery workflows
          </Paragraph>
          <ButtonEmptyWrapper>
            {modal}
          </ButtonEmptyWrapper>
        </EmptyStateContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ButtonWrapper>
        {modal}
      </ButtonWrapper>
      <ScrollableContainer
        isLoading={!!isLoading}
        hasMoreData={!!hasNextPage}
        loadMore={() => loadMore?.(cursor ?? '')}
        id="insights-list"
      >
        {insights
          .filter(hasPresentKey('doc'))
          .map((docTarget) => (
            <QuoteCard
              key={docTarget.doc.id}
              docId={docTarget.doc.id}
              showFeature
            />
          ))}
        {isPaginationLoading && (
          <div className="flex flex-col gap-4">
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton
                key={index}
                height={16}
              />
            ))}
          </div>
        )}
      </ScrollableContainer>
      {children}
    </Container>
  );
};
