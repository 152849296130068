import type { Editor as TipTapEditor } from '@tiptap/core';
import { FC, useCallback, KeyboardEvent, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';

import { EditorProps, Editor } from 'src/components/Editor';
import { useDoc } from 'src/hooks/api/useDoc';
import { useMe } from 'src/hooks/api/useMe';
import useRealtimeEditorConfig from 'src/hooks/doc/useRealtimeEditorConfig';
import { getEditorAi } from 'src/reactives';
import { setDocPanel, useGetDocPanel } from 'src/reactives/docPanel.reactive';
import { getLayer } from 'src/reactives/layer.reactive';
import { Layer } from 'src/types/layers.types';

import { StyledEditorSkeleton } from './DocEditor.styles';

if (!import.meta.env.VITE_WS_API_SYNC_SERVER) {
  throw new Error('The variable WS_API_SYNC_SERVER is not defined');
}

/* eslint-disable @typescript-eslint/indent */
type DocEditorProps = Pick<
  EditorProps,
  | 'parentRef'
  | 'autoFocus'
  | 'content'
  | 'applyTemplateOnDoctypeUpdate'
  | 'defaultDoctypeId'
  | 'onUpdate'
  | 'hideQuickActions'
  | 'disabledActions'
  | 'disabledShortcuts'
>;
/* eslint-enable @typescript-eslint/indent */

type Props = DocEditorProps & {
  docId?: string;
  displayLoader?: boolean;
  prefillTemplate?: boolean;
  applyTemplateOnMount?: boolean;
  draft?: boolean;
  onEditorReady?: (editor: TipTapEditor) => void;
  onEscape?: VoidFunction;
  showAddTemplate?: boolean;
  showIntegrations?: boolean;
  smallEmptyBlock?: boolean;
  hideEmptyBlock?: boolean;
  loading?: boolean;
};

const DocEditor: FC<React.PropsWithChildren<Props>> = ({
  docId,
  displayLoader = true,
  defaultDoctypeId,
  draft,
  onEditorReady,
  onEscape,
  showAddTemplate,
  showIntegrations,
  smallEmptyBlock,
  hideEmptyBlock,
  loading = false,
  ...editorProps
}) => {
  const { doc: docBase } = useDoc(docId);
  const previousDocId = useRef<string | null>();
  const { me } = useMe();
  const theme = useTheme();
  const { isEditorInit } = useGetDocPanel();

  const {
    provider,
    yDoc,
    isSync,
  } = useRealtimeEditorConfig({
    docId,
    isEnabled: !draft,
    isEditorInit,
    disableEditorOnDisconnect: true,
  });

  useEffect(() => {
    if (isSync && !isEditorInit) {
      previousDocId.current = docId;
      setDocPanel({ isEditorInit: true });
    } else if (isEditorInit && docId !== previousDocId.current) {
      previousDocId.current = docId;
      setDocPanel({ isEditorInit: false });
    }
  }, [isEditorInit, isSync, docId]);

  const cursorName = `${me.firstName} ${me.lastName}`;

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (
      e.key !== 'Escape' ||
      getLayer()[Layer.DropdownZ1] ||
      getLayer()[Layer.Dropdown] ||
      !!getEditorAi().visible
    ) return;
    e.stopPropagation();
    onEscape?.();
  }, [onEscape]);

  const isLoading = displayLoader && (!isEditorInit || !isSync || loading);

  if (!provider || isLoading || !docBase) return <StyledEditorSkeleton />;

  return (
    <div
      style={{ display: isLoading ? 'none' : 'block' }}
      {...onEscape && { onKeyDown }}
    >
      <Editor
        key={docBase?.id}
        showAddTemplate={showAddTemplate}
        showIntegrations={showIntegrations}
        doc={docBase}
        collaboration={yDoc ? { document: yDoc } : undefined}
        cursors={provider ? {
          provider,
          user: {
            color: theme.userColors.main,
            name: cursorName,
          },
        } : undefined}
        defaultDoctypeId={defaultDoctypeId}
        onEditorReady={onEditorReady}
        isDraft={draft}
        smallEmptyBlock={smallEmptyBlock}
        hideEmptyBlock={hideEmptyBlock}
        {...editorProps}
      />
    </div>
  );
};

export default DocEditor;
