import { ColorTheme } from '@cycle-app/utilities';
import styled, { css, keyframes } from 'styled-components';

import { body400 } from '../../../theme/typo';
import { BOX_SHADOW_COLOR_Z2, BOX_SHADOW_COLOR_Z1 } from '../../../utils/styles.util';
import { Label } from '../CheckboxInput/CheckboxInput.styles';

export type ToggleVariant = 'primary' | 'secondary' | 'ai';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
`;

export const Toggle = styled.div`
  background: var(--bg);
  border-radius: var(--toggle-height);
  width: calc(var(--toggle-width) + var(--dot-margin));
  height: calc(var(--toggle-height) + var(--dot-margin));
  position: relative;
  transition: background-color 0.15s ease;
  background-size: 200% 200%;
  animation: ${gradientAnimation} 5s ease infinite;
`;

export const ToggleDot = styled.div`
  background: var(--dotBg);
  position: absolute;
  top: calc(var(--toggle-height) - var(--dot-size));
  left: calc(var(--toggle-height) - var(--dot-size));
  border-radius:var(--toggle-height);
  width: var(--dot-size);
  height: var(--dot-size);
  transition: transform 0.15s ease;
  box-shadow: var(--shadowDot);
`;

export const StyledInput = styled.input<{ $variant?: ToggleVariant }>`
  display: none;

  &:checked + ${Label} {
    --bg: var(--bgChecked);

    ${ToggleDot} {
      transform: translateX(calc(var(--toggle-width) - var(--dot-size) - var(--dot-margin)));
    }
  }

  ${p => p.$variant === 'ai' && css`
    :checked + ${Label} ${LabelText} {
      background: var(--aiGradient);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 200% 200%;
      animation: ${gradientAnimation} 5s ease infinite;
    }
  `}
`;

export const LabelText = styled.span`
  ${body400};
  color: ${p => p.theme.colors.text.secondary};
  display: inline-flex;
  align-items: center;
`;

interface ContainerProps {
  $isDisabled: boolean;
  $variant?: ToggleVariant;
  $size?: 'S' | 'M';
}

export const Container = styled.div<ContainerProps>`
  --bg: ${p => p.theme.colors.background.disabled};
  --bgChecked: hsl(var(--cycle));
  --bgHover: hsl(var(--cycle600));
  --dotBg: ${p => p.theme.colors.background.white};
  --color: ${p => p.theme.colors.text.primary};
  --shadow: ${BOX_SHADOW_COLOR_Z2};
  --shadowDot: ${BOX_SHADOW_COLOR_Z1};
  --aiGradient:
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(134.84deg, #AE8FFF 3.13%, #00D2F3 57.65%, #4DED8D 107.19%);

  --toggle-height: 16px;
  --toggle-width: 26px;
  --dot-size: 14px;
  --dot-margin: 2px;

  ${p => p.$size === 'S' && `
    --toggle-height: 11.5px;
    --toggle-width: 18.5px;
    --dot-size: 10px;
    --dot-margin: 1.5px;
  `}

  ${p => p.$isDisabled && css`
    --color: ${p.theme.colors.text.disabled};
    opacity: 0.75;

    ${Label} {
      cursor: not-allowed;
    }
  `};

  ${p => !p.$isDisabled && css`
    &:hover {
      ${Toggle} {
        filter: drop-shadow(var(--shadow));
      }

      ${StyledInput}:checked + ${Label} {
        --bg: var(--bgHover);
      }
    }
  `}

  ${p => (p.$variant === 'secondary' || p.$variant === 'ai') && p.theme.isDark && `
    --bg: ${p.theme.colors.background.primary};
    --dotBg: ${ColorTheme.Grey400};
  `}

  ${p => p.$variant === 'ai' && `
    --bgChecked: var(--aiGradient);
    --bgHover: var(--aiGradient);
  `}
`;
