import { useSuspenseQuery } from '@apollo/client';
import { DocNodeDocument } from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';
import { PropsWithChildren, ReactNode, Suspense } from 'react';

import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';

import { QuoteProvider, useQuoteId } from './QuoteContext';

export const QuotePanelLoader = ({
  quoteId, children, fallback,
}: PropsWithChildren<{
  quoteId: string;
  fallback?: ReactNode;
}>) => {
  if (!quoteId) return null;
  return (
    <ErrorBoundary>
      <QuoteProvider quoteId={quoteId}>
        <BoardConfigContextProvider skip>
          <Suspense fallback={fallback}>
            <QuotePanelQuery />
            {children}
          </Suspense>
        </BoardConfigContextProvider>
      </QuoteProvider>
    </ErrorBoundary>
  );
};

const QuotePanelQuery = () => {
  useSuspenseQuery(DocNodeDocument, {
    variables: { id: useQuoteId() },
    fetchPolicy: 'cache-and-network',
  });
  return null;
};

export const QuotePanelSeleton = () => (
  <div className="flex flex-col gap-4 p-6">
    <Skeleton height={16} />
    <Skeleton height={16} />
    <Skeleton height={16} />
    <Skeleton height={16} />
  </div>
);
