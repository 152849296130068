import { DoctypeFragment, DoctypeType, SearchDocQueryVariables, SearchSuggestionDocsQueryVariables } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useCallback, forwardRef } from 'react';
import { isPresent } from 'ts-is-present';

import { DocSearch, DocSearchProps } from 'src/components/DocSearch/DocSearch';
import { useDocAttributeUpdate, useDocParent } from 'src/hooks';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useAttributesFromDoc } from 'src/hooks/api/useAttributesFromBoardConfig/useAttributesFromDoc';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { getInheritedAttributeFromDoc, getSubmittableAttributeValues } from 'src/utils/attributes.util';
import { getDocFromCache } from 'src/utils/cache.utils';

export type DocParentPanelProps = {
  docId?: string;
  docType: DoctypeFragment;
  placeholder?: string;
  hide: VoidFunction;
  onChange?: (parentId: string | null) => void;
  onOptionsChange?: VoidFunction;
  showNoneOption?: boolean;
  possibleDoctypeIds?: string[];
  searchVariables?: Partial<SearchDocQueryVariables>;
  recentSearchVariables?: Partial<SearchDocQueryVariables>;
  searchSuggestionsVariables?: Partial<SearchSuggestionDocsQueryVariables>;
  initialSearch?: string;
  showLinearAutoCreate?: boolean;
  suggestionMax?: number;
  suggestedParentName?: string | null;
  productAreaIds: string[] | undefined;
};

export const DocParentPanel = forwardRef<HTMLDivElement, DocParentPanelProps>(({
  docId, docType, placeholder, hide, onChange, showNoneOption = true, possibleDoctypeIds, searchVariables, initialSearch, showLinearAutoCreate,
  searchSuggestionsVariables, suggestionMax,
  suggestedParentName,
  recentSearchVariables,
  onOptionsChange,
  productAreaIds,
}, ref) => {
  const changeDocParent = useChangeDocParent();
  const { parent } = useDocParent({ docId });
  const docAttributes = useAttributesFromDoc(parent);
  const { addAttributeToDoc } = useDocAttributeUpdate();

  const changeParent = useCallback<NonNullable<DocSearchProps['onAdd']>>(async (parentId, options) => {
    if (!docId) {
      onChange?.(parentId);
      hide();
      return;
    }

    await changeDocParent({
      docId,
      parentId: options?.remove ? undefined : parentId,
    });

    // The newly created parent doc should inherit attributes from insight doc
    if (docType.type === DoctypeType.Insight) {
      const insightDoc = getDocFromCache(docId);
      const parentDoc = getDocFromCache(parentId);
      const parentDocType = getDocType(parentDoc?.doctype?.id);
      if (!insightDoc || !parentDoc) {
        hide();
        return;
      }
      const { common } = getInheritedAttributeFromDoc({
        originAttributes: nodeToArray(insightDoc.attributes),
        targetAttributesDefinitions: nodeToArray(parentDocType?.attributeDefinitions),
      });

      // Only add attributes that are not already on the parent doc
      const parentAttributes = nodeToArray(parentDoc.attributes);
      const commonFiltered = common.filter(c => !parentAttributes.some(p => p.definition.id === c.definition.id));

      await Promise.all(getSubmittableAttributeValues(commonFiltered).map(c => addAttributeToDoc({
        attributeDefinitionId: c.attributeDefinitionId,
        docId: parentDoc.id,
        value: c.value,
      })));
    }

    hide();
  }, [addAttributeToDoc, changeDocParent, docId, docType.type, hide, onChange]);

  const onRemoveParent = useCallback(() => {
    if (!docId) {
      onChange?.(null);
      hide();
      return;
    }

    if (!parent?.id) return;
    changeParent(parent.id, { remove: true });
  }, [docId, parent?.id, changeParent, onChange, hide]);

  const possibleParentDocTypes = useMemo(() => {
    const allParents = nodeToArray(docType?.parents);
    if (!possibleDoctypeIds) return allParents;
    return possibleDoctypeIds.map(id => allParents.find(p => p.id === id)).filter(isPresent);
  }, [docType?.parents, possibleDoctypeIds]);

  return (
    <DocSearch
      ref={ref}
      inheritedAttributes={docAttributes}
      onAdd={changeParent}
      placeholder={placeholder}
      onRemove={onRemoveParent}
      childDoctypeId={docType.id}
      possibleDoctypes={possibleParentDocTypes}
      showNoneOption={(!!parent || !docId) && showNoneOption}
      sourceId={parent?.source?.id}
      customerId={docType?.customer ? parent?.customer?.id : undefined}
      suggestionDocId={docId}
      searchVariables={searchVariables}
      recentSearchVariables={recentSearchVariables}
      searchSuggestionsVariables={searchSuggestionsVariables}
      initialSearch={initialSearch}
      showLinearAutoCreate={showLinearAutoCreate}
      suggestionMax={suggestionMax}
      suggestedName={suggestedParentName}
      onOptionsChange={onOptionsChange}
      productAreaIds={productAreaIds}
    />
  );
});
