import { BoardWithMinimalConfigAndDraftConfigIdFragment, SectionType } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { BoardLinkSectionField } from 'src/app/Main/Board/BoardLinkSectionField';
import { BoardConfigSkeletons } from 'src/components/BoardConfigForm/BoardConfigSkeletons';
import { BoardEditForm } from 'src/components/BoardEditCommonModal/BoardEditForm';
import { Header, Title, CloseButtonStyled } from 'src/components/DialogModal/DialogModal.styles';
import { InboxBoardConfigFields } from 'src/components/InboxBoardConfigFields';
import useDraftBoardConfigMutations from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import useDraftBoardConfig from 'src/hooks/api/useDraftBoardConfig';
import { useBoardLinkSectionField } from 'src/hooks/boards/useBoardLinkSectionField';
import { useNavigate } from 'src/hooks/useNavigate';
import { useGetPermission } from 'src/reactives';
import { useGetBoardConfigModal } from 'src/reactives/boardConfig.reactive';
import { getBoardSlug } from 'src/utils/slug.util';

import { InboxDeleteView } from './InboxDeleteView';
import { Modal, ModalFooter, ModalActions } from './InboxViewSettings.styles';

export type InboxEditViewModalProps = {
  board: BoardWithMinimalConfigAndDraftConfigIdFragment;
  draftBoardConfigId: string;
};

export const InboxViewSettingsModal = ({
  board, draftBoardConfigId,
}: InboxEditViewModalProps) => {
  const { disabled } = useGetBoardConfigModal();
  const { canDeleteView } = useGetPermission();
  const { navigateToInbox } = useNavigate();
  const boardMutations = useBoardMutations();
  const {
    revertUnsavedDraftConfig,
    publishBoardConfig,
    loading,
  } = useDraftBoardConfigMutations(draftBoardConfigId, board.id);

  const isLoadingDraftBoardConfig = !useDraftBoardConfig(board.id).data?.node?.draftBoardConfig;

  const revert = async () => {
    if (disabled || isLoadingDraftBoardConfig || loading.publish || boardMutations.loading) return;
    await revertUnsavedDraftConfig();
  };

  const {
    boardLinkSectionId,
    setBoardLinkSectionId,
    updateBoardLink,
  } = useBoardLinkSectionField(board.id);

  return (
    <Modal hide={revert}>
      <Header>
        <Title>
          Feedback view settings
        </Title>
        <CloseButtonStyled
          size="L"
          onClick={revert}
        >
          <CloseIcon />
        </CloseButtonStyled>
      </Header>

      {disabled || isLoadingDraftBoardConfig ? <BoardConfigSkeletons /> : (
        <BoardEditForm
          sectionType={SectionType.Feedback}
          defaultValues={board}
          focusNameOnMount
          onSubmit={async (formData, isDirty) => {
            const [publishResult, boardResult] = await Promise.all([
              publishBoardConfig(),
              ...isDirty ? [boardMutations.updateBoard({
                ...formData,
                boardId: board.id,
              })] : [],
            ]);
            if (publishResult.errors || boardResult?.errors) return;
            updateBoardLink();
            const slug = getBoardSlug(boardResult?.data?.updateBoard);
            if (slug) navigateToInbox(slug);
          }}
        >
          <BoardLinkSectionField
            value={boardLinkSectionId}
            onChange={setBoardLinkSectionId}
          />

          <InboxBoardConfigFields boardId={board.id} />

          <ModalFooter>
            <ModalActions>
              {canDeleteView && <InboxDeleteView boardId={board.id} />}
            </ModalActions>

            <ModalActions>
              <Button
                size="M"
                variant="secondary"
                onClick={revert}
                isLoading={loading.revertUnsaved}
              >
                Cancel
              </Button>

              <Button
                size="M"
                type="submit"
                isLoading={boardMutations.loading || loading.publish}
              >
                Save
              </Button>
            </ModalActions>
          </ModalFooter>
        </BoardEditForm>
      )}
    </Modal>
  );
};
