import { CreateReleaseNotePosition } from '@cycle-app/graphql-codegen';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

import { DocSearchResult } from 'src/components/DocSearchDropdown/DocSearchResult';
import { DropdownLayer } from 'src/components/DropdownLayer';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useReleaseNotesContext } from 'src/contexts/releaseNotesContext';
import { useNavigate, useOptimizedBooleanState } from 'src/hooks';
import { useCreateReleaseNote } from 'src/hooks/releases/useCreateReleaseNote';
import { useGetDocTypesWithReleaseProperty } from 'src/reactives/docTypes.reactive';
import { resetReleasesAction } from 'src/reactives/releases.reactive';

import { Card } from './ReleaseNoteCard.styles';
import { Input, DropdownContainer } from './ReleaseNoteForm.styles';
import { ReleaseNoteSkeleton } from './ReleaseNoteSkeleton';

export const ReleaseNoteForm = ({
  isOther, isOpen, position,
}: {
  isOther: boolean;
  isOpen: boolean;
  position: CreateReleaseNotePosition;
}) => {
  const { navigateToReleaseNote } = useNavigate();

  const {
    createReleaseNote, isCreatingReleaseNote,
  } = useCreateReleaseNote({ position }, data => {
    navigateToReleaseNote(data.release.id, data.id);
  });

  if (isCreatingReleaseNote) return <ReleaseNoteSkeleton />;
  if (!isOpen) return null;
  return (
    <ReleaseNoteFormActive
      isOther={isOther}
      position={position}
      createReleaseNote={createReleaseNote}
    />
  );
};

const ReleaseNoteFormActive = ({
  isOther, position, createReleaseNote,
}: {
  isOther: boolean;
  position: CreateReleaseNotePosition;
  createReleaseNote: ReturnType<typeof useCreateReleaseNote>['createReleaseNote'];
}) => {
  const docTypes = useGetDocTypesWithReleaseProperty();
  const releaseId = useReleaseNotesContext(ctx => ctx.releaseId);

  const [isDropdownVisible, {
    setTrueCallback: openDropdown,
    setFalseCallback: closeDropdown,
  }] = useOptimizedBooleanState(false);

  const hide = () => {
    closeDropdown();
    setTimeout(resetReleasesAction);
  };

  const [search, setSearch] = useState('');
  const [searchDebounced] = useDebounce(search, INPUT_ONCHANGE_DEBOUNCE);

  const possibleDocTypes = Object.values(docTypes);

  return (
    <DropdownLayer
      visible={isDropdownVisible}
      hide={hide}
      placement="bottom-start"
      offsetY={6}
      closingArea={false}
      content={(
        <DropdownContainer>
          <DocSearchResult
            productAreaIds={undefined}
            possibleDoctypes={possibleDocTypes}
            showAddSelectOption
            search={searchDebounced}
            searchVariables={{ doctypeIds: possibleDocTypes.map(d => d.id) }}
            filterDoc={doc => !doc.releaseNote}
            onAdd={docId => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              createReleaseNote({
                docId,
                releaseId,
                isOther,
                position,
              });
              hide();
            }}
          />
        </DropdownContainer>
      )}
    >
      <Card>
        <Input
          autoFocus
          onFocus={openDropdown}
          placeholder="Search…"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </Card>
    </DropdownLayer>
  );
};
