import { DocumentEmptyIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { getFileSizeWithUnit } from 'src/utils/files.util';

import { Container, Heading, Text, IconContainer, Image } from './FilePreview.styles';

type Props = {
  file: File;
};
const FilePreview: FC<React.PropsWithChildren<Props>> = ({ file }) => {
  if (file.type.includes('image')) {
    return (
      <Image
        src={URL.createObjectURL(file)}
        alt="temporary-preview"
      />
    );
  }

  return (
    <Container>
      <IconContainer>
        <DocumentEmptyIcon />
      </IconContainer>
      <Heading>
        {file.name}
      </Heading>
      <Text>
        {getFileSizeWithUnit(file.size)}
      </Text>
    </Container>
  );
};

export default FilePreview;
