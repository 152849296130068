import { FC } from 'react';
import { useTheme } from 'styled-components';

import { NewBadge } from 'src/components/NewBadge';
import { getNewFeaturesToasterId } from 'src/constants/features.constants';
import { HELP_URL_CALL_RECORDING } from 'src/constants/help.constants';
import { useProductAddOn } from 'src/hooks';
import { setLimitationsModal } from 'src/reactives';
import { setFeaturesShowed } from 'src/reactives/features.reactive';
import { Feature } from 'src/types/features.types';
import { closeToaster } from 'src/utils/toasters.utils';

import {
  ImageContainer, Image, Header, Title, Description, Buttons, Cta,
} from './FeatureToasterContent.styles';

const recordingLight = '/images/recording-avatars-light.png';
const recordingDark = '/images/recording-avatars-dark.png';

const MeetingIntegration = () => {
  const { isEnabled } = useProductAddOn('MEETINGS');
  const { isDark } = useTheme();
  return (
    <div>
      <ImageContainer>
        <Image src={isDark ? recordingDark : recordingLight} />
      </ImageContainer>
      <Header>
        <NewBadge size="M" />
        <Title>
          Introducing Call recording
        </Title>
      </Header>
      <Description>
        Record your calls and automatically fetch transcripts as Cycle feedback. It works with Zoom, Google Meet & Microsoft Teams.
      </Description>
      {isEnabled && (
        <Buttons>
          <Cta
            size="M"
            variant="secondary"
            onClick={() => {
              setFeaturesShowed(Feature.MeetingIntegration);
              closeToaster(getNewFeaturesToasterId(Feature.MeetingIntegration));
              setLimitationsModal({
                action: 'USE_ADD_ON',
                brand: 'MEETINGS',
              });
            }}
          >
            Request access
          </Cta>
          <Cta
            size="M"
            variant="secondary"
            onClick={() => {
              window.open(HELP_URL_CALL_RECORDING, '_blank');
            }}
          >
            Learn more
          </Cta>
        </Buttons>
      )}
    </div>
  );
};

export const featureToasterContents: Partial<Record<Feature, FC>> = {
  [Feature.MeetingIntegration]: MeetingIntegration,
};
