import { Color, SectionType } from '@cycle-app/graphql-codegen';
import { FC, useEffect, ReactNode } from 'react';
import { Controller, FormState, useForm } from 'react-hook-form';

import { BoardIconInput } from '../BoardIconInput';
import {
  Form,
  Row,
  InputStyled,
  Label,
} from './BoardEditCommonModal.styles';

type BoardEditDefaultValues = {
  name?: string;
  description?: string | null;
  emoji?: string | null;
  color?: Color | null;
};

export type BoardEditCommonFormData = {
  name: NonNullable<BoardEditDefaultValues['name']>;
  description: NonNullable<BoardEditDefaultValues['description']>;
  emoji: BoardEditDefaultValues['emoji'];
  color: BoardEditDefaultValues['color'];
};

export type BoardEditFormProps = {
  defaultValues?: BoardEditDefaultValues | null;
  focusNameOnMount?: boolean;
  focusDescriptionOnMount?: boolean;
  onSubmit: (data: BoardEditCommonFormData, isDirty: boolean) => void;
  isIconHidden?: boolean;
  isNameDisabled?: boolean;
  showDescription?: boolean;
  isDisabled?: boolean;
  children?: ReactNode | ((formState: FormState<BoardEditCommonFormData>) => ReactNode);
  sectionType: SectionType | undefined;
};

export const BoardEditForm: FC<React.PropsWithChildren<BoardEditFormProps>> = ({
  children,
  defaultValues,
  focusDescriptionOnMount,
  focusNameOnMount,
  isDisabled = false,
  isIconHidden,
  isNameDisabled = false,
  sectionType,
  onSubmit,
  showDescription = true,
}) => {
  const {
    register,
    control,
    handleSubmit,
    setFocus,
    formState,
    setValue,
    watch,
  } = useForm<BoardEditCommonFormData>({
    defaultValues: {
      name: defaultValues?.name || '',
      description: defaultValues?.description || '',
      emoji: defaultValues?.emoji,
      color: defaultValues?.color,
    },
  });

  useEffect(() => {
    if (focusNameOnMount) {
      setFocus('name');
    } else if (focusDescriptionOnMount) {
      setFocus('description');
    }
  }, [focusDescriptionOnMount, focusNameOnMount, setFocus]);

  const { isDirty } = formState;

  const color = watch('color');

  return (
    <Form
      onSubmit={handleSubmit((data) => {
        // Investigate why changing the color does not set isDirty to true.
        if (!formState.isSubmitting) onSubmit(data, isDirty || defaultValues?.color !== color);
      })}
    >
      <section>
        <Row>
          {!isIconHidden && (
            <Controller
              name="emoji"
              control={control}
              render={({
                field: {
                  value,
                  onChange,
                },
              }) => (
                <BoardIconInput
                  sectionType={sectionType}
                  color={color}
                  icon={value}
                  onColorChange={newColor => setValue('color', newColor)}
                  onIconChange={onChange}
                />
              )}
            />
          )}
          <div style={{ flexGrow: 1 }}>
            <Label>
              Name
            </Label>
            <InputStyled
              id="boardEdit-name"
              placeholder="Your view name"
              autoFocus
              disabled={isNameDisabled}
              {...register('name', { required: true })}
            />
          </div>
        </Row>
      </section>

      {showDescription && (
        <section>
          <Label>
            Description
          </Label>
          <InputStyled
            id="boardEdit-description"
            placeholder="Your view description"
            disabled={isDisabled}
            {...register('description')}
          />
        </section>
      )}

      {typeof children === 'function' ? children(formState) : children}
    </Form>
  );
};
