import { DocBaseFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { Spinner, Shortcut, Toaster } from '@cycle-app/ui';
import { DownIcon, UpIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { getDocSlug } from '@cycle-app/utilities';
import {
  FC, useCallback, useRef, useReducer, useEffect, ReactNode,
} from 'react';

import { DocProcessButton } from 'src/components/DocProcessButton';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ErrorPage } from 'src/components/ErrorPage';
import { Size } from 'src/components/ErrorPage/ErrorPage.types';
import { VerifyQuotes, VerifyQuotesContainer } from 'src/components/VerifyQuotes';
import { PageId, routing } from 'src/constants/routing.constant';
import { shortcuts, ShortcutDocPanel } from 'src/constants/shortcuts.constants';
import { useDoc, useLocation, useRouteMatch } from 'src/hooks';
import { useBoard } from 'src/hooks/api/useBoard';
import { useDocShortcutListener } from 'src/hooks/shortcuts/useDocPanelShortcutListener';
import { useInitLayer } from 'src/hooks/state/useInitLayer';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { useIsDocInBoard } from 'src/hooks/useIsDocInBoard';
import { useNavigate } from 'src/hooks/useNavigate';
import { useDocId } from 'src/hooks/usePathParams';
import { usePrevNextDoc } from 'src/hooks/usePrevNextDoc';
import { getEditorAi } from 'src/reactives';
import { getZoom } from 'src/reactives/zoom.reactve';
import { Layer } from 'src/types/layers.types';
import { isFeedback } from 'src/utils/docType.util';
import { getOpenedLayersAbove } from 'src/utils/layers.util';

import { DocPanelBaseProps } from '../DocPanel.types';
import {
  Container,
  MainSection,
  Overlay,
  ToasterContainer,
  StyledEmoji,
  PrevNextActions,
  StyledActionButton,
  PanelsContainer,
} from './DocPanelBoardPage.styles';
import { DocPanelContent } from '../DocPanelContent/DocPanelContent';
import DocPanelHeader from '../DocPanelHeader/DocPanelHeader';
import DocPanelRightPanel from '../DocPanelRightPanel/DocPanelRightPanel';
import { FeatureDocPanelContent, FeatureDocReleaseNotePanel, FeatureDocReleaseNotePanelContent } from '../FeatureDocPanelContent';

interface Props extends Omit<DocPanelBaseProps, 'doc'> {
  widthSidebar: number;
  children?: ReactNode;
}

const DocPanelBoardPage: FC<React.PropsWithChildren<Props>> = ({
  widthSidebar,
  getDropzonePropsCover,
  isDraggingCover,
  isUploadingCover,
  onOpenCoverInputFile,
  onTitleUpdated,
  children,
}) => {
  const docId = useDocId();
  const {
    doc: docBase, loading: isLoadindDocBase,
  } = useDoc(docId);

  const location = useLocation();
  useInitLayer(Layer.DocPanel);
  useDocShortcutListener(docBase);
  const [expanded, expand] = useReducer(() => true, false);
  const {
    navigate, navigateToDocPanelParent,
  } = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);

  const [visible, hide] = useReducer(() => false, true);

  const goBack = useCallback(() => {
    hide();
    setTimeout(navigateToDocPanelParent);
  }, [navigateToDocPanelParent]);

  const onExpand = useCallback(() => {
    if (!docBase) return;
    expand();
    setTimeout(() => {
      navigate(PageId.DocFullPage, { docSlug: getDocSlug(docBase) });
    }, 100);
  }, [navigate, docBase]);

  const {
    navigateToPrevDoc, navigateToNextDoc, isFirstDoc, isLastDoc, loadingNextDoc, shouldLoadMore, loadMore,
  } = usePrevNextDoc(docBase);

  useEffect(() => {
    if (shouldLoadMore) loadMore();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadMore]);

  const getIsDocInBoard = useIsDocInBoard();
  const isDocInBoard = location.state?.isDocRelative || getIsDocInBoard(docBase) !== false;

  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const prevButtonRef = useRef<HTMLButtonElement>(null);

  const focusOnPrevButton = () => {
    prevButtonRef.current?.focus();
  };

  const focusOnNextButton = () => {
    nextButtonRef.current?.focus();
  };

  const onEscapeEdition = () => {
    if (getEditorAi().visible || getZoom().src || getOpenedLayersAbove(Layer.DocPanel)) return;
    if (isLastDoc && isFirstDoc) goBack();
    if (isLastDoc) {
      focusOnPrevButton();
    } else {
      focusOnNextButton();
    }
  };

  const isNavigationDisabled = (
    location.state?.isDocRelative &&
    // Add the insight id in `docRelativeTo` on open. This is be convenient in the prev/next navigation.
    !location.state.docRelativeTo
  ) || !isDocInBoard;
  const isPrevDocDisabled = isNavigationDisabled || isFirstDoc;
  const isNextDocDisabled = isNavigationDisabled || isLastDoc;

  useAppHotkeys('k', () => {
    focusOnPrevButton();
    navigateToPrevDoc();
  }, { enabled: () => !isPrevDocDisabled });

  useAppHotkeys('j', () => {
    focusOnNextButton();
    navigateToNextDoc();
  }, { enabled: () => !isNextDocDisabled });

  if (!visible || !docId) return null;

  const noDoc = !docBase && !isLoadindDocBase;
  const isFeedbackDoctype = isFeedback(docBase?.doctype);

  return (
    <>
      <Overlay onClick={goBack} />

      <Container
        ref={containerRef}
        $widthSidebar={widthSidebar}
        $expanded={expanded}
      >
        {noDoc ? (
          <ErrorPage
            size={Size.SMALL}
            message="Oops, this doc no longer exists"
          />
        ) : (
          <ErrorBoundary>
            <MainSection>
              <DocPanelHeader
                onClose={goBack}
                onExpand={onExpand}
                onOpenCoverInputFile={onOpenCoverInputFile}
                onDeleteDoc={goBack}
                prevNextButtons={(
                  <PrevNextActions isNotInBoard={!isDocInBoard}>
                    <StyledActionButton
                      size="L"
                      onClick={() => {
                        navigateToPrevDoc();
                      }}
                      // eslint-disable-next-line no-nested-ternary
                      tooltip={!isDocInBoard
                        ? <NotInBoardMessage doc={docBase} />
                        : (isPrevDocDisabled ? null : (
                          <Shortcut
                            keys={shortcuts[ShortcutDocPanel.PrevDoc]}
                            label="Previous"
                          />
                        ))}
                      tooltipPlacement="bottom"
                      disabled={isPrevDocDisabled}
                      ref={prevButtonRef || !isDocInBoard}
                    >
                      <UpIcon />
                    </StyledActionButton>
                    <StyledActionButton
                      size="L"
                      onClick={() => {
                        navigateToNextDoc();
                      }}
                      // eslint-disable-next-line no-nested-ternary
                      tooltip={!isDocInBoard
                        ? <NotInBoardMessage doc={docBase} />
                        : (isNextDocDisabled ? null : (
                          <Shortcut
                            keys={shortcuts[ShortcutDocPanel.NextDoc]}
                            label="Next"
                          />
                        ))}
                      tooltipPlacement="bottom"
                      disabled={isNextDocDisabled}
                      ref={nextButtonRef}
                    >
                      {!isLastDoc && loadingNextDoc ? <Spinner /> : <DownIcon />}
                    </StyledActionButton>
                  </PrevNextActions>
                )}
                {...docBase && isFeedbackDoctype && docBase.status && {
                  processButton: (
                    <DocProcessButton
                      docId={docBase.id}
                      docStatusCategory={docBase.status.category}
                      onCompleted={isLastDoc ? goBack : navigateToNextDoc}
                      parent="doc-panel"
                      quotesCount={docBase.quotesCount ?? 0}
                    />
                  ),
                }}
              />

              {isFeedback(docBase?.doctype) ? (
                <DocPanelContent
                  containerRef={containerRef}
                  getDropzonePropsCover={getDropzonePropsCover}
                  isDraggingCover={isDraggingCover}
                  isUploadingCover={isUploadingCover}
                  onTitleUpdated={onTitleUpdated}
                  onOpenCoverInputFile={onOpenCoverInputFile}
                  onEscapeEdition={onEscapeEdition}
                  focusOnMount={false}
                />
              ) : (
                <FeatureDocPanelContent
                  docId={docId}
                  containerRef={containerRef}
                  getDropzonePropsCover={getDropzonePropsCover}
                  isDraggingCover={isDraggingCover}
                  isUploadingCover={isUploadingCover}
                  onTitleUpdated={onTitleUpdated}
                  onOpenCoverInputFile={onOpenCoverInputFile}
                  onEscapeEdition={onEscapeEdition}
                  focusOnMount={false}
                />
              )}

              {docBase && !isDocInBoard && (
                <ToasterContainer>
                  <Toaster
                    icon={<InfoIconOutline />}
                    title={<NotInBoardMessage doc={docBase} />}
                  />
                </ToasterContainer>
              )}
            </MainSection>

            {docBase && (
              <PanelsContainer>
                {isFeedbackDoctype && (
                  <VerifyQuotesContainer docPanelRef={containerRef}>
                    <VerifyQuotes doc={docBase} />
                  </VerifyQuotesContainer>
                )}

                {!isFeedbackDoctype && docBase.releaseNote && (
                  <FeatureDocReleaseNotePanel
                    containerRef={containerRef}
                  >
                    <FeatureDocReleaseNotePanelContent
                      releaseNote={docBase.releaseNote}
                    />
                  </FeatureDocReleaseNotePanel>
                )}

                <DocPanelRightPanel doc={docBase} />
              </PanelsContainer>
            )}

            {children}
          </ErrorBoundary>
        )}
      </Container>
    </>
  );
};

export default DocPanelBoardPage;

const NotInBoardMessage = ({ doc }: { doc: DocBaseFragment | undefined | null }) => {
  const board = useBoard();
  const matchCustomView = useRouteMatch(routing[PageId.Board]);
  const emoji = !!matchCustomView && (
    <StyledEmoji
      inline
      emoji={board?.emoji}
      size={12}
    />
  );
  if (!doc) return null;

  return (
    // Non-breaking space and pre-line guarantees that the emoji will be on the same line as the board name
    <span style={{ whiteSpace: 'pre-line' }}>
      {`${getDocName(doc)} is no longer in `}
      {emoji}
      {!!emoji && '\u00A0'}
      {board?.name}
    </span>
  );
};

const getDocName = (doc: DocBaseFragment) => {
  switch (doc.doctype.type) {
    case DoctypeType.Feedback:
      return 'This feedback';
    case DoctypeType.Insight:
      return 'This insight';
    default:
      return doc._docKey;
  }
};
