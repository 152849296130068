import { Tooltip, InfiniteScroll } from '@cycle-app/ui';
import { RecordOutlineIcon, LinkIcon, CheckIcon, CloseIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState, memo } from 'react';

import { DropzoneIcon, DropzoneBackground, useDropzoneProps } from 'src/components/Dropzone';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useIsDropIslandEnabled } from 'src/hooks/useIsDropIslandEnabled';
import { usePrevious } from 'src/hooks/usePrevious';
import { useZoomableProps } from 'src/hooks/useZoomableProps';
import { discardError, useGetDocImport } from 'src/reactives/docImport.reactive';
import { setFeaturesShowed, useGetFeatures } from 'src/reactives/features.reactive';
import { Feature } from 'src/types/features.types';
import { Layer } from 'src/types/layers.types';
import { getTitle, getCliboardFileName } from 'src/utils/dropzone.utils';
import { getFileSizeWithUnit, getFileBaseName, getFileExtension } from 'src/utils/files.util';

import { ClipboardButton } from './ClipboardButton';
import { containerVariants, contentVariants, listVariants } from './DropzoneMini.motion';
import {
  Hover, Container, Content,
  ActiveState, EmptyState,
  Infos, Title, Description,
  Buttons, PrimaryButton, SecondaryButton,
  RecorderContainer, ClipboardContainer,
  ImagePreview, Thumbnail,
  StyledLinkInput,
  ListContent, ListContainer, Scrollable,
  StyledFileCard,
  Border, MainButton,
} from './DropzoneMini.styles';
import { IslandLoader } from './IslandLoader';
import { MiniRecorder } from './MiniRecorder';
import { UploadButton } from './UploadButton';
import { CreateDocButton, DiscardDocButton } from '../Dropzone/DropzoneButtons';
import {
  DiscardButton,
  Title as FileTitle,
  Name as FileName,
  Infos as FileInfos,
} from '../Dropzone/FileCard.styles';

const transition = {
  type: 'spring',
  damping: 30,
  mass: 1.2,
  stiffness: 210,
};

const NOTIFICATION_DURATION = 3000;

export const DropzoneMini = memo(() => {
  const isEnabled = useIsDropIslandEnabled();
  return isEnabled ? <DropzoneMiniEnabled /> : null;
});

const DropzoneMiniEnabled = memo(() => {
  const {
    fileRejections, loadingFiles,
  } = useGetDocImport();
  const previousLoadingFiles = usePrevious(loadingFiles);

  const [isAutoClose, setIsAutoClose] = useState(true);
  const [isCloseDisabled, setIsCloseDisabled] = useState(false);

  // Open the dropzone when there are pending files and the document is visible
  const [isNotified, setIsNotified] = useState(false);
  useEffect(() => {
    const notify = () => setIsNotified(true);
    if (previousLoadingFiles && loadingFiles.length < previousLoadingFiles.length) {
      if (document.visibilityState === 'visible') {
        notify();
      } else {
        document.addEventListener('visibilitychange', notify);
      }
    }
    return () => {
      document.removeEventListener('visibilitychange', notify);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingFiles]);

  // Close the dropzone after a delay when notified or when close is disabled
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    if (isNotified || (isCloseDisabled && isAutoClose)) {
      timeoutId = setTimeout(() => {
        setIsNotified(false);
        setIsCloseDisabled(false);
      }, NOTIFICATION_DURATION);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isNotified, isCloseDisabled, isAutoClose]);

  const [isHover, setIsHover] = useState(false);

  const {
    isLoading,
    isDetails,
    isDragActive,
    isMaxFilesError,
    onDrop,
    onMouseEnter,
    getRootProps,
    getInputProps,
    open,
    rootRef,
    handleFiles,
    handleLink,
    isLoadingDocImport,
    hasMoreDocImport,
    loadMoreDocImport,
    docs,
  } = useDropzoneProps({
    isMini: true,
  });

  // Exit discover mode on click or after 10 seconds
  const discover = useGetFeatures().queue.includes(Feature.UniversalFeedbackCapture);
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    const rootEl = rootRef.current;
    const exitDiscover = () => setFeaturesShowed(Feature.UniversalFeedbackCapture);
    if (discover) {
      rootEl?.addEventListener('click', exitDiscover);
      timeoutId = setTimeout(() => {
        exitDiscover();
        rootEl?.removeEventListener('click', exitDiscover);
      }, 10_000);
    }
    return () => {
      rootEl?.removeEventListener('click', exitDiscover);
      clearTimeout(timeoutId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discover]);

  const [isRecordOpen, setIsRecordOpen] = useState(false);
  const [isLinkOpen, setIsLinkOpen] = useState(false);

  const [imageBlob, setImageBlob] = useState<Blob | null>(null);

  const isOpen = (discover && !isLoading) || isHover || isDragActive || isMaxFilesError || isNotified || isCloseDisabled || isRecordOpen;

  const getZoomableProps = useZoomableProps({
    onZoomOut: () => setIsAutoClose(true),
  });

  const pendingCount = fileRejections.length + docs.length;

  return (
    <Hover
      initial="rest"
      animate={isOpen ? 'hover' : 'rest'}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={(e) => {
        setIsHover(false);
        setIsNotified(false);

        // Prevent upload on key press if the focused element is in the dropzone
        const { activeElement } = document;
        if (!(activeElement instanceof HTMLElement)) return;
        if (!(e.target instanceof HTMLElement)) return;
        if (!e.target.contains(activeElement)) return;
        activeElement.blur();
      }}
      style={{
        zIndex: mappingZindex[Layer.Sidebar] + 1,
      }}
    >
      <Container
        {...getRootProps({
          onMouseEnter,
          onDrop: (e) => onDrop(e, true),
        })}
        initial="rest"
        animate={isOpen ? 'hover' : 'rest'}
        variants={containerVariants}
        transition={transition}
        $isHover={isOpen}
        $isLoading={isLoading}
      >
        {isOpen && <Border $discover={discover} />}
        <Content
          variants={contentVariants}
          transition={transition}
        >
          {isOpen && (
            <DropzoneBackground
              rootRef={rootRef}
              isDragActive={false}
              isLoading={isLoading}
              isError={isMaxFilesError}
              isMousePositionEnabled={false}
            />
          )}

          {!(isMaxFilesError || isDragActive || isLoading) && !isRecordOpen && !isLinkOpen && !imageBlob && (
            <EmptyState>
              <Infos>
                <Title>
                  {isDetails
                    ? 'Feedback capture done'
                    : 'Drop anything here to capture feedback'}
                </Title>
                <Description>
                  {isDetails
                    ? `${pendingCount} pending ${pendingCount > 1 ? 'files' : 'file'}`
                    : 'Docs, images, videos, audio files, links & more'}
                </Description>
              </Infos>
              <Buttons>
                <Tooltip
                  withPortal
                  placement="bottom"
                  content="Upload"
                >
                  <UploadButton
                    {...getInputProps()}
                    disabled={!isOpen}
                    open={open}
                  />
                </Tooltip>

                <Tooltip
                  withPortal
                  withWrapper={false}
                  placement="bottom"
                  content="Record"
                >
                  <MainButton
                    disabled={!isOpen}
                    onClick={e => {
                      e.stopPropagation();
                      setIsRecordOpen(true);
                    }}
                  >
                    <RecordOutlineIcon size={16} />
                  </MainButton>
                </Tooltip>

                <Tooltip
                  withPortal
                  withWrapper={false}
                  placement="bottom"
                  content="Link"
                >
                  <MainButton
                    disabled={!isOpen}
                    onClick={e => {
                      e.stopPropagation();
                      setIsLinkOpen(true);
                    }}
                  >
                    <LinkIcon size={16} />
                  </MainButton>
                </Tooltip>

                <ClipboardButton
                  isDisabled={!isOpen}
                  setImageBlob={setImageBlob}
                />
              </Buttons>
            </EmptyState>
          )}

          {(isDragActive || isLoading || isMaxFilesError) && !isRecordOpen && !isLinkOpen && !imageBlob && (
            <ActiveState>
              {!isLoading && (
                <DropzoneIcon
                  isMaxFilesError={isMaxFilesError}
                  isLoading={isLoading}
                />
              )}
              <Title>
                {getTitle(isMaxFilesError, isLoading)}
              </Title>
              {isLoading && (
                <Description>
                  This may take some time
                </Description>
              )}
            </ActiveState>
          )}

          {isRecordOpen && (
            <RecorderContainer
              $isVisible={isOpen || !isLoading}
              onClick={e => e.stopPropagation()}
            >
              <MiniRecorder
                onRecorded={file => handleFiles([file])}
                close={() => setIsRecordOpen(false)}
                isDisabled={!isOpen}
              />
            </RecorderContainer>
          )}

          {isLinkOpen && (
            <RecorderContainer
              $isVisible={isOpen || !isLoading}
              onClick={e => e.stopPropagation()}
            >
              <StyledLinkInput
                close={() => setIsLinkOpen(false)}
                onSubmit={handleLink}
                helper={undefined}
              >
                {(linkProps) => (
                  <PrimaryButton
                    disabled={linkProps.isDisabled || !isOpen}
                    onClick={linkProps.submit}
                  >
                    <CheckIcon />
                  </PrimaryButton>
                )}
              </StyledLinkInput>

              <SecondaryButton
                disabled={!isOpen}
                onClick={() => {
                  setIsLinkOpen(false);
                }}
              >
                <CloseIcon />
              </SecondaryButton>
            </RecorderContainer>
          )}

          {imageBlob && (
            <ClipboardContainer
              $isVisible={isOpen || !isLoading}
              onClick={e => e.stopPropagation()}
            >
              <ImagePreview>
                <Thumbnail
                  {...getZoomableProps({
                    src: URL.createObjectURL(imageBlob),
                    alt: 'Clipboard',
                    isDisabled: !isOpen,
                    onMouseDown: () => {
                      setIsAutoClose(false);
                      setIsCloseDisabled(true);
                    },
                  })}
                />

                <FileTitle>
                  <FileName>
                    {getCliboardFileName()}
                  </FileName>
                  <FileInfos>
                    <span>
                      PNG
                    </span>
                    <span>
                      {getFileSizeWithUnit(imageBlob.size)}
                    </span>
                  </FileInfos>
                </FileTitle>
              </ImagePreview>

              <PrimaryButton
                disabled={!isOpen}
                onClick={() => {
                  try {
                    const file = new File([imageBlob], `${getCliboardFileName()}.png`, { type: 'image/png' });
                    handleFiles([file]);
                    setImageBlob(null);
                  } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error('Error creating file from image blob', error);
                  }
                }}
              >
                <CheckIcon size={18} />
              </PrimaryButton>

              <SecondaryButton
                disabled={!isOpen}
                onClick={() => {
                  setImageBlob(null);
                }}
              >
                <CloseIcon size={18} />
              </SecondaryButton>
            </ClipboardContainer>
          )}
        </Content>

        {!isOpen && <IslandLoader isLoading={isLoading} />}
      </Container>

      <ListContainer
        initial="rest"
        animate={isOpen && pendingCount > 0 ? 'hover' : 'rest'}
        variants={listVariants}
        transition={transition}
      >
        <ListContent>
          <Scrollable>
            <InfiniteScroll
              isLoading={isLoadingDocImport}
              hasMoreData={hasMoreDocImport}
              loadMore={loadMoreDocImport}
            >
              <AnimatePresence initial={false}>
                {fileRejections.map((item, index) => (
                  <StyledFileCard
                    key={item.id}
                    name={getFileBaseName(item.rejection.file.name)}
                    extension={getFileExtension(item.rejection.file.name)}
                    mimeType={item.rejection.file.type}
                    size={item.rejection.file.size}
                    errorCode={item.rejection.errors[0]?.code}
                    errorMessage={item.rejection.errors[0]?.message}
                    discardButton={(
                      <DiscardButton
                        $isCompact
                        onClick={() => {
                          // Keep the dropzone open if there are still pending files, otherwise close it
                          setIsCloseDisabled(pendingCount > 1);
                          discardError(item.id);
                        }}
                        tooltip="Discard"
                        tooltipPlacement="top"
                      >
                        <CloseIcon size={12} />
                      </DiscardButton>
                    )}
                    isLast={index === fileRejections.length - 1 && !docs.length}
                    isCompact
                    variant="secondary"
                  />
                ))}

                {docs.map((doc, index) => (
                  <StyledFileCard
                    key={doc.id}
                    name={doc.title}
                    extension={getFileExtension(doc.source?.url)}
                    url={doc.source?.url}
                    size={doc.source && 'fileSize' in doc.source ? doc.source.fileSize : null}
                    discardButton={(
                      <DiscardDocButton
                        isCompact
                        docId={doc.id}
                        tooltip="Discard"
                        tooltipPlacement="top"
                        onClick={() => {
                          // Keep the dropzone open if there are still pending files, otherwise close it
                          setIsCloseDisabled(pendingCount > 1);
                        }}
                      />
                    )}
                    createButton={(
                      <CreateDocButton
                        isCompact
                        docId={doc.id}
                        tooltip="Create"
                        tooltipPlacement="top"
                      />
                    )}
                    isLast={index === docs.length - 1}
                    isCompact
                    variant="secondary"
                    onZoomIn={() => {
                      setIsAutoClose(false);
                      setIsCloseDisabled(true);
                    }}
                    onZoomOut={() => {
                      setIsAutoClose(true);
                    }}
                  />
                ))}
              </AnimatePresence>
            </InfiniteScroll>
          </Scrollable>
        </ListContent>
      </ListContainer>
    </Hover>
  );
});
