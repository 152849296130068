import { Skeleton } from '@cycle-app/ui';
import { useTheme } from 'styled-components';
import { hasPresentKey } from 'ts-is-present';

import { IllustrationDark } from 'src/components/InsightsList/IllustrationDark';
import { IllustrationLight } from 'src/components/InsightsList/IllustrationLight';
import { QuoteListContainer } from 'src/components/InsightsList/InsightsList.styles';
import LoadMore from 'src/components/LoadMore/LoadMore';
import { useInsightDocType } from 'src/hooks';
import { useDocChildrenSubscription } from 'src/hooks/api/useDocChildrenSubscription';
import { useDocInsightChildren } from 'src/hooks/insight/useDocInsightChildren';
import { useFeatureQuotesShortcutListener } from 'src/hooks/shortcuts/useFeatureQuotesShortcutListener';

import { FeatureDocQuote } from './FeatureDocQuote';

export const FeatureDocQuotes = ({ docId }: { docId: string }) => {
  const insightDoctypeId = useInsightDocType()?.id;
  if (!insightDoctypeId) return null;
  return (
    <FeatureDocQuotesList
      docId={docId}
      insightDoctypeId={insightDoctypeId}
    />
  );
};

type Props = {
  docId: string;
  insightDoctypeId: string;
};

const FeatureDocQuotesList = ({
  docId, insightDoctypeId,
}: Props) => {
  const {
    insights, isLoading, loadMore, pageInfo, isPaginationLoading, count, paginationSize,
  } = useDocInsightChildren({
    docId: docId,
    doctypeId: insightDoctypeId,
    pagination: 'quotes',
  });

  useDocChildrenSubscription(docId);
  useFeatureQuotesShortcutListener({ docIds: insights.map(insight => insight?.doc?.id) });

  const theme = useTheme();

  const moreCount = Math.min((count - insights.length), paginationSize);

  if (isLoading) return <QuotesSkeleton />;

  if (!insights.length) {
    return (
      <div className="flex flex-col items-center gap-2">
        {theme.isDark ? <IllustrationDark /> : <IllustrationLight />}
        <div className="text-body font-medium text-primary">
          No quote yet
        </div>
        <div className="text-center text-caption text-secondary">
          Quotes let you connect feedback to
          <br />
          product delivery workflows
        </div>
      </div>
    );
  }

  return (
    <QuoteListContainer
      id="insights-list"
      className="-m-1 box-content flex w-full flex-col gap-4 overflow-x-hidden p-1"
    >
      {insights
        .filter(hasPresentKey('doc'))
        .map(docTarget => (
          <FeatureDocQuote
            key={docTarget.doc.id}
            docId={docTarget.doc.id}
          />
        ))}

      {isPaginationLoading && <QuotesSkeleton count={moreCount} />}

      {!isPaginationLoading && pageInfo?.hasNextPage && (
        <LoadMore
          className="self-start"
          onClick={() => loadMore(pageInfo.endCursor ?? '')}
          count={moreCount}
        />
      )}
    </QuoteListContainer>
  );
};

export const QuotesSkeleton = ({ count = 4 }: { count?: number }) => {
  return (
    <div className="flex flex-col gap-4">
      {Array.from({ length: count }).map((_, index) => (
        <Skeleton
          key={index}
          height={16}
        />
      ))}
    </div>
  );
};
