import type { Color } from '@cycle-app/graphql-codegen';
import { getOS, isSafari } from '@cycle-app/utilities';
import { forwardRef, ComponentProps } from 'react';

import { LucideIcon, getLucideIconName } from '../Icon';
import { Container, Size } from './Emoji.styles';

export type EmojiSize = Size;

type Props = ComponentProps<typeof Container> & {
  emoji?: string | null;
  inline?: boolean;
  size?: Size;
  color?: Color;
};

const USE_NATIVE_EMOJI = getOS() === 'macOS' && !isSafari;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'em-emoji': {
        id?: string;
        shortcodes?: string;
        native?: string;
        size?: string;
        fallback?: string;
        set?: 'native' | 'apple' | 'facebook' | 'google' | 'twitter';
        skin?: 1 | 2 | 3 | 4 | 5 | 6;
      };
    }
  }
}

export const Emoji = forwardRef<HTMLDivElement, Props>(({
  emoji,
  size = 16,
  inline = false,
  color,
  ...otherProps
}, ref) => {
  if (!emoji) return null;
  const lucideIconName = getLucideIconName(emoji);
  return (
    <Container
      ref={ref}
      $size={size}
      $inline={inline}
      $native={USE_NATIVE_EMOJI}
      role="img"
      $color={color}
      $isIcon={!!lucideIconName}
      {...otherProps}
    >
      {lucideIconName
        ? (
          <LucideIcon
            name={lucideIconName}
            size={`${size}px`}
          />
        )
        : (
          <em-emoji
            {...USE_NATIVE_EMOJI ? { native: emoji } : { id: emoji }}
            set="apple"
            size={size.toString()}
          />
        )}
    </Container>
  );
});
