import { BillingPlan, Role } from '@cycle-app/graphql-codegen';

import { PageId } from 'src/constants/routing.constant';
import { useProductSelector } from 'src/hooks/api/selectors/useProductSelector';
import { useProductStatus } from 'src/reactives/product.reactive';

import { useRole } from '../useRoles';

export const useRouteAccess = (pageId: PageId) => {
  const productPlan = useProductSelector(p => p?.plan);
  const { productStatus } = useProductStatus();
  const {
    globalRole, billing,
  } = useRole();
  switch (pageId) {
    case PageId.SettingsBilling: return (
      productPlan === BillingPlan.Standard &&
      productStatus !== 'trial' &&
      (billing || globalRole === Role.SuperAdmin));
    default: return true;
  }
};
