import { BoardSectionFragment, ProductFragment, SectionType } from '@cycle-app/graphql-codegen';

type Section = Pick<BoardSectionFragment, 'name' | 'type'>;

export const getSectionName = (currentSection: Section, sectionsCount: number) => {
  if (currentSection.type === SectionType.Default) {
    // Display "Main section" when we only have the default section.
    return sectionsCount === 1 ? 'Main section' : 'Other';
  }
  return currentSection.name;
};

export const getBoardLinkSectionName = (
  boardId: string | null | undefined,
  product: ProductFragment | null | undefined,
) => {
  if (!product) return null;

  const sectionsEdges = product.boardSections.edges
    .filter(edge => [SectionType.Custom, SectionType.Default].includes(edge.node.type));

  for (const { node: section } of sectionsEdges) {
    for (const { node: boardLink } of section.boardLinks.edges) {
      if (boardLink.board.id === boardId) {
        return getSectionName(section, sectionsEdges.length);
      }
    }
  }

  return null;
};
