import { Skeleton } from '@cycle-app/ui';
import { LoadingIcon } from '@cycle-app/ui/icons';

export function ChangelogBuilderLoader() {
  return (
    <div className="flex h-full flex-col bg-grey-100 dark:bg-black">
      <div className="flex h-20 items-center px-12">
        <div className="ml-auto flex items-center gap-2">
          <Skeleton>
            Language
          </Skeleton>
          <Skeleton>
            Colors
          </Skeleton>
          <Skeleton>
            Typography
          </Skeleton>
          <Skeleton>
            Templates
          </Skeleton>
        </div>
      </div>

      <div
      // eslint-disable-next-line max-len
        className="-mx-px flex flex-1 items-center justify-center rounded-t-xl border-x border-t border-grey-300 bg-white drop-shadow-md dark:border-grey-850 dark:bg-grey-900"
      >
        <LoadingIcon className="size-6 animate-spin text-disabled" />
      </div>
    </div>
  );
}
