import { ViewType, DocBaseFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { NewDocPosition } from '@cycle-app/ui';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import memoize from 'fast-memoize';
import { useCallback, useRef, useState, useMemo } from 'react';

import BoardContentListeners from 'src/components/BoardContentListeners/BoardContentListeners';
import { BoardGroupName } from 'src/components/BoardGroup/BoardGroupName';
import { DocItemHandle } from 'src/components/DocItem';
import DocItemOverlay from 'src/components/DocItemOverlay/DocItemOverlay';
import LoadMore from 'src/components/LoadMore/LoadMore';
import Sortable from 'src/components/Sortable/Sortable';
import { MESSAGE_CREATE_DOC_DISABLED_INSIGHT, SWIMLANE_NO_VALUE_ID } from 'src/constants/boardGroups.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { DocProvider } from 'src/contexts/docContext';
import { useProductDoctypes } from 'src/hooks';
import { useBoardGroups } from 'src/hooks/api/useBoardGroups';
import useBoardWithSwimlane from 'src/hooks/api/useBoardWithSwimlane';
import { useProductBase } from 'src/hooks/api/useProduct';
import useSwimlaneDocsDnd from 'src/hooks/dnd/useSwimlaneDocsDnd';
import useSwimlaneGroupsDnd from 'src/hooks/dnd/useSwimlaneGroupsDnd';
import { useResetViewStateOnUnmount } from 'src/hooks/useResetViewStateOnUnmount';
import { useDocUrl } from 'src/hooks/useUrl';
import { setBoardNewDocPositionState, useBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { setCollapsedGroups, useGetCollapsedGroups } from 'src/reactives/collapsedGroups.reactive';
import { setDocItemHoverId } from 'src/reactives/docItem.reactive';
import { getDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { useGetSelection } from 'src/reactives/selection.reactive';
import { getAttributeName } from 'src/utils/attributes.util';
import { groupsToItems } from 'src/utils/dnd.util';
import { getDocKey } from 'src/utils/doc.util';
import { getGroupName } from 'src/utils/groups.util';

import {
  Container,
  Content,
  GroupByHeaders,
  StyledGroup,
  SwimlaneDocGroups,
  CollapsedGroupIcon,
  Bottom,
  StyledBoardGroupNewDoc,
  // SwimlaneDocCreating,
  GroupByHeadersBg,
  LinePlaceholder,
  DraggableGroup,
  DraggingColumnDocs,
  DraggingColumnGroup,
} from './BoardContentWithSwimlane.styles';
import SwimlaneDoc from './SwimlaneDoc/SwimlaneDoc';
import SwimlaneGroup from './SwimlaneGroup/SwimlaneGroup';
import { StyledDocItem } from './SwimlaneGroup/SwimlaneGroup.styles';

const BoardContentWithSwimlane = () => {
  const product = useProductBase();
  const {
    getDocFullPageUrl, getDocItemUrlFromView,
  } = useDocUrl();
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const groupByProperty = useBoardConfig(ctx => ctx.groupByProperty);
  const isDndGroupsEnabled = useBoardConfig(ctx => ctx.isDndGroupsEnabled);
  const isSortByDate = useBoardConfig(ctx => ctx.isSortByDate);
  const isSortByOldest = useBoardConfig(ctx => ctx.isSortByOldest);
  const isFeedbackView = useBoardConfig(ctx => ctx.isFeedbackView);

  const {
    groups,
    swimlanes,
    builtInDisplay,
    moreSwimlanes,
    getDoc,
    getReasonDocCreationDisabled,
    swimlanebyConfig,
  } = useBoardWithSwimlane();

  const {
    dndContextProps: dndContextPropsCols,
    items: itemsCols,
    activeId: activeIdCols,
    draggingGroup,
    overGroup,
    draggingGroupContent,
    getSwimlaneDataFromDndContext,
  } = useSwimlaneGroupsDnd();
  const {
    dndContextProps,
    direction,
    activeId,
    activeType,
    items,
    swimlaneItems,
    activeSwimlaneDoc,
  } = useSwimlaneDocsDnd();

  const { selected } = useGetSelection();

  const docItemRefs = useRef<DocItemHandle[][]>([]);
  const {
    groupId: newDocGroupId,
    draftPosition: draftDocPosition,
  } = useBoardNewDocPositionState();

  // On group change, readOnlyGroups and groups ids wont match, so inside on each loop
  // readOnlyGroups[group.id] === undefined. It is ok as <BoardGroupName /> are readOnly by default.
  // Manage groupBy groups edition
  const [readOnlyGroups, setReadOnlyGroups] = useState<Record<string, boolean>>(groups.reduce((acc, group) => ({
    ...acc,
    [group.id]: true,
  }), {}));
  const setReadOnlyGroup = useMemo(() => memoize((groupId: string) => (readOnly: boolean) => {
    setReadOnlyGroups(currentGroups => ({
      ...currentGroups,
      [groupId]: readOnly,
    }));
  }), []);
  const setGroupAsReadOnly = useCallback((id: string) => () => setReadOnlyGroup(id)(true), [setReadOnlyGroup]);

  // Manage collapsed groups (groupBy & swimlanes)
  const collapsedGroups = useGetCollapsedGroups();
  const toggleCollapse = useCallback((groupId: string) => {
    setCollapsedGroups({ [groupId]: !collapsedGroups[groupId] });
  }, [collapsedGroups]);

  // Callbacks
  const onNewDocClicked = useMemo(() => memoize((groupId: string) => (pos: NewDocPosition) => setBoardNewDocPositionState({
    groupId,
    draftPosition: pos,
  })), []);
  const onSwimlaneDocEnter = useMemo(() => memoize((docId: string) => () => {
    setDocItemHoverId(docId);
    if (getDocIdPreview().docIdPreview) setDocIdPreview({ docIdPreview: docId });
  }), []);
  const onSwimlaneDocLeave = useCallback(() => {
    setDocItemHoverId(null);
  }, []);

  const getDocWithKey = useCallback((doc: DocBaseFragment) => ({
    ...doc,
    _docKey: getDocKey(product?.key, doc.publicId),
  }), [product?.key]);
  const doctypesFromProduct = useProductDoctypes();

  const attributeName =
    boardConfig && (
      boardConfig.docQuery.__typename === 'BoardQueryWithGroupBy' ||
      boardConfig.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
    ) ? getAttributeName(boardConfig.docQuery.groupbyConfig.property, isFeedbackView) : null;

  // Empty group
  const emptyGroup = groups.find(g => !g.propertyValue);
  const emptyGroupName = getGroupName(emptyGroup, attributeName);
  const isEmptyGroupNameGroupCollapsed = emptyGroup ? collapsedGroups[emptyGroup?.baseId] : false;

  const { groups: boardGroups } = useBoardGroups();
  const [docIds] = useMemo(() => groupsToItems(boardGroups ?? {}), [boardGroups]);

  useResetViewStateOnUnmount();

  if (groups.length === 0) return null;

  return (
    <Container>
      <BoardContentListeners
        activeId=""
        docIds={docIds}
        viewType={ViewType.Kanban}
        docItemRefs={docItemRefs}
      />
      <Content>
        <GroupByHeadersBg />
        <GroupByHeaders>
          {emptyGroup && (
            <StyledGroup
              groupId={emptyGroup.baseId}
              collapse={isEmptyGroupNameGroupCollapsed}
              toggleCollapse={toggleCollapse}
              collapsedContent={<CollapsedGroupIcon />}
              inputName={(
                <BoardGroupName
                  groupName={emptyGroupName}
                  readOnly={!emptyGroup.propertyValue?.id || readOnlyGroups[emptyGroup.id]}
                  setReadOnly={setGroupAsReadOnly(emptyGroup.id)}
                  attributeValueId={emptyGroup.propertyValue?.id}
                />
              )}
              tooltip={emptyGroupName}
              tooltipPlacement="top"
              tooltipDisabled={!isEmptyGroupNameGroupCollapsed}
            />
          )}
          <DndContext {...dndContextPropsCols}>
            <SortableContext
              strategy={horizontalListSortingStrategy}
              items={itemsCols.header || []}
            >
              {itemsCols.header?.map((gId, index, headers) => {
                const isLastHeader = index === headers.length - 1;
                const group = groups.find(g => g.propertyValue?.id === gId);
                if (!group?.propertyValue?.id) return null;

                const groupName = getGroupName(group);
                const groupId = group.baseId;
                const isGroupCollapsed = collapsedGroups[groupId];
                return (
                  <Sortable
                    key={group.id}
                    id={group.propertyValue.id}
                    disabled={!isDndGroupsEnabled || !group.propertyValue}
                    render={(renderGroupProps) => (
                      <DraggableGroup
                        {...renderGroupProps}
                        {...isLastHeader && { className: 'swimlane-last-header' }}
                      >
                        {draggingGroup?.id === group.id ? (
                          <LinePlaceholder />
                        ) : (
                          <StyledGroup
                            groupId={groupId}
                            groupCategory={group.propertyValue?.__typename === 'Status' ? group.propertyValue.category : undefined}
                            collapse={isGroupCollapsed}
                            toggleCollapse={toggleCollapse}
                            isDraggable={isDndGroupsEnabled}
                            collapsedContent={<CollapsedGroupIcon />}
                            inputName={(
                              <BoardGroupName
                                groupName={groupName}
                                readOnly={!group.propertyValue?.id || readOnlyGroups[group.id]}
                                setReadOnly={setGroupAsReadOnly(group.id)}
                                attributeValueId={group.propertyValue?.id}
                              />
                            )}
                            tooltip={groupName}
                            tooltipPlacement="top"
                            tooltipDisabled={!isGroupCollapsed}
                          />
                        )}
                      </DraggableGroup>
                    )}
                  />
                );
              })}

            </SortableContext>
            <DragOverlay>
              {activeIdCols && draggingGroup && (
                <StyledGroup
                  isDragging
                  groupId={activeIdCols}
                  collapsedContent={<CollapsedGroupIcon />}
                  inputName={(
                    <BoardGroupName
                      groupName={getGroupName(draggingGroup)}
                      readOnly={!draggingGroup.propertyValue?.id || readOnlyGroups[draggingGroup.id]}
                      setReadOnly={setGroupAsReadOnly(draggingGroup.id)}
                      attributeValueId={draggingGroup.propertyValue?.id}
                    />
                  )}
                >
                  <DraggingColumnDocs>
                    {draggingGroupContent.map((draggingGroupSwimlane, draggingGroupIndex) => (
                      // eslint-disable-next-line
                      <DraggingColumnGroup key={draggingGroupIndex}>
                        {draggingGroupSwimlane.map(draggingGroupDoc => (
                          <DocProvider
                            key={draggingGroupDoc.id}
                            value={draggingGroupDoc}
                          >
                            <StyledDocItem
                              viewType={ViewType.Kanban}
                              showAssignee={builtInDisplay.assignee}
                              showComments={builtInDisplay.comments}
                              showCover={builtInDisplay.cover}
                              showDocParent={builtInDisplay.parent}
                              showLinear={builtInDisplay.linear}
                              showAiState={builtInDisplay.aiState}
                            />
                          </DocProvider>
                        ))}
                      </DraggingColumnGroup>
                    ))}
                  </DraggingColumnDocs>
                </StyledGroup>
              )}
            </DragOverlay>
          </DndContext>
        </GroupByHeaders>

        <DndContext {...dndContextProps}>
          <SortableContext items={swimlanes.edges.map(e => e.node.swimlaneDoc?.id ?? SWIMLANE_NO_VALUE_ID)}>
            {swimlaneItems.map((swimlaneItem, swimlaneIndex) => {
              const data = getSwimlaneDataFromDndContext(swimlaneItem);

              if (!data) return null;

              const {
                swimlaneId,
                swimlaneDoc,
                doctypeChildrenId,
                swimlaneGroups,
              } = data;
              const swimlaneDocId = swimlaneDoc?.id ?? SWIMLANE_NO_VALUE_ID;
              const isNoValue = swimlaneDocId === SWIMLANE_NO_VALUE_ID;
              return (
                <Sortable
                  id={swimlaneDocId}
                  key={swimlaneId}
                  dataType="swimlane"
                  disabled={isNoValue}
                  render={(swimlaneRenderProps) => (
                    <>
                      <SwimlaneDoc
                        {...swimlaneRenderProps}
                        id={swimlaneId}
                        boardConfigId={boardConfig?.id}
                        collapsed={!!collapsedGroups[swimlaneId]}
                        onToggleCollapsed={toggleCollapse}
                        {...swimlaneDoc
                          ? {
                            doc: {
                              ...swimlaneDoc,
                              _docKey: getDocKey(product?.key, swimlaneDoc.publicId),
                            },
                            docUrl: getDocFullPageUrl(swimlaneDoc),
                            onMouseEnter: onSwimlaneDocEnter(swimlaneDoc.id),
                            onMouseLeave: onSwimlaneDocLeave,
                            asPlaceholder: activeType === 'swimlane' && activeId === swimlaneDoc.id,
                          }
                          : {
                            name: `No ${swimlanebyConfig.doctype?.name ?? 'Doctype'}`,
                          }}
                      />

                      {!collapsedGroups[swimlaneId] && activeId !== swimlaneDoc?.id && (
                        <SwimlaneDocGroups>
                          {swimlaneGroups
                            .filter((docGroupNode) => (!draggingGroup ? true : docGroupNode.propertyValue?.id !== draggingGroup?.propertyValue?.id))
                            .map((docGroupNode, docGroupIndex) => {
                              const reasonDocCreationDisabled = getReasonDocCreationDisabled(docGroupNode, doctypeChildrenId);
                              const filteredDoctypeChildrenId = isNoValue
                                ? doctypeChildrenId.filter(doctypeId => (
                                  doctypesFromProduct.find(({ id }) => id === doctypeId)?.type !== DoctypeType.Insight
                                ))
                                : doctypeChildrenId;

                              const groupIndex = swimlaneIndex * swimlaneGroups.length + docGroupIndex;

                              return (
                                <SwimlaneGroup
                                  key={docGroupNode.id}
                                  ref={(docItemRefsInGroup) => {
                                    if (docItemRefsInGroup !== null) {
                                      docItemRefs.current[groupIndex] = docItemRefsInGroup;
                                    }
                                  }}
                                  groupIndex={groupIndex}
                                  swimlaneDocId={swimlaneDocId}
                                  groupId={docGroupNode.id}
                                  swimlaneDoctypeId={swimlanebyConfig.doctype?.id}
                                  builtInDisplay={builtInDisplay}
                                  collapsed={!!collapsedGroups[docGroupNode.baseId]}
                                  reasonCreateDocDisabled={reasonDocCreationDisabled}
                                  onNewDocClick={(reasonDocCreationDisabled === MESSAGE_CREATE_DOC_DISABLED_INSIGHT
                                    ? undefined
                                    : onNewDocClicked(docGroupNode.id)
                                  )}
                                  newDocPosition={!isSortByDate || isSortByOldest ? 'bottom' : 'top'}
                                  pageInfo={docGroupNode.docs.pageInfo}
                                  docIds={items[docGroupNode.id] ?? []}
                                  selectedDocIds={selected}
                                  activeId={activeId}
                                  activeType={activeType}
                                  getDoc={getDoc}
                                  getUrl={getDocItemUrlFromView}
                                  isOver={!!overGroup && overGroup?.propertyValue?.id === docGroupNode.propertyValue?.id}
                                  draftDocPosition={draftDocPosition}
                                  draftDoc={!reasonDocCreationDisabled && newDocGroupId === docGroupNode.id && draftDocPosition && (
                                    <StyledBoardGroupNewDoc
                                      $pos={draftDocPosition}
                                      targetPosition={draftDocPosition}
                                      groupId={docGroupNode.id}
                                      possibleDoctypesId={groupByProperty?.__typename === 'DoctypeDefinition' && docGroupNode.propertyValue?.id
                                        ? [docGroupNode.propertyValue.id]
                                        : filteredDoctypeChildrenId}
                                      parentId={swimlaneDoc?.id}
                                      statusId={docGroupNode.propertyValue?.__typename === 'Status' ? docGroupNode.propertyValue?.id : undefined}
                                    />
                                  )}
                                />
                              );
                            })}
                        </SwimlaneDocGroups>
                      )}
                    </>
                  )}
                />
              );
            })}
          </SortableContext>

          <DragOverlay>
            {activeType === 'item' && activeId && (
              <DocItemOverlay
                activeId={activeId}
                direction={direction}
                viewType={ViewType.Kanban}
              />
            )}
            {activeType === 'swimlane' && activeId && activeSwimlaneDoc && (
              <SwimlaneDoc
                id={activeId}
                boardConfigId={boardConfig?.id}
                collapsed={!!collapsedGroups[activeId]}
                doc={getDocWithKey(activeSwimlaneDoc)}
              />
            )}
          </DragOverlay>
        </DndContext>

        <Bottom>
          {swimlanes.pageInfo.hasNextPage
            ? (
              <LoadMore
                className="w-full justify-start"
                onClick={moreSwimlanes.execute}
                loading={moreSwimlanes.loading}
              />
            )
            : (
              (null)
              // TODO: Waiting for reworked UX
              // <>
              //   {loadingCreateSwimlane
              //     ? <SwimlaneDocCreating><Spinner /></SwimlaneDocCreating>
              //     : <NewSwimlane onSubmit={createSwimlane} />}
              // </>
            )}
        </Bottom>

      </Content>
    </Container>
  );
};

export default BoardContentWithSwimlane;
