import { nodeToArray } from '@cycle-app/utilities';

import { DocParentPanel } from 'src/components/DocParentDropdown/DocParentPanel';
import { DropdownLayer, DropdownLayerProps } from 'src/components/DropdownLayer';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useDoctypeFragment } from 'src/hooks/api/fragments/doctype';
import { useDocBase } from 'src/hooks/api/useDoc';
import { Layer } from 'src/types/layers.types';

type Props = Omit<DropdownLayerProps, 'content'> & {
  docId: string;
};

export const QuoteFeatureDropdown = ({
  docId, ...props
}: Props) => {
  return (
    <DropdownLayer
      placement="bottom-start"
      layer={Layer.DropdownModalZ3}
      withWrapper={false}
      {...props}
      content={(
        <QuoteFeatureDropdownContent
          docId={docId}
          hide={() => props.hide?.()}
        />
      )}
    />
  );
};

export const QuoteFeatureDropdownContent = ({
  docId,
  hide,
}: {
  docId: string;
  hide: () => void;
}) => {
  const { doc } = useDocBase(docId);
  const doctype = useDoctypeFragment(doc?.doctype.id);
  const productAreaIds = nodeToArray(doc?.productAreas).map(a => a.id);

  const feedbackDocId = doc?.docSource?.doc?.id;
  const feedbackDoc = useDocBase(feedbackDocId || '', {
    skip: !feedbackDocId || productAreaIds.length > 0,
  });

  if (!doctype) return null;

  return (
    <BoardConfigContextProvider>
      <DocParentPanel
        productAreaIds={productAreaIds.length > 0
          ? productAreaIds
          // If the quote does not have product area, features are filtered with feedback product areas.
          : nodeToArray(feedbackDoc.doc?.productAreas).map(a => a.id)}
        docId={docId}
        docType={doctype}
        hide={hide}
        showNoneOption={!!doc?.parent?.id}
        showLinearAutoCreate
      />
    </BoardConfigContextProvider>
  );
};
