import { makeVar, ReactiveVar } from '@apollo/client';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import shallowequal from 'shallowequal';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector';

import {
  getLocalState,
  resetLocalState,
  setLocalState,
  hookGetLocalState,
  hookLocalState,
} from 'src/hooks/state/useLocalState';
import { ParamsState, StateHookResult } from 'src/types/state.types';

import { subscribeToLocalStorage } from './localStorage.utils';

/**
 * @see https://reactjs.org/docs/hooks-reference.html#usesyncexternalstore
 * @see https://github.com/apollographql/apollo-client/blob/main/src/cache/inmemory/reactiveVars.ts
 */
const useReactiveWithSelector = <T, S>(
  reactive: ReactiveVar<T>,
  selector: (state: T) => S,
  isEqual?: (a: S, b: S) => boolean,
): S => useSyncExternalStoreWithSelector(
    (onChange: VoidFunction) => {
      let unsubscribe: VoidFunction;
      const listener = () => {
        // Notify parent listener
        onChange();
        // Resubscribe to the next variable value change.
        unsubscribe = reactive.onNextChange(listener);
      };
      unsubscribe = reactive.onNextChange(listener);
      return unsubscribe;
    },
    reactive,
    reactive,
    selector,
    isEqual,
  );

type MakeResult<T> = {
  hookState: () => StateHookResult<T>;
  hookValue: () => T;
  hookWithSelector: <S>(
    selector: (state: T) => S,
    isEqual?: (a: S, b: S) => boolean,
  ) => S;
  getValue: () => T;
  setValue: (value: Record<string, T[keyof T]>) => void;
  resetValue: VoidFunction;
};

const parseContent = (content: string) => {
  try {
    return JSON.parse(content);
  } catch {
    return {};
  }
};

export const make = <T>({
  defaultState,
  getInitialState,
  localKey,
  mergeTarget,
  omitFromLocalKey,
  areEqual = shallowequal,
  crossTabSync = false,
}: ParamsState<T>): MakeResult<T> => {
  const fromLocalStorage = localKey ? localStorage.getItem(localKey) : undefined;
  const storageState = fromLocalStorage ? parseContent(fromLocalStorage) : null;

  const initialState: T = getInitialState ? getInitialState(defaultState, storageState) : {
    ...defaultState,
    ...storageState,
  };

  const reactive = makeVar<T>(initialState);

  const setLocalStorageItem = localKey ? debounce((updatedState) => {
    if (!localKey) return;
    localStorage.setItem(localKey, JSON.stringify(omitFromLocalKey ? omit(updatedState, omitFromLocalKey) : updatedState));
  }, 1000) : undefined;

  const setValue = setLocalState({
    reactive,
    mergeTarget,
    defaultState,
    setLocalStorageItem,
    areEqual,
  });

  if (crossTabSync && localKey) {
    subscribeToLocalStorage({
      key: localKey,
      callback: setValue,
    });
  }

  return {
    hookState: hookLocalState({
      reactive,
      defaultState,
      setLocalStorageItem,
    }),
    hookValue: hookGetLocalState({ reactive }),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    hookWithSelector: (selector, isEqual) => useReactiveWithSelector(reactive, selector, isEqual),
    getValue: getLocalState({ reactive }),
    setValue,
    resetValue: resetLocalState({
      defaultState,
      reactive,
      localKey,
    }),
  };
};
