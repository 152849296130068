import { FilterPropertyRuleEdgesFragment } from '@cycle-app/graphql-codegen';

import { isFilterDoctypePropertyRuleByInsight } from '../../utils/boardConfig/boardConfig.util';
import { useBoardConfigDoctypesFilterFragment } from '../api/fragments/boardConfig';

export function useBoardConfigFilters(boardConfigId?: string | null) {
  const boardConfig = useBoardConfigDoctypesFilterFragment(boardConfigId);
  const filterProperties = boardConfig?.filterProperties as FilterPropertyRuleEdgesFragment | undefined;
  const insightDoctypeFilter = isFilterDoctypePropertyRuleByInsight(filterProperties);
  const insightDocType = {
    isTheOnlyOne: insightDoctypeFilter?.isOnlyInsightSelected,
    isInBoard: insightDoctypeFilter?.isInsightSelected,
  };

  return {
    isLoading: !boardConfig,
    insightDocType,
  };
}
