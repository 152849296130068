import { ViewType } from '@cycle-app/graphql-codegen';
import { htmlToText } from '@cycle-app/utilities';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { useDocContext } from 'src/contexts/docContext';

interface Props {
  viewType: ViewType;
  className?: string;
}

export const DocItemContextTitle: FC<React.PropsWithChildren<Props>> = ({
  viewType, className,
}) => {
  const title = useDocContext(ctx => ctx.title);
  const docSourceContent = useDocContext(ctx => ctx.docSource?.content);
  const content = htmlToText(docSourceContent || title);
  return content.trim().length ? (
    <div
      className={twMerge(
        className,
        'overflow-anywhere line-clamp-3 max-h-20 overflow-hidden rounded-md bg-grey-100 px-2 py-1 dark:bg-grey-800', viewType === ViewType.List && 'line-clamp-2',
      )}
    >
      {content}
    </div>
  ) : null;
};
