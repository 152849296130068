import { BillingPlan, Role } from '@cycle-app/graphql-codegen';

import { PermissionAction } from 'src/types/permissionAction.types';

const PermissionsCollaborator: PermissionAction[] = [
  'USER_COLLABORATOR_CREATE',
  'USE_AI',
  'INSIGHT_CREATE',
  'INSIGHT_DELETE',
  'INSIGHT_UPDATE',
  'FEEDBACK_STATUS_UPDATE',
  'SETTINGS_READ_ONLY',
];

const PermissionsMaker: PermissionAction[] = [
  ...PermissionsCollaborator,
  'EXPORT_CSV',
  'GROUP_CREATE',
  'GROUP_UPDATE',
  'GROUPS_REORDER',
  'HANDLE_ATTRIBUTES_OPTIONS',
  'INSIGHT_CREATE',
  'INSIGHT_DELETE',
  'INSIGHT_UPDATE',
  'INTEGRATION_ADD',
  'RELEASE_CREATE',
  'RELEASE_DELETE',
  'RELEASE_NOTE_CREATE',
  'RELEASE_NOTE_DELETE',
  'RELEASE_NOTE_UPDATE',
  'RELEASE_UPDATE',
  'SETTINGS_READ',
  'SETTINGS_READ_ONLY',
  'TRANSCRIPT',
  'USE_AI',
  'USER_MAKER_CREATE',
  'USER_UPDATE_ROLE_COLLABORATOR',
  'USER_UPDATE_ROLE_MAKER',
  'VIEW_CREATE',
  'VIEW_CUSTOM_CREATE',
  'VIEW_CUSTOM_DELETE',
  'VIEW_CUSTOM_SECTION_CREATE',
  'VIEW_CUSTOM_SECTION_DELETE',
  'VIEW_CUSTOM_SECTION_UPDATE',
  'VIEW_CUSTOM_UPDATE',
  'VIEW_DELETE',
  'VIEW_UPDATE',
  'VIEWS_REORDER',
];

const PermissionsPlanFree: PermissionAction[] = [];

const PermissionsPlanStandard: PermissionAction[] = [
  'DOCTYPE_CREATE',
  'INSIGHT_CREATE',
  'INTEGRATION_ADD',
  'SLACK_AUTOMATION_UPDATE',
  'SLACK_NOTIFICATIONS_UPDATE',
  'UPLOAD_NOT_RESTRICTED',
  'VIEW_CREATE',
  'VIEW_CUSTOM_CREATE',
  'VIEW_CUSTOM_SECTION_CREATE',
];

export const PermissionsByRoles = {
  [Role.Root]: PermissionsMaker,
  [Role.SuperAdmin]: PermissionsMaker,
  [Role.Admin]: PermissionsMaker,
  [Role.Maker]: PermissionsMaker,
  [Role.Collaborator]: PermissionsCollaborator,
  [Role.User]: PermissionsCollaborator,
};

export const PermissionsByPlan = {
  [BillingPlan.Free]: PermissionsPlanFree,
  [BillingPlan.Standard]: PermissionsPlanStandard,
  [BillingPlan.BusinessPlus]: PermissionsPlanStandard,
};
