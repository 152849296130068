import { forwardRef } from 'react';

import { OfflineIcon } from '../../icons';
import { StateToaster } from '../StateToaster';
import { OfflineToasterProps } from './OfflineToaster.types';

const CONTENT_TEXT = "Looks like you're offline - Go back online to continue using Cycle";

export const OfflineToaster = forwardRef<HTMLDivElement, OfflineToasterProps>((
  {
    label = CONTENT_TEXT,
    ...props
  },
  ref,
) => (
  <StateToaster
    role="alert"
    ref={ref}
    {...props}
  >
    <OfflineIcon />
    {label}
  </StateToaster>
));
