/* eslint-disable react/no-array-index-key */
import { Color } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import { BulbLight, MoreHorizCircleIcon, PlusBoxRoundedIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import {
  Container,
  Sidebard,
  SidebarHeader,
  SidebarNavitem,
  SidebarNavitemLabel,
  SidebarNavs,
  Wrapper,
} from './AsideApp.styles';

interface Props {
  logo: ReactNode;
  avatar: ReactNode;
  color: Color;
  main?: ReactNode;
  className?: string;
}

const top = [
  {
    icon: (
      <Icon
        name="search"
        className="size-[18px]"
      />
    ),
    width: 47,
  },
  {
    icon: (
      <Icon
        name="home"
        className="size-[18px]"
      />
    ),
    width: 40,
  },
  {
    icon: (
      <Icon
        name="feedback"
        className="size-[17px]"
      />
    ),
    width: 66,
  },
  {
    icon: (
      <BulbLight
        width={18}
        height={18}
        style={{
          marginLeft: '-1px',
          marginRight: '-1px',
        }}
      />
    ),
    width: 53,
  },
  {
    icon: (
      <MoreHorizCircleIcon
        width={18}
        height={18}
      />
    ),
    width: 35,
  },
  {
    icon: (
      <PlusBoxRoundedIcon
        width={20}
        height={20}
      />
    ),
    width: 65,
  },
];
export const AsideApp: FC<React.PropsWithChildren<Props>> = ({
  avatar, logo, main, className,
}) => {
  return (
    <Wrapper className={className}>
      <Container>
        <Sidebard>
          <SidebarHeader>
            {logo}
            {avatar}
          </SidebarHeader>
          <SidebarNavs>
            {top.map((nav, i) => (
              <SidebarNavitem key={i}>
                {nav.icon}
                <SidebarNavitemLabel style={{ width: `${nav.width}px` }} />
              </SidebarNavitem>
            ))}
          </SidebarNavs>
        </Sidebard>
        {main}
      </Container>
    </Wrapper>
  );
};
