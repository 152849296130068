import { Icon } from '@cycle-app/ui';
import { atom, useAtom } from 'jotai';
import { ReactNode, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { Placement } from 'tippy.js';

import { DropdownLayer } from 'src/components/DropdownLayer';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useTippyOffsetAdapter } from 'src/hooks/useTippyOffsetAdapter';
import { Layer } from 'src/types/layers.types';

const activeDropdownIdAtom = atom<string>();

export function ChangelogDropdown({
  dropdownId,
  children,
  empty,
  hoverStateClassName = '-m-3 p-3 rounded-2xl',
  content,
  onClick,
  placement,
}: {
  dropdownId?: string;
  children: ReactNode;
  empty?: boolean;
  /**
   * @description You'll need to add the spacing and radius classes
   * @default "-m-3 p-3 rounded-2xl"
   */
  hoverStateClassName?: string;
  content: ((payload: { hide: VoidFunction }) => ReactNode);
  onClick?: VoidFunction;
  placement?: Placement;
}) {
  const [activeId, setActiveIdState] = useAtom(activeDropdownIdAtom);

  const [renderPopoverOpen, setRenderPopoverOpen] = useState(false);
  const offsetAdapter = useTippyOffsetAdapter();

  const setActiveId = (isActive: boolean) => {
    if (!dropdownId) return;

    setActiveIdState(isActive ? dropdownId : undefined);
  };

  const closePopover = () => {
    setRenderPopoverOpen(false);
    setActiveIdState(undefined);
  };

  return (
    <DropdownLayer
      placement={placement ?? 'bottom-start'}
      controlled
      visible={renderPopoverOpen}
      hide={() => closePopover()}
      interactive
      withPortal
      withWrapper={false}
      animation
      zIndex={mappingZindex[Layer.Dropdown]}
      content={content({ hide: () => closePopover() })}
      {...offsetAdapter.tippyProps}
    >
      <div
        className={twJoin(
          'relative cursor-pointer transition-colors duration-100 hover:z-10 focus-visible:outline aria-expanded:z-10',
          'dropdown-container [--visible:0] hover:[--visible:1] has-[.dropdown-container:hover]:[--visible:0] aria-expanded:[--visible:1]',
          'border border-[color-mix(in_srgb,_var(--interaction-color-level-2)_calc(var(--visible)*100%),_transparent_calc((1-var(--visible))*100%))]',
          'bg-[color-mix(in_srgb,_var(--interaction-color-level-1)_calc(var(--visible)*100%),_transparent_calc((1-var(--visible))*100%))]',
          empty && '[--visible:1]',
          dropdownId && (activeId === dropdownId) && '[--visible:1]',
          hoverStateClassName,
        )}
        onPointerEnter={(e) => {
          e.stopPropagation();
          setActiveId(true);
        }}
        onPointerLeave={(e) => {
          e.stopPropagation();
          setActiveId(false);
        }}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.();
          setRenderPopoverOpen(true);
        }}
        role="button"
        tabIndex={0}
        onKeyUp={(e) => {
          if (e.key === 'Enter') { setRenderPopoverOpen(true); }
        }}
      >
        {children}
      </div>
    </DropdownLayer>
  );
}

export function ChangelogDropdownContent({
  title,
  hide,
  children,
}: {
  title: ReactNode;
  hide?: VoidFunction;
  children: ReactNode;
}) {
  return (
    <div className="w-72 px-3">
      <div className="flex items-center justify-between gap-2 py-3 font-medium text-primary">
        {title}
        {hide && (
          <button
            autoFocus
            className="btn-tertiary btn-sm btn-square"
            onClick={hide}
            type="button"
          >
            <Icon name="close" />
          </button>
        )}
      </div>
      <ChangelogDropdownDivider />
      <div className="py-4">
        {children}
      </div>
    </div>
  );
}

export function ChangelogDropdownDivider({ className }: { className?: string }) {
  return (
    <div className={twJoin('-mx-3 h-px bg-grey-200 dark:bg-grey-800', className)} />
  );
}
