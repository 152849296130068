import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { Icon, Input } from '@cycle-app/ui';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { ColorInput } from 'src/app/Changelog/ChangelogBuilder/shared/ColorInput';
import { ChangelogTag } from 'src/app/Changelog/ChangelogTag';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useChangelog } from 'src/hooks/releases/useChangelog';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { useReleaseNoteTagCreate } from 'src/hooks/releases/useReleaseNoteTagCreate';
import { useReleaseNoteTags } from 'src/hooks/releases/useReleaseNoteTags';
import { useReleaseNoteTagUpdate } from 'src/hooks/releases/useReleaseNoteTagUpdate';
import { useReleasePublicStatusUpdate } from 'src/hooks/releases/useReleasePublicStatusUpdate';
import { useTippyOffsetAdapter } from 'src/hooks/useTippyOffsetAdapter';
import { Layer } from 'src/types/layers.types';

import { DropdownLayer } from '../DropdownLayer';

const CREATE_COLOR = '#E2FDEE';

export const ReleaseNoteTags = ({ releaseNoteId }: { releaseNoteId: string }) => {
  const offsetAdapter = useTippyOffsetAdapter();
  const [renderPopoverOpen, setRenderPopoverOpen] = useState(false);
  const [editTagId, setEditTagId] = useState<string>();
  const [search, setSearch] = useState('');

  const {
    tags, isLoading: isTagsLoading,
  } = useReleaseNoteTags(releaseNoteId);

  const {
    changelog, isLoading: isChangelogLoading,
  } = useChangelog();

  const handleClose = () => {
    setEditTagId(undefined);
    setRenderPopoverOpen(false);
  };

  if (isTagsLoading) return null;

  const editTag = changelog?.releaseNoteTagValues.find(t => t.id === editTagId);

  return (
    <DropdownLayer
      placement="bottom-start"
      controlled
      visible={renderPopoverOpen}
      hide={handleClose}
      interactive
      withPortal
      withWrapper={false}
      animation
      zIndex={mappingZindex[Layer.Dropdown]}
      {...offsetAdapter.tippyProps}
      content={(
        <div className="w-72 px-3">
          <div className="flex items-center justify-between gap-2 py-3 font-medium text-primary">
            {editTag ? (
              <button
                className="btn-tertiary pl-1"
                onClick={() => setEditTagId(undefined)}
                type="button"
              >
                <Icon name="chevron-left" />
                Back
              </button>
            ) : (
              <span>
                Release tags
              </span>
            )}
            <button
              type="button"
              onClick={handleClose}
              className="btn-tertiary btn-sm btn-square"
              autoFocus
            >
              <Icon name="close" />
            </button>
          </div>
          <div className="-mx-3 h-px bg-grey-200 dark:bg-grey-800" />
          {editTag ? (
            <EditTagContent
              tag={editTag}
              onBack={() => setEditTagId(undefined)}
            />
          ) : (
            <TagsSelectionAndCreation
              releaseNoteId={releaseNoteId}
              onEditTag={setEditTagId}
              search={search}
              setSearch={setSearch}
            />
          )}
        </div>
      )}
    >
      <div
        onClick={() => {
          setRenderPopoverOpen(true);
          setSearch('');
        }}
        role="button"
        tabIndex={0}
        className={twJoin('flex select-none flex-wrap items-center gap-2', isChangelogLoading ? 'opacity-50' : 'cursor-pointer')}
      >
        {tags.map((tag) => (
          <ChangelogTag
            key={tag.id}
            color={tag.color}
            value={tag.value}
            releaseTagStyle={changelog?.releaseTagStyle}
          />
        ))}
        <div className="btn-tertiary btn-sm btn-inline-y">
          <Icon name="plus" />
          {tags.length === 0 && 'Release tag'}
        </div>
      </div>
    </DropdownLayer>
  );
};

function TagsSelectionAndCreation({
  releaseNoteId, onEditTag, search, setSearch,
}: {
  releaseNoteId: string;
  onEditTag: (tagId: string) => void;
  search: string;
  setSearch: (search: string) => void;
}) {
  const { changelog } = useChangelog();
  const { createTag } = useReleaseNoteTagCreate();
  const { addTag } = useReleaseNoteTagUpdate();
  const [isCreating, setIsCreating] = useState(false);
  const { releaseNote } = useReleaseNote(releaseNoteId);
  const { editPublished } = useReleasePublicStatusUpdate(releaseNote?.release.id);

  if (!changelog) return null;

  const tagVisible = (tagValue: string) => {
    if (!search) return true;
    return tagValue.toLowerCase().includes(search.toLowerCase());
  };

  const isCreateVisible = search.length > 0 && !changelog.releaseNoteTagValues.some(
    tag => tag.value.toLowerCase() === search.toLowerCase(),
  );

  const handleCreateTag = async () => {
    if (!changelog?.id) return;
    if (releaseNote?.release.publicStatus === ReleasePublicStatus.Published) {
      await editPublished();
    }
    setIsCreating(true);
    try {
      const newTag = await createTag({
        changelogId: changelog.id,
        value: search,
        color: CREATE_COLOR,
      });

      if (newTag) {
        await addTag({
          releaseNoteId,
          tagId: newTag.id,
        });
        onEditTag(newTag.id);
      }
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div>
      <label className="-mx-3 flex items-center border-b border-grey-200 p-3 dark:border-grey-800">
        <Icon
          name="search"
          className="text-disabled"
        />
        <input
          autoFocus
          type="search"
          className="w-full bg-transparent pl-3 text-sm text-primary placeholder:text-disabled focus:outline-none"
          placeholder="Search or create tag"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </label>

      <div className="-mx-3 max-h-64 overflow-y-auto px-3 py-2">
        {changelog.releaseNoteTagValues.map((tag) => (
          tagVisible(tag.value) && (
            <ChangelogTagSelectableItem
              key={tag.id}
              releaseNoteId={releaseNoteId}
              tag={tag}
              onEdit={() => onEditTag(tag.id)}
            />
          )
        ))}
        {isCreateVisible && (
          <button
            type="button"
            className="flex w-full cursor-pointer items-center gap-2 rounded p-2 hover:bg-grey-100 dark:hover:bg-grey-800"
            onClick={handleCreateTag}
            disabled={isCreating}
          >
            <Icon
              name="plus"
              className="size-3.5 text-primary"
            />
            <span className="text-disabled">
              Create:
            </span>
            <span className="text-primary">
              {search}
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

function EditTagContent({
  tag,
  onBack,
}: {
  tag: {
    id: string;
    color: string;
    value: string;
  };
  onBack: VoidFunction;
}) {
  const [color, setColor] = useState(tag.color);
  const [value, setValue] = useState(tag.value);
  const [isUpdating, setIsUpdating] = useState(false);
  const { updateTag } = useReleaseNoteTagUpdate();

  const handleSave = async () => {
    // If no changes or empty values, don't save
    if (!color || !value) return;
    if (color === tag.color && value === tag.value) return;

    setIsUpdating(true);
    try {
      await updateTag({
        tagId: tag.id,
        color,
        value,
      });
      onBack();
    } finally {
      setIsUpdating(false);
    }
  };

  const hasChanges = color !== tag.color || value !== tag.value;
  const isValid = color && value;

  return (
    <div className="py-3">
      <Input
        label="Title"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={isUpdating}
      />

      <div className="mt-4 grid grid-cols-2 items-center gap-2">
        <span className="text-primary">
          Color
        </span>
        <ColorInput
          value={color}
          onChange={setColor}
          disabled={isUpdating}
        />
      </div>

      <div className="mt-6 flex items-center justify-end">
        <button
          className={twJoin('btn-primary', isUpdating && 'btn-loading')}
          onClick={handleSave}
          type="button"
          disabled={isUpdating || !hasChanges || !isValid}
        >
          Save
        </button>
      </div>
    </div>
  );
}

function ChangelogTagSelectableItem({
  releaseNoteId, tag, onEdit,
}: {
  releaseNoteId: string;
  tag: {
    id: string;
    color: string;
    value: string;
  };
  onEdit: VoidFunction;
}) {
  const [pending, setPending] = useState(false);
  const { tags } = useReleaseNoteTags(releaseNoteId);
  const { changelog } = useChangelog();
  const { releaseNote } = useReleaseNote(releaseNoteId);
  const { editPublished } = useReleasePublicStatusUpdate(releaseNote?.release.id);

  const {
    addTag, removeTag,
  } = useReleaseNoteTagUpdate();

  const selectedTagIds = tags.map((t) => t.id);

  const handleChange = async () => {
    if (releaseNote?.release.publicStatus === ReleasePublicStatus.Published) {
      await editPublished();
    }
    setPending(true);
    if (selectedTagIds.includes(tag.id)) {
      await removeTag({
        releaseNoteId,
        tagId: tag.id,
      });
    } else {
      await addTag({
        releaseNoteId,
        tagId: tag.id,
      });
    }
    setPending(false);
  };

  return (
    <label
      key={tag.id}
      className="group -mx-2 flex items-center gap-2 rounded px-2 py-1 hover:bg-grey-100 dark:hover:bg-grey-800"
    >
      <input
        type="checkbox"
        checked={selectedTagIds.includes(tag.id)}
        onChange={handleChange}
        disabled={pending}
      />

      <ChangelogTag
        color={tag.color}
        value={tag.value}
        releaseTagStyle={changelog?.releaseTagStyle}
      />

      <button
        type="button"
        className="btn-tertiary ml-auto opacity-0 focus-visible:opacity-100 group-hover:opacity-100"
        onClick={onEdit}
      >
        Edit
      </button>
    </label>
  );
}
