import { useSubscription } from '@apollo/client';
import { AskDocDocument } from '@cycle-app/graphql-codegen';

import { updateAskAnswer, getAskAnswers, setAskPanel } from 'src/reactives/ask.reactive';

export const useAskDocSubscription = (docId: string) => {
  return useSubscription(AskDocDocument, {
    variables: {
      docId,
    },
    ignoreResults: true,
    onData: (result) => {
      const data = result.data.data?.askDoc;
      if (!data) return;
      const answers = Object.values(getAskAnswers().answers);
      const anwserId = answers.find(answer => answer.uuid === data.id)?.id;
      if (!anwserId) return;

      setAskPanel(docId, {
        conversationId: data.conversationId,
      });

      updateAskAnswer(anwserId, {
        askId: docId,
        id: anwserId,
        status: 'answered',
        uuid: data.id,
        content: {
          answer: data.answer,
        },
      });
    },
  });
};
