import { LinearProjectFragment } from '@cycle-app/graphql-codegen';
import { Button, Emoji } from '@cycle-app/ui';
import { Label } from '@cycle-app/ui/components/Inputs/Input/Input.styles';
import { useForm } from 'react-hook-form';

import { MultiSelectField } from 'src/components/Form/MultiSelectField';
import { useCreateLinearProject } from 'src/hooks/api/mutations/integrations/useCreateLinearProject';
import { useLinearTeams } from 'src/hooks/api/queries/integrations/useLinearTeams';
import { getLinearModal, closeLinearModal } from 'src/reactives/linear.reactive';
import { Layer } from 'src/types/layers.types';

import { Form, MoreValues, StyledEmojiPicker, ProjectTitleInput } from './DocLinear.styles';

interface Props {
  defaultTitle: string;
  defaultTeamIds?: string[];
  onCreate?: (project: LinearProjectFragment) => void;
}

export const LinearProjectForm = ({
  defaultTitle, defaultTeamIds, onCreate,
}: Props) => {
  const { createLinearProject } = useCreateLinearProject();
  const teamsQuery = useLinearTeams();

  const {
    handleSubmit, register, formState, watch, setValue,
  } = useForm<{
    title: string;
    teamIds: string[];
    icon: string;
  }>({
    defaultValues: {
      title: defaultTitle,
      teamIds: defaultTeamIds || [],
      icon: 'rocket',
    },
  });

  register('teamIds', {
    validate: value => value.length > 0 || 'Teams are required',
  });

  const iconValue = watch('icon');

  const teamIdsValue = watch('teamIds');
  const firstSelectedTeam = teamsQuery.teams.find(t => teamIdsValue.includes(t.id));

  return (
    <Form
      onSubmit={handleSubmit(async values => {
        const { docId } = getLinearModal();
        if (!docId) return;
        const result = await createLinearProject({
          docId,
          name: values.title,
          teamIds: values.teamIds,
          icon: values.icon,
        });
        const project = result.data?.createLinearProject;
        if (!project) return;

        if (onCreate) {
          onCreate(project);
        }
        closeLinearModal();
      })}
    >
      <div className="flex items-center gap-4">
        <div>
          <Label>
            Icon
          </Label>
          <StyledEmojiPicker
            variant="outlined-alt"
            dropdownProps={{ placement: 'bottom' }}
            onSelect={value => {
              if ('id' in value && value.id) {
                setValue('icon', value.id);
              }
            }}
          >
            <Emoji emoji={iconValue} />
          </StyledEmojiPicker>
        </div>

        <ProjectTitleInput
          autoFocus
          label="Project title"
          placeholder="Your project title"
          error={formState.errors.title?.message}
          {...register('title', {
            required: 'Project title is required',
          })}
        />
      </div>

      <MultiSelectField
        layer={Layer.DropdownModalZ3}
        label="Teams"
        options={teamsQuery.teams.map(t => ({
          value: t.id,
          label: t.name,
          selected: teamIdsValue.includes(t.id),
        }))}
        value={firstSelectedTeam ? (
          <>
            {firstSelectedTeam.name}
            {teamIdsValue.length > 1 && (
              <MoreValues>
                {`+${teamIdsValue.length - 1}`}
              </MoreValues>
            )}
          </>
        ) : 'Choose from list'}
        isLoading={teamsQuery.isLoading}
        onSelectOption={option => setValue('teamIds', [...teamIdsValue, option.value])}
        onUnselectOption={option => setValue('teamIds', teamIdsValue.filter(id => id !== option.value))}
        onSelectAll={() => setValue('teamIds', teamsQuery.teams.map(t => t.id))}
        onUnselectAll={() => setValue('teamIds', [])}
        error={formState.errors.teamIds?.message}
      />

      <footer>
        <Button
          size="M"
          variant="secondary"
          onClick={closeLinearModal}
        >
          Cancel
        </Button>
        <Button
          size="M"
          type="submit"
          disabled={teamsQuery.isLoading}
          isLoading={formState.isSubmitting}
        >
          Create
        </Button>
      </footer>
    </Form>
  );
};
