import {
  DoctypeCategory,
  DoctypeType,
  DraftBoardConfigFragment,
  FilterPropertyRuleSelectableValueDoctypeFragment,
  PropertyValueFragment,
  ViewType,
} from '@cycle-app/graphql-codegen/generated';
import { Emoji } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { getAttributeName } from 'src/utils/attributes.util';
import { isFilterDoctypePropertyRule } from 'src/utils/boardConfig/boardConfig.util';
import { isCustom } from 'src/utils/docType.util';
import { getGroupName } from 'src/utils/groups.util';

import { PreviewCard } from './PreviewCard';
import {
  PreviewBox,
  GroupContent,
  GroupContainer,
  GroupList,
  GroupStyled,
  SwimlaneContainer,
  SwimlaneContent,
} from './ViewPreview.styles';

const MAX_ITEMS = 2;

export const ViewPreview = ({
  draftBoardConfig, isFeedbackView,
}: {
  draftBoardConfig: DraftBoardConfigFragment;
  isFeedbackView?: boolean;
}) => {
  const {
    viewType, docQuery, filterProperties,
  } = draftBoardConfig;

  const doctypesFilter = useMemo(() => filterProperties.edges
    .find(isFilterDoctypePropertyRule)?.node, [filterProperties.edges]);

  const doctypesFilterArray = useMemo(() => nodeToArray(doctypesFilter?.doctypeRule.values), [doctypesFilter]);
  const doctypesFilterSelected = useMemo(() => doctypesFilterArray
    .filter(doctypeFilter => doctypeFilter.selected), [doctypesFilterArray]);
  const doctypesFilterArrayAllSelected =
  doctypesFilterArray.every(doctypeFilter => !doctypeFilter.selected) ||
  doctypesFilterArray.every(doctypeFilter => doctypeFilter.selected);
  const doctypes = useMemo(() => (doctypesFilterArrayAllSelected ? doctypesFilterArray : doctypesFilterSelected),
    [doctypesFilterArray, doctypesFilterArrayAllSelected, doctypesFilterSelected]);

  const isGrouped = (docQuery.__typename === 'BoardQueryWithGroupBy' || docQuery.__typename === 'BoardQueryWithSwimlaneBy');
  const isKanban = viewType === ViewType.Kanban;

  const isSwimlaneBy = isKanban && docQuery.__typename === 'BoardQueryWithSwimlaneBy';
  const attributeName = isGrouped ? getAttributeName(docQuery.groupbyConfig.property, isFeedbackView) : null;

  const groupConfigArray = useMemo(() => ((
    docQuery.__typename === 'BoardQueryWithGroupBy' ||
    docQuery.__typename === 'BoardQueryWithSwimlaneBy') &&
    nodeToArray(docQuery.groupbyConfig.values)) || [], [docQuery]);

  const cards = useMemo(() => {
    const cardsList = doctypes.slice(0, MAX_ITEMS);
    // Edge case where the product do not have any doctypes.
    if (!cardsList.length) {
      return [createFakeCard({
        id: 'card-1',
        emoji: 'cherry_blossom',
      }), createFakeCard({
        id: 'card-2',
        emoji: 'rainbow',
      })];
    }
    // UX: we should always display at least two cards.
    if (cardsList.length <= 1) {
      return [
        ...cardsList,
        createFakeCard({
          id: 'clone',
        }, cardsList[0]),
      ];
    }
    return cardsList;
  }, [doctypes]);

  const groups = useMemo(() => {
    const groupList = groupConfigArray.filter((group) => group.propertyValue && getGroupName(group, attributeName));
    const groupListOrdered = (
      groupList.every(group => group.propertyValue?.__typename === 'Doctype')
        // UX: make sure doctypes and properties are in the same order.
        ? cards.map(card => (doctypesFilterArrayAllSelected || card.selected) && groupList.find(group => group.propertyValue?.id === card.value.id))
        : groupList)
      .filter(Boolean)
      .slice(0, MAX_ITEMS);

    if (isGrouped && !groupListOrdered.length) {
      // Edge case where the product do not have any groupable values.
      return [createFakeGroup({
        id: 'group-1',
        text: '🎨 In design',
      }), createFakeGroup({
        id: 'group-2',
        text: '✅ Done',
      })];
    }
    return groupListOrdered;
  }, [cards, groupConfigArray, isGrouped, doctypesFilterArrayAllSelected, attributeName]);

  const {
    showComments, showAvatar,
  } = useMemo(() => {
    const properties = nodeToArray(draftBoardConfig.properties);
    return {
      showComments: properties.some(p => p.displayed && p.property.__typename === 'CommentDefinition'),
      showAvatar: properties.some(p => p.displayed &&
        (p.property.__typename === 'BuiltInCustomerDefinition' || p.property.__typename === 'AssigneeDefinition')),
    };
  }, [draftBoardConfig.properties]);

  return (
    <PreviewBox>
      {renderGroupList({ hasSwimlaneAfter: isSwimlaneBy })}
      {isSwimlaneBy && (
        <>
          {docQuery.__typename === 'BoardQueryWithSwimlaneBy' && (
            <SwimlaneContainer>
              <SwimlaneContent>
                {docQuery.swimlanebyConfig.doctype?.emoji && isCustom(docQuery.swimlanebyConfig.doctype) && (
                  <Emoji emoji={docQuery.swimlanebyConfig.doctype.emoji} />
                )}
                <span>
                  {docQuery.swimlanebyConfig.doctype?.name}
                </span>
              </SwimlaneContent>
            </SwimlaneContainer>
          )}
          {renderGroupList({ hasSwimlaneBefore: true })}
        </>
      )}
    </PreviewBox>
  );

  type GroupListProps = {
    hasSwimlaneAfter?: boolean;
    hasSwimlaneBefore?: boolean;
  };

  function renderGroupList({
    hasSwimlaneAfter, hasSwimlaneBefore,
  }: GroupListProps) {
    return viewType && (
      <GroupList $isRow={isKanban}>
        {isGrouped
          ? groups
            .map(group => group && (
              <GroupContainer
                key={group.id}
                $isRow={isKanban}
              >
                <GroupStyled
                  $hasSpaceAfter={hasSwimlaneAfter}
                  $hasSpaceBefore={hasSwimlaneBefore}
                  inputName={!hasSwimlaneBefore && (
                    <h2>
                      {getGroupName(group, attributeName)}
                    </h2>
                  )}
                  viewType={viewType}
                  withGroupBy
                >
                  <GroupContent>
                    {cards.slice(hasSwimlaneBefore ? 1 : 0, hasSwimlaneAfter ? 1 : MAX_ITEMS).map(doc => (
                      <PreviewCard
                        key={doc.id}
                        emoji={isCustom(doc.value) ? doc.value.emoji : undefined}
                        viewType={viewType}
                      />
                    ))}
                  </GroupContent>
                </GroupStyled>
              </GroupContainer>
            ))
          : (
            <GroupContainer $isRow={isKanban}>
              <GroupStyled viewType={viewType}>
                <GroupContent>
                  {cards.map(doc => (
                    <PreviewCard
                      key={doc.id}
                      emoji={isCustom(doc.value) ? doc.value.emoji : undefined}
                      viewType={viewType}
                      showComments={showComments}
                      showAvatar={showAvatar}
                    />
                  ))}
                </GroupContent>
              </GroupStyled>
            </GroupContainer>
          )}
      </GroupList>
    );
  }
};

function createFakeGroup({
  id, text,
}: { id: string; text: string }): { id: string; name: string; propertyValue: PropertyValueFragment } {
  return {
    id,
    name: '',
    propertyValue: {
      __typename: 'AttributeTextValue',
      id: '',
      text,
    },
  };
}

function createFakeCard({
  id, emoji,
}: { id: string; emoji?: string }, fromCard?: FilterPropertyRuleSelectableValueDoctypeFragment): FilterPropertyRuleSelectableValueDoctypeFragment {
  return fromCard
    ? {
      ...fromCard,
      selected: false,
      id: `${fromCard.id}-${id}`,
    }
    : {
      id,
      selected: true,
      value: {
        __typename: 'Doctype',
        id: '',
        emoji: emoji ?? 'cherry_blossom',
        name: '',
        category: DoctypeCategory.Discovery,
        type: DoctypeType.Custom,
      },
    };
}
