import { SelectPanel, Spinner } from '@cycle-app/ui';
import { FC } from 'react';
import { useController, Control } from 'react-hook-form';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { useGithubProject } from 'src/hooks/api/queries/integrations/useGithubProject';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setLastGitHubDataUsed } from 'src/reactives/githubRepository.reactive';
import { CreateGithubIssueFormValues } from 'src/types/integrations.types';
import { Layer } from 'src/types/layers.types';

import { Container, Label, Caret, Select } from './ProjectsField.styles';

type Option = {
  label: string;
  value: string;
};

type Props = {
  control: Control<CreateGithubIssueFormValues>;
};

export const ProjectsField: FC<React.PropsWithChildren<Props>> = ({ control }) => {
  const { projects } = useGithubProject();

  const [isRepositoriesVisible, {
    setTrueCallback, setFalseCallback,
  }] = useOptimizedBooleanState(false);

  const {
    field: {
      onChange, onBlur, name, ref, value,
    },
  } = useController({
    name: 'project',
    control,
    rules: { required: true },
    defaultValue: undefined,
  });

  const options: Option[] = projects?.map(p => ({
    label: p?.title ?? '',
    value: p?.id ?? '',
  })) || [];

  return (
    <Container>
      <Label>
        Project
      </Label>
      <DropdownLayer
        layer={Layer.DropdownModal}
        visible={isRepositoriesVisible}
        hide={() => {
          onBlur();
          setFalseCallback();
        }}
        placement="bottom-start"
        content={(
          <SelectPanel
            selectedValue={value?.id}
            options={options}
            onOptionChange={onRepositorySelected}
          />
        )}
      >
        <Select
          name={name}
          ref={ref}
          onClick={setTrueCallback}
          type="button"
          forceFocus={isRepositoriesVisible}
          disabled={!projects?.length}
        >
          {value ? value.title : <Spinner />}
          <Caret />
        </Select>
      </DropdownLayer>
    </Container>
  );

  function onRepositorySelected(option: Option) {
    const project = projects?.find(p => p?.id === option.value);

    if (!project) return;

    onChange(project);
    setLastGitHubDataUsed(project.id, 'project');
    setFalseCallback();
  }
};
