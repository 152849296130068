import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import type { ItemPropsFunction } from '@cycle-app/utilities';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { getDocTypeName } from 'src/utils/docType.util';

import { DocTypeIcon } from '../DocTypeIcon';
import { ButtonContent, NewItemButton, NewItemButtonLabel, StyledDocLinearAutoCreate } from './DocSearchDropdown.styles';

interface Props {
  doctype: Pick<DoctypeFragment, 'type' | 'emoji' | 'name' | 'id'>;
  getItemProps: ItemPropsFunction;
  isHoverDisabled: boolean;
  isLoading: boolean;
  isSelected: boolean;
  onWithLinearChange?: (doctypeId: string, checked: boolean) => void;
  search: string;
  showLinearAutoCreate?: boolean;
  value: string;
  withLinearChecked?: boolean;
}

export const AddNewItemLineButton = ({
  doctype,
  getItemProps,
  isHoverDisabled,
  isLoading,
  isSelected,
  onWithLinearChange,
  showLinearAutoCreate,
  value,
  withLinearChecked,
}: Props) => {
  const isLinearInstalled = useWorkspaceContext(ctx => ctx.isLinearInstalled);
  return (
    <NewItemButton
      tabIndex={0}
      $hoverDisabled={isHoverDisabled}
      $isSelected={isSelected}
      $variant="secondary"
      $size="S"
      {...getItemProps(value)}
    >
      <NewItemButtonLabel>
        {isLoading ? <Spinner /> : (
          <DocTypeIcon
            doctype={doctype}
            size={14}
          />
        )}
        <ButtonContent>
          {`Create ${getDocTypeName(doctype)}`}
        </ButtonContent>
      </NewItemButtonLabel>
      {isLinearInstalled && showLinearAutoCreate && onWithLinearChange && (
        <StyledDocLinearAutoCreate
          checked={!!withLinearChecked}
          doctypeId={doctype.id}
          hideTooltip
          onChange={(checked) => onWithLinearChange(doctype.id, checked)}
        />
      )}
    </NewItemButton>
  );
};
