import { FC } from 'react';

import { WarningIcon } from '../../icons';
import { ActionButton } from '../Buttons/ActionButton/ActionButton';

interface Props {
  tooltip?: string;
}

export const Warning: FC<React.PropsWithChildren<Props>> = ({ tooltip = 'This will hide the doc from current view' }) => (
  <ActionButton
    tooltipPlacement="top"
    tooltip={tooltip}
  >
    <WarningIcon />
  </ActionButton>
);
