import { Icon } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { twJoin } from 'tailwind-merge';

import { ProductAreaTag } from 'src/components/DocProductAreas/DocProductAreas';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { ProductAreasManager } from 'src/components/ProductAreasManager';
import { useDoc } from 'src/hooks';
import { useDocProductAreaUpdate } from 'src/hooks/doc/useDocProductAreaUpdate';
import { useLocationSelector } from 'src/hooks/router/useLocation';
import { useBoardConfigCompatibility } from 'src/hooks/useCompatibility';
import { Layer } from 'src/types/layers.types';
import { getPublishedBoardConfig } from 'src/utils/boardConfig/boardConfig.util';
import { getPathParams } from 'src/utils/routing.utils';

import { useQuoteId } from './QuoteContext';

export const QuotePanelProductAreas = () => {
  const docId = useQuoteId();
  const { doc } = useDoc(docId);
  const productArea = doc?.productAreas.edges[0]?.node;
  const productAreaUpdate = useDocProductAreaUpdate();

  // Use the boardConfig of the current view if the quote panel is opened from a group
  const groupId = useLocationSelector(location => location.state?.groupId);
  const boardId = groupId ? getPathParams(location.pathname).boardId : undefined;
  const boardConfig = getPublishedBoardConfig(boardId);

  const {
    compatibleProductAreas,
    isProductAreaReadonly,
  } = useBoardConfigCompatibility(boardConfig);

  return (
    <ToggleDropdown
      layer={Layer.DropdownModalZ3}
      placement="bottom"
      button={props => {
        if (productArea) {
          return (
            <ProductAreaTag
              icon={(
                <Icon
                  name="tri-shapes"
                  className="text-grey-400 flex-none dark:text-grey-600"
                />
              )}
              value={productArea.value}
              isDisabled={isProductAreaReadonly}
              onClick={props.onClick}
              limitSize={false}
              className="min-w-fit! whitespace-nowrap"
            />
          );
        }
        if (!isProductAreaReadonly) {
          return (
            <button
              onClick={props.onClick}
              className={twJoin(
                'btn btn-tertiary btn-square size-5 text-disabled',
                props['data-active'] && 'btn-hover text-primary',
              )}
            >
              <AddIcon size={12} />
            </button>
          );
        }

        return null;
      }}
      content={() => (
        <ProductAreasManager
          docTypeName="Quote"
          hideIncompatibleValues={false}
          isMulti={false}
          isRequired={!productArea}
          onSelect={updateData => productAreaUpdate.update({
            docId: docId,
            productAreaId: updateData.productAreaId,
            isMulti: updateData.isMulti,
            isIncompatible: updateData.isIncompatible,
          })}
          onClearValue={(removeData) => {
            if (!productArea) return;
            return productAreaUpdate.remove({
              docId: docId,
              productAreaId: productArea.id,
              isIncompatible: removeData.isIncompatible,
            });
          }}
          selectedValues={[]}
          compatibleIds={compatibleProductAreas.map(area => area.id)}
        />
      )}
    />
  );
};
