import { Emoji } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { ParentPopover } from 'src/components/ParentPopover/ParentPopover';
import { QuoteFeatureDropdown } from 'src/components/QuoteCard/QuoteFeatureDropdown';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useDocBase } from 'src/hooks/api/useDoc';
import { useGetDocType } from 'src/reactives/docTypes.reactive';

import { useQuoteId } from './QuoteContext';

export const QuotePanelFeature = () => {
  const quoteId = useQuoteId();
  const { doc } = useDocBase(quoteId);
  const quoteDoctype = useGetDocType(doc?.doctype?.id);
  const featureDoctype = useGetDocType(doc?.parent?.doctype?.id);
  const mainBtnRef = useRef<HTMLButtonElement>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const changeDocParent = useChangeDocParent();

  if (!doc || !quoteDoctype) return null;

  return (
    <>
      <div className="flex items-center gap-1.5">
        <button
          ref={mainBtnRef}
          onClick={() => setDropdownVisible(true)}
          className={twJoin(
            'btn-tertiary gap-1.5 overflow-hidden bg-grey-100 hover:bg-grey-200 hover:text-primary dark:bg-grey-850 dark:hover:bg-grey-800',
            doc.parent || dropdownVisible ? 'text-primary' : 'text-secondary',
            dropdownVisible && 'btn-hover',
          )}
        >
          {doc.parent ? (
            <>
              <Emoji emoji={featureDoctype?.emoji} />
              <span className="truncate">
                {doc.parent.title.trim() || 'Untitled'}
              </span>
            </>
          ) : (
            <>
              <AddIcon
                size={12}
                className="mx-0.5"
              />
              Link feature
            </>
          )}
        </button>
      </div>

      {doc.parent && (
        <ParentPopover
          parentDocId={doc.parent.id}
          reference={mainBtnRef}
          onClickChange={() => setDropdownVisible(true)}
          onClickUnlink={() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            changeDocParent({
              docId: doc.id,
              parentId: undefined,
            });
          }}
        />
      )}

      <QuoteFeatureDropdown
        docId={doc.id}
        reference={mainBtnRef}
        visible={dropdownVisible}
        hide={() => setDropdownVisible(false)}
      />
    </>
  );
};
