import { ChangelogDateStyle } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import { format, type Locale } from 'date-fns';
import {
  enUS, fr, de, es, it, nl,
} from 'date-fns/locale';

import { ChangelogDropdown, ChangelogDropdownContent } from '../shared/ChangelogDropdown';
import { ChangelogRadioInput } from '../shared/ChangelogRadioInput';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

const displayedDates = [
  new Date(), // today
  new Date(new Date().setDate(new Date().getDate() - 7)), // last week
  new Date(new Date().setMonth(new Date().getMonth() - 1)), // last month
] as const;

type TranslateObject = { translations: Locale; format: string };

const localesWithFormats: Record<string, TranslateObject> = {
  en: {
    translations: enUS,
    format: 'MMM dd, yyyy',
  },
  de: {
    translations: de,
    format: 'dd. MMM yyyy',
  },
  fr: {
    translations: fr,
    format: 'dd MMM yyyy',
  },
  es: {
    translations: es,
    format: 'dd MMM yyyy',
  },
  it: {
    translations: it,
    format: 'dd MMM yyyy',
  },
  nl: {
    translations: nl,
    format: 'dd MMM yyyy',
  },
};

function formatDate(date: string | Date, locale: string = 'en') {
  const localeObject = localesWithFormats[locale] ?? localesWithFormats.en as TranslateObject;

  return format(new Date(date), localeObject.format, { locale: localeObject?.translations });
}

export function ChangelogBuilderContentDates() {
  const { watch } = useChangelogBuilderForm();
  const language = watch('language');
  const dateStyle = watch('dateStyle');

  return (
    <div className="sticky top-8 flex">
      <ChangelogDropdown
        content={({ hide }) => (
          <ChangelogDropdownContent
            title="Dates"
            hide={hide}
          >
            <DatesPopover />
          </ChangelogDropdownContent>
        )}
        empty={dateStyle === ChangelogDateStyle.Hidden}
      >
        <div className="w-28">
          {dateStyle === ChangelogDateStyle.Stacked && (
            <ul className="flex flex-col gap-2">
              {displayedDates.map((date, index) => (
                <li
                  key={index}
                  className="text-(--changelog-body-color) first:mb-2 first:font-semibold"
                >
                  {formatDate(date, watch('language'))}
                </li>
              ))}
            </ul>
          )}
          {dateStyle === ChangelogDateStyle.Single && (
            <div className="font-semibold text-(--changelog-body-color)">
              {formatDate(displayedDates[0], language)}
            </div>
          )}

          {dateStyle === ChangelogDateStyle.SingleTitle && (
            <div className="text-(--changelog-body-color)">
              <div className="text-lg font-semibold">
                Release title
              </div>
              <div className="mt-2 opacity-70">
                {formatDate(displayedDates[0], language)}
              </div>
            </div>
          )}
          {dateStyle === ChangelogDateStyle.Hidden && (
            <div className="flex h-24 items-center justify-center gap-2 text-(--changelog-body-color)">
              <Icon name="plus" />
              Dates
            </div>
          )}
        </div>
      </ChangelogDropdown>
    </div>
  );
}

function DatesPopover() {
  const { register } = useChangelogBuilderForm();

  return (
    <>
      <div className="mb-2 font-medium">
        Style
      </div>
      <ChangelogRadioInput
        label="Stacked"
        support={(
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="4"
              y="13"
              width="21"
              height="3"
              rx="1.5"
              fill="#D9D9D9"
            />
            <rect
              x="4"
              y="8"
              width="21"
              height="3"
              rx="1.5"
              fill="#D9D9D9"
            />
            <rect
              x="4"
              y="18"
              width="21"
              height="3"
              rx="1.5"
              fill="#D9D9D9"
            />
          </svg>
        )}
        value={ChangelogDateStyle.Stacked}
        {...register('dateStyle')}
      />
      <ChangelogRadioInput
        label="Single"
        support={(
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="5"
              y="13"
              width="20"
              height="4"
              rx="2"
              fill="#D9D9D9"
            />
          </svg>
        )}
        value={ChangelogDateStyle.Single}
        {...register('dateStyle')}
      />
      <ChangelogRadioInput
        label="Release title + date"
        support={(
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="5"
              y="16"
              width="12"
              height="4"
              rx="2"
              fill="#D9D9D9"
            />
            <rect
              x="5"
              y="10"
              width="20"
              height="4"
              rx="2"
              fill="#D9D9D9"
            />
          </svg>
        )}
        value={ChangelogDateStyle.SingleTitle}
        {...register('dateStyle')}
      />
      <ChangelogRadioInput
        label="Hidden"
        value={ChangelogDateStyle.Hidden}
        {...register('dateStyle')}
      />
    </>
  );
}
