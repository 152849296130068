import { CustomerAvatar, Tooltip, Icon } from '@cycle-app/ui';
import { MouseEventHandler } from 'react';

import { useCustomerFragment } from 'src/hooks/api/fragments/customer';
import { closeCompanyModal, openCompanyModal } from 'src/hooks/modals/useCompanyModal';
import { openCustomerModal } from 'src/hooks/modals/useCustomerModal';
import { copyToClipboard } from 'src/utils/clipboard.utils';

type Props = {
  customerId: string | null | undefined;
  onProfileOpen?: MouseEventHandler<HTMLButtonElement>;
  isReadOnly?: boolean;
  onClickUpdate?: VoidFunction;
};

export const DocCompanyCustomerModalContent = ({
  customerId,
  onProfileOpen,
  isReadOnly = false,
  onClickUpdate,
}: Props) => {
  const customer = useCustomerFragment(customerId);
  if (!customer) return null;

  const name = customer.name;
  const email = customer.email;
  const company = customer.company;

  return (
    <div
      className="relative min-w-80 max-w-96 cursor-auto rounded-[10px] border border-grey-300 bg-white text-primary shadow-z2 dark:border-grey-800 dark:bg-grey-850"
      onClick={e => e.stopPropagation()}
      onWheel={e => e.stopPropagation()}
    >
      <div className="flex items-center gap-2.5 p-3 pb-2">
        <div className="flex size-9 items-center justify-center">
          <CustomerAvatar
            customer={customer}
            hideCompany
            size="S-M"
            className="scale-125"
          />
        </div>

        <div className="space-y-0.5">
          {name && (
            <div className="text-body font-medium leading-snug text-primary">
              {name}
            </div>
          )}

          <div className="overflow-anywhere text-body leading-snug text-secondary">
            {email}

            {!!email && !!company && (
              <span className="mx-1.5">
                ·
              </span>
            )}

            {company && (
              <Tooltip
                content="Open company"
                placement="top"
                withPortal
                withWrapper={false}
              >
                <button
                  className="cursor-pointer p-0 text-body"
                  onClick={(e) => {
                    e.stopPropagation();
                    onProfileOpen?.(e);
                    openCompanyModal(company.id);
                  }}
                >
                  {company.logo && (
                    <img
                      src={company.logo}
                      className="mr-1.5 inline size-4 rounded-full object-cover align-sub"
                    />
                  )}
                  {company.name}
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center gap-1 border-t border-grey-200 p-1 dark:border-grey-800">
        <button
          className="btn-tertiary"
          onClick={e => {
            closeCompanyModal();
            onProfileOpen?.(e);
            openCustomerModal(customer.id);
          }}
        >
          <Icon
            name="arrow-right-up"
            className="size-3.5"
          />
          Open
        </button>

        {email && (
          <button
            className="btn-tertiary"
            onClick={() => {
              copyToClipboard({
                text: email,
                notification: 'Email copied to clipboard',
              });
            }}
          >
            <Icon name="copy" />
            Copy email
          </button>
        )}

        {!isReadOnly && onClickUpdate && (
          <button
            className="btn-tertiary"
            onClick={e => {
              e.stopPropagation();
              onClickUpdate();
            }}
          >
            <Icon name="edit" />
            Change customer
          </button>
        )}
      </div>
    </div>
  );
};
