import { SelectOption, Icon } from '@cycle-app/ui';
import { QuoteIcon } from '@cycle-app/ui/icons';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useSectionFilter, setSectionFilter } from 'src/reactives/commandbar.reactive';
import { Layer } from 'src/types/layers.types';

import { SectionSelectPanel, FilterButton, ArrowIcon } from './CommandSectionFilter.styles';

export const CommandSectionFilter = () => {
  const sectionFilter = useSectionFilter();

  const isSingle = Object.values(sectionFilter).filter(Boolean).length < 2;

  const options: SelectOption[] = [
    {
      label: 'Feedback',
      value: 'feedback',
      icon: <Icon name="feedback" />,
      selected: sectionFilter.feedback,
      disabled: sectionFilter.feedback && isSingle,
    },
    {
      label: 'Quotes',
      value: 'quotes',
      icon: <QuoteIcon />,
      selected: sectionFilter.quotes,
      disabled: sectionFilter.quotes && isSingle,
    },
    {
      label: 'Features',
      value: 'features',
      icon: (
        <Icon
          name="feature"
          className="mx-px size-3.5"
        />
      ),
      selected: sectionFilter.features,
      disabled: sectionFilter.features && isSingle,
    },
  ];

  return (
    <ToggleDropdown
      layer={Layer.Commandbar}
      placement="bottom-start"
      button={props => (
        <FilterButton {...props}>
          {sectionFilter.feedback && (
            <Icon
              name="feedback"
              className="size-3.5"
            />
          )}
          {sectionFilter.quotes && <QuoteIcon size={14} />}
          {sectionFilter.features && (
            <Icon
              name="feature"
              className="size-3.5"
            />
          )}
          {isSingle && options.find(option => option.selected)?.label}
          <ArrowIcon size={16} />
        </FilterButton>
      )}
      content={() => (
        <SectionSelectPanel
          isMulti
          hideSearch
          selectType="toggle"
          options={options}
          onSelectOption={option => setSectionFilter({ [option.value]: true })}
          onUnselectOption={option => setSectionFilter({ [option.value]: false })}
        />
      )}
    />
  );
};
