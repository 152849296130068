import { Tooltip, Shortcut } from '@cycle-app/ui';
import { UpIcon, DownIcon } from '@cycle-app/ui/icons';

import { ShortcutDocPanel, shortcuts } from 'src/constants/shortcuts.constants';
import useAppHotkeys from 'src/hooks/useAppHotkeys';

export const QuotePrevNext = ({
  isPrevDisabled, isNextDisabled, openPrev, openNext,
}: {
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
  openPrev: () => void;
  openNext: () => void;
}) => {
  useAppHotkeys('k', openPrev, { enabled: () => !isPrevDisabled });
  useAppHotkeys('j', openNext, { enabled: () => !isNextDisabled });

  return (
    <>
      <Tooltip
        content={(
          <Shortcut
            keys={shortcuts[ShortcutDocPanel.PrevDoc]}
            label="Previous"
          />
        )}
        placement="top"
        withWrapper={false}
      >
        <button
          className="btn-tertiary btn-square"
          disabled={isPrevDisabled}
          onClick={openPrev}
        >
          <UpIcon />
        </button>
      </Tooltip>

      <Tooltip
        content={(
          <Shortcut
            keys={shortcuts[ShortcutDocPanel.NextDoc]}
            label="Next"
          />
        )}
        placement="top"
        withWrapper={false}
      >
        <button
          className="btn-tertiary btn-square"
          disabled={isNextDisabled}
          onClick={openNext}
        >
          <DownIcon />
        </button>
      </Tooltip>
    </>
  );
};
