import { CustomerFragment } from '@cycle-app/graphql-codegen';
import { CustomerAvatar, CompanyLogo, TextHighlighter } from '@cycle-app/ui';
import { FC } from 'react';

import { Company, Container, Name, Email, Text } from './CommandCustomerAction.styles';

interface Props {
  customer: CustomerFragment;
  searchText: string;
}

export const CommandCustomerAction: FC<React.PropsWithChildren<Props>> = ({
  customer, searchText,
}) => {
  return (
    <Container>
      <Name>
        <CustomerAvatar
          size="S"
          customer={customer}
          hideCompany
        />
        <Text>
          {customer.name && (
            <TextHighlighter
              searchWords={[searchText]}
              textToHighlight={customer.name}
              className="highlight"
            />
          )}
        </Text>
      </Name>
      <Company>
        {customer.company && (
          <>
            <CompanyLogo
              company={customer.company}
              size="S"
            />
            <Text>
              {customer.company.name && (
                <TextHighlighter
                  searchWords={[searchText]}
                  textToHighlight={customer.company.name}
                  className="highlight"
                />
              )}
            </Text>
          </>
        )}
      </Company>
      <Email>
        {customer.email && (
          <TextHighlighter
            searchWords={[searchText]}
            textToHighlight={customer.email}
            className="highlight"
          />
        )}
      </Email>
    </Container>
  );
};
