import { Icon } from '@cycle-app/ui';
import { ZoomIcon, MicrosoftTeamsIcon } from '@cycle-app/ui/icons';

import { IconsGroup } from './CallRecordingIcons.styles';

export const CallRecordingIcons = () => (
  <IconsGroup>
    <ZoomIcon />
    <Icon
      name="brand/google-meet"
      className="size-4"
    />
    <MicrosoftTeamsIcon />
  </IconsGroup>
);
