import { IntegrationType } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';

import { Events } from 'src/constants/analytics.constants';
import { integrationsDataMap } from 'src/constants/integrations.constants';
import { useOptimizedBooleanState } from 'src/hooks';
import { useRemoveIntegration } from 'src/hooks/api/mutations/integrations/useRemoveIntegration';
import { useSlackIntegration } from 'src/hooks/slack/useSlackIntegration';
import { useSlackWorkspace } from 'src/hooks/slack/useSlackWorkspace';
import { closeSlackSettingsModal } from 'src/reactives/slackSettingsModal.reactive';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { integrationNameTitles } from 'src/utils/integrations.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { SlackActionItem } from '../SlackActionItem';
import { ContentHeading, ContentHeader, ContentBody } from '../SlackSettingsModal.styles';
import { WsLogo, RemoveModalStyled } from './WorkspaceTab.styles';

export const WorkspaceTab = () => {
  const { integration } = useSlackIntegration();
  const {
    name, logo, isLoading,
  } = useSlackWorkspace();
  const {
    remove, isLoading: isRemoveLoading,
  } = useRemoveIntegration();
  const [isDeleteModalOpen, {
    setTrueCallback: showDeleteModal, setFalseCallback: hideDeleteModal,
  }] = useOptimizedBooleanState(false);

  const onUninstall = async () => {
    if (integration?.id) {
      const result = await remove(integration.id);
      if ('removeIntegration' in (result?.data || {})) {
        closeSlackSettingsModal();
        trackAnalytics(Events.IntegrationRemoved, {
          type: IntegrationType.Slack,
        });
        addToaster({
          title: 'Successfully uninstalled',
          message: `${integrationNameTitles[IntegrationType.Slack]} has been successfully uninstalled`,
        });
      }
    }
  };

  return (
    <div>
      <ContentHeader>
        <ContentHeading>
          Connected Slack workspaces
        </ContentHeading>
      </ContentHeader>
      <ContentBody>
        <ul>
          {isLoading && <SlackActionItem isLoading />}
          {name && (
            <SlackActionItem
              icon={logo && (
                <WsLogo
                  src={logo}
                  alt={logo}
                />
              )}
              text={name}
              action={(
                <Button
                  active={false}
                  variant="outlined"
                  onClick={showDeleteModal}
                >
                  Disconnect
                </Button>
              )}
            />
          )}
        </ul>
      </ContentBody>
      {isDeleteModalOpen && (
        <RemoveModalStyled
          title={integrationsDataMap[IntegrationType.Slack].remove?.title || 'Uninstall Slack'}
          hide={hideDeleteModal}
          info={(
            <p>
              {integrationsDataMap[IntegrationType.Slack].remove?.text ??
                `Are you sure you want to uninstall ${integrationNameTitles[IntegrationType.Slack]} integration?`}
            </p>
          )}
          onConfirm={onUninstall}
          loading={isRemoveLoading}
        />
      )}
    </div>
  );
};
