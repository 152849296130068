import { IntegrationType, UpdateProductTemplateDocument, Feature } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { useListNav } from '@cycle-app/utilities';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { templates } from 'src/components/RoadmapTemplatesModal/roadmapTemplates.data';
import type { Template } from 'src/components/RoadmapTemplatesModal/roadmapTemplates.data';
import { TemplateHierarchy } from 'src/components/RoadmapTemplatesModal/TemplateHierarchy';
import { PageId } from 'src/constants/routing.constant';
import {
  useIntegration, useFeatureFlagUpdate, useFeatureFlagReload, useSafeMutation, useProductBase, useNavigate,
} from 'src/hooks';
import { useInstallIntegration } from 'src/hooks/useInstallIntegration';
import { useFeatures } from 'src/reactives/features.reactive';
import { Layer } from 'src/types/layers.types';

import {
  STEP_CTA, STEP_DESCRIPTION, STEP_TITLE, step1Content, step3Content, step4Content,
} from './EnableRoadmapModal.constants';
import {
  Modal,
  ModalTitle,
  ModalDescription,
  Form,
  SelectButton,
  Caret,
  PanelContainer,
  Lines,
  TemplatePreview,
  CloseButton,
  LinearButton,
  StyledSelectLine,
} from './EnableRoadmapModal.styles';

export const EnableRoadmapModal = () => {
  const [{ enablingFeature }, set] = useFeatures();
  if (enablingFeature !== Feature.Roadmap) return null;
  return <EnableRoadmapModalContent hide={() => set({ enablingFeature: null })} />;
};

const EnableRoadmapModalContent = ({ hide }: { hide: VoidFunction }) => {
  const product = useProductBase();
  const [template, setTemplate] = useState<Template | undefined>(undefined);
  const [step, setStep] = useState(0);
  const { integration } = useIntegration({ type: IntegrationType.Linear });
  const {
    activateFeature, loading: featureFlagLoading,
  } = useFeatureFlagUpdate();
  const reloadFlags = useFeatureFlagReload();
  const install = useInstallIntegration();
  const [updateProductTemplate, { loading: isProductTemplateLoading }] = useSafeMutation(UpdateProductTemplateDocument);
  const { navigate } = useNavigate();

  const onHide = () => {
    // If user hide after enabling roadmap, we should redirect
    if (step > 1) {
      navigate(PageId.Roadmap);
    }
    hide();
  };

  return (
    <Modal hide={onHide}>
      {step <= 1 && (
        <CloseButton onClick={onHide}>
          <CloseIcon />
        </CloseButton>
      )}
      <ModalTitle $hasPaddingBottom={!STEP_DESCRIPTION[step]}>
        {STEP_TITLE[step]}
      </ModalTitle>
      {STEP_DESCRIPTION[step] && (
        <ModalDescription>
          {STEP_DESCRIPTION[step]}
        </ModalDescription>
      )}
      {(() => {
        switch (step) {
          case 3:
            return step4Content;
          case 2:
            return step3Content;
          case 1:
            return <StepTemplateContent onTemplateChange={setTemplate} />;
          case 0:
          default:
            return step1Content;
        }
      })()}
      {step === 3 && (
        <LinearButton onClick={async () => {
          if (!integration?.type) return;
          onHide();
          await install(integration.type, integration);
        }}
        >
          Enable Linear integration
        </LinearButton>
      )}
      <Button
        size="L"
        style={{
          width: '100%',
          marginTop: '20px',
          maxHeight: '32px',
        }}
        onClick={async () => {
          switch (step) {
            case 3: {
              onHide();
              break;
            }
            case 2:
              setStep(step + 1);
              break;
            case 1: {
              if (template && product?.id) {
                await updateProductTemplate({
                  variables: {
                    productId: product.id,
                    templateType: template.type,
                  },
                });
              }
              const featureFlagUpdated = await activateFeature(Feature.Roadmap);
              if (featureFlagUpdated) reloadFlags();
              setStep(step + 1);
              break;
            }
            case 0:
            default:
              setStep(step + 1);
          }
        }}
        isLoading={featureFlagLoading || isProductTemplateLoading}
      >
        {STEP_CTA[step]}
      </Button>
    </Modal>
  );
};

type StepTemplateContentProps = {
  onTemplateChange: (template: Template | undefined) => void;
};

const StepTemplateContent = ({ onTemplateChange }: StepTemplateContentProps) => {
  const {
    watch,
    setValue,
  } = useForm<{ template: string }>({
    defaultValues: { template: '' },
  });
  const selectedTemplateType = watch('template');
  const selectedTemplate = templates.find(t => t.type === selectedTemplateType);
  useEffect(() => { onTemplateChange(selectedTemplate); }, [onTemplateChange, selectedTemplate]);
  return (
    <>
      <Form>
        <ToggleDropdown
          placement="bottom"
          layer={Layer.DropdownModalZ2}
          button={props => (
            <SelectButton
              disabled={false}
              {...props}
            >
              <span>
                {selectedTemplate?.name || 'No template'}
              </span>
              <Caret direction={props['data-active'] ? 'top' : 'bottom'} />
            </SelectButton>
          )}
          content={props => (
            <TemplatePanel
              onSelect={templateType => {
                if (templateType) {
                  setValue('template', templateType);
                  props.hide();
                }
              }}
            />
          )}
        />
        {selectedTemplate && (
          <div
            style={{
              width: '100%',
              height: '200px',
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          >
            <TemplateHierarchy template={selectedTemplate} />
          </div>
        )}
      </Form>
    </>
  );
};

type TemplatePanelProps = {
  onSelect: (templateType: string | null) => void;
};

const TemplatePanel = ({ onSelect }: TemplatePanelProps) => {
  const {
    listProps,
    itemProps,
    hovered,
  } = useListNav({
    optionsValues: ['none', ...templates.map(t => t.type)],
    value: 'none',
    onSelect,
    selectOnHover: true,
    autoFocus: true,
    defaultIndex: 0,
  });
  const selectedTemplateH = templates.find(t => t.type === hovered);
  return (
    <PanelContainer>
      <Lines {...listProps}>
        <StyledSelectLine
          label="No template"
          isSelected={hovered === 'none'}
          {...itemProps('none')}
        />
        {templates.map(t => (
          <StyledSelectLine
            key={t.name}
            label={t.name}
            isSelected={hovered === t.type}
            {...itemProps(t.type)}
          />
        ))}
      </Lines>
      <TemplatePreview>
        {selectedTemplateH && (
          <TemplateHierarchy
            template={selectedTemplateH}
            height={280}
          />
        )}
      </TemplatePreview>
    </PanelContainer>
  );
};
