import { GithubAssignee, IntegrationType } from '@cycle-app/graphql-codegen';
import { ActionButton, Tooltip } from '@cycle-app/ui';
import { AddMemberIcon } from '@cycle-app/ui/icons';
import { MouseEventHandler, useCallback, FC } from 'react';

import { GithubAssigneesSelectDropdown } from 'src/components/GithubAssigneesSelectDropdown/GithubAssigneesSelectDropdown';
import { DEFAULT_AVATARS } from 'src/constants/avatars.constants';
import { useChangeAssigneesGithubIssue } from 'src/hooks/api/mutations/integrations/useChangeAssigneesGithubIssue';
import { useGithubAssignees } from 'src/hooks/api/queries/integrations/useGithubAssignees';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';

import { Container, Image, MoreNumber, AvatarsContainer } from './GithubAssignees.styles';

const MAX_DISPLAYED_ELEMENT = 5;

type Props = {
  issueAssignees: GithubAssignee[];
  issuePublicId?: string;
  repository?: string;
};
export const GithubAssignees: FC<React.PropsWithChildren<Props>> = ({
  issueAssignees = [], issuePublicId, repository,
}) => {
  const [isAssigneeModalOpen, { toggleCallback }] = useOptimizedBooleanState(false);
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Github);
  const { assignees } = useGithubAssignees();
  const {
    change, isLoading: isChangeAssigneesLoading,
  } = useChangeAssigneesGithubIssue();

  const handleClick: MouseEventHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleCallback();
  }, []);

  return (
    <Container>
      <GithubAssigneesSelectDropdown
        assignees={assignees}
        isVisible={isAssigneeModalOpen}
        onClose={toggleCallback}
        selectedIds={issueAssignees.map(a => a.id)}
        isLoading={isChangeAssigneesLoading}
        onChange={async (assignee, action) => {
          if (!issuePublicId || !repository || !integration?.provider?.id) return;

          if (action === 'add') {
            await change({
              action: 'add',
              providerId: integration.provider.id,
              assignees: [assignee.login],
              publicId: Number(issuePublicId),
              repo: repository,
            });
          } else if (action === 'remove') {
            await change({
              action: 'remove',
              providerId: integration.provider.id,
              assignees: [assignee.login],
              publicId: Number(issuePublicId),
              repo: repository,
            });
          }
        }}
      >
        {issueAssignees.length ? assigneesContent() : noAssigneesContent()}
      </GithubAssigneesSelectDropdown>
    </Container>
  );

  function assigneesContent() {
    const displayedAssignees = issueAssignees.length > MAX_DISPLAYED_ELEMENT
      ? issueAssignees.slice(0, MAX_DISPLAYED_ELEMENT - 1)
      : issueAssignees;
    const hiddenAssignees = issueAssignees.length > MAX_DISPLAYED_ELEMENT
      ? issueAssignees.slice(MAX_DISPLAYED_ELEMENT - 1)
      : [];

    return (
      <AvatarsContainer onClick={handleClick}>
        {displayedAssignees.map((element) => (
          <Tooltip
            key={`${element.id}`}
            content={element.login}
            placement="top"
          >
            <Image src={element.avatarUrl ?? DEFAULT_AVATARS[0]} />
          </Tooltip>
        ))}
        {!!hiddenAssignees.length && (
          <Tooltip
            content={hiddenAssignees.reduce((acc, curr) => (acc ? `${acc}, ${curr.name}` : curr.name as string), '')}
            placement="top"
            withPortal
          >
            <MoreNumber>
              {`+${hiddenAssignees.length}`}
            </MoreNumber>
          </Tooltip>
        )}
      </AvatarsContainer>
    );
  }

  function noAssigneesContent() {
    return (
      <Tooltip content="Add assignees">
        <ActionButton onClick={handleClick}>
          <AddMemberIcon />
        </ActionButton>
      </Tooltip>
    );
  }
};
