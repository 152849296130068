import { Color, MateFragment, Role } from '@cycle-app/graphql-codegen';
import { getOptimizedSrc } from '@cycle-app/utilities';
import { FC, MouseEvent, useState } from 'react';

import { Container, Img, Size, Background } from './Avatar.styles';

export type AvatarProps = {
  className?: string;
  src?: string;
  size?: Size; // Scope the size to keep consistency design
  onClick?: (e: MouseEvent) => void;
  pointer?: boolean;
  user?: Pick<MateFragment, 'jobTitle' | 'role' | 'color' | 'firstName' | 'avatar' | 'email'>;
  userColor?: Color;
  oppositeColor?: boolean;
  padding?: number;
  pending?: boolean;
};

export const Avatar: FC<React.PropsWithChildren<AvatarProps>> = ({
  className,
  size = 24,
  onClick,
  pointer,
  user,
  src: srcProps,
  userColor,
  oppositeColor = false,
  padding,
  pending = !user?.jobTitle && user?.role !== Role.SuperAdmin,
}) => {
  const color = userColor || user?.color;
  const src = srcProps ?? user?.avatar?.url;

  return (
    <Container
      className={className}
      $size={size}
      $color={color}
      $pointer={pointer}
      onClick={onClick}
      $padding={padding}
      $pending={pending}
    >
      <Background />
      <Content
        letter={(user?.firstName?.trimStart() || user?.email?.trimStart())?.[0] ?? ''}
        src={src ? getOptimizedSrc(src, size) : undefined}
        oppositeColor={oppositeColor}
      />
    </Container>
  );
};

const Content = ({
  letter,
  src,
  oppositeColor = false,
  pending = false,
}: {
  letter?: string;
  src?: string;
  oppositeColor?: boolean;
  pending?: boolean;
}) => {
  const [isError, setIsError] = useState(false);

  if (!pending && !isError && src) {
    return (
      <Img
        oppositeColor={oppositeColor}
        src={src}
        alt="avatar"
        onError={() => setIsError(true)}
      />
    );
  }

  if (!pending && isError && !!letter) {
    return (
      <Img
        oppositeColor={oppositeColor}
        as="div"
      >
        {letter}
      </Img>
    );
  }

  return (
    <Img
      oppositeColor={oppositeColor}
      as="div"
    />
  );
};
