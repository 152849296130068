import { ViewType } from '@cycle-app/graphql-codegen';
import { AnimatePresence, motion } from 'framer-motion';
import { useReducer } from 'react';

import { DocItem } from 'src/components/DocItem';
import { DocContextDocument, DocProvider, useDocContext } from 'src/contexts/docContext';
import { useSortedCustomDocTypeChildren } from 'src/hooks/useCustomDocTypeChildren';
import { useDocUrl } from 'src/hooks/useUrl';
import { useDocChildrenExpanded } from 'src/reactives/docChildren.reactive';

import { DocChildrenGroup } from './DocChildrenGroup';
import { motionProps } from './NestedChildren.motion';

export const NestedChildren = () => {
  const { getDocItemUrlFromView } = useDocUrl();
  const path = useDocContext(ctx => ctx.path);
  const groupId = useDocContext(ctx => ctx.groupId);
  const docTypeId = useDocContext(ctx => ctx.doctype.id);
  const childrenCount = useDocContext(ctx => ctx.childrenCount - ctx.insightsCount);
  const isExpanded = useDocChildrenExpanded(path);
  const docTypeChildren = useSortedCustomDocTypeChildren(docTypeId);
  const [isAnimationComplete, setAnimationComplete] = useReducer(() => true, isExpanded);

  return (
    <AnimatePresence
      initial={false}
      mode="wait"
    >
      {isExpanded && (
        <motion.div
          onAnimationComplete={setAnimationComplete}
          onMouseDown={e => e.stopPropagation()}
          {...motionProps}
          {...childrenCount === 0 && { transition: { duration: 0 } }}
        >
          {docTypeChildren.map((docType, docTypeIndex) => (
            <DocChildrenGroup
              key={docType.id}
              docType={docType}
              showHeader={docTypeChildren.length > 1}
              isAnimationComplete={isAnimationComplete}
              isLastGroup={docTypeIndex === docTypeChildren.length - 1}
            >
              {docs => docs.map((doc, docIndex) => (
                <DocProvider
                  key={doc.id}
                  value={doc as DocContextDocument}
                  path={path}
                  isLastChild={docIndex === docs.length - 1}
                  groupId={groupId}
                >
                  <DocItem
                    docUrl={getDocItemUrlFromView({
                      doc,
                      doctype: doc.doctype,
                      docDocSource: doc.docSource?.doc,
                    })}
                    isSelectable={false}
                    viewType={ViewType.List}
                    showDocType={docTypeChildren.length < 2}
                    showDocId
                    showStatus
                    showAssignee
                    showChildren
                    showSource={false}
                    showCustomer={false}
                    showDocParent={false}
                    showCover={false}
                    showComments={false}
                    showProperties={false}
                    showLinear={false}
                    showAiState={false}
                  />
                </DocProvider>
              ))}
            </DocChildrenGroup>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
