import { useFragment } from '@apollo/client';
import { DocAssigneeFragmentDoc, DoctypeType } from '@cycle-app/graphql-codegen';
import { Avatar } from '@cycle-app/ui';

import { AssigneePopover } from 'src/components/DocAssignee/AssigneePopover';

export const QuoteReporter = ({ docId }: { docId: string }) => {
  const {
    data: doc, complete,
  } = useFragment({
    fragment: DocAssigneeFragmentDoc,
    fragmentName: 'DocAssignee',
    from: docId,
  });
  if (!complete || !doc?.assignee) return null;
  return (
    <AssigneePopover
      assignee={doc.assignee}
      docTypeType={DoctypeType.Insight}
      isDisabled
      withWrapper
    >
      <Avatar
        user={doc.assignee}
        size={16}
        className="scale-90"
      />
    </AssigneePopover>
  );
};
