import { Emoji } from '@cycle-app/ui';

import slackBot from 'src/assets/slack-bot.png';
import slackMessage from 'src/assets/slack-message-2.png';
import { useSlackReacji } from 'src/hooks/slack/useSlackReacji';

import { ImageBox, ImageContainer, StyledSlackMessageEmoji } from './SlackTourInvite.styles';
import { TextContainer } from './SlackTourModal.styles';

export const SlackTourInvite = () => {
  const { emoji } = useSlackReacji();
  return (
    <>
      <div>
        <ImageContainer>
          <ImageBox>
            <img
              src={slackBot}
              alt="cycle bot"
            />
          </ImageBox>
          <ImageBox>
            <img
              src={slackMessage}
              alt="slack message"
            />
            <StyledSlackMessageEmoji>
              <Emoji
                emoji={emoji}
                size={12}
              />
              1
            </StyledSlackMessageEmoji>
          </ImageBox>
        </ImageContainer>
      </div>
      <TextContainer>
        <h2>
          <span>
            Invite @Cycle and add
            {' '}
            <Emoji
              emoji={emoji}
              inline
            />
            {' '}
            reacjis
          </span>
        </h2>
        <p>
          The fastest way to create feedback in Slack is to invite @Cycle to a channel and add
          {' '}
          <Emoji
            emoji={emoji}
            inline
          />
          {' '}
          reactions to feedback messages.
        </p>
      </TextContainer>
    </>
  );
};
