import { AiState } from '@cycle-app/graphql-codegen';
import { AiIcon, CheckIcon } from '@cycle-app/ui/icons';

import { AiStatePopover } from 'src/components/AiStatePopover';
import { PageId } from 'src/constants/routing.constant';
import { useDocBase } from 'src/hooks/api/useDoc';
import { closeQuoteModal } from 'src/hooks/modals/useQuoteModal';
import { navigate } from 'src/hooks/useNavigate';
import { getPageId } from 'src/utils/routing.utils';

import { useQuoteId } from './QuoteContext';

export const QuotePanelAiState = ({ openNext }: { openNext?: () => void }) => {
  const quoteId = useQuoteId();
  const { doc } = useDocBase(quoteId);

  if (doc?.aiState === AiState.UserValidated) {
    return (
      <div className="btn-tertiary max-w-full cursor-default gap-2 bg-grey-100 dark:bg-grey-850">
        <div className="flex items-center gap-1 text-secondary">
          <AiIcon size={14} />
          <CheckIcon size={11} />
        </div>
        <div className="truncate text-primary">
          AI-generated · user-verified
        </div>
      </div>
    );
  }

  if (doc?.aiState === AiState.AiCreated) {
    return (
      <AiStatePopover
        docId={quoteId}
        onVerify={openNext}
        onDiscard={() => {
          if (openNext) {
            openNext();
            return;
          }
          if (getPageId() === PageId.Quote) {
            navigate(PageId.Main);
            return;
          }
          closeQuoteModal();
        }}
      >
        <button
          className="btn-tertiary gap-2 bg-grey-100 text-primary hover:bg-grey-200 dark:bg-grey-850 dark:hover:bg-grey-800"
        >
          <AiIcon
            size={14}
            className="text-secondary"
          />
          <div className="truncate text-primary">
            AI-generated
          </div>
        </button>
      </AiStatePopover>
    );
  }

  if (doc?.aiState === null) {
    return (
      <div className="btn-tertiary cursor-default bg-grey-100 dark:bg-grey-850">
        <div className="truncate text-primary">
          User-generated
        </div>
      </div>
    );
  }

  return null;
};
