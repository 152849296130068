import { CompanyFullFragment, Color } from '@cycle-app/graphql-codegen/generated';
import { Tag, Dropdown } from '@cycle-app/ui';
import { TagIcon } from '@cycle-app/ui/icons';

import { TooltipClickToCopy } from 'src/components/TooltipClickToCopy';
import { formatCustomAttributeDate } from 'src/utils/date.utils';

export const CellCustomAttribute = ({
  name, definitionId, color, isSelectDefiniton, company,
}: {
  name: string;
  definitionId: string;
  color: Color;
  isSelectDefiniton: boolean;
  company: CompanyFullFragment;
}) => {
  const nodeValue = company.attributes.edges.find(edge => edge.node.definition.id === definitionId)?.node.value;
  if (!nodeValue) return null;

  const values = (() => {
    if (nodeValue.__typename === 'AttributeNumberValue') {
      return [nodeValue.numberValue];
    }
    if (nodeValue.__typename === 'AttributeDateValue') {
      const date = formatCustomAttributeDate(nodeValue.dateValue);
      return date ? [date] : null;
    }
    if (nodeValue.__typename === 'AttributeCheckboxValue') {
      return nodeValue.checkboxValue ? [name] : null;
    }
    if (nodeValue.__typename === 'AttributeTextValue') {
      return isSelectDefiniton
        ? nodeValue.textValue.split(',')
        : [nodeValue.textValue];
    }
    return null;
  })();

  if (values === null) return null;

  if (values.length === 1) {
    if (!values[0]) return null;
    return (
      <TooltipClickToCopy
        title={name}
        value={values[0]}
      >
        {props => (
          <Tag
            className="w-fit max-w-full!"
            color={color}
            {...props}
          >
            {values[0]}
          </Tag>
        )}
      </TooltipClickToCopy>
    );
  }

  return (
    <Dropdown
      withWrapper={false}
      content={(
        <div className="shy-scrollbar flex max-h-96 flex-col gap-2 p-2">
          {values.map((value, index) => (
            <TooltipClickToCopy
              key={index}
              title={name}
              value={value}
            >
              {props => (
                <Tag
                  color={color}
                  {...props}
                >
                  {value}
                </Tag>
              )}
            </TooltipClickToCopy>
          ))}
        </div>
      )}
    >
      <Tag
        color="grey"
        icon={<TagIcon size={12} />}
        className="w-fit max-w-full! font-medium! text-secondary!"
      >
        {values.length}
      </Tag>
    </Dropdown>
  );
};
