import { Emoji } from '@cycle-app/ui';
import { ParentAltIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { DocParentPanel } from 'src/components/DocParentDropdown/DocParentPanel';
import { DropdownLayer } from 'src/components/DropdownLayer';
import { ParentPopover, ParentPopoverProps } from 'src/components/ParentPopover/ParentPopover';
import { useDoctypeFragment } from 'src/hooks/api/fragments/doctype';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useDocBase } from 'src/hooks/api/useDoc';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';

type Props = {
  docId: string;
  emojiSize?: number;
  className?: string;
};

export const FeatureDocParent = ({
  docId, className, emojiSize,
}: Props) => {
  const { doc } = useDocBase(docId);
  const doctype = useGetDocType(doc?.doctype?.id);
  const parentDoctype = useGetDocType(doc?.parent?.doctype?.id);
  const mainBtnRef = useRef<HTMLButtonElement>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  if (!doc || !doctype) return null;
  return (
    <>
      <button
        ref={mainBtnRef}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setDropdownVisible(true);
        }}
        className={twJoin(
          'btn-tertiary btn-sm overflow-hidden rounded font-normal',
          dropdownVisible && 'btn-hover',
          !doc.parent && 'flex-none',
          className,
        )}
      >
        {doc.parent ? (
          <>
            <Emoji
              size={emojiSize}
              emoji={parentDoctype?.emoji}
            />
            <span className="truncate">
              {doc.parent.title ?? 'Untitled'}
            </span>
          </>
        ) : (
          <>
            <ParentAltIcon size={10} />
            Add
          </>
        )}
      </button>

      {doc.parent && (
        <DocPanelParentPopover
          docId={doc.id}
          parentDocId={doc.parent.id}
          reference={mainBtnRef}
          onClickChange={() => setDropdownVisible(true)}
        />
      )}

      <DropdownLayer
        placement="bottom-start"
        layer={Layer.DropdownModalZ2}
        withWrapper={false}
        reference={mainBtnRef}
        visible={dropdownVisible}
        hide={() => setDropdownVisible(false)}
        content={(
          <DocPanelParentDropdownContent
            docId={docId}
            hide={() => setDropdownVisible(false)}
          />
        )}
      />
    </>
  );
};

const DocPanelParentPopover = ({
  docId, ...props
}: Omit<ParentPopoverProps, 'onClickUnlink'> & {
  docId : string;
}) => {
  const changeDocParent = useChangeDocParent();
  return (
    <ParentPopover
      withPortal
      {...props}
      onClickUnlink={() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        changeDocParent({
          docId,
          parentId: undefined,
        });
      }}
    />
  );
};

const DocPanelParentDropdownContent = ({
  docId,
  hide,
}: {
  docId: string;
  hide: () => void;
}) => {
  const { doc } = useDocBase(docId);
  const doctype = useDoctypeFragment(doc?.doctype.id);
  const productAreaIds = nodeToArray(doc?.productAreas).map(a => a.id);

  const feedbackDocId = doc?.docSource?.doc?.id;
  const feedbackDoc = useDocBase(feedbackDocId || '', {
    skip: !feedbackDocId || productAreaIds.length > 0,
  });

  if (!doctype) return null;

  return (
    <DocParentPanel
      docId={docId}
      docType={doctype}
      hide={hide}
      showNoneOption={!!doc?.parent?.id}
      onChange={hide}
      showLinearAutoCreate
      productAreaIds={productAreaIds.length > 0
        ? productAreaIds
        // If the quote does not have product area, features are filtered with feedback product areas.
        : nodeToArray(feedbackDoc.doc?.productAreas).map(a => a.id)}
    />
  );
};
