import { DownIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { useIsOnboarding, useSidebarCollapsed, useBoardsStarred, useProduct, useLocationSelector } from 'src/hooks';
import { useBoardLinkSectionName } from 'src/hooks/api/useBoardLinkSectionName';
import { toggleStarredSection, useGetStarredSection } from 'src/reactives/starredSection.reactive';
import { getPathParams } from 'src/utils/routing.utils';

import { BoardSectionNavigationItem } from './SidebarBoardSection.styles';
import { SidebarItemStarredBoard } from '../../SidebarBoard.styles';

export const SidebarBoardStarredSections = () => {
  const isCollapsed = useSidebarCollapsed();
  const isOnboarding = useIsOnboarding();
  const { boards } = useBoardsStarred();
  const { product } = useProduct();
  const { isOpen: isStarredSectionOpen } = useGetStarredSection();

  const boardsById = useMemo(() => {
    return keyBy(
      product?.boardSections.edges.flatMap(
        section => nodeToArray(section.node.boards).map(
          board => ({
            id: board.id,
            board,
            sectionType: section.node.type,
          }),
        ),
      ),
      'id',
    );
  }, [product?.boardSections.edges]);

  const activeBoardId = useLocationSelector(location => {
    if (isCollapsed || isStarredSectionOpen || !location.state?.fromStarredBoard) return undefined;
    const { boardId } = getPathParams(location.pathname);
    return boards?.find(board => board.id === boardId)?.id;
  });

  if (isOnboarding || !boards.length) return null;

  const items = boards.map(board => {
    if (!isStarredSectionOpen && board.id !== activeBoardId) return null;
    return boardsById[board.id];
  }).filter(isPresent);

  if (isCollapsed && items.length === 0) return null;

  return (
    <div className="flex flex-col gap-0.5 pb-1">
      {!isCollapsed && (
        <BoardSectionNavigationItem
          onClick={toggleStarredSection}
          $isOpen={isStarredSectionOpen}
          label="Favorites"
          className="item--group"
          postName={(
            <DownIcon
              size={14}
              direction={isStarredSectionOpen ? 'top' : 'bottom'}
            />
          )}
          isSidebarColor
        />
      )}
      {items.map(item => (
        <SidebarItemStarredBoard
          key={item.id}
          board={item.board}
          linkState={{ fromStarredBoard: true }}
          $isCollapsed={isCollapsed}
          label={(
            <>
              {item.board.name}
              <SectionName boardId={item.board.id} />
            </>
          )}
        />
      ))}
    </div>
  );
};

export const SectionName = ({ boardId }: { boardId: string }) => {
  const sectionName = useBoardLinkSectionName(boardId);
  if (!sectionName) return null;
  return (
    <span className="ml-2 text-caption text-secondary">
      {sectionName}
    </span>
  );
};
