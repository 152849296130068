import { ColorTheme } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const EmptyStateContainer = styled.div<{ $withHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${p => p.$withHeight ? '600px' : 'auto'};
  max-width: 540px;
  margin: 0 auto;
  padding: 20px;
`;

export const EmptyStateTitle = styled.h2`
  margin-top: 24px;
  color: ${p => p.theme.colors.text.primary};
  font-size: 16px;
`;

export const EmptyStateDescription = styled.p`
  margin-top: 16px;
  text-align: center;
  color: ${p => p.theme.colors.text.secondary};
`;

// illustration

export const EmptyStateIllustrationCard = styled(motion.div)`
  display: grid;
  place-items: center;
  width: 66px;
  height: 80px;
  background-color: ${p => p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200};
  border-radius: 8px;
  color: ${p => p.theme.isDark ? ColorTheme.Grey700 : ColorTheme.Grey400};
`;
