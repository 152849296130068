import { CycleLoader } from '@cycle-app/ui';
import { FC } from 'react';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { useProductLoading } from 'src/hooks/api/selectors/useProductSelector';
import { useInitialLoading } from 'src/reactives/initial.reactive';
import { Layer } from 'src/types/layers.types';

import { StyledEmptyStateInitialTimeout } from './AppLoader.styles';

interface Props {
  isTimeout: boolean;
}

export const AppLoader: FC<React.PropsWithChildren<Props>> = ({
  children, isTimeout,
}) => {
  const initialLoading = useInitialLoading();
  const isProductLoading = useProductLoading();
  if (initialLoading || isProductLoading) {
    return (
      <>
        <CycleLoader
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: mappingZindex[Layer.LoaderFullpage],
          }}
        />
        {isTimeout && <StyledEmptyStateInitialTimeout />}
      </>
    );
  }
  return (
    <>
      {children}
    </>
  );
};
