import { ComponentProps } from 'react';

import { Emoji } from '../Emoji';
import sprite from './linear-project-sprite.svg';

/* eslint-disable max-len */
export const projectIds = ['Project', 'Radar', 'Chip', 'Joystick', 'Battery', 'Sound', 'Cone', 'Database', 'Terminal', 'Watch', 'Clock', 'Lock', 'Compass', 'Server', 'Calendar', 'Apple', 'Android', 'Page', 'Robot', 'Chat', 'LightBulb', 'Alert', 'Mac', 'MobilePhone', 'Computer', 'Tablet', 'Cube', 'Bookmark', 'Video', 'Sign', 'Inbox', 'Subscribe', 'Bucket', 'Label', 'Brush', 'Home', 'Camera', 'Trash', 'GitHub', 'GitLab', 'Book', 'Megaphone', 'Education', 'Present', 'Umbrella', 'Feather', 'Cross', 'Gears', 'Phone', 'Image', 'Basket', 'DesignTools', 'TeeShirt', 'Link', 'Dashboard', 'CreditCard', 'Briefcase', 'Bank', 'Dollar', 'Euro', 'Bitcoin', 'Etherum', 'Cart', 'Shop', 'Shield', 'PieChart', 'BarChart', 'Chart', 'Crown', 'Airplane', 'Bike', 'Car', 'Ship', 'Rocket', 'Anchor', 'World', 'Asia', 'Australia', 'Europe', 'Africa', 'SouthAmerica', 'NorthAmerica', 'Pin', 'Face', 'UnhappyFace', 'ThumbsUp', 'ThumbsDown', 'Skull', 'FaceHeartEyes', 'FaceFlatSmile', 'FaceMask', 'FaceSurprise', 'FaceMonocle', 'FaceSunglasses', 'FaceStarEyes', 'FaceTongue', 'FaceTired', 'Cloud', 'Leaf', 'Tree', 'Mountain', 'Sun', 'Flower', 'Heart', 'Fire', 'Users', 'Bug', 'Recycle', 'Dna', 'Bolt', 'Favorite', 'Coffee', 'Accessibility', 'Burger', 'FaceId', 'FootPrint', 'Mic', 'MusicKey', 'Paint', 'Pizza', 'Ramen', 'WindTurbine', 'Modem', 'Jersey', 'Moon', 'Scissors'];

const DEFAULT_PROJECT_ID = projectIds[0];
const DEFAULT_COLOR = '#bec2c8';

type Props = ComponentProps<'svg'> & {
  /** Emoji id (starts with ':') or Linear project id (Project, Radar...) */
  icon?: string | null;
  /** Hex color code */
  color?: string | null;
  /** Size in pixels */
  size?: number;
};

export const LinearProjectIcon = ({
  icon,
  color = DEFAULT_COLOR,
  size = 16,
  style,
  ...props
}: Props) => {
  if (icon?.startsWith(':')) return (
    <Emoji
      emoji={icon}
      size={size}
    />
  );
  const projectId = icon && projectIds.includes(icon) ? icon : DEFAULT_PROJECT_ID;
  return (
    <svg
      style={{
        width: size,
        height: size,
        color,
        flex: 'none',
        ...style,
      }}
      {...props}
    >
      <use href={`${sprite}#${projectId}`} />
    </svg>
  );
};
