import { makeVar } from 'src/utils/makeVar.utils';

const {
  hook,
  produce,
} = makeVar<Record<string, boolean>>({});

export const useExtractNoQuote = (docId: string) => hook()[docId];

export const setExtractNoQuote = (docId: string, value: boolean) => produce(state => {
  state[docId] = value;
});
