import { ReactNode } from 'react';

import { Container, Title, Body } from './MeetingsEmpty.styles';

interface Props {
  body: string;
  icon: ReactNode;
  title: string;
}

export const MeetingsEmpty = ({
  icon, title, body,
}: Props) => {
  return (
    <Container>
      {icon}
      <Title>
        {title}
      </Title>
      <Body>
        {body}
      </Body>
    </Container>
  );
};
