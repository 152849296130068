import { Role } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { EmptyState } from 'src/components/Views';
import { WorkspaceProvider } from 'src/contexts/workspaceContext';
import { useMaybeMeV2, useProduct } from 'src/hooks';
import { useProductSelector } from 'src/hooks/api/selectors/useProductSelector';
import { useInitPermission } from 'src/hooks/useInitPermission';
import { setProductStatus, useProductStatus } from 'src/reactives/product.reactive';
import { getProductStatus } from 'src/utils/product.utils';

import { Main } from './Main';
import { TrialEnded, ProEnded } from './ProductEmptyState';

export const ProductSwitch = () => {
  const { productStatus } = useProductStatus();
  const productId = useProductSelector(product => product?.id);
  const productSlug = useProductSelector(product => product?.slug);
  const productKey = useProductSelector(product => product?.key);
  const isChangelogOnly = useProductSelector(product => product?.isChangelogOnly);

  switch (productStatus) {
    case 'trial':
    case 'pro':
    case 'pro-cancelled':
      if (!productId || !productSlug || !productKey || isChangelogOnly == null) return null;
      return (
        <WorkspaceProvider
          productId={productId}
          productSlug={productSlug}
          productKey={productKey}
          isChangelogOnly={isChangelogOnly}
        >
          <Main />
        </WorkspaceProvider>
      );
    case 'pro-ended':
      return <ProEnded />;
    case 'trial-ended':
      return <TrialEnded />;
    case 'unavailable':
      return <EmptyState mode="workspace-access" />;
    default:
      return null;
  }
};

export const ProductSwitchListeners = () => {
  useInitPermission();
  const { me } = useMaybeMeV2();
  const isSuperAdmin = me?.role === Role.SuperAdmin;

  const { product } = useProduct();

  useEffect(() => {
    setProductStatus({
      productStatus: getProductStatus(product, isSuperAdmin),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    product?.id,
    product?.endOfFreeTrial,
    product?.subscriptionPeriodEnd,
    product?.plan,
  ]);

  return null;
};
