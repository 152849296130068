import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, RefObject, useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { RIGHT_PANEL_WIDTH } from 'src/constants/docPanel.constants';
import { useIsTablet } from 'src/reactives';
import { useGetDocPanel, useFeatureReleaseNoteOpen, closeFeatureReleaseNote } from 'src/reactives/docRightPanel.reactive';

const DEFAULT_PANEL_WIDTH = 640;

type Props = {
  containerRef: RefObject<HTMLDivElement>;
};

export const FeatureDocReleaseNotePanel = ({
  containerRef, children,
}: PropsWithChildren<Props>) => {
  const isOpen = useFeatureReleaseNoteOpen();
  const docPanel = useGetDocPanel();
  const [absolute, setAbsolute] = useState(false);
  const [panelWidth, setPanelWidth] = useState(DEFAULT_PANEL_WIDTH);
  const isTablet = useIsTablet();

  // Reset the reactive, the panel must closed when the doc opens
  useEffect(() => {
    return () => {
      closeFeatureReleaseNote();
    };
  }, []);

  // Observe the container width
  useEffect(() => {
    if (!containerRef.current || !isOpen) return;
    const observer = new ResizeObserver(([entry]) => {
      if (!entry) return;
      const containerWidth = entry.contentRect.width;

      // Absolute position if the container is not wide enough to display both the feature and the release note
      const newAbsolute = containerWidth < 1000;
      setAbsolute(newAbsolute);

      // Full width if the container is not wide enough to partially display the feature
      if (containerWidth < 800) {
        setPanelWidth(containerWidth);
        return;
      }

      // Half-width if the container is narrow but wide enough to display both the feature and the release note
      if (!newAbsolute && containerWidth < DEFAULT_PANEL_WIDTH * 2) {
        setPanelWidth(containerWidth / 2);
        return;
      }

      setPanelWidth(DEFAULT_PANEL_WIDTH);
    });

    observer.observe(containerRef.current);
    return () => {
      observer.disconnect();
    };
  }, [containerRef, isOpen]);

  // If the doc right panel (comments and activity) is expanded and not absolutely positioned (isTablet),
  // the width animation starts from the right panel width
  const initialWidth = docPanel.isExpanded && !isTablet ? RIGHT_PANEL_WIDTH : 0;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={twJoin(
            'z-10 h-full overflow-hidden bg-white dark:bg-grey-900',
            'border-l border-grey-200 dark:border-grey-850',
            absolute && 'absolute right-0 top-0',
          )}
          style={{
            marginRight: -initialWidth,
          }}
          variants={{
            visible: {
              opacity: 1,
              width: panelWidth,
            },
            hidden: {
              opacity: 0,
              width: initialWidth,
            },
          }}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <div
            className="relative h-full overflow-hidden"
            style={{
              width: panelWidth,
            }}
          >
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

