import { typo, truncate } from '@cycle-app/ui';
import styled from 'styled-components';

import { FormGroup } from 'src/utils/form.util.styles';

export const Form = styled.form<{ isStale: boolean }>`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 450px;
  max-width: 100%;
  border-radius: 8px;
  gap: 16px;
  ${p => p.isStale && `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const Footer = styled(FormGroup)`
  justify-content: flex-end;
`;

export const ParentTitle = styled.span`
  ${truncate}
  flex: 1;
`;

export const Label = styled.h2`
  ${typo.body500};
  margin-bottom: 8px;
`;

export const FormRow = styled.div`
  width: 100%;
`;
