import { ListPositionInput } from '@cycle-app/graphql-codegen';
import { GroupContainer, Spinner } from '@cycle-app/ui';
import { NextArrowIcon, DownArrowIcon } from '@cycle-app/ui/icons';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC, memo, useCallback, useMemo, useRef } from 'react';

import BoardContentListeners from 'src/components/BoardContentListeners/BoardContentListeners';
import BoardGroup from 'src/components/BoardGroup/BoardGroup';
import BoardGroupSortable from 'src/components/BoardGroup/BoardGroupSortable';
import { DocItemHandle } from 'src/components/DocItem';
import DocItemOverlay from 'src/components/DocItemOverlay/DocItemOverlay';
import { BOARD_CONTENT_ID } from 'src/constants/board.constant';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import { useMoveDocs } from 'src/hooks/api/mutations/useMoveDoc';
import { BoardGroup as BoardGroupData, useBoardGroups } from 'src/hooks/api/useBoardGroups';
import { useCrossGroupStrategy } from 'src/hooks/dnd/useCrossGroupStrategy';
import { useGroupsDnd, OnItemsMovedParams, OnGroupMovedParams } from 'src/hooks/dnd/useGroupsDnd';
import { usePreventDndToaster } from 'src/hooks/dnd/usePreventDndToaster';
import { useShouldPreventDnD } from 'src/hooks/dnd/useShouldPreventDnD';
import { useResetViewStateOnUnmount } from 'src/hooks/useResetViewStateOnUnmount';
import { setBoardDnd } from 'src/reactives/boardDnd.reactive';
import { getCollapsedGroups, setCollapsedGroups, useGetCollapsedGroups } from 'src/reactives/collapsedGroups.reactive';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { getSelection, setSelection } from 'src/reactives/selection.reactive';
import { ViewType } from 'src/types/viewType.types';
import { getBoardCollisionDetection, groupsToItems } from 'src/utils/dnd.util';

import { Container, Content, LoadMoreGroups } from './BoardContent.styles';
import BoardContentNewGroup from './BoardContentNewGroup/BoardContentNewGroup';
import { BoardContentSkeleton } from './BoardContentSkeleton';

interface BoardContentProps {
  viewType: ViewType;
  boardConfigId: string | undefined;
}

const BoardContent: FC<React.PropsWithChildren<BoardContentProps>> = ({
  viewType,
  boardConfigId,
}) => {
  const groupByProperty = useBoardConfig(ctx => ctx.groupByProperty);
  const isDndGroupsEnabled = useBoardConfig(ctx => ctx.isDndGroupsEnabled);
  const isGroupCreationDisabled = useBoardConfig(ctx => ctx.isGroupCreationDisabled);
  const { canUpdateFeedbackStatus } = useGetPermission();

  const {
    groups, mappingDocIds, withGroupBy, moreGroups,
  } = useBoardGroups();

  const docItemRefs = useRef<DocItemHandle[][]>([]);

  const { moveSelectAttributeValue } = useAttributesMutations();
  const { moveDocs } = useMoveDocs();

  const [docIds] = useMemo(() => groupsToItems(groups ?? {}), [groups]);

  const onItemsMoved = useCallback(async ({
    groupId,
    previousGroupIds,
    itemsId,
    updatedItems,
    position,
  }: OnItemsMovedParams) => {
    setBoardDnd({ dragging: false });
    if (!boardConfigId) return;
    await moveDocs({
      groupId,
      previousGroupIds,
      docsMoved: itemsId.map(itemId => mappingDocIds[itemId]),
      docsListUpdated: updatedItems[groupId]?.map(itemId => mappingDocIds[itemId]),
      groupByProperty,
      position,
      boardConfigId,
    });

    setSelection({ lock: false });
  }, [groupByProperty, mappingDocIds, moveDocs, boardConfigId]);

  const onGroupMoved = useCallback(async ({
    groupId, sortedGroupIds, position,
  }: OnGroupMovedParams) => {
    setBoardDnd({ dragging: false });

    const group = groups?.[groupId];
    if (!groups || !group?.attributeValueId || !groupByProperty) return;

    const beforeGroup = 'before' in position ? groups[position.before] : null;
    const afterGroup = 'after' in position ? groups[position.after] : null;
    if (!beforeGroup?.attributeValueId && !afterGroup?.attributeValueId) return;

    const attributeValuePosition = {
      ...(beforeGroup?.attributeValueId && { before: beforeGroup.attributeValueId }),
      ...(afterGroup?.attributeValueId && { after: afterGroup.attributeValueId }),
    } as ListPositionInput;

    await moveSelectAttributeValue({
      attributeId: groupByProperty.id,
      valueId: group.attributeValueId,
      position: attributeValuePosition,
      sortedItems: sortedGroupIds,
      boardConfigId,
    });
  }, [boardConfigId, groupByProperty, groups, moveSelectAttributeValue]);

  const getDoc = useCallback((docId: string) => mappingDocIds[docId], [mappingDocIds]);

  const crossGroupStrategy = useCrossGroupStrategy(groupByProperty?.__typename);
  const shouldPreventDnD = useShouldPreventDnD(groupByProperty?.__typename, getDoc, canUpdateFeedbackStatus);
  const dndToaster = usePreventDndToaster();

  const {
    dndContextProps,
    direction,
    activeId,
    activeType,
    items,
  } = useGroupsDnd({
    initialItems: docIds,
    onItemsMoved,
    onGroupMoved,
    crossGroupStrategy,
    collisionDetection: getBoardCollisionDetection(viewType),
    onStart: (docActiveId) => {
      const currentSelected = getSelection().selected;
      if (currentSelected.length && !currentSelected.includes(docActiveId)) {
        setSelection({ selected: [] });
      }
      setBoardDnd({ dragging: true });
    },
    onCancel: () => setBoardDnd({ dragging: false }),
    shouldPreventDnD,
    onOver: ({
      sameGroup, isInsightInSelection, prevent: preventDnd,
    }) => {
      if (!sameGroup && (preventDnd || crossGroupStrategy === 'disabled')) {
        dndToaster.add({ isInsightInSelection });
      }
    },
  });

  const collapsedGroups = useGetCollapsedGroups();
  const toggleCollapse = useCallback((groupId: string) => {
    setCollapsedGroups({ [groupId]: !getCollapsedGroups()[groupId] });
  }, []);

  const {
    canReorderGroups, canCreateGroup,
  } = useGetPermission();

  useResetViewStateOnUnmount();

  if (!groups) {
    return (
      <BoardContentSkeleton viewType={viewType} />
    );
  }

  const activeGroup = activeId ? groups[activeId] : null;
  return (
    <Container>
      <BoardContentListeners
        activeId={activeId}
        viewType={viewType}
        docIds={docIds}
        docItemRefs={docItemRefs}
      />

      <DndContext {...dndContextProps}>
        <SortableContext
          items={Object.keys(items)}
          strategy={viewType === ViewType.Kanban ? horizontalListSortingStrategy : verticalListSortingStrategy}
        >
          <Content viewType={viewType}>
            <GroupContainer
              id={BOARD_CONTENT_ID}
              viewType={viewType}
            >
              <>
                {Object
                  .keys(items)
                  .map(((groupId, groupIndex) => {
                    const group: BoardGroupData | undefined = groups[groupId];
                    if (!group) {
                      return null;
                    }

                    const groupItems = items[groupId];

                    return (
                      <BoardGroupSortable
                        ref={(docItemRefsInGroup) => {
                          if (docItemRefsInGroup !== null) {
                            docItemRefs.current[groupIndex] = docItemRefsInGroup;
                          }
                        }}
                        key={groupId}
                        items={groupItems || []}
                        groupId={groupId}
                        groupIndex={groupIndex}
                        groupCount={Object.keys(items).length}
                        boardConfigId={boardConfigId}
                        groupByProperty={groupByProperty}
                        group={group}
                        activeId={activeId}
                        activeType={activeType}
                        viewType={viewType}
                        getDoc={getDoc}
                        withGroupBy={withGroupBy}
                        disableDnd={!isDndGroupsEnabled || !canReorderGroups}
                        collapse={collapsedGroups[groupId]}
                        toggleCollapse={toggleCollapse}
                      />
                    );
                  }))}

                {moreGroups?.pageInfo?.hasNextPage && !!moreGroups?.exec && (
                  <LoadMoreGroups
                    onClick={moreGroups.exec}
                    disabled={moreGroups?.loading}
                    isLoading={moreGroups?.loading}
                    viewType={viewType}
                  >
                    {moreGroups?.loading && <Spinner size={14} />}
                    {!moreGroups?.loading && (
                      <>
                        {viewType === ViewType.Kanban ? <NextArrowIcon /> : <DownArrowIcon size={12} />}
                        <div>
                          Load more
                        </div>
                      </>
                    )}
                  </LoadMoreGroups>
                )}

                {groupByProperty &&
                  !isGroupCreationDisabled &&
                  !moreGroups?.loading &&
                  !moreGroups?.pageInfo?.hasNextPage &&
                  canCreateGroup && <BoardContentNewGroup viewType={viewType} />}
              </>
            </GroupContainer>
          </Content>
          <DragOverlay>
            {activeType === 'item' && activeId && (
              <DocItemOverlay
                activeId={activeId}
                direction={direction}
                viewType={viewType}
              />
            )}
            {activeType === 'group' && activeId && activeGroup && (
              <BoardGroup
                groupId={activeId}
                boardConfigId={boardConfigId}
                group={activeGroup}
                activeType={activeType}
                items={items[activeId] || []}
                viewType={viewType}
                withGroupBy={withGroupBy}
                getDoc={getDoc}
                isDragging
                collapse={collapsedGroups[activeId]}
              />
            )}
          </DragOverlay>
        </SortableContext>
      </DndContext>
    </Container>
  );
};

export default memo(BoardContent);
