import { useProductHierarchyId } from '../../../hooks/usePathParams';
import { ErrorBoundary } from '../../ErrorBoundary';
import { ErrorPage } from '../../ErrorPage';
import { ProductAreaPanel } from '../../ProductAreaPanel';

export const ProductHierarchy = () => {
  const id = useProductHierarchyId();
  if (!id) return <ErrorPage />;
  return (
    <div className="min-h-full bg-white dark:bg-grey-900">
      <ErrorBoundary>
        <ProductAreaPanel id={id} />
      </ErrorBoundary>
    </div>
  );
};
