import { Skeleton } from '@cycle-app/ui';

import { Meeting } from './MeetingEvent.styles';

export const MeetingEventSkeleton = () => {
  return (
    <Meeting>
      <div>
        <Skeleton
          height={10}
          style={{ marginTop: '6px' }}
          width={210}
        />
        <Skeleton
          height={10}
          style={{ marginTop: '16px' }}
          width={114}
        />
        <Skeleton
          height={10}
          style={{
            marginTop: '16px',
            marginBottom: '11px',
          }}
          width={170}
        />
      </div>
    </Meeting>
  );
};
