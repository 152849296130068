import { createContext, useContext, PropsWithChildren } from 'react';

const context = createContext<string>(undefined as unknown as string);

export const QuoteProvider = (props: PropsWithChildren<{ quoteId: string }>) => (
  <context.Provider value={props.quoteId}>
    {props.children}
  </context.Provider>
);

export const useQuoteId = () => useContext(context);
