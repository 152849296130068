import { Spinner } from '@cycle-app/ui';
import { WarningIcon } from '@cycle-app/ui/icons';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import { IMAGE_EXTENSIONS } from 'src/constants/dropzone.constants';
import { useZoomableProps } from 'src/hooks/useZoomableProps';
import { getFileSizeWithUnit } from 'src/utils/files.util';

import {
  Card, Title, Infos, Name, Error, IconContainer, Buttons,
  FileCardVariant, FileImage,
} from './FileCard.styles';
import { ImportFileIcon } from './ImportFileIcon';

export type FileCardProps = {
  isLoading?: boolean;
  name?: string | null;
  extension?: string | null;
  mimeType?: string | null;
  size?: number | null;
  errorCode?: string;
  errorMessage?: string;
  createButton?: ReactNode;
  discardButton?: ReactNode;
  isLast?: boolean;
  isCompact?: boolean;
  variant?: FileCardVariant;
  url?: string;
  className?: string;
  onZoomIn?: VoidFunction;
  onZoomOut?: VoidFunction;
};

export const FileCard = ({
  isLoading = false,
  name,
  extension,
  mimeType,
  size,
  errorCode,
  errorMessage,
  createButton,
  discardButton,
  isLast = false,
  isCompact = false,
  variant = 'primary',
  url,
  className,
  onZoomIn,
  onZoomOut,
}: FileCardProps) => {
  const gap = isCompact ? 8 : 16;
  const isImage = extension && IMAGE_EXTENSIONS.includes(extension.toLowerCase());
  const getZoomableProps = useZoomableProps({ onZoomOut });
  return (
    <motion.div
      className={className}
      initial={false}
      animate={{
        height: isLast ? 56 : 56 + gap,
        opacity: 1,
      }}
      exit={{
        height: 0,
        opacity: 0,
      }}
      transition={{
        opacity: {
          duration: 0.2,
        },
      }}
    >
      <Card
        $isLoading={isLoading}
        $hasError={!!errorCode}
        $variant={variant}
      >
        {isLoading && <Spinner />}

        {!isLoading && !isImage && (
          <IconContainer $variant={variant}>
            <ImportFileIcon
              mimeType={mimeType}
              extension={extension}
            />
          </IconContainer>
        )}

        {!isLoading && isImage && url && (
          <IconContainer $variant={variant}>
            <FileImage {...getZoomableProps({
              src: url,
              alt: name ?? '',
              onMouseDown: onZoomIn,
            })}
            />
          </IconContainer>
        )}

        {name && (
          <Title>
            <Name>
              {name}
            </Name>
            <Infos>
              {extension && (
                <span>
                  {extension}
                </span>
              )}
              {size != null && (
                <span>
                  {getFileSizeWithUnit(size)}
                </span>
              )}
            </Infos>
          </Title>
        )}

        {errorMessage && (
          <Error>
            <WarningIcon size={14} />
            <span>
              {errorMessage}
            </span>
          </Error>
        )}

        {!isLoading && (
          <Buttons>
            {createButton}
            {discardButton}
          </Buttons>
        )}
      </Card>
    </motion.div>
  );
};
