import { typo, Input } from '@cycle-app/ui';
import styled from 'styled-components';

export const Section = styled.div`
  margin-top: 24px;
`;

export const Heading = styled.h3`
  ${typo.body500}
  vertical-align: center;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Counter = styled.span`
  color: ${p => p.theme.colors.text.disabled};
`;

export const SearchInput = styled(Input)`
  input {
    height: 36px;
  }

  svg {
    color: ${p => p.theme.colors.text.secondary};
  }
`;
