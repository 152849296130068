import { FC } from 'react';

import SortableItem from 'src/components/SortableItem/SortableItem';

export const DocItemSortable: FC<React.PropsWithChildren<{
  isDragging?: boolean;
  itemId?: string;
}>> = ({
  children, isDragging, itemId,
}) => {
  if (isDragging || !itemId) return (
    <>
      {children}
    </>
  );
  /* For Dnd to work properly SortableItem must be mounted only when is is in a real group and not in a DragOverlay */
  return (
    <SortableItem id={itemId}>
      {children}
    </SortableItem>
  );
};
