import { useFragment } from '@apollo/client';
import { ProductKeywordsFragmentDoc } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';

export const useProductKeywords = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    data, complete,
  } = useFragment({
    fragment: ProductKeywordsFragmentDoc,
    fragmentName: 'ProductKeywords',
    from: productId,
  });
  if (!complete) return [];
  return nodeToArray(data.keywords);
};
