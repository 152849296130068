import { z } from 'zod';

const passwordSchema = z.string()
  .min(10, 'At least 10 characters')
  .regex(/[A-Z]/, 'At least 1 uppercase character (A-Z)')
  .regex(/[a-z]/, 'At least 1 lowercase character (a-z)')
  .regex(/\d/, 'At least 1 digit (0-9)')
  .regex(/[\W_]/, 'At least 1 special character (punctuation)');

export const validatePassword = (password?: string) => {
  const result = passwordSchema.safeParse(password);

  if (!result.success) {
    return {
      valid: false,
      error: result.error.errors[0]?.message,
    };
  }

  return {
    valid: true,
  };
};
