import { LinearIssueFullFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel, LinearProjectIcon } from '@cycle-app/ui';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { InfosTitle, StyledSelectButton } from 'src/components/LinearEditorMentionView/LinearEditorMentionView.styles';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useUpdateLinearIssue } from 'src/hooks/api/mutations/integrations/useUpdateLinearIssue';
import { useLinearProjects } from 'src/hooks/api/queries/integrations/useLinearProjects';
import { Layer } from 'src/types/layers.types';

export const IssueProject = ({ issue }: { issue: LinearIssueFullFragment }) => {
  const { updateLinearIssue } = useUpdateLinearIssue();
  const integrationId = useWorkspaceContext(ctx => ctx.linearIntegrationId);
  const query = useLinearProjects(integrationId, '');

  const options = query.projects
    ?.filter(p => p.teams.some(team => team?.id === issue.team?.id))
    .map(p => ({
      value: p.id,
      label: p.name ?? 'Untitled',
      icon: (
        <LinearProjectIcon
          icon={p.icon}
          color={p.color}
          size={14}
        />
      ),
    }));

  const selected = options?.find(p => p.value === issue.projectId);

  return (
    <div>
      <InfosTitle>
        Project
      </InfosTitle>
      <ToggleDropdown
        layer={Layer.DropdownModalZ4}
        placement="bottom-start"
        content={props => (
          <SelectPanel
            options={options}
            isLoading={query.isLoading && !query.projects}
            onOptionChange={option => {
              const projectId = option.value;
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              updateLinearIssue(issue.id, { projectId }, {
                ...issue,
                projectId,
              });
              props.hide();
            }}
          />
        )}
        button={props => (
          <StyledSelectButton
            {...props}
          >
            {selected?.icon}
            {selected?.label}
            {!selected && 'No project'}
          </StyledSelectButton>
        )}
      />
    </div>
  );
};
