import { useState, FC, ReactNode, MouseEvent } from 'react';

import {
  Container,
  PrimaryContainer,
  SecondaryContainer,
  Title,
  ButtonIcon,
  Footer,
  StyledCaretIcon,
} from './EditorIntegrationCard.styles';

export type EditorIntegrationCardProps = {
  actions?: ReactNode;
  assignees?: ReactNode;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  preTitleSlot?: ReactNode;
  title: string;
  description?: ReactNode;
  footerSlot?: ReactNode;
  isReadOnly?: boolean;
  hasExpandContent?: boolean;
  isSelected?: boolean;
};

export const EditorIntegrationCard: FC<React.PropsWithChildren<EditorIntegrationCardProps>> = ({
  actions,
  assignees,
  onClick,
  preTitleSlot,
  title,
  description,
  footerSlot,
  isReadOnly = false,
  isSelected = false,
  hasExpandContent: hasExpandContentFromProps,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const hasExpandContent = hasExpandContentFromProps ?? !!description;

  return (
    <Container
      onClick={onClick}
      $canHover={!isReadOnly}
      $isSelected={isSelected}
    >
      <PrimaryContainer>
        {hasExpandContent && (
          <ButtonIcon onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
          >
            <StyledCaretIcon direction={isExpanded ? 'top' : 'bottom'} />
          </ButtonIcon>
        )}
        {preTitleSlot}
        <Title>
          {title}
        </Title>
        {assignees}
        {actions}
      </PrimaryContainer>
      <SecondaryContainer
        isVisible={hasExpandContent && isExpanded}
        onClick={e => {
          if (isExpanded) {
            e.stopPropagation();
          }
        }}
      >
        {isExpanded && description}
        {!!footerSlot && (
          <Footer>
            {footerSlot}
          </Footer>
        )}
      </SecondaryContainer>
    </Container>
  );
};
