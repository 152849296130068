import { useMemo } from 'react';

import { useCompanyBaseList } from 'src/hooks/api/queries/customers/useCompanyBaseList';

export const useCycleAskExamples = () => {
  const {
    companies,
    loading: isCompaniesLoading,
  } = useCompanyBaseList({
    size: 3,
  }, {
    fetchPolicy: 'cache-first',
  });

  const company1 = companies[1]?.name;
  const company2 = companies[2]?.name;
  const company3 = companies[3]?.name;

  return useMemo(() => {
    return [
      {
        id: 'a',
        isLoading: false,
        content: 'What are customers saying about performance this month?',
      },
      {
        id: 'b',
        isLoading: isCompaniesLoading,
        content: `What feedback do we have from customers ${(company1 || 'Figma')} and ${company2 || 'Canva'}?`,
      },
      {
        id: 'c',
        isLoading: false,
        content: 'Can you please summarize the feedback we\'ve received over the past two weeks?',
      },
      {
        id: 'd',
        isLoading: isCompaniesLoading,
        content: `I'm meeting with customer ${company3 || 'Google'} tomorrow, I'd love to have a summary of their feedback on reporting feature`,
      },
    ];
  }, [company1, company2, company3, isCompaniesLoading]);
};
