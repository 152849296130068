import { useState, FC, useMemo, useEffect, useCallback } from 'react';

import { ReadOnlyEditor } from 'src/components/Editor';
import { useAddTemplate } from 'src/hooks/api/mutations/templatesHooks';
import { useDoctype } from 'src/hooks/api/useDocType';
import useDoctypeTemplates from 'src/hooks/api/useDoctypeTemplates';
import { hideTemplateModal, setTemplate, TemplateState, useGetTemplate } from 'src/reactives/template.reactive';
import { Layer } from 'src/types/layers.types';
import { getParsedJSON } from 'src/utils/json.util';
import { addToaster } from 'src/utils/toasters.utils';

import NewTemplateEditor from '../TemplateEditor/NewTemplateEditor';
import TemplateList from './TemplateList/TemplateList';
import { CreateTemplateBtn } from './Templates.styles';
import {
  Sidebar,
  TemplateViewHeader,
  SidebarListContainer,
  TemplateView,
  SelectButton,
  EditorContainer,
  PortalModalStyled,
} from '../TemplateModal.styles';

interface Props {
  initialView?: TemplateState['initialView'];
  modeAdmin?: boolean;
  docTypeId?: string;
}

const Templates: FC<React.PropsWithChildren<Props>> = ({
  initialView = 'list',
  modeAdmin,
  docTypeId,
}) => {
  const doctype = useDoctype(docTypeId);

  const { selectedTemplateId } = useGetTemplate();

  const {
    templates, refetch: refetchTemplates,
  } = useDoctypeTemplates();
  const {
    add,
    loading,
  } = useAddTemplate(doctype);

  const [view, setView] = useState(initialView);

  const onCancelCreation = useCallback(() => {
    if (initialView === 'creation') {
      hideTemplateModal();
    } else {
      setView('list');
    }
  }, [initialView]);

  const template = useMemo(
    () => templates.find(({ id }) => id === selectedTemplateId),
    [templates, selectedTemplateId],
  );
  const templateContent = useMemo(
    () => (template?.contentJSON
      ? getParsedJSON(template.contentJSON)
      : undefined),
    [template?.contentJSON],
  );

  useEffect(() => {
    if (!selectedTemplateId) {
      setTemplate({ selectedTemplateId: templates[0]?.id });
    }
  }, [templates, selectedTemplateId]);

  useEffect(() => {
    if (templateContent === null) {
      // Error in JSON parsing
      addToaster({
        message: 'We could not load the template',
      });
      hideTemplateModal();
    }
  }, [templateContent]);

  if (view === 'creation') {
    return (
      <NewTemplateEditor
        onCancel={onCancelCreation}
        onTemplateAdded={!modeAdmin ? hideTemplateModal : async () => {
          setView('list');
          await refetchTemplates();
        }}
        modeAdmin={modeAdmin}
        docTypeId={docTypeId}
      />
    );
  }

  return (
    <PortalModalStyled
      hide={hideTemplateModal}
      layer={Layer.Modal}
    >
      <SidebarListContainer>
        <Sidebar>
          <TemplateList />
          <CreateTemplateBtn
            size="M"
            onClick={() => setView('creation')}
          >
            Create new template
          </CreateTemplateBtn>
        </Sidebar>

        <TemplateView>
          <TemplateViewHeader>
            {template?.title}
            {modeAdmin ? (
              <SelectButton
                size="M"
                onClick={editTemplate}
              >
                Edit
              </SelectButton>
            ) : (
              <SelectButton
                size="M"
                onClick={selectTemplate}
                isLoading={loading}
              >
                Select
              </SelectButton>
            )}
          </TemplateViewHeader>
          {templateContent && (
            <EditorContainer>
              <ReadOnlyEditor
                key={template?.contentJSON ?? ''}
                content={templateContent}
              />
            </EditorContainer>
          )}
        </TemplateView>
      </SidebarListContainer>
    </PortalModalStyled>
  );

  function editTemplate() {
    setTemplate({ mode: 'edit' });
  }

  async function selectTemplate() {
    if (!doctype || !template) return;

    await add({
      doctypeId: doctype.id,
      title: template.title,
      content: template.content,
      contentJSON: template.contentJSON,
      category: template.category,
    });

    hideTemplateModal();
  }
};

export default Templates;
