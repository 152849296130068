import { ProductBySlugDocument } from '@cycle-app/graphql-codegen';

import { useProductSlug } from 'src/hooks/usePathParams';
import client from 'src/services/apollo/client';
import { getParams } from 'src/utils/routing.utils';

export const useProductId = () => {
  const productSlug = useProductSlug();
  if (!productSlug) return null;
  return getProductId(productSlug);
};

export const getProductId = (productSlug?: string) => {
  const slug = productSlug ?? getParams().productSlug;
  if (!slug) return null;
  return client.readQuery({
    query: ProductBySlugDocument,
    variables: { slug },
  })?.product?.id ?? null;
};
