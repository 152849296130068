import { Tooltip } from '@cycle-app/ui';
import { QuoteIcon } from '@cycle-app/ui/icons';

import { useDocPanelContext } from 'src/contexts/docPanelContext';

import { BlockButton } from './DocPanelBody.styles';
import { SpeakerButton } from './SpeakerButton';
import { useQuoteFromBlock } from './useQuoteFromBlock';

type Props = {
  containerRef: React.RefObject<HTMLDivElement>;
};

export const QuoteFromBlock = ({ containerRef }: Props) => {
  const editor = useDocPanelContext(ctx => ctx.editor);
  const {
    position, active, onClick, speaker,
  } = useQuoteFromBlock(containerRef.current);

  if (!editor || !position) return null;

  return (
    <div
      className="absolute flex w-0 items-center justify-end overflow-visible"
      style={{
        left: position.left + 20,
        top: position.top,
      }}
    >
      {speaker && (
        <SpeakerButton
          speaker={speaker}
          style={{
            left: position.left,
            top: position.top,
          }}
        />
      )}

      <Tooltip
        content="Extract quote"
        placement="top"
        withWrapper={false}
        withPortal
      >
        <BlockButton
          onClick={onClick}
          forceFocus={active}
        >
          <QuoteIcon size={14} />
        </BlockButton>
      </Tooltip>
    </div>
  );
};
