import { CycleLogo, Tag } from '@cycle-app/ui';

import { SourceIcon } from 'src/components/DocSource/DocSource';
import { SourcePopover } from 'src/components/SourcePopover/SourcePopover';
import { useDocSourceFragment } from 'src/hooks/api/fragments/doc';

export const QuoteSource = ({ docId }: { docId: string }) => {
  const source = useDocSourceFragment(docId)?.source;
  if (!source) return null;
  return (
    <SourcePopover
      docId={docId}
      placement="top"
      withPortal
    >
      <Tag
        color="grey"
        tabIndex={0}
        role="button"
        className="size-5"
        icon={(
          <SourceIcon
            source={source}
            size={12}
            defaultIcon={(
              <CycleLogo
                size={12}
                color="blue"
              />
            )}
          />
        )}
      />
    </SourcePopover>
  );
};
