import { FC, PropsWithChildren } from 'react';
import { useInView } from 'react-intersection-observer';

/**
 * Component that hides its children until they are fully visible
 */
export const PartialHidden: FC<PropsWithChildren> = ({ children }) => {
  const [ref, isVisible] = useInView({ threshold: 1 });
  return (
    <div
      ref={ref}
      style={{ opacity: isVisible ? 1 : 0 }}
    >
      {children}
    </div>
  );
};
