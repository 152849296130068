import { Emoji } from '@cycle-app/ui';

import { getDocTypeName, showDocTypeEmoji } from 'src/utils/docType.util';

import {
  DoctypeTag,
  DoctypeTagContent,
} from './DoctypesOptions.styles';
import { DoctypesOptionsEditable, DoctypesOptionsEditableProps } from './DoctypesOptionsEditable';

type DoctypesOptionsProps = DoctypesOptionsEditableProps & {
  disabled?: boolean;
};

const DoctypesOptions = ({
  disabled, ...props
}: DoctypesOptionsProps) => {
  const {
    tooltipDisabled, className, context, tooltipContent, doctype,
  } = props;

  if (!disabled) return <DoctypesOptionsEditable {...props} />;

  if (!doctype) return null;

  const showEmoji = showDocTypeEmoji(doctype);

  return (
    <DoctypeTag
      className={className}
      tooltip={{
        placement: 'top',
        disabled: tooltipDisabled || context === 'doc-panel',
        title: tooltipContent || (
          <DoctypeTagContent>
            {showEmoji && (
              <Emoji
                emoji={doctype.emoji}
                size={12}
              />
            )}
            {getDocTypeName(doctype)}
          </DoctypeTagContent>
        ),
        content: undefined,
        withPortal: true,
      }}
      color="grey"
    >
      <DoctypeTagContent $disabled>
        {showEmoji && (
          <Emoji
            emoji={doctype.emoji}
            size={12}
          />
        )}
        {(context === 'doc-panel' || !showEmoji) && getDocTypeName(doctype)}
      </DoctypeTagContent>
    </DoctypeTag>
  );
};

export default DoctypesOptions;
