import { DocAttributeFragment } from '@cycle-app/graphql-codegen';
import { Tag } from '@cycle-app/ui';
import { TippyProps } from '@tippyjs/react';
import sortBy from 'lodash/sortBy';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { PropertyDropdownValue } from 'src/components/PropertyDropdownValue';
import { Layer } from 'src/types/layers.types';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';

import { Info, TooltipSubtitle } from './DocAttributes.styles';

export const DocAttributesMultiSelect = ({
  docId,
  attribute,
  disabled = false,
  visible,
  openDropdown,
  hideDropdown,
  hideIncompatibleValues,
  layer,
  appendTo,
}: {
  docId?: string;
  attribute: DocAttributeFragment;
  disabled?: boolean;
  visible: boolean;
  openDropdown: VoidFunction;
  hideDropdown: VoidFunction;
  hideIncompatibleValues?: boolean;
  layer?: Layer;
  appendTo?: TippyProps['appendTo'];
}) => {
  if (attribute.__typename !== 'DocAttributeMultiSelect') return null;
  const attributeData = getCustomAttributeTypeData('AttributeMultiSelectDefinition');
  const { definition } = attribute;

  return (
    <>
      <DropdownLayer
        layer={layer}
        closingArea
        placement="bottom-start"
        disabled={disabled || !docId}
        appendTo={appendTo}
        onClickOutside={appendTo === 'parent' ? hideDropdown : undefined}
        content={docId && (
          <PropertyDropdownValue
            docId={docId}
            attributeDefinitionId={definition.id}
            hide={hideDropdown}
            onRemoveValue={() => {
              if (attribute.selectValues?.length === 1) hideDropdown();
            }}
            hideIncompatibleValues={hideIncompatibleValues}
          />
        )}
        visible={visible}
        hide={hideDropdown}
        // remove of unnecessary gap
        style={{ marginRight: -6 }}
      >
        {/* Useful to set the position of the dropdown */}
        <div style={{ height: 20 }} />
      </DropdownLayer>

      {sortBy(attribute.selectValues, 'value')
        ?.filter(selectValue => !!selectValue.value)
        .map(selectValue => (
          <Tag
            key={`${definition.id}-${selectValue.id}`}
            color={definition.color}
            {...!disabled && {
              onClick: e => {
                e.preventDefault();
                if (!visible) openDropdown();
              },
            }}
            limitSize
            tooltip={{
              placement: 'top',
              withPortal: true,
              content: (
                <div>
                  <Info>
                    {attributeData.icon}
                    <span>
                      {definition.name}
                    </span>
                  </Info>
                  {!disabled && (
                    <TooltipSubtitle>
                      Click to update
                    </TooltipSubtitle>
                  )}
                </div>
              ),
            }}
          >
            {selectValue.value}
          </Tag>
        ))}
    </>
  );
};
