import { Popover, PopoverProps } from '@cycle-app/ui';
import { useRef } from 'react';
import { Instance } from 'tippy.js';

import { ParentPopoverContent } from './ParentPopoverContent';

export type ParentPopoverProps = Omit<PopoverProps, 'content'> & {
  parentDocId: string;
  onClickChange: () => void;
  onClickUnlink: () => void;
};

export const ParentPopover = ({
  parentDocId, reference, onClickChange, onClickUnlink, ...props
}: ParentPopoverProps) => {
  const tippyInstance = useRef<Instance | null>(null);
  return (
    <Popover
      placement="top-start"
      withWrapper={false}
      interactive
      animation
      delay={[200, 0]}
      popperOptions={{
        strategy: 'fixed',
      }}
      reference={reference}
      onMount={(instance: Instance) => {
        tippyInstance.current = instance;
      }}
      {...props}
      content={(
        <ParentPopoverContent
          parentDocId={parentDocId}
          hide={() => tippyInstance.current?.hide()}
          onClickChange={onClickChange}
          onClickUnlink={onClickUnlink}
        />
      )}
    />
  );
};
