import { Input, Popover, ColorPalettePicker } from '@cycle-app/ui';
import { ComponentProps, forwardRef, useState } from 'react';

import { colorPaletteSaveColor, useGetColorPaletteSavedColors } from 'src/reactives/colorPaletteSavedColors.reactive';

type ColorInputProps = {
  value: string;
  onChange: (value: string) => void;
  appendToParent?: boolean;
} & Omit<ComponentProps<typeof Input>, 'onChange' | 'value'>;

export const ColorInput = forwardRef<HTMLInputElement, ColorInputProps>(({
  value, onChange, onFocus, appendToParent = true, ...inputProps
},
ref,
) => {
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const { savedColors } = useGetColorPaletteSavedColors();

  return (
    <Popover
      controlled
      visible={colorPickerVisible}
      placement="right-end"
      offset={[0, 25]}
      interactive
      appendTo={appendToParent ? 'parent' : undefined}
      content={(
        <div className="w-52 rounded-lg border border-grey-100 bg-white shadow-sm">
          <ColorPalettePicker
            color={value}
            onChange={newValue => onChange(newValue)}
            savedColors={savedColors}
            onAddColor={colorPaletteSaveColor}
          />
        </div>
      )}
      onClickOutside={() => setColorPickerVisible(false)}
    >
      <Input
        ref={ref}
        value={value}
        onChange={e => onChange(e.target.value?.trim())}
        endInput={(
          <div
            className="size-5 cursor-pointer rounded-full border border-white shadow-sm"
            style={{ backgroundColor: value }}
            onClick={() => setColorPickerVisible(!colorPickerVisible)}
          />
        )}
        onFocus={(e) => {
          setColorPickerVisible(true);
          onFocus?.(e);
        }}
        {...inputProps}
      />
    </Popover>
  );
});
