import { BoardWithMinimalConfigAndDraftConfigIdFragment } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { BoardConfigSkeletons } from 'src/components/BoardConfigForm/BoardConfigSkeletons';
import { Header, Title, Actions, CloseButtonStyled } from 'src/components/DialogModal/DialogModal.styles';
import { InboxBoardConfigFields } from 'src/components/InboxBoardConfigFields/InboxBoardConfigFields';
import useDraftBoardConfigMutations from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import useDraftBoardConfig from 'src/hooks/api/useDraftBoardConfig';
import { useGetBoardConfigModal } from 'src/reactives/boardConfig.reactive';

import { Modal } from './InboxEditView.styles';
import { InboxEditViewForm } from './InboxEditViewForm';

export type InboxEditViewModalProps = {
  board: BoardWithMinimalConfigAndDraftConfigIdFragment;
  draftBoardConfigId: string;
};

export const InboxEditViewModal = ({
  board, draftBoardConfigId,
}: InboxEditViewModalProps) => {
  const { disabled } = useGetBoardConfigModal();
  const {
    revertUnsavedDraftConfig,
    loading,
  } = useDraftBoardConfigMutations(draftBoardConfigId, board.id);

  const isLoadingDraftBoardConfig = !useDraftBoardConfig(board.id).data?.node?.draftBoardConfig;

  const revert = async () => {
    if (disabled || isLoadingDraftBoardConfig || loading.publish) return;
    await revertUnsavedDraftConfig();
  };

  return (
    <Modal hide={revert}>
      <Header>
        <Title>
          Feedback view settings
        </Title>
        <CloseButtonStyled
          size="L"
          onClick={revert}
        >
          <CloseIcon />
        </CloseButtonStyled>
      </Header>

      {disabled || isLoadingDraftBoardConfig ? <BoardConfigSkeletons /> : (
        <InboxEditViewForm
          board={board}
          draftBoardConfigId={draftBoardConfigId}
        >
          {(isLoading) => (
            <>
              <InboxBoardConfigFields
                boardId={board.id}
              />

              <Actions>
                <Button
                  size="M"
                  variant="secondary"
                  onClick={revert}
                  isLoading={loading.revertUnsaved}
                >
                  Cancel
                </Button>

                <Button
                  size="M"
                  type="submit"
                  isLoading={isLoading || loading.publish}
                >
                  Save
                </Button>
              </Actions>
            </>
          )}
        </InboxEditViewForm>
      )}
    </Modal>
  );
};
