import { useCallback, useEffect, useRef, useState } from 'react';

/** Expand/Collapse tabs section on scroll */
export const useExpandTabsSection = () => {
  const profileRef = useRef<HTMLDivElement>(null);
  const tabPanelsRef = useRef<HTMLDivElement>(null);
  const [tabsExpanded, setTabsExpanded] = useState(false);

  const handlePan = useCallback((target: EventTarget | null, deltaY: number) => {
    const profileElement = profileRef.current;
    const tabPanelsElement = tabPanelsRef.current;

    // Do not handle if elements are not ready
    if (!(target instanceof Element) || !tabPanelsElement || !profileElement) return;

    // Only capture events inside the profile container
    if (!profileElement.contains(target)) return;

    // Expand tabs section if panning up or down in the top section
    if (!tabPanelsElement.contains(target)) {
      if (Math.abs(deltaY) > 0) {
        setTabsExpanded(state => !state);
      }
      return;
    }

    // Expand tabs section if panning up in the tabs section
    if (deltaY < 0) {
      setTabsExpanded(true);
      return;
    }

    // Collapse tabs section if panning down in the tabs section and it's scrolled to the top
    if (deltaY > 0 && tabPanelsElement.scrollTop === 0) {
      setTabsExpanded(false);
    }
  }, [tabsExpanded]);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      const profileElement = profileRef.current;
      const tabPanelsElement = tabPanelsRef.current;

      // Do not handle if elements are not ready
      if (!(e.target instanceof Element) || !tabPanelsElement || !profileElement) return;

      // Only capture events inside the profile container
      if (!profileElement.contains(e.target)) return;

      const threshold = tabPanelsElement.contains(e.target) ? 0 : 5;

      // Expand tabs section if scrolling down
      if (e.deltaY > threshold) {
        setTabsExpanded(true);
        return;
      }

      // Collapse tabs section if scrolling up in the top section
      if (!tabPanelsElement.contains(e.target) && e.deltaY < -threshold) {
        setTabsExpanded(false);
        return;
      }

      // Collapse tabs section if scrolling up in the tabs section and it's not scrolled to the top
      if (tabPanelsElement.contains(e.target) && tabPanelsElement.scrollTop === 0 && e.deltaY < 0) {
        setTabsExpanded(false);
      }
    };
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [handlePan]);

  return {
    profileRef,
    tabPanelsRef,
    tabsExpanded,
    handlePan,
  };
};
