import { SubscriptionHookOptions, useSubscription } from '@apollo/client';
import { AutopilotStateDocument, AutopilotStateSubscription, AutopilotStateSubscriptionVariables } from '@cycle-app/graphql-codegen';

type Options = SubscriptionHookOptions<AutopilotStateSubscription, AutopilotStateSubscriptionVariables>;

export const useAutopilotStateSubscription = (docId: string, options: Omit<Options, 'variables'> = {}) => {
  return useSubscription(AutopilotStateDocument, {
    variables: { docId },
    ...options,
  });
};
