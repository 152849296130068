
import { Input } from '@cycle-app/ui';
import { isUrl } from '@cycle-app/utilities';
import { useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useUpdateDocSource } from 'src/hooks/api/mutations/useUpdateDocSource';

export const SourceUrlForm = ({
  docId, defaultValue, hide,
}: {
  docId: string;
  defaultValue: string;
  hide: () => void;
}) => {
  const { updateDocSource } = useUpdateDocSource();

  const {
    handleSubmit,
    register,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm({
    defaultValues: {
      url: defaultValue,
    },
  });

  const onSubmit = handleSubmit(async (values) => {
    if (isSubmitting) return;
    await updateDocSource(docId, values.url);
    hide();
  });

  const onChange = useDebouncedCallback(
    handleSubmit(async (values) => {
      return updateDocSource(docId, values.url);
    }),
    INPUT_ONCHANGE_DEBOUNCE,
  );

  return (
    <form
      className="w-full"
      onSubmit={onSubmit}
    >
      <Input
        autoFocus
        inputSize="S"
        className="p-2 pt-1"
        error={errors.url?.message}
        onKeyDown={e => {
          e.stopPropagation();
          if (e.key === 'Escape') {
            hide();
          }
        }}
        onKeyUp={e => {
          e.stopPropagation();
        }}
        {...register('url', {
          onBlur: () => {
            hide();
          },
          setValueAs: rawValue => {
            const value = rawValue.trim();
            return isUrl(value) ? value.trim() : `https://${value}`;
          },
          validate: (value) => {
            if (!value || !isUrl(value, { strict: false })) {
              return 'You should provide a valid URL';
            }
            return undefined;
          },
          onChange,
        })}
      />
    </form>
  );
};
