import { BoardWithMinimalConfigFragment, ViewType } from '@cycle-app/graphql-codegen';
import { Redirect } from 'react-router-dom';

import { ErrorPage, Size } from 'src/components/ErrorPage';
import { OfflineState } from 'src/components/OfflineState';
import { useBoardConfig, BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useIsOffline } from 'src/hooks';
import { useBoard } from 'src/hooks/api/useBoard';
import { useBoardUrl } from 'src/hooks/useUrl';

import { BoardContentSkeleton } from './BoardContent/BoardContentSkeleton';

const BoardRedirect = ({ board }: { board: BoardWithMinimalConfigFragment }) => {
  const url = useBoardUrl(board, { withDocSlug: true });
  return <Redirect to={url} />;
};

const Board = () => {
  const hasError = useBoardConfig(ctx => ctx.hasError);
  const notFound = useBoardConfig(ctx => ctx.notFound);
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const loading = useBoardConfig(ctx => ctx.loading);
  const board = useBoard(boardId);
  const isOffline = useIsOffline();

  if (isOffline && hasError) return <OfflineState />;

  if (hasError) return <ErrorPage size={Size.SMALL} />;

  if (loading) return <BoardContentSkeleton viewType={ViewType.List} />;

  if (notFound || !board) return (
    <ErrorPage
      message="Oops, this view no longer exists"
      size={Size.SMALL}
    />
  );

  return <BoardRedirect board={board} />;
};

const BoardWithBoardConfig = () => (
  <BoardConfigContextProvider>
    <Board />
  </BoardConfigContextProvider>
);

export default BoardWithBoardConfig;
