import { NotionAttributes } from '@cycle-app/editor-extensions/src';
import { IntegrationCardSkeleton, EditorIntegrationCard, Icon } from '@cycle-app/ui';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC, useEffect } from 'react';

import { ContainerNodeView } from 'src/components/Integrations/IntegrationsCommon.styles';
import { useEditorContext } from 'src/contexts/editorContext';
import { useNotionPage } from 'src/hooks/api/queries/integrations/useNotionPage';

import { NotionEditorMentionViewActions } from './NotionEditorMentionViewActions';

interface Props extends NodeViewRendererProps {
  selected: boolean;
  updateAttributes: (attr: NotionAttributes) => void;
}

export const NotionEditorMentionView: FC<React.PropsWithChildren<Props>> = ({
  node, selected, getPos, updateAttributes,
}) => {
  const { page } = useNotionPage(node.attrs as NotionAttributes, {
    // Ignore errors, as the embed can still be displayed even if the page is not found
    // The `Page not found` error will be displayed on the Notion page itself
    errorPolicy: 'ignore',
  });
  const editor = useEditorContext(ctx => ctx.editor);

  useEffect(() => {
    if (page) {
      queueMicrotask(() => {
        updateAttributes({
          id: page.id,
          url: page.url ?? '',
          title: page.title ?? '',
          iconEmoji: page.iconEmoji ?? '',
          typename: page.__typename,
        });
      });
    }
  }, [page, updateAttributes]);

  return (
    <ContainerNodeView data-drag-handle>
      {renderContent()}
    </ContainerNodeView>
  );

  function renderContent() {
    const {
      url, title, iconEmoji,
    } = node.attrs;
    if (!url) return <IntegrationCardSkeleton />;
    return (
      <EditorIntegrationCard
        isSelected={selected}
        title={title}
        preTitleSlot={(
          <>
            <Icon
              name="brand/notion"
              className="size-4"
            />
            {iconEmoji}
          </>
        )}
        actions={(
          <NotionEditorMentionViewActions
            editor={editor}
            getPos={getPos}
            node={node}
          />
        )}
        onClick={() => {
          const u = node.attrs.url || page?.url;
          if (u) {
            window.open(u, '_blank');
          }
        }}
      />
    );
  }
};
