import { Avatar, Icon } from '@cycle-app/ui';
import { CalendarIcon, ActivityIcon } from '@cycle-app/ui/icons';
import { format, parseISO } from 'date-fns';
import { memo } from 'react';

import { useDocPanelContext } from 'src/contexts/docPanelContext';

import {
  Container, List, Item, Label, Value,
  ActivityContainer, ActivityTitle, FakeActivities, Blur, ActivityItem, ComingSoon,
} from './DocPanelActivity.styles';

export const DocPanelActivity = memo(() => (
  <Container>
    <List>
      <CreatorItem />
      <CreatedAtItem />
    </List>
    <ActivityLog />
  </Container>
));

const CreatorItem = () => {
  const creator = useDocPanelContext(ctx => ctx.doc?.creator);
  if (!creator) return null;
  return (
    <Item>
      <Label>
        <Icon name="user" />
        Creator
      </Label>
      <Value>
        <Avatar
          size={20}
          user={creator}
        />
        {`${creator.firstName} ${creator.lastName}`}
      </Value>
    </Item>
  );
};

const CreatedAtItem = () => {
  const createdAt = useDocPanelContext(ctx => ctx.doc?.createdAt);
  if (!createdAt) return null;
  return (
    <Item>
      <Label>
        <CalendarIcon />
        Creation date
      </Label>
      <Value>
        {format(parseISO(createdAt), 'MMMM d, yyyy')}
      </Value>
    </Item>
  );
};

const ActivityLog = () => {
  return (
    <ActivityContainer>
      <ActivityIcon size={24} />
      <ActivityTitle>
        Activity log
      </ActivityTitle>
      <FakeActivities>
        <Blur>
          {[10, 30, 20].map(nbItems => (
            <ActivityItem key={nbItems}>
              <CalendarIcon />
              <span>
                {'Fake activity that will be coming soon!'.slice(0, nbItems)}
              </span>
            </ActivityItem>
          ))}
        </Blur>
        <ComingSoon>
          More coming soon
        </ComingSoon>
      </FakeActivities>
    </ActivityContainer>
  );
};
