import { LinearAssignee, LinearIssueFullFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel } from '@cycle-app/ui';

import { LinearAssignee as LinearAssigneeAvatar } from 'src/components/LinearAssignee/LinearAssignee';
import { useUpdateLinearIssue } from 'src/hooks/api/mutations/integrations/useUpdateLinearIssue';
import { useLinearAssignees } from 'src/hooks/api/queries/integrations/useLinearAssignees';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { LinearIssuesCommandAttributes } from 'src/types/editor.types';
import { Layer } from 'src/types/layers.types';

import { StyledAssigneeSelectButton } from './LinearEditorMentionView.styles';
import DropdownLayer from '../DropdownLayer/DropdownLayer';

export const IssueAssignee = ({
  issue,
  assignee = issue?.assignee,
  updateAttributes,
  layer = Layer.DropdownModal,
}: {
  issue: LinearIssueFullFragment;
  assignee?: LinearAssignee | null;
  updateAttributes?: (attr: Partial<LinearIssuesCommandAttributes>) => void;
  layer?: Layer;
}) => {
  const [isAssigneeSelectOpened, { toggleCallback: toggleAssigneeSelect }] = useOptimizedBooleanState(false);

  const { updateLinearIssue } = useUpdateLinearIssue();

  const {
    assignees, isLoading: isAssigneesLoading,
  } = useLinearAssignees();

  const assigneesOptions = assignees.map(a => ({
    value: a.id,
    label: a.name,
    icon: (
      <LinearAssigneeAvatar
        assignee={a}
        tooltip={false}
      />
    ),
  }));

  const onAssigneeChange = (assigneeId: string) => {
    if (!issue) return;
    updateAttributes?.({
      assignee: assignees.find(a => a.id === assigneeId),
    });
    toggleAssigneeSelect();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateLinearIssue(issue.id, { assigneeId }, {
      ...issue,
      assignee: assignees.find(a => a.id === assigneeId),
    });
  };

  return (
    <DropdownLayer
      layer={layer}
      visible={isAssigneeSelectOpened}
      hide={toggleAssigneeSelect}
      placement="bottom-end"
      content={(
        <SelectPanel
          options={assigneesOptions}
          header="Linear assignee"
          isLoading={!issue && isAssigneesLoading}
          onOptionChange={({ value }) => onAssigneeChange(value)}
        />
      )}
    >
      <StyledAssigneeSelectButton
        size="S"
        variant="nospace"
        onClick={e => { e.stopPropagation(); toggleAssigneeSelect(); }}
      >
        <LinearAssigneeAvatar assignee={assignee} />
      </StyledAssigneeSelectButton>
    </DropdownLayer>
  );
};
