import { StatusCategory } from '@cycle-app/graphql-codegen';
import { SelectOption, StatusIcon } from '@cycle-app/ui';
import { AllStatusesIcon, DownIcon } from '@cycle-app/ui/icons';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { StatusButton, StatusPanel } from './Profile.styles';

const options: SelectOption[] = [
  {
    value: 'all',
    icon: <AllStatusesIcon size={16} />,
    label: 'All statuses',
  }, {
    value: StatusCategory.NotStarted,
    icon: (
      <StatusIcon
        category={StatusCategory.NotStarted}
        withBackground
      />
    ),
    label: 'Not Started',
  }, {
    value: StatusCategory.Started,
    icon: (
      <StatusIcon
        category={StatusCategory.Started}
        withBackground
      />
    ),
    label: 'Started',
  }, {
    value: StatusCategory.Completed,
    icon: (
      <StatusIcon
        category={StatusCategory.Completed}
        withBackground
      />
    ),
    label: 'Completed',
  }, {
    value: StatusCategory.Canceled,
    icon: (
      <StatusIcon
        category={StatusCategory.Canceled}
        withBackground
      />
    ),
    label: 'Canceled',
  },
];

type Props = {
  value: StatusCategory | null;
  onChange: (value: StatusCategory | null) => void;
};

export const StatusCategorySelect = ({
  value, onChange,
}: Props) => {
  const selectedOption = options.find(option => option.value === (value ?? 'all'));
  return (
    <ToggleDropdown
      visible
      placement="bottom-start"
      withPortal
      withWrapper={false}
      layer={Layer.DropdownModalZ3}
      button={buttonProps => (
        <StatusButton
          onClick={buttonProps.onClick}
          forceFocus={buttonProps['data-active']}
        >
          {selectedOption?.icon}
          {selectedOption?.label}
          <DownIcon
            size={14}
            direction={buttonProps['data-active'] ? 'top' : 'bottom'}
          />
        </StatusButton>
      )}
      content={contentProps => (
        <StatusPanel
          hideSearch
          options={options}
          onOptionChange={option => {
            const newValue = option.value as StatusCategory | 'all';
            onChange(newValue === 'all' ? null : newValue);
            contentProps.hide();
          }}
        />
      )}
    />
  );
};
