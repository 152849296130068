import { DocBaseFragment, StatusCategory } from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import { QuoteIcon } from '@cycle-app/ui/icons';
import { useEffect } from 'react';

import DocAction from 'src/components/DocAction/DocAction';
import { OpenInsightCreateModal } from 'src/components/DocHierarchy/DocHierarchyGroup/OpenInsightCreateModal';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { verifyQuotesLabel } from 'src/components/ExtractQuotes/ExtractQuotes';
import { useDocStatusFragment } from 'src/hooks/api/fragments/doc';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useTippyOffsetAdapter } from 'src/hooks/useTippyOffsetAdapter';
import { setDocItemHoverId } from 'src/reactives/docItem.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { isFeedback } from 'src/utils/docType.util';

import { StyledDocHierarchyInsight, QuoteAction } from './DocInsights.styles';

type Props = {
  doc: DocBaseFragment;
  context?: 'doc-item';
  isReadOnly?: boolean;
  layer?: Layer;
};

export const DocQuotes = (props: Props) => {
  const {
    doc, context, isReadOnly = false,
  } = props;
  const docType = useGetDocType(doc.doctype.id);
  const count = isFeedback(docType) ? doc.docTargetsCount : doc.insightsCount;
  return (
    <>
      {count === 0 && isReadOnly
        ? null
        : count > 0 ? (
          <DocInsightsList
            {...props}
            context={context}
            count={count}
          />
        ) : (
          <DocInsightsEmpty
            {...props}
            context={context}
          />
        )}

      {isFeedback(docType) && (
        <DocAiQuotes
          doc={doc}
          context={context}
        />
      )}
    </>
  );
};

const DocAiQuotes = ({
  doc, context,
}: Props) => {
  const statusCategory = useDocStatusFragment(doc.id)?.status?.category;
  if (!statusCategory) return null;
  if ([StatusCategory.Completed, StatusCategory.Canceled].includes(statusCategory)) {
    return null;
  }

  if (doc.findInsightState !== null || doc.autopilotState !== null) {
    return (
      <QuoteAction
        tooltipPlacement="top"
        label="Extracting quotes"
        buttonIcon={(
          <Spinner
            hasGradient
            size={14}
          />
        )}
      />
    );
  }

  if (!doc.quotesCount) {
    return null;
  }

  return (
    <QuoteAction
      tooltipPlacement="top"
      label={verifyQuotesLabel(doc.quotesCount)}
      buttonIcon={(
        <QuoteIcon
          size={14}
          hasGradient
        />
      )}
      counter={doc.quotesCount}
      context={context}
    />
  );
};

const DocInsightsEmpty = ({
  doc, context,
}: Props) => {
  return (
    <OpenInsightCreateModal
      doc={doc}
      button={buttonProps => (
        <DocAction
          forceFocus={buttonProps.isOpen}
          tooltipPlacement="top"
          label="Add quote"
          buttonIcon={<QuoteIcon size={14} />}
          counter={0}
          onClick={(e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            buttonProps.onClick();
          }}
          context={context}
        />
      )}
    />
  );
};

type DocInsightsListProps = Props & {
  count: number;
};

const DocInsightsList = ({
  doc,
  context,
  count,
  isReadOnly = false,
  layer = Layer.DocItemDropdown,
}: DocInsightsListProps) => {
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  useEffect(() => {
    if (isDropdownVisible) {
      setDocItemHoverId(null);
    }
  }, [isDropdownVisible]);

  const offsetAdapter = useTippyOffsetAdapter();

  return (
    <DropdownLayer
      visible={isDropdownVisible}
      hide={hideDropdown}
      layer={layer}
      disableDocPreview={false}
      placement="bottom-start"
      exitAnimation={false}
      content={(
        <StyledDocHierarchyInsight
          docId={doc.id}
          count={count}
          isReadOnly={isReadOnly}
          className="bg-white dark:bg-grey-900"
        />
      )}
      {...offsetAdapter.tippyProps}
    >
      <DocAction
        forceFocus={isDropdownVisible}
        tooltipPlacement="top"
        label="Quotes"
        buttonIcon={<QuoteIcon size={14} />}
        counter={count}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          toggleDropdown();
        }}
        context={context}
      />
    </DropdownLayer>
  );
};
