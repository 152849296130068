/**
 * Tabs UI component
 * @see: For A11Y - http://web-accessibility.carnegiemuseums.org/code/tabs/
 */
import { nextFrame } from '@cycle-app/utilities/src/utils/async.utils';
import { DndContext, closestCenter } from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core';
import { SortableContext, horizontalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { FC, useState, HTMLAttributes } from 'react';

import { Tab } from './Tab';
import { Container } from './Tabs.styles';
import { TabItem } from './Tabs.types';
import { getDefaultValue, useTabsDnDSensors, convertIdToString } from './Tabs.utils';

export type OnItemMovedParam = {
  draggedItemId: string;
  newIds: string[];
  oldIds: string[];
  toPlaceAfter: string | null;
  toPlaceBefore: string | null;
};

export interface TabsProps extends HTMLAttributes<HTMLDivElement> {
  defaultValue?: TabItem['id'];
  items: TabItem[];
  onItemMoved?: (data: OnItemMovedParam) => void;
  value?: TabItem['id'];
}

export const Tabs: FC<TabsProps> = (props) => {
  const {
    defaultValue = '',
    items = [],
    onItemMoved,
    value,
    ...containerProps
  } = props;

  const [activeTabId, setActiveTabId] = useState(getDefaultValue(items, defaultValue));

  const itemsElements = items.map(item => (
    <Tab
      count={item.count}
      emoji={item.emoji}
      id={item.id}
      isActive={typeof value === 'undefined'
        ? item.id === activeTabId
        : item.id === value}
      isSortable={!!onItemMoved}
      key={item.id}
      label={item.label}
      onAdd={item.onAdd}
      onClick={async (e) => {
        if (typeof value === 'undefined' && activeTabId !== item.id) setActiveTabId(item.id);
        await nextFrame();
        item.onClick?.(e);
      }}
      tooltip={item.tooltip}
      onContextMenu={item.onContextMenu}
      end={item.end}
    />
  ));

  if (onItemMoved) {
    const onMoved = ({
      active, over,
    }: DragEndEvent) => {
      const activeId = convertIdToString(active.id);
      const activeInitialIndex = items.findIndex(i => i.id === activeId);
      const overIndex = over ? items.findIndex(i => i.id === convertIdToString(over.id)) : -1;
      const initialIds = items.map(i => i.id);
      const finalIds = arrayMove(items, activeInitialIndex, overIndex).map(i => i.id);
      const activeFinalIndex = finalIds.findIndex(id => id === activeId);
      const beforeActiveId = activeFinalIndex > 0 ? finalIds[activeFinalIndex - 1] : '';
      const afterActiveId = activeFinalIndex < finalIds.length - 1 ? finalIds[activeFinalIndex + 1] : '';

      onItemMoved({
        draggedItemId: activeId,
        newIds: overIndex === -1 ? initialIds : finalIds,
        oldIds: initialIds,
        toPlaceAfter: beforeActiveId ?? null,
        toPlaceBefore: afterActiveId ?? null,
      });
    };

    return (
      <ContentDraggable
        items={items.map(i => i.id)}
        onItemMoved={onMoved}
        {...containerProps}
      >
        {itemsElements}
      </ContentDraggable>
    );
  }

  return (
    <Container
      role="tablist"
      {...containerProps}
    >
      {itemsElements}
    </Container>
  );
};

interface ContentDraggableProps extends HTMLAttributes<HTMLDivElement> {
  items: string[];
  onItemMoved: (event: DragEndEvent) => void;
}

const ContentDraggable: FC<React.PropsWithChildren<ContentDraggableProps>> = ({
  onItemMoved,
  items,
  children,
  ...containerProps
}) => {
  const sensors = useTabsDnDSensors();
  const onDragEnd = (event: DragEndEvent) => {
    if (!event.over || event.active.id === event.over?.id) return;

    onItemMoved(event);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      sensors={sensors}
    >
      <Container
        role="tablist"
        {...containerProps}
      >
        <SortableContext
          items={items}
          strategy={horizontalListSortingStrategy}
        >
          {children}
        </SortableContext>
      </Container>
    </DndContext>
  );
};
