import { ComponentProps, ReactNode, forwardRef } from 'react';

import {
  Container, Content, Link, Name, TagButton, Start, TagButtonClose, TagButtonLink,
} from './Tag.styles';
import { Color as ColorApi } from '../../../../graphql-codegen/generated';
import { LinkIcon, ArrowRightUpIcon } from '../../icons';
import { TooltipProps, Tooltip } from '../Tooltip/Tooltip';

export type TagProps = Omit<ComponentProps<'div'>, 'ref'> & {
  icon?: ReactNode;
  color?: ColorApi | 'grey' | 'greyAlt';
  limitSize?: boolean;
  tooltip?: TooltipProps;
  externalLink?: string | null;
  onDelete?: () => void;
  onDarkBg?: boolean;
  onClickCopyLink?: VoidFunction;
  start?: ReactNode;
  'data-active'?: boolean;
  inline?: boolean;
  onClickLink?: VoidFunction;
  linkTooltip?: ReactNode;
  linkIcon?: ReactNode;
  copyTooltip?: ReactNode;
  copyIcon?: ReactNode;
  squared?: boolean;
};

export const Tag = forwardRef<HTMLDivElement, TagProps>(({
  className,
  icon,
  children,
  color,
  limitSize = true,
  onClick,
  onDelete,
  onDarkBg,
  tooltip,
  externalLink,
  onClickCopyLink,
  start,
  onClickLink,
  linkTooltip,
  linkIcon,
  copyTooltip = 'Copy link',
  copyIcon,
  inline = false,
  squared = false,
  ...props
}, ref) => {
  const content = (
    <Content
      $color={color}
      $onDarkBg={onDarkBg}
      onClickCapture={onClick}
      $hasSideButton={!!externalLink || !!onClickCopyLink || !!onDelete}
      data-active={props['data-active']}
      $inline={inline}
      $squared={squared}
    >
      {icon}
      {children && (
        <Name $limitSize={limitSize}>
          {children}
        </Name>
      )}
    </Content>
  );

  return (
    <Container
      className={className}
      $color={color}
      $onDarkBg={onDarkBg}
      ref={ref}
      $inline={inline}
      {...props}
    >
      {start && (
        <Start
          $color={color}
          $onDarkBg={onDarkBg}
        >
          {start}
        </Start>
      )}

      {tooltip
        ? (
          <Tooltip {...tooltip}>
            {content}
          </Tooltip>
        )
        : content}

      {externalLink && (
        <Tooltip
          placement="top"
          withPortal
          content={linkTooltip ?? 'Open link'}
        >
          <Link
            href={externalLink}
            target="_blank"
            rel="noopener noreferrer"
            $color={color}
            $onDarkBg={onDarkBg}
            onClick={e => e.stopPropagation()}
          >
            <ArrowRightUpIcon />
          </Link>
        </Tooltip>
      )}

      {onClickCopyLink && (
        <Tooltip
          placement="top"
          withPortal
          content={copyTooltip}
          disabled={!copyTooltip}
        >
          <TagButton
            $color={color}
            $onDarkBg={onDarkBg}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClickCopyLink();
            }}
          >
            {copyIcon ?? <LinkIcon size={12} />}
          </TagButton>
        </Tooltip>
      )}

      {onDelete && (
        <TagButton
          onClick={onDelete}
          $color={color}
          $onDarkBg={onDarkBg}
        >
          <TagButtonClose />
        </TagButton>
      )}

      {onClickLink && (
        <Tooltip
          placement="top"
          withPortal
          content={linkTooltip}
        >
          <TagButton
            $color={color}
            $onDarkBg={onDarkBg}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClickLink();
            }}
          >
            {linkIcon || <TagButtonLink />}
          </TagButton>
        </Tooltip>
      )}
    </Container>
  );
});
