import { Button } from '@cycle-app/ui';

import { useLogout } from 'src/hooks';

import { Container, Title } from './EmptyStateInitialTimeout.styles';

export const EmptyStateInitialTimeout = ({ className }: { className?: string }) => {
  const logout = useLogout();
  return (
    <Container className={className}>
      <Title>
        Oops, looks like it&apos;s taking too much time.
        <br />
        You can wait or back to login.
      </Title>
      <Button
        size="M"
        variant="secondary"
        onClick={logout}
      >
        Back to login
      </Button>
    </Container>
  );
};
