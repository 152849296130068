import { BillingPlan, Color, ThemeType, UserJourney } from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import { getTheme } from '@cycle-app/ui/utils/theme.util';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useEffect, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { twJoin } from 'tailwind-merge';

import { ErrorPage } from 'src/components/ErrorPage';
import { EmptyState } from 'src/components/Views/EmptyState';
import { useAuthFromUrl } from 'src/hooks/user/useAuthFromUrl';
import { useRole } from 'src/hooks/useRoles';
import { setInitial } from 'src/reactives/initial.reactive';
import { setOnboarding, useGetOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { setThemeConfig } from 'src/reactives/theme.reactive';
import { initFullStory } from 'src/services/fullStory';
import { LightOnboardingScreen } from 'src/types/onboarding.types';
import { isUserOnboarded, isUserOnboardedWithoutWorkspace } from 'src/utils/users.util';

import { OnboardingSources } from '../OnboardingSources/OnboardingSources';
import { OnboardingStepAccount } from '../OnboardingStepAccount/OnboardingStepAccount';
import { OnboardingStepBookCall } from '../OnboardingStepBookCall';
import { OnboardingStepCallBooked } from '../OnboardingStepCallBooked';
import { Loading } from './Onboarding.styles';
import { OnboardingStepChangelog } from '../OnboardingStepChangelog/OnboardingStepChangelog';
import { OnboardingStepCustomize } from '../OnboardingStepCustomize/OnboardingStepCustomize';
import { OnboardingStepDone } from '../OnboardingStepDone/OnboardingStepDone';
import { OnboardingStepFlowSelection } from '../OnboardingStepFlowSelection';
import { OnboardingStepLinear } from '../OnboardingStepLinear/OnboardingStepLinear';
import { OnboardingStepPassword } from '../OnboardingStepPassword/OnboardingStepPassword';
import { OnboardingStepWelcome } from '../OnboardingStepWelcome/OnboardingStepWelcome';
import { OnboardingStepWorkspace } from '../OnboardingStepWorkspace/OnboardingStepWorkspace';
import { OnboardingUserResearch } from '../OnboardingUserResearch/OnboardingUserResearch';

const Loader = () => (
  <Loading>
    <Spinner />
  </Loading>
);

export const Onboarding = () => {
  const {
    tokenFromUrl, meIdFromUrl, from,
  } = useAuthFromUrl();

  // Case: login from chrome extension with not onboarded user email and one-time code
  useEffect(() => {
    if (from === 'chrome-extension' && tokenFromUrl && meIdFromUrl) {
      setOnboarding({ screen: LightOnboardingScreen.AccountInfosPasswordless });
    }
  }, [from]);

  const initialized = useRef(false);
  const {
    isLoading, me, error, role,
  } = useRole();
  const products = useMemo(() => nodeToArray(me?.products), [me]);
  const product = products[0];

  useEffect(() => {
    initFullStory({
      me,
      plan: product?.plan,
      role,
      isOnboarding: true,
      productSlug: product?.slug || '',
    });
  }, [me, product?.plan, role, product?.slug]);

  const {
    screen, theme,
  } = useGetOnboarding();

  useEffect(() => {
    if (products.length) {
      setInitial({ products });
    }
  }, [products]);

  if (error) return <ErrorPage />;

  if (
    !initialized.current &&
    (
      (tokenFromUrl && !me) || isLoading
    )
  ) return <Loader />;
  initialized.current = true;

  const isFirstMaker = product ? product.nbMakers < 2 && product.userCount < 2 : true;
  const isFreePlan = product ? product.plan === BillingPlan.Free : true;
  const shouldBookCall = isFirstMaker && isFreePlan;

  const content = () => {
    switch (screen) {
      case LightOnboardingScreen.AccountVerify:
        if (isUserOnboarded(me)) {
          setThemeConfig({ colorTheme: theme });
          return <EmptyState mode="un-auth" />;
        }
        if (products.length) {
          if (tokenFromUrl || !me?.jobTitle) {
            setOnboarding({ screen: LightOnboardingScreen.AccountInfos });
          } else if (shouldBookCall) {
            setOnboarding({ screen: LightOnboardingScreen.BookCall });
          } else {
            setOnboarding({ screen: LightOnboardingScreen.Done });
          }
          return null;
        }

        return <OnboardingStepAccount />;
      case LightOnboardingScreen.AccountInfos:
      case LightOnboardingScreen.AccountInfosPasswordless:
        if (isUserOnboarded(me)) {
          setOnboarding({ screen: LightOnboardingScreen.AccountPreferences });
          return null;
        }
        return me ? (
          <OnboardingStepPassword
            isPasswordless={screen === LightOnboardingScreen.AccountInfosPasswordless}
            me={me}
          />
        ) : <Loader />;
      case LightOnboardingScreen.AccountPreferences:
        return me ? (
          <OnboardingStepCustomize
            me={me}
            products={products}
            shouldBookCall={shouldBookCall}
          />
        ) : <Loader />;
      case LightOnboardingScreen.Workspace:
        if (products.length) {
          setOnboarding({ screen: LightOnboardingScreen.BookCall });
          return null;
        }
        return me ? <OnboardingStepWorkspace me={me} /> : <Loader />;
      case LightOnboardingScreen.FlowSelection:
        return product ? <OnboardingStepFlowSelection productId={product.id} /> : <Loader />;
      case LightOnboardingScreen.PM: // legacy
      case LightOnboardingScreen.Sources:
        return me && product ? <OnboardingSources product={product} /> : <Loader />;
      // Keep Readonly with the switch to handle retro-compatibility
      case LightOnboardingScreen.Readonly:
      case LightOnboardingScreen.Readonly1:
      case LightOnboardingScreen.Readonly2:
      case LightOnboardingScreen.Readonly3:
      case LightOnboardingScreen.Readonly4:
        setOnboarding({ screen: shouldBookCall ? LightOnboardingScreen.BookCall : LightOnboardingScreen.Done });
        return null;
      case LightOnboardingScreen.BookCall:
        return me && product ? (
          <OnboardingStepBookCall
            me={me}
            productId={product.id}
            plan={product.plan}
          />
        ) : <Loader />;
      case LightOnboardingScreen.CallBooked:
        return me && product ? (
          <OnboardingStepCallBooked
            userId={me.id}
            productId={product.id}
            plan={product.plan}
          />
        ) : <Loader />;
      case LightOnboardingScreen.Doctypes: // legacy
      case LightOnboardingScreen.Linear:
        return me && product ? (
          <OnboardingStepLinear
            productId={product.id}
            shouldBookCall={shouldBookCall}
          />
        ) : <Loader />;
      case LightOnboardingScreen.UserResearch:
        return me && product ? (
          <OnboardingUserResearch
            productId={product.id}
            shouldBookCall={shouldBookCall}
          />
        ) : <Loader />;
      case LightOnboardingScreen.Changelog:
        return me && product ? (
          <OnboardingStepChangelog
            productId={product.id}
            shouldBookCall={shouldBookCall}
            productSlug={product.slug}
            productLogoUrl={product.logo?.url}
          />
        ) : <Loader />;
      case LightOnboardingScreen.Done:
        return me ? <OnboardingStepDone products={products} /> : <Loader />;
      default:
        if (isUserOnboarded(me)) {
          setThemeConfig({ colorTheme: theme });
          if (me?.userJourney === UserJourney.CallBooked) {
            setOnboarding({ screen: LightOnboardingScreen.CallBooked });
            return null;
          }
          if (isUserOnboardedWithoutWorkspace(me)) {
            return (
              <OnboardingStepWelcome
                products={products}
                showOnboardingSentence={isFirstMaker}
                me={me}
              />
            );
          }
          return <EmptyState mode="un-auth" />;
        }
        return (
          <OnboardingStepWelcome
            products={products}
            showOnboardingSentence={isFirstMaker}
            me={me}
          />
        );
    }
  };

  return (
    <ThemeProvider theme={getTheme(theme, Color.A)}>
      <div className={twJoin('contents', theme === ThemeType.Nightfall && 'dark')}>
        {content()}
      </div>
    </ThemeProvider>
  );
};
