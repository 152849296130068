import { useMeasure } from '@cycle-app/utilities';
import { CSSProperties } from 'react';

import { PreviewType } from './types';

const CONTAINER_PADDING = 24;

type Size = {
  width: number;
  height: number;
};

export function usePreviewStyle(previewType: PreviewType) {
  const {
    ref,
    rect,
  } = useMeasure<HTMLDivElement>();

  return {
    containerRef: ref,
    style: getPreviewTypeStyle(previewType, rect),
  };
}

function getPreviewTypeStyle(previewType: PreviewType, containerSize?: Size): CSSProperties {
  if (previewType === PreviewType.TABLET) {
    return {
      ...getScaleStyle({
        width: 820,
        height: 1180,
      }, containerSize),
      borderRadius: 30,
    };
  }

  if (previewType === PreviewType.MOBILE) {
    return {
      ...getScaleStyle({
        width: 390,
        height: 844,
      }, containerSize),
      borderRadius: 30,
    };
  }

  return {
    maxWidth: 1440,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  };
}

function getScaleStyle(idealSize: Size, containerSize?: Size): CSSProperties {
  if (!containerSize) {
    return {
      ...idealSize,
    };
  }

  // Account for padding on both sides
  const availableWidth = containerSize.width - (CONTAINER_PADDING * 2);
  const availableHeight = containerSize.height - (CONTAINER_PADDING * 2);
  const widthScale = availableWidth / idealSize.width;
  const heightScale = availableHeight / idealSize.height;
  const scale = Math.min(1, widthScale, heightScale);

  return {
    ...idealSize,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: `translate(-50%, -50%) scale(${scale})`,
    transformOrigin: 'center',
  };
}
