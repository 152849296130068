import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { getTheme } from '@cycle-app/ui/utils/theme.util';
import { FC, ReactNode } from 'react';
import { ThemeProvider as ThemeProviderStyled } from 'styled-components';

import { useMeSelector } from 'src/hooks/api/selectors/useMeSelector';
import { useGetThemeConfig } from 'src/reactives/theme.reactive';
import { convertLegacyLocalStorageValue } from 'src/utils/theme.utils';

interface Props {
  children: ReactNode;
}

const ThemeProvider: FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const { colorTheme } = useGetThemeConfig();
  const myColor = useMeSelector(me => me?.color);
  const theme = getTheme(convertLegacyLocalStorageValue(colorTheme), myColor ?? ColorApi.A);
  return (
    <ThemeProviderStyled theme={theme}>
      {children}
    </ThemeProviderStyled>
  );
};

export default ThemeProvider;
