import { DocInGroupFragment, ViewType } from '@cycle-app/graphql-codegen';
import { NewDocPosition } from '@cycle-app/ui';
import { SortableContext } from '@dnd-kit/sortable';
import memoize from 'fast-memoize';
import {
  forwardRef, ReactNode, useCallback, useImperativeHandle, useMemo, useRef,
} from 'react';
import { twJoin } from 'tailwind-merge';

import { DocItemHandle } from 'src/components/DocItem';
import LoadMore from 'src/components/LoadMore/LoadMore';
import SortableItem from 'src/components/SortableItem/SortableItem';
import { BuiltInDisplay } from 'src/contexts/boardConfigContext';
import { DocProvider } from 'src/contexts/docContext';
import { useMoreDocsInGroup } from 'src/hooks/api/useMoreDocsInGroup';
import { DndItemType } from 'src/hooks/dnd/useGroupsDnd';
import { useGroupOptionsShortcutListener } from 'src/hooks/shortcuts/useGroupOptionsShortcutListener';
import { DocDataView } from 'src/hooks/useUrl';
import { setBoardGroupState } from 'src/reactives/boardGroup.reactive';
import { useBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { StyledGroup } from '../BoardContentWithSwimlane.styles';
import { DocsList, StyledDocItem } from './SwimlaneGroup.styles';

interface Props {
  className?: string;
  groupId: string;
  swimlaneDocId: string;
  swimlaneDoctypeId: string | undefined;
  builtInDisplay: BuiltInDisplay;
  collapsed: boolean;
  reasonCreateDocDisabled: string | undefined;
  onNewDocClick?: (pos: NewDocPosition) => void;
  pageInfo: {
    hasNextPage: boolean;
    endCursor?: string | null;
  } | undefined;

  docIds: string[];
  selectedDocIds: string[];
  activeId: string | null;
  activeType: DndItemType;

  getDoc: (id: string) => DocInGroupFragment | undefined;
  getUrl: (params: DocDataView) => undefined | string;

  isOver?: boolean;
  groupIndex?: number;
  draftDocPosition?: NewDocPosition | null;
  draftDoc?: ReactNode;
  children?: ReactNode;
  newDocPosition?: NewDocPosition;
}

const SwimlaneGroup = forwardRef<DocItemHandle[], Props>(({
  className,
  groupId,
  swimlaneDocId,
  swimlaneDoctypeId,
  builtInDisplay,
  collapsed,
  reasonCreateDocDisabled,
  onNewDocClick,
  getDoc,
  getUrl,
  docIds,
  selectedDocIds,
  activeId,
  activeType,
  pageInfo,
  isOver,
  draftDocPosition,
  draftDoc,
  newDocPosition,
}, forwardedRef) => {
  const {
    moreDocs,
    loading,
  } = useMoreDocsInGroup(groupId);

  const { groupId: newDocGroupId } = useBoardNewDocPositionState();

  const isMobile = useIsMobile();

  const docItemRefs = useRef<DocItemHandle[]>([]);

  useImperativeHandle(forwardedRef, () => docItemRefs.current, [docItemRefs]);

  const makeMemoizedSetRef = useMemo(() => memoize(
    (index: number) => (docItemHandle: DocItemHandle) => {
      if (docItemHandle !== null) {
        // eslint-disable-next-line no-param-reassign
        docItemRefs.current[index - 1] = docItemHandle;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const onMouseEnterGroup = useCallback(() => {
    if (draftDocPosition) return;
    setBoardGroupState({ hoverGroupId: groupId });
  }, [groupId, draftDocPosition]);

  const onMouseLeaveGroup = useCallback(() => {
    if (draftDocPosition) return;
    setBoardGroupState({ hoverGroupId: null });
  }, [draftDocPosition]);

  const { docTypes } = useGetDocTypes();

  const isCreatingDoc = draftDocPosition !== null;
  const createDocHidden = (newDocPosition !== 'top' && pageInfo?.hasNextPage) || (isCreatingDoc && newDocGroupId === groupId);

  useGroupOptionsShortcutListener({
    groupId,
    enabled: () => !createDocHidden && !reasonCreateDocDisabled && !!onNewDocClick,
    draftPosition: newDocPosition,
  });

  return (
    <StyledGroup
      className={className}
      collapse={collapsed}
      reasonCreateDocDisabled={reasonCreateDocDisabled}
      onNewDocClick={onNewDocClick}
      isOver={isOver}
      createDocHidden={createDocHidden}
      onMouseEnter={onMouseEnterGroup}
      onMouseLeave={onMouseLeaveGroup}
      newDocPosition={newDocPosition}
    >
      {draftDocPosition === 'top' && draftDoc}
      <DocsList>
        <SortableContext items={docIds}>
          {docIds
            .filter((itemId) => {
              const isSelected = selectedDocIds.includes(itemId);
              return activeType !== 'item' || !isSelected || activeId === itemId;
            })
            .map((docId, docIndex) => {
              const doc = getDoc(docId);
              const docType = docTypes[doc?.doctype.id ?? ''];
              const canHaveSwimlaneDoctypeParent = !!docType?.parents?.edges.find(({ node }) => node.id === swimlaneDoctypeId);
              return (
                <SortableItem
                  id={docId}
                  key={docId}
                  metaData={{
                    swimlaneDocId,
                    canHaveSwimlaneDoctypeParent,
                  }}
                >
                  {doc && (
                    <DocProvider value={doc}>
                      <StyledDocItem
                        ref={makeMemoizedSetRef(docIndex)}
                        viewType={ViewType.Kanban}
                        showAssignee={builtInDisplay.assignee}
                        showComments={builtInDisplay.comments}
                        showCover={builtInDisplay.cover}
                        showDocParent={builtInDisplay.parent}
                        showChildren={builtInDisplay.children}
                        showInsights={builtInDisplay.insights}
                        showCustomer={builtInDisplay.customer && !!docType?.customer}
                        showDocId={builtInDisplay.docId && !doc.isDraft}
                        showDocType={builtInDisplay.docType}
                        showSource={builtInDisplay.source && !doc.isDraft && !!doc.source}
                        showStatus={builtInDisplay.status}
                        showRelease={builtInDisplay.release}
                        showLinear={builtInDisplay.linear}
                        showAiState={builtInDisplay.aiState}
                        asPlaceholder={docId === activeId}
                        docUrl={getUrl({
                          doc,
                          doctype: doc.doctype,
                          docDocSource: doc.docSource?.doc,
                        })}
                        isSelectable={!isMobile}
                      />
                    </DocProvider>
                  )}
                </SortableItem>
              );
            })}

        </SortableContext>
        {pageInfo?.hasNextPage && pageInfo?.endCursor && (
          <LoadMore
            onClick={() => moreDocs(pageInfo.endCursor as string)}
            loading={loading}
            className={twJoin('justify-start', createDocHidden && 'mb-2')}
          />
        )}
      </DocsList>
      {draftDocPosition === 'bottom' && draftDoc}
    </StyledGroup>
  );
});

export default SwimlaneGroup;
