import { GithubAssignee } from '@cycle-app/graphql-codegen';
import { Tooltip, Avatar } from '@cycle-app/ui';
import { FC, useMemo } from 'react';

import { Container, FirstAssignee, SecondText } from './SelectedAssignees.styles';

type Props = {
  assignees?: GithubAssignee[];
};
export const SelectedAssignees: FC<React.PropsWithChildren<Props>> = ({ assignees = [] }) => {
  const [firstAssignee, ...restAssignees] = assignees;
  const restAssigneesNames = useMemo(() => (restAssignees.length
    ? restAssignees.reduce((acc, curr) => (acc ? `${acc}, ${curr.login}` : curr.login), '')
    : ''), [restAssignees]);

  if (!assignees.length) return (
    <SecondText>
      Add assignees
    </SecondText>
  );

  return (
    <Container>
      {!!firstAssignee?.avatarUrl && <Avatar src={firstAssignee.avatarUrl} />}
      {firstAssignee?.login && (
        <FirstAssignee>
          {firstAssignee.login}
        </FirstAssignee>
      )}
      {!!restAssignees.length && (
        <Tooltip content={restAssigneesNames}>
          <SecondText>
            {`+${restAssignees.length}`}
          </SecondText>
        </Tooltip>
      )}
    </Container>
  );
};
