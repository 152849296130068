import { CustomerFragment } from '@cycle-app/graphql-codegen/generated';
import { CustomerAvatar, CustomerAvatarProps } from '@cycle-app/ui';
import { TrashIcon, AddIcon } from '@cycle-app/ui/icons';
import { ReactNode, useCallback } from 'react';

import ImageInput from 'src/components/ImageInput/ImageInput';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useCustomerUpdate } from 'src/hooks/api/mutations/customers/useCustomerUpdate';

import { Container, Button, Buttons, StyledCustomerCompanyAction } from './CustomerAvatarEditable.styles';

export type CustomerAvatarEditableProps = {
  customer: CustomerFragment;
  isReadOnly?: boolean;
  onCompanyClick: (companyId: string) => void;
  size?: CustomerAvatarProps['size'];
  loader?: ReactNode;
  className?: string;
};

export const CustomerAvatarEditable = ({
  customer, isReadOnly = false, onCompanyClick, size, loader, className,
}: CustomerAvatarEditableProps) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const updateCustomer = useCustomerUpdate(customer);
  const updateAvatar = useCallback(async (avatar?: File) => {
    if (updateCustomer.isLoading || !customer?.id) return;
    await updateCustomer.update({
      customerId: customer.id,
      name: customer.name ?? '',
      avatarInput: avatar ? { avatar } : null,
    });
  }, [customer.id, customer.name, updateCustomer]);

  if (updateCustomer.isLoading && loader) {
    return (
      <>
        {loader}
      </>
    );
  }

  return (
    <Container className={className}>
      <ImageInput
        previewModalTitle="New customer picture"
        previewModalSubmitLabel="Set new customer picture"
        onChange={(file) => updateAvatar(file)}
      >
        {(inputRef) => (
          <CustomerAvatar
            {...!isReadOnly && {
              tooltip: 'Change avatar',
              isEditable: true,
            }}
            customer={customer}
            isCompanyEditable={!isReadOnly && !customer.company}
            onCompanyClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (customer.company) {
                onCompanyClick(customer.company.id);
              }
            }}
            onClick={() => {
              if (!isReadOnly) {
                inputRef.current?.click();
              }
            }}
            companyTitle={customer.company ? customer.company.name : undefined}
            companyTooltip={customer.company ? 'Open company' : undefined}
            companyInput={(
              <StyledCustomerCompanyAction
                customer={customer}
                productId={productId}
              >
                <AddIcon />
              </StyledCustomerCompanyAction>
            )}
            size={size}
          />
        )}
      </ImageInput>
      {!isReadOnly && (
        <Buttons>
          {!!customer.avatar && (
            <Button
              tooltip="Remove avatar"
              tooltipPlacement="top"
              onClick={() => updateAvatar()}
            >
              <TrashIcon />
            </Button>
          )}
        </Buttons>
      )}
    </Container>
  );
};
