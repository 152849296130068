import { CompanyFragment } from '@cycle-app/graphql-codegen';
import { CompanyLogo } from '@cycle-app/ui';

export const getCompanyOption = (company: CompanyFragment) => ({
  value: company.id,
  label: company.name ?? '',
  icon: (
    <CompanyLogo
      size="S"
      company={company}
    />
  ),
});
