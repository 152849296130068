import { MateFragment, Role } from '@cycle-app/graphql-codegen';
import { InfiniteScroll, Avatar, DropdownSelect, SelectOption } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import { plural } from '@cycle-app/utilities';
import { useState } from 'react';

import SettingsUserRemoveUserModal from '../../app/Main/Settings/SettingsUsers/SettingsUserRemoveUserModal';
import { useCurrentBilling } from '../../hooks';
import { useChangeUserProductRole } from '../../hooks/api/mutations/useChangeUserProductRole';
import { useActiveUsers } from '../../hooks/api/useUsers';
import { useGetPermission } from '../../reactives';
import { Layer } from '../../types/layers.types';
import { ToggleDropdown } from '../DropdownLayer';
import {
  RoleButtonLabel,
  StyledMakersAvatar,
  Title,
  UserItem,
  UserName,
  UserNameLabel,
  UsersListContainer,
} from './UpgradeBoxFormMakers.styles';

const planMessage: Partial<SelectOption> = {
  tooltipContent: 'You need at least 1 maker',
  tooltipPlacement: 'bottom',
  tooltipOffset: [0, 20],
};

export const UpgradeBoxFormMakers = () => {
  const {
    users,
    loading,
    loadMore,
    hasNextPage,
  } = useActiveUsers();
  const currentBilling = useCurrentBilling();
  const permission = useGetPermission();
  const { changeUserProductRole } = useChangeUserProductRole();
  const [userToRemove, setUserToRemove] = useState<MateFragment | null>(null);

  return (
    <>
      <Title>
        {plural(`${currentBilling?.nbMakers} maker`, currentBilling?.nbMakers ?? 0)}
      </Title>
      <ToggleDropdown
        layer={Layer.DropdownModal}
        content={(
          <UsersListContainer>
            <InfiniteScroll
              isLoading={loading}
              hasMoreData={hasNextPage}
              loadMore={loadMore}
            >
              <ul>
                {users.map(user => {
                  const canDelete = user.productRole !== Role.Admin;
                  const canUpdateRole =
                    (user.productRole === Role.Maker && permission.canUpdateUserRoleMaker) ||
                    (user.productRole === Role.Collaborator && permission.canUpdateUserRoleCollaborator);
                  // Separate rule: button display is tested if canUpdateRole/canDelete,
                  // this one is used to disable and add the tooltip message for minimum 1 maker.
                  const allowUpdateOrDelete = user.productRole !== Role.Maker || (currentBilling?.nbMakers || 0) > 1;

                  const options = [{
                    label: 'Maker',
                    value: Role.Maker,
                    onSelect: async () => {
                      if (user.productRole) {
                        await changeUserProductRole(Role.Maker, user.id, user.productRole);
                      }
                    },
                    ...!allowUpdateOrDelete && {
                      disabled: true,
                      ...planMessage,
                    },
                  }, {
                    label: 'Collaborator',
                    value: Role.Collaborator,
                    onSelect: async () => {
                      if (user.productRole) {
                        await changeUserProductRole(Role.Collaborator, user.id, user.productRole);
                      }
                    },
                    ...!allowUpdateOrDelete && {
                      disabled: true,
                      ...planMessage,
                    },
                  }];
                  if (canDelete) {
                    options.push({
                      label: 'Remove',
                      value: 'remove',
                      variant: 'danger',
                      onSelect: () => setUserToRemove(user),
                      ...!allowUpdateOrDelete && {
                        disabled: true,
                        ...planMessage,
                      },
                    });
                  }
                  return (
                    <UserItem key={user.id}>
                      <UserName $isDisabled={!user.jobTitle}>
                        <Avatar user={user} />
                        <UserNameLabel>
                          {user.jobTitle ? `${user.firstName} ${user.lastName}` : user.email}
                        </UserNameLabel>
                      </UserName>
                      {canUpdateRole && (
                        <DropdownSelect
                          placement="bottom-end"
                          hideSearch
                          options={options}
                        >
                          <button className="btn-tertiary btn-sm h-7">
                            <RoleButtonLabel>
                              {user.productRole}
                            </RoleButtonLabel>
                            <CaretIcon size={10} />
                          </button>
                        </DropdownSelect>
                      )}
                    </UserItem>
                  );
                })}
              </ul>
            </InfiniteScroll>
          </UsersListContainer>
        )}
        placement="bottom"
        width={320}
        button={({ onClick }) => (
          <StyledMakersAvatar
            size={24}
            onClick={onClick}
            limit={5}
            label={(nbMakers, nbMakersDisplayed) => {
              if (nbMakers > nbMakersDisplayed) return `+${nbMakers - nbMakersDisplayed}`;
              return '';
            }}
          />
        )}
      />
      {userToRemove && (
        <SettingsUserRemoveUserModal
          user={userToRemove}
          onClose={() => setUserToRemove(null)}
        />
      )}
    </>
  );
};
