import { DocSourceUpdateDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';
export const useUpdateDocSource = () => {
  const [mutate] = useSafeMutation(DocSourceUpdateDocument);

  const updateDocSource = async (docId: string, url: string) => mutate({
    variables: {
      docId,
      url,
    },
  });

  return {
    updateDocSource,
  };
};
