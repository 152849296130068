import { Role } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { PermissionsByRoles, PermissionsByPlan } from 'src/constants/permission.constants';
import { useRole } from 'src/hooks/useRoles';
import { setPermission, setBillingPermission, setIntegrationPermission } from 'src/reactives/permission.reactive';

import { BILLING_LIMIT_ACTIVE_INSIGHTS } from '../constants/billing.constants';
import { useIsMobile } from '../reactives/responsive.reactive';
import { useCurrentBilling } from './api/useProduct';

export const useInitPermission = () => {
  const {
    role, globalRole, isLoading,
  } = useRole();
  const isMobile = useIsMobile();
  const currentBilling = useCurrentBilling();
  useEffect(() => {
    if (isLoading) return;
    const roles = PermissionsByRoles[role];
    setPermission({
      isPermissionInit: true,
      canCreateAttributeOption: roles.includes('HANDLE_ATTRIBUTES_OPTIONS'),
      canCreateGroup: roles.includes('GROUP_CREATE'),
      canCreateInsight: roles.includes('INSIGHT_CREATE'),
      canCreateRelease: roles.includes('RELEASE_CREATE'),
      canCreateReleaseNote: roles.includes('RELEASE_NOTE_CREATE'),
      canCreateView: roles.includes('VIEW_CREATE'),
      canCreateViewCustom: roles.includes('VIEW_CUSTOM_CREATE'),
      canCreateViewCustomSection: roles.includes('VIEW_CUSTOM_SECTION_CREATE'),
      canDeleteAttributeOption: roles.includes('HANDLE_ATTRIBUTES_OPTIONS'),
      canDeleteInsight: roles.includes('INSIGHT_DELETE'),
      canDeleteRelease: roles.includes('RELEASE_DELETE'),
      canDeleteReleaseNote: roles.includes('RELEASE_NOTE_DELETE'),
      canDeleteView: roles.includes('VIEW_DELETE'),
      canDeleteViewCustom: roles.includes('VIEW_CUSTOM_DELETE'),
      canDeleteViewCustomSection: roles.includes('VIEW_CUSTOM_SECTION_DELETE'),
      canExportCSV: roles.includes('EXPORT_CSV'),
      canInviteCollaborator: roles.includes('USER_COLLABORATOR_CREATE'),
      canInviteMaker: roles.includes('USER_MAKER_CREATE'),
      canReadSettings: !isMobile && roles.includes('SETTINGS_READ'),
      canReadOnlySettings: !isMobile && roles.includes('SETTINGS_READ_ONLY'),
      canReorderBoards: roles.includes('VIEWS_REORDER'),
      canReorderGroups: roles.includes('GROUPS_REORDER'),
      canTranscribe: roles.includes('TRANSCRIPT'),
      canUpdateAttributeOption: roles.includes('HANDLE_ATTRIBUTES_OPTIONS'),
      canUpdateFeedbackStatus: roles.includes('FEEDBACK_STATUS_UPDATE'),
      canUpdateGroup: roles.includes('GROUP_UPDATE'),
      canUpdateInsight: roles.includes('INSIGHT_UPDATE'),
      canUpdateRelease: roles.includes('RELEASE_UPDATE'),
      canUpdateReleaseNote: roles.includes('RELEASE_NOTE_UPDATE'),
      canUpdateUserRoleCollaborator: roles.includes('USER_UPDATE_ROLE_COLLABORATOR'),
      canUpdateUserRoleMaker: roles.includes('USER_UPDATE_ROLE_MAKER'),
      canUpdateView: !isMobile && roles.includes('VIEW_UPDATE'),
      canUpdateViewCustom: roles.includes('VIEW_CUSTOM_UPDATE'),
      canUpdateViewCustomSection: roles.includes('VIEW_CUSTOM_SECTION_UPDATE'),
      canUpgradeBilling: roles.includes('SETTINGS_READ'),
      canUseAi: roles.includes('USE_AI'),
    });
  }, [role, isMobile, isLoading]);

  useEffect(() => {
    if (isLoading) return;
    const roles = PermissionsByRoles[role];
    const plans = currentBilling?.plan ? PermissionsByPlan[currentBilling.plan] : [];
    setBillingPermission({
      canUploadNotRestricted:
        globalRole === Role.SuperAdmin ||
        plans.includes('UPLOAD_NOT_RESTRICTED'),
      canPlanCreateViewCustomSection:
        globalRole === Role.SuperAdmin ||
        (
          roles.includes('VIEW_CREATE') &&
          plans.includes('VIEW_CUSTOM_SECTION_CREATE')
        ),
      canPlanCreateView:
        globalRole === Role.SuperAdmin ||
        (roles.includes('VIEW_CREATE') && plans.includes('VIEW_CREATE')),
      canPlanCreateViewCustom:
        globalRole === Role.SuperAdmin ||
        (roles.includes('VIEW_CUSTOM_CREATE') && plans.includes('VIEW_CUSTOM_CREATE')),
      canPlanUpdateSlackNotifications:
        globalRole === Role.SuperAdmin ||
        plans.includes('SLACK_NOTIFICATIONS_UPDATE'),
      canPlanUpdateSlackAutomations:
        globalRole === Role.SuperAdmin ||
        plans.includes('SLACK_AUTOMATION_UPDATE'),
      canPlanCreateDoctype:
        globalRole === Role.SuperAdmin ||
        plans.includes('DOCTYPE_CREATE'),
      canPlanCreateInsight:
        globalRole === Role.SuperAdmin ||
        (!!currentBilling && (currentBilling.nbActiveInsights < BILLING_LIMIT_ACTIVE_INSIGHTS || plans.includes('INSIGHT_CREATE'))),
    });
    setIntegrationPermission({
      canAddIntegration:
        globalRole === Role.SuperAdmin ||
        (
          roles.includes('SETTINGS_READ') && (roles.includes('INTEGRATION_ADD') || plans.includes('INTEGRATION_ADD'))
        ),
    });
  }, [currentBilling, globalRole, role, isLoading]);
};
