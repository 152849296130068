import { CaretIcon } from '@cycle-app/ui/icons';
import { twJoin } from 'tailwind-merge';

import { ToggleDropdown, ToggleDropdownProps } from 'src/components/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { ProductCurrent, ProductsPanel } from '../Product';

export const ProductSelect = ({
  button, onChange, className, ...props
}: Partial<ToggleDropdownProps> & {
  onChange?: (slug?: string) => void;
  className?: string;
}) => {
  return (
    <ToggleDropdown
      layer={Layer.DropdownModalZ2}
      withPortal={false}
      {...props}
      button={buttonProps => (typeof button === 'function' ? button(buttonProps) : (
        <button
          className={twJoin(
            'btn-tertiary h-7 text-body font-normal text-primary',
            buttonProps['data-active'] && 'btn-hover',
          )}
          onClick={buttonProps.toggle}
        >
          <ProductCurrent />
          <CaretIcon
            size={10}
            direction={buttonProps['data-active'] ? 'top' : 'bottom'}
          />
        </button>
      ))}
      content={contentProps => (
        <ProductsPanel
          className={className}
          onDone={contentProps.hide}
          onChange={onChange}
        />
      )}
    />
  );
};
