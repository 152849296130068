import { Emoji } from '@cycle-app/ui';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { PageId, routing } from 'src/constants/routing.constant';
import { useRouteMatch } from 'src/hooks';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import { useBoard } from 'src/hooks/api/useBoard';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useNavigate } from 'src/hooks/useNavigate';
import { getBoardSlug } from 'src/utils/slug.util';

import { TagStyled } from './DeleteBoardModal.styled';

type DeleteBoardModalProps = {
  boardId: string;
  onHide: VoidFunction;
  showViewEmoji?: boolean;
};

export const DeleteBoardModal = ({
  boardId, onHide, showViewEmoji,
}: DeleteBoardModalProps) => {
  const board = useBoard(boardId);
  const { removeBoard } = useBoardMutations();
  const { sections } = useBoardSections();

  const matchFeedbackViewSlug = useRouteMatch(routing[PageId.InboxView])?.params?.boardSlug;
  const matchRoadmapsViewSlug = useRouteMatch(routing[PageId.RoadmapView])?.params?.boardSlug;
  const matchCustomViewSlug = useRouteMatch(routing[PageId.Board])?.params?.boardSlug;

  const { navigate } = useNavigate();

  if (!board) return null;

  const isCustomSection = sections?.some(section => section.boards.edges.some(edge => edge.node.id === boardId));

  return (
    <DialogModal
      hide={onHide}
      title="Delete view"
      onConfirm={async () => {
        await removeBoard(board.id);
        switch (getBoardSlug(board)) {
          case matchFeedbackViewSlug: navigate(PageId.Inbox); break;
          case matchRoadmapsViewSlug: navigate(PageId.Roadmap); break;
          case matchCustomViewSlug: navigate(PageId.Main); break;
          default: break;
        }
      }}
      info={(
        <>
          {'Are you sure you want to delete '}
          <TagStyled
            limitSize={false}
            icon={isCustomSection && board.emoji && showViewEmoji && (
              <Emoji
                inline
                emoji={board.emoji}
                color={board.color}
                size={12}
              />
            )}
          >
            {board.name}
          </TagStyled>
          {' ?'}
        </>
      )}
      confirmLabel="Delete"
      width="auto"
    />
  );
};
