import { CreateLinearIssueDocument, LinearIssueFullFragment, LinearStatus } from '@cycle-app/graphql-codegen';
import { Input, Button, LinearProjectIcon } from '@cycle-app/ui';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isPresent } from 'ts-is-present';

import { SelectField } from 'src/components/Form/SelectField';
import { LinearAssignee } from 'src/components/LinearAssignee/LinearAssignee';
import { LinearStatus as LinearStatusIcon } from 'src/components/LinearStatus/LinearStatus';
import { useSafeMutation } from 'src/hooks';
import { useLinearAssignees } from 'src/hooks/api/queries/integrations/useLinearAssignees';
import { useLinearProjects } from 'src/hooks/api/queries/integrations/useLinearProjects';
import { useLinearTeams } from 'src/hooks/api/queries/integrations/useLinearTeams';
import { getLinearModal, closeLinearModal, setLinearModal } from 'src/reactives/linear.reactive';
import { Layer } from 'src/types/layers.types';

import { Form, FieldSet, FieldValue, FieldName } from './DocLinear.styles';

interface Props {
  defaultTitle: string;
  onCreate?: (data: LinearIssueFullFragment) => void;
}

export const LinearIssueForm = ({
  defaultTitle, onCreate,
}: Props) => {
  const [createLinearIssue] = useSafeMutation(CreateLinearIssueDocument);
  const teamsQuery = useLinearTeams();
  const assigneesQuery = useLinearAssignees();
  const projectsQuery = useLinearProjects(getLinearModal().integrationId);
  const [statuses, setStatuses] = useState<LinearStatus[]>([]);

  const {
    handleSubmit, register, formState, watch, setValue,
  } = useForm<{
    title: string;
    description: string;
    teamId: string | null;
    statusId: string | null;
    assigneeId: string | null;
    projectId: string | null;
  }>({
    defaultValues: {
      title: defaultTitle,
      description: '',
      teamId: null,
      statusId: null,
      assigneeId: null,
      projectId: null,
    },
  });

  register('teamId', { required: 'Team is required' });

  const teamIdValue = watch('teamId');
  const selectedTeam = teamsQuery.teams.find(t => t.id === teamIdValue);

  const statusIdValue = watch('statusId');
  const selectedStatus = statuses.find(s => s.id === statusIdValue);

  const assigneeIdValue = watch('assigneeId');
  const selectedAssignee = assigneesQuery.assignees.find(a => a.id === assigneeIdValue);

  const projectIdValue = watch('projectId');
  const selectedProject = projectsQuery.projects.find(p => p.id === projectIdValue);

  // Each team has its own projects
  const projectOptions = useMemo(() => {
    return projectsQuery.projects
      .filter(project => project.teams.some(team => team?.id === teamIdValue))
      .map(project => ({
        value: project.id,
        label: project.name ?? 'Untitled',
        icon: (
          <LinearProjectIcon
            icon={project.icon}
            color={project.color}
            size={16}
          />
        ),
      }));
  }, [projectsQuery.projects, teamIdValue]);

  useEffect(() => {
    const { lastTeamId } = getLinearModal();
    const defaultTeamId = teamsQuery.teams.find(t => t.id === lastTeamId)?.id ?? teamsQuery.teams[0]?.id;
    if (defaultTeamId) setValue('teamId', defaultTeamId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsQuery.teams]);

  useEffect(() => {
    const { lastAssigneeId } = getLinearModal();
    const defaultAssigneeId = assigneesQuery.assignees.find(t => t.id === lastAssigneeId)?.id;
    if (defaultAssigneeId) setValue('assigneeId', defaultAssigneeId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigneesQuery.assignees]);

  useEffect(() => {
    const { lastProjectId } = getLinearModal();
    const defaultProjectId = projectOptions.find(t => t.value === lastProjectId)?.value;
    if (defaultProjectId) setValue('projectId', defaultProjectId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectOptions]);

  useEffect(() => {
    const availableStatuses = selectedTeam?.availableStatus.filter(isPresent) ?? [];
    const defaultStatusId = availableStatuses.find(s => s.id === getLinearModal().lastStatusId)?.id ?? availableStatuses[0]?.id;
    if (defaultStatusId) setValue('statusId', defaultStatusId);
    setStatuses(availableStatuses);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam]);

  return (
    <Form
      onSubmit={handleSubmit(async values => {
        const { docId } = getLinearModal();
        if (!docId || !values.teamId) return;
        const result = await createLinearIssue({
          variables: {
            docId,
            title: values.title,
            description: values.description,
            teamId: values.teamId,
            statusId: values.statusId,
            assigneeId: values.assigneeId,
            projectId: values.projectId,
          },
        });
        const issue = result.data?.createLinearIssue;
        if (!issue?.url) return;
        onCreate?.(issue);
        setLinearModal({
          integrationId: null,
          docId: null,
          type: null,
          searchText: null,
          lastTeamId: values.teamId,
          lastStatusId: values.statusId,
          lastAssigneeId: values.assigneeId,
          lastProjectId: values.projectId,
        });
      })}
    >
      <Input
        autoFocus
        label="Issue title"
        placeholder="Your issue title"
        error={formState.errors.title?.message}
        {...register('title', {
          required: 'Issue title is required',
        })}
      />

      <Input
        label="Description"
        placeholder="Your description"
        {...register('description')}
      />

      <SelectField
        layer={Layer.DropdownModalZ3}
        label="Team"
        options={teamsQuery.teams.map(t => ({
          value: t.id,
          label: t.name,
        }))}
        value={selectedTeam?.name ?? ''}
        onChange={value => setValue('teamId', value)}
        isLoading={teamsQuery.isLoading}
        hasError={!!formState.errors.teamId}
      />

      <FieldSet>
        <SelectField
          layer={Layer.DropdownModalZ3}
          label="Status"
          options={statuses.map(s => ({
            value: s.id,
            label: s.name,
            icon: (
              <LinearStatusIcon
                status={s}
                size={14}
              />
            ),
          }))}
          value={selectedStatus && (
            <FieldValue>
              <LinearStatusIcon
                status={selectedStatus}
                size={14}
              />
              <FieldName>
                {selectedStatus.name}
              </FieldName>
            </FieldValue>
          )}
          onChange={value => setValue('statusId', value)}
          isLoading={teamsQuery.isLoading}
        />

        <SelectField
          layer={Layer.DropdownModalZ3}
          label="Assignee"
          options={assigneesQuery.assignees.map(a => ({
            value: a.id,
            label: a.name,
            icon: <LinearAssignee assignee={a} />,
          }))}
          value={selectedAssignee && (
            <FieldValue>
              <LinearAssignee assignee={selectedAssignee} />
              <FieldName>
                {selectedAssignee.name}
              </FieldName>
            </FieldValue>
          )}
          onChange={value => setValue('assigneeId', value)}
          onClearValue={() => setValue('assigneeId', null)}
          isLoading={assigneesQuery.isLoading}
        />
      </FieldSet>

      <FieldSet>
        <SelectField
          layer={Layer.DropdownModalZ3}
          label="Project"
          options={projectOptions}
          value={selectedProject && (
            <FieldValue>
              <LinearProjectIcon
                icon={selectedProject.icon}
                color={selectedProject.color}
                size={16}
              />
              <FieldName>
                {selectedProject.name}
              </FieldName>
            </FieldValue>
          )}
          onChange={value => setValue('projectId', value)}
          onClearValue={() => setValue('projectId', null)}
          isLoading={projectsQuery.isLoading && !projectsQuery.projects.length}
        />
      </FieldSet>

      <footer>
        <Button
          size="M"
          variant="secondary"
          onClick={closeLinearModal}
        >
          Cancel
        </Button>
        <Button
          size="M"
          type="submit"
          disabled={teamsQuery.isLoading || assigneesQuery.isLoading || projectsQuery.isLoading}
          isLoading={formState.isSubmitting}
        >
          Create
        </Button>
      </footer>
    </Form>
  );
};
