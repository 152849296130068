import { Color, SectionType } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { FC } from 'react';

import { Layer } from '../../types/layers.types';
import { BoardDefaultIcon } from '../BoardDefaultIcon';
import { IconDropdown, IconInput } from '../IconDropdown';
import { Label, Container } from './BoardIconInput.styles';

type BoardIconInputProps = {
  color: Color | null | undefined;
  icon: string | null | undefined;
  onColorChange: (value: Color) => void;
  onIconChange: (value: string) => void;
  sectionType: SectionType | undefined;
};

export const BoardIconInput: FC<React.PropsWithChildren<BoardIconInputProps>> = ({
  color, onIconChange, icon, onColorChange, sectionType,
}: BoardIconInputProps) => {
  return (
    <Container>
      <Label>
        Icon
      </Label>
      <IconDropdown
        layer={Layer.DropdownModalZ1}
        placement="bottom-start"
        color={color}
        onIconChange={onIconChange}
        onColorChange={onColorChange}
        button={buttonProps => (
          <IconInput
            ref={buttonProps.ref}
            onClick={buttonProps.onClick}
            forceFocus={buttonProps['data-active']}
          >
            {icon && (
              <Emoji
                emoji={icon}
                size={16}
                color={color}
              />
            )}
            {!icon && (
              <BoardDefaultIcon
                sectionType={sectionType}
                color={color}
              />
            )}
          </IconInput>
        )}
      />
    </Container>
  );
};
