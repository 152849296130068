import { Icon } from '@cycle-app/ui';
import { getDocSlug, getHighlightHash } from '@cycle-app/utilities';

import { FeedbackPopover } from 'src/components/FeedbackPopover';
import { PageId } from 'src/constants/routing.constant';
import { useDocDocSourceFragment } from 'src/hooks/api/fragments/doc';
import { getUrl } from 'src/utils/routing.utils';

import { useQuoteId } from './QuoteContext';
import { QuotePanelLink } from './QuotePanelLink';

export const QuotePanelFeedback = () => {
  const docId = useQuoteId();

  const doc = useDocDocSourceFragment(docId);
  if (!doc?.docSource?.doc) return null;

  return (
    <FeedbackPopover feedbackDocId={doc.docSource.doc.id}>
      <QuotePanelLink
        to={getUrl(PageId.DocFullPage, {
          docSlug: getDocSlug(doc.docSource.doc),
          hash: getHighlightHash({
            docId: doc.id,
            blockId: doc.docSource.blockId,
          }),
        })}
      >
        <Icon
          name="feedback"
          className="flex-none text-secondary"
        />
        <span className="truncate">
          {doc.docSource.doc.title.trim() || 'Untitled'}
        </span>
      </QuotePanelLink>
    </FeedbackPopover>
  );
};
