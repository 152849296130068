export const Arrow = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="405"
      height="8"
      viewBox="0 0 405 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M400 4H7.99999"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeDasharray="4 4"
      />
      <path
        d="M2.86217 2.56892L5.68446 1.15777C6.45118 0.774411 6.83454 0.582732 7.14616 0.628773C7.41827 0.668977 7.66181 0.81949 7.81946 1.04489C8 1.30303 8 1.73164 8 2.58885V5.41115C8 6.26836 8 6.69697 7.81946 6.95511C7.66181 7.18051 7.41827 7.33102 7.14616 7.37123C6.83454 7.41727 6.45118 7.22559 5.68446 6.84223L2.86217 5.43108C1.94095 4.97048 1.48035 4.74017 1.33254 4.42852C1.20391 4.15732 1.20391 3.84268 1.33254 3.57148C1.48035 3.25983 1.94095 3.02952 2.86217 2.56892Z"
        fill="currentcolor"
      />
    </svg>
  );
};
