import { WriteContentDocument } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption, Spinner } from '@cycle-app/ui';
import { CaretIcon, ReleaseNoteIcon } from '@cycle-app/ui/icons';
import { atom, useAtom } from 'jotai';
import { twJoin } from 'tailwind-merge';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useDocId } from 'src/hooks/usePathParams';
import useSafeMutation from 'src/hooks/useSafeMutation';

const templateOptions: SelectOption[] = [{
  label: 'Basic',
  value: 'Basic',
}, {
  label: 'Figma',
  value: 'Figma',
}];

const agentOptions: SelectOption[] = [{
  label: 'Shreyas',
  value: 'Shreyas',
}, {
  label: 'Agent2',
  value: 'Agent2',
}, {
  label: 'Agent3',
  value: 'Agent3',
}];

const templateIdAtom = atom(templateOptions[0]?.value);
const agentIdAtom = atom(agentOptions[0]?.value);

export const WriteContent = () => {
  const docId = useDocId();
  const [agentId, setAgentId] = useAtom(agentIdAtom);
  const [templateId, setTemplateId] = useAtom(templateIdAtom);
  const [writeContent, { loading }] = useSafeMutation(WriteContentDocument);

  return (
    <div
      className="flex items-center gap-0.5"
      onClick={e => e.stopPropagation()}
    >

      <ToggleDropdown
        withPortal
        withWrapper={false}
        placement="bottom"
        button={props => (
          <button
            className={twJoin('btn-tertiary flex-none gap-1.5 font-normal')}
            onClick={props.toggle}
          >
            {templateOptions.find(option => option.value === templateId)?.label ?? 'Select template'}
            <CaretIcon
              size={10}
              direction={props['data-active'] ? 'top' : 'bottom'}
            />
          </button>
        )}
        content={props => (
          <SelectPanel
            hideSearch
            options={templateOptions}
            onOptionChange={option => {
              setTemplateId(option.value);
              props.hide();
            }}
          />
        )}
      />

      <ToggleDropdown
        withPortal
        withWrapper={false}
        placement="bottom"
        button={props => (
          <button
            className={twJoin('btn-tertiary flex-none gap-1.5 font-normal')}
            onClick={props.toggle}
          >
            {agentOptions.find(option => option.value === agentId)?.label ?? 'Select agent'}
            <CaretIcon
              size={10}
              direction={props['data-active'] ? 'top' : 'bottom'}
            />
          </button>
        )}
        content={props => (
          <SelectPanel
            hideSearch
            options={agentOptions}
            onOptionChange={option => {
              setAgentId(option.value);
              props.hide();
            }}
          />
        )}
      />

      <button
        className="btn-tertiary flex-none gap-1.5 font-normal"
        disabled={loading}
        onClick={() => {
          if (!docId || !templateId || !agentId) return;
          void writeContent({
            variables: {
              docId,
              templateId,
              agentId,
            },
          });
        }}
      >
        {loading ? <Spinner /> : <ReleaseNoteIcon />}
        Write content
      </button>
    </div>
  );
};
