import { MateFragment } from '@cycle-app/graphql-codegen';
import { FC } from 'react';
import { twJoin } from 'tailwind-merge';

import { useMe } from 'src/hooks/api/useMe';

import RealtimeMore from './RealtimeMore';
import RealtimeUser from './RealtimeUser';

interface Props {
  className?: string;
  users: MateFragment[] | undefined;
  showLocation?: boolean;
}

const PREVIEW_USERS_LENGTH = 5;

const RealtimeUsers: FC<React.PropsWithChildren<Props>> = ({
  className,
  showLocation = false,
  ...props
}) => {
  const { me } = useMe();
  const users = props.users?.filter(user => user.id !== me.id);
  if (!users?.length) return null;

  const firstUsers = users.slice(0, PREVIEW_USERS_LENGTH);
  const moreUsers = users.slice(PREVIEW_USERS_LENGTH);

  return (
    <div className={twJoin('flex items-center gap-1.5', className)}>
      {firstUsers.map(user => (
        <RealtimeUser
          key={user.id}
          user={user}
          showLocation={showLocation}
        />
      ))}
      {moreUsers.length > 0 && (
        <RealtimeMore
          users={moreUsers}
        />
      )}
    </div>
  );
};

export default RealtimeUsers;
