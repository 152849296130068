import { Icon } from '@cycle-app/ui';
import { atom, useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

import { PageId } from 'src/constants/routing.constant';
import { useChangelog } from 'src/hooks/releases/useChangelog';
import { getUrl } from 'src/utils/routing.utils';

import { useChangelogBuilderForm } from '../useChangelogBuilderForm';
import { PreviewTypeRadioGroup } from './PreviewTypeRadioGroup';
import { CustomEvents, PreviewType } from './types';
import { usePreviewStyle } from './usePreviewAttrs';
import { changelogValueToEventData } from './utils';

const previewDomain = import.meta.env.VITE_CHANGELOG_PREVIEW_DOMAIN ?? '';

const previewTypeItems = [
  {
    value: PreviewType.DESKTOP,
    label: 'Desktop',
    icon: <Icon name="desktop" />,
  },
  {
    value: PreviewType.TABLET,
    label: 'Tablet',
    icon: <Icon name="tablet" />,
  },
  {
    value: PreviewType.MOBILE,
    label: 'Mobile',
    icon: <Icon name="phone" />,
  },
];

const previewTypeAtom = atom(PreviewType.DESKTOP);

export function ChangelogPreview() {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { changelog } = useChangelog();
  const [previewLoading, setPreviewLoading] = useState(true);
  const [previewType, setPreviewType] = useAtom(previewTypeAtom);

  const {
    containerRef, style,
  } = usePreviewStyle(previewType);

  const {
    getValues, watch,
  } = useChangelogBuilderForm();

  useEffect(() => {
    const controller = new AbortController();

    window.addEventListener('message', (ev) => {
      if (ev.data.type === CustomEvents.CLIENT_APP_READY && ev.origin === previewDomain) {
        iframeRef.current?.contentWindow?.postMessage({
          type: CustomEvents.PREVIEW_CHANGE,
          data: changelogValueToEventData(getValues()),
        }, previewDomain);
        setPreviewLoading(false);
      }
    }, {
      signal: controller.signal,
    });

    return () => controller.abort();

  }, [getValues]);

  if (!changelog || !previewDomain) return null;

  return (
    <div className="fixed inset-0 z-20 flex size-full h-screen min-w-0 flex-col bg-grey-100 dark:bg-black">
      <header className="flex items-center p-6 ">
        <div className="flex flex-1 items-center gap-2">
          <Link
            to={getUrl(PageId.Changelog)}
            className="btn-tertiary"
          >
            <Icon name="chevron-left" />
            Back
          </Link>
        </div>
        <div>
          <PreviewTypeRadioGroup
            items={previewTypeItems}
            value={previewType}
            onChange={setPreviewType}
          />
        </div>
        <div className="flex-1" />
      </header>

      <div
        className="relative flex flex-1 items-center justify-center"
        ref={containerRef}
      >
        {previewLoading && (
          <Icon
            name="loading"
            className="absolute size-8 animate-spin mix-blend-exclusion"
          />
        )}

        <iframe
          ref={iframeRef}
          title="Preview"
          src={`${previewDomain}/${changelog.slug}/preview`}
          sandbox="allow-scripts allow-same-origin allow-forms"
          className={twJoin('absolute size-full shadow-lg', previewLoading && 'opacity-0')}
          style={{
            backgroundColor: watch('backgroundColor'),
            ...style,
          }}
        />
      </div>
    </div>
  );
}
