import { LinearStatus as LinearStatusType } from '@cycle-app/graphql-codegen';
import { SelectOption, LinearProjectIcon, Tabs } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { ReactNode, useState } from 'react';

import { ToggleContentProps } from 'src/components/DropdownLayer';
import { LinearStatus } from 'src/components/LinearStatus/LinearStatus';
import { useLinearIssues } from 'src/hooks/api/queries/integrations/useLinearIssues';
import { useLinearProjects } from 'src/hooks/api/queries/integrations/useLinearProjects';
import { LinearModalState, setLinearModal } from 'src/reactives/linear.reactive';
import { AutomationType } from 'src/types/automation.types';
import { issueInfos, projectInfos } from 'src/utils/linear.utils';

import { Header, Infos, IssueIcon, ResultPanel, CreateButton } from './DocLinear.styles';
import { OptionData } from './DocLinear.types';

export type LinearSearchProps = Omit<ToggleContentProps, 'buttonRect'> & {
  defaultSearchText?: string;
  defaultSearchType?: AutomationType;
  docId?: string;
  integrationId: string;
  onCreate: NonNullable<LinearModalState['onCreate']>;
  onSelect: (data: { type: AutomationType; url: string; id: string }) => void;
  skeletons: ReactNode;
};

export const LinearSearch = ({
  hide, skeletons, defaultSearchType, integrationId, docId, defaultSearchText, onCreate, onSelect,
}: LinearSearchProps) => {
  const [searchType, setSearchType] = useState<NonNullable<AutomationType>>(defaultSearchType || 'issue');
  const [searchText, setSearchText] = useState(defaultSearchText || '');
  const projectsquery = useLinearProjects(integrationId, searchText, { skip: searchType !== 'project' });
  const issuesQuery = useLinearIssues(integrationId, searchText, { skip: searchType !== 'issue' });

  const isLoading = searchType === 'project'
    ? projectsquery.isLoading && projectsquery.projects.length === 0
    : issuesQuery.isLoading && issuesQuery.issues.length === 0;

  const options: SelectOption<OptionData>[] = searchType === 'project'
    ? projectsquery.projects.map(project => ({
      value: project.id,
      label: project.name ?? '',
      data: {
        url: project.url,
        type: 'project',
      },
      icon: (
        <LinearProjectIcon
          icon={project.icon}
          color={project.color}
          size={14}
        />
      ),
      end: (
        <Infos>
          {projectInfos(project.progress, project.issues.length)}
        </Infos>
      ),
    }))
    : issuesQuery.issues.map(issue => ({
      value: issue.id,
      label: issue.title ?? '',
      data: {
        url: issue.url,
        type: 'issue',
      },
      icon: (
        <LinearInfos
          url={issue.url}
          teamName={issue.team?.name}
          status={issue.status}
        />
      ),
    }));

  const isFetching =
    (searchType === 'project' && projectsquery.isFetching) ||
    (searchType === 'issue' && issuesQuery.isFetching);

  return (
    <ResultPanel
      $isFetching={isFetching}
      style={{
        maxHeight: 390,
        padding: 0,
      }}
      defaultFilter={defaultSearchText}
      debounceSearch
      onSearchChange={setSearchText}
      // filtering is done with the API
      filterOptionsOnInputChange={false}
      options={options}
      onOptionChange={option => {
        // type assertion is needed because stylelint does not support styled components with generics
        const data = option.data as OptionData | undefined;
        const url = data?.url;
        const type = data?.type;
        if (!url || !type) return;
        onSelect({
          type,
          url,
          id: option.value,
        });
        hide();
      }}
      header={props => (
        <>
          <Header>
            <Tabs
              value={searchType}
              items={[{
                id: 'project',
                label: 'Projects',
                onClick: () => {
                  props.inputRef.current?.focus();
                  setSearchType('project');
                },
              }, {
                id: 'issue',
                label: 'Issues',
                onClick: () => {
                  props.inputRef.current?.focus();
                  setSearchType('issue');
                },
              }]}
            />
            <CreateButton
              onClick={async () => {
                setLinearModal({
                  integrationId,
                  docId: docId ?? null,
                  type: searchType,
                  searchText: props.inputRef.current?.value.trim() ?? null,
                  onCreate,
                });
                hide();
              }}
            >
              <AddIcon size={12} />
              {`Create new ${searchType}`}
            </CreateButton>
          </Header>
        </>
      )}
    >
      {isLoading && skeletons}
    </ResultPanel>
  );
};

export const LinearInfos = ({
  url, teamName, status,
}: {
  url: string | null | undefined;
  teamName?: string | null | undefined;
  status: LinearStatusType | null | undefined;
}) => (
  <Infos>
    <IssueIcon size={14} />
    {issueInfos(url, teamName)}
    {status && (
      <LinearStatus
        status={status}
        size={14}
      />
    )}
  </Infos>
);
