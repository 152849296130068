import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const StyledScrollableContent = styled.div`
  overflow-x: hidden;
  padding: 0;
  position: relative;
  background-color: ${p => p.theme.colors.ugradeLayout.bg};
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
  position: relative;
`;

export const Centered = styled.div`
  flex: 1;
  max-width: 1180px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  z-index: 10;
`;

export const Main = styled.div`
  max-width: 400px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 83px;
`;

export const LogoContainer = styled.div`
  display: inline-flex;
  position: relative;
`;

export const Title = styled.h1`
  ${typo.headerLarge}
  margin-top: 24px;
`;

export const Desc = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.ugradeLayout.desc};
  margin: 12px auto 0;
  max-width: 400px;
`;
