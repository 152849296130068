import { Language, Tone } from '@cycle-app/graphql-codegen';
import { AiIcon } from '@cycle-app/ui/icons';
import { forwardRef } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import { resetEditorAi, getEditorAi, useEditorAiValue } from 'src/reactives';
import type { EditorAiAction } from 'src/types/editor.types';

import { PromptContainer, AiIsWriting, PromptInput } from './EditorAiMenu.styles';

type EditorAiPromptContainerProps = {
  prompt: string;
  askAi: (
    actionParam: EditorAiAction,
    data?: Language | Tone | string
  ) => Promise<void>;
  setPrompt: Dispatch<SetStateAction<string>>;
  onInputFocus: VoidFunction;
};

export const EditorAiPromptContainer = forwardRef<HTMLInputElement, EditorAiPromptContainerProps>((
  {
    askAi,
    prompt,
    setPrompt,
    onInputFocus,
  },
  inputRef,
) => {
  const {
    isLoading, suggestion, isPromptCommand,
  } = useEditorAiValue();
  return (
    <PromptContainer
      $hasBorder={!isPromptCommand || !!suggestion}
      onClick={onInputFocus}
    >
      <AiIcon
        hasGradient
        isAnimated={isLoading}
      />
      {isLoading
        ? (
          <AiIsWriting>
            Cycle AI is writing…
          </AiIsWriting>
        )
        : (
          <PromptInput
            ref={inputRef}
            onChange={e => setPrompt(e.target.value)}
            // eslint-disable-next-line no-nested-ternary
            placeholder={suggestion
              ? 'Tell AI what to do next…'
              : isPromptCommand
                ? 'Ask Cycle AI to write anything…'
                : 'Ask Cycle AI to generate or edit…'}
            onKeyDown={async (e) => {
              if (e.code === 'Enter' && !!prompt.length) {
                await askAi('prompt', prompt);
              }
            }}
            onBlur={() => {
              if (suggestion) return;
              if (!getEditorAi().visible) {
                resetEditorAi();
              }
            }}
          />
        )}
    </PromptContainer>
  );
});
