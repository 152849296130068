import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { SelectOption } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { useMemo } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { useOptimizedBooleanState, useProductBase } from 'src/hooks';
import { usePaginatedReleases } from 'src/hooks/releases/useReleases';
import { useRemoveReleaseNote } from 'src/hooks/releases/useRemoveReleaseNote';
import { openCreateRelease } from 'src/reactives/releases.reactive';

import { StyledSelectPanel, PanelHeader, PanelFooter } from './ReleasePanel.styles';

export type ReleasesPanelProps = {
  hide: VoidFunction;
  onChange:(docId: string) => void;
  title?: string;
  releaseId?: string;
  releaseNoteId?: string;
  onCreateRelease?: VoidFunction;
};

export const ReleasesPanel = ({
  hide, onChange, title, releaseId, releaseNoteId, onCreateRelease,
}: ReleasesPanelProps) => {
  const product = useProductBase();
  const {
    releasesList, isLoadingMore, hasNextPage, loadMore,
  } = usePaginatedReleases(product?.id);

  const options = useMemo<SelectOption[]>(() => releasesList
    .filter(release => release.id !== releaseId && release.publicStatus !== ReleasePublicStatus.Published)
    .map(release => {
      let label = release.date ? toShortLocaleDateString(release.date) : 'No release';
      const releaseTitle = release.title?.trim();
      if (release.date && releaseTitle) label += ` · ${releaseTitle}`;
      return {
        label,
        value: release.id,
      };
    }), [releaseId, releasesList]);

  const [isModalVisible, {
    setFalseCallback: hideModal,
    setTrueCallback: showModal,
  }] = useOptimizedBooleanState(false);

  const { removeReleaseNote } = useRemoveReleaseNote(releaseId);

  if (!product) return null;

  return (
    <>
      <StyledSelectPanel
        title={title && (
          <PanelHeader>
            {title}
          </PanelHeader>
        )}
        onClearValue={releaseNoteId ? showModal : undefined}
        clearLabel="Unlink release"
        hideSearch
        options={options}
        infiniteScroll={{
          isLoading: isLoadingMore,
          hasMoreData: hasNextPage,
          loadMore,
        }}
        onOptionChange={option => {
          hide();
          onChange(option.value);
        }}
        footer={(
          <PanelFooter
            startSlot={<AddIcon size={10} />}
            label="New release"
            onClick={() => {
              hide();
              if (onCreateRelease) {
                onCreateRelease();
              } else {
                openCreateRelease();
              }
            }}
          />
        )}
      />

      {isModalVisible && releaseNoteId && (
        <DialogModal
          title="Unlink the release"
          hide={hideModal}
          confirmLabel="Unlink"
          onConfirm={async () => {
            await removeReleaseNote(releaseNoteId);
            hide();
          }}
          info={(
            <>
              <p>
                Are you sure you want to unlink the release?
              </p>
              <p>
                Note that it will delete the release note.
              </p>
            </>
          )}
        />
      )}
    </>
  );
};
