import { SelectPanel } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import { useRef, useState } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useProducts } from 'src/hooks/api/useProducts';
import { navigate } from 'src/hooks/useNavigate';

export const WorkspacesPanel = () => {
  const currentProduct = useProductBase();
  const [searchText, setSearchText] = useState('');
  const query = useProducts({ searchText });
  const productsCount = useRef(query.products.length);
  return (
    <SelectPanel
      hideSearch={productsCount.current < 10}
      listMaxHeight={`${Math.min(600, window.innerHeight - 80)}px`}
      debounceSearch
      infiniteScroll={{
        isLoading: query.loading,
        hasMoreData: query.hasNextPage,
        loadMore: query.fetchNextPage,
      }}
      filterOptionsOnInputChange={false}
      onSearchChange={value => setSearchText(value)}
      onOptionChange={option => navigate(PageId.Main, { productSlug: option.data })}
      options={query.products.map(product => ({
        value: product.id,
        label: product.name,
        data: product.slug,
        icon: (
          <div
            className="size-4 flex-none rounded-[4px] bg-cover bg-center"
            style={{
              backgroundImage: product.logo?.url ? `url(${product.logo.url})` : 'none',
            }}
          />
        ),
        end: product.id === currentProduct?.id
          ? (
            <CheckIcon
              size={14}
              className="text-secondary"
            />
          )
          : undefined,
      }))}
    />
  );
};
