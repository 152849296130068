import { useFragment } from '@apollo/client';
import { ProductAreaFragmentDoc } from '@cycle-app/graphql-codegen';

export const useProductArea = (id = '') => {
  const {
    data, complete,
  } = useFragment({
    fragment: ProductAreaFragmentDoc,
    fragmentName: 'ProductArea',
    from: id,
  });
  return complete && data.id ? data : null;
};
