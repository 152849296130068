import { StatusCategory } from '@cycle-app/graphql-codegen';
import { SelectOption, StatusIcon as StatusCategoryIcon } from '@cycle-app/ui';
import { StatusIcon } from '@cycle-app/ui/icons';
import { STATUS_CATEGORY } from '@cycle-app/utilities/src/constants/statusCategory.constants';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useStatusFilter, setStatusFilter, getStatusFilter } from 'src/reactives/commandbar.reactive';
import { Layer } from 'src/types/layers.types';

import { SectionSelectPanel, FilterButton, ArrowIcon } from './CommandStatusFilter.styles';

export const CommandStatusFilter = () => {
  const statusFilter = useStatusFilter();

  const isSingle = Object.values(statusFilter).filter(Boolean).length < 2;

  const options: SelectOption[] = Object.entries(STATUS_CATEGORY).map(([category, label]) => {
    const selected = statusFilter[category as StatusCategory];
    return ({
      label,
      value: category,
      icon: (
        <StatusCategoryIcon
          category={category as StatusCategory}
          withBackground
        />
      ),
      selected,
      disabled: selected && isSingle,
    });
  });

  return (
    <ToggleDropdown
      layer={Layer.Commandbar}
      placement="bottom-start"
      button={props => (
        <FilterButton {...props}>
          <StatusIcon />
          {getStatusFilterLabel()}
          <ArrowIcon size={16} />
        </FilterButton>
      )}
      content={() => (
        <SectionSelectPanel
          isMulti
          hideSearch
          selectType="toggle"
          options={options}
          onSelectOption={option => setStatusFilter({ [option.value]: true })}
          onUnselectOption={option => setStatusFilter({ [option.value]: false })}
        />
      )}
    />
  );
};

const getStatusFilterLabel = () => {
  const isAll = Object.values(getStatusFilter()).every(Boolean);
  if (isAll) return 'All statuses';

  const state = getStatusFilter();
  const selected = Object.keys(state).filter(key => state[key as StatusCategory]);
  if (selected.length < 2) return STATUS_CATEGORY[selected[0] as StatusCategory];

  return `${selected.length} statuses`;
};
