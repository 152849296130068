import {
  ChangelogUpdateDocument,
  ReleaseNoteTagAddDocument,
  ReleaseNoteTagRemoveDocument,
} from '@cycle-app/graphql-codegen';

import { useSafeMutation } from '..';
import { useChangelog } from './useChangelog';
import { getPermission, setLimitationsModal } from '../../reactives';

export const useReleaseNoteTagUpdate = () => {
  const [addMutation] = useSafeMutation(ReleaseNoteTagAddDocument);
  const [removeMutation] = useSafeMutation(ReleaseNoteTagRemoveDocument);
  const [updateChangelogMutation] = useSafeMutation(ChangelogUpdateDocument);
  const { changelog } = useChangelog();

  const addTag = async ({
    releaseNoteId, tagId,
  }: { releaseNoteId: string; tagId: string }) => {
    if (!getPermission().canUpdateRelease) {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
      return;
    }
    await addMutation({
      variables: {
        releaseNoteId,
        tagId,
      },
      optimisticResponse: {
        addReleaseNoteTag: {
          id: releaseNoteId,
        },
      },
      update: (cache) => {
        cache.modify({
          id: releaseNoteId,
          fields: {
            tags: (currentTags: any, { toReference }) => {
              return [
                ...currentTags,
                toReference(tagId),
              ];
            },
          },
        });
      },
    });
  };

  const removeTag = async ({
    releaseNoteId, tagId,
  }: { releaseNoteId: string; tagId: string }) => {
    if (!getPermission().canUpdateRelease) {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
      return;
    }
    await removeMutation({
      variables: {
        releaseNoteId,
        tagId,
      },
      optimisticResponse: {
        removeReleaseNoteTag: {
          id: releaseNoteId,
        },
      },
      update: (cache) => {
        cache.modify({
          id: releaseNoteId,
          fields: {
            tags: (currentTags: any, { readField }) => {
              return currentTags.filter((tag: any) => readField('id', tag) !== tagId);
            },
          },
        });
      },
    });
  };

  const updateTag = async ({
    tagId,
    color,
    value,
  }: {
    tagId: string;
    color: string;
    value: string;
  }) => {
    if (!getPermission().canUpdateRelease) {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
      return;
    }

    if (!changelog?.id) return;

    const updatedTags = changelog.releaseNoteTagValues.map((tag) => ({
      id: tag.id,
      value: tag.id === tagId ? value : tag.value,
      color: tag.id === tagId ? color : tag.color,
    }));

    await updateChangelogMutation({
      variables: {
        id: changelog.id,
        tags: updatedTags,
      },
      update: (cache) => {
        cache.modify({
          id: cache.identify({
            __typename: 'ReleaseNoteTagValue',
            id: tagId,
          }),
          fields: {
            color: () => color,
            value: () => value,
          },
        });
      },
    });
  };

  return {
    addTag,
    removeTag,
    updateTag,
  };
};
