import { useMemo, useState } from 'react';
import type { ChangeEvent } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from '../../../constants/inputs.constant';
import { SelectOption } from '../option.type';

type UseFilterParams = {
  isDebounceEnabled?: boolean;
  isDisabled?: boolean;
  options: SelectOption[];
  onSearchChange?: (newOptionValue: SelectOption['value'])=> void;
  defaultFilter?: string;
};

type UseFilterReturn = {
  filteredOptions: SelectOption[];
  filterText: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  resetFilter: VoidFunction;
};
type UseFilterOptions = (params: UseFilterParams) => UseFilterReturn;

export const useFilteredOptions: UseFilterOptions = ({
  isDebounceEnabled = false,
  isDisabled = false,
  onSearchChange,
  options,
  defaultFilter,
}) => {
  const [filterText, setFilterText] = useState(defaultFilter || '');

  const filteredOptions = useMemo(() => {
    if (isDisabled) return options;
    return options.filter(o => (filterText
      ? !o.ignoredToSearch && o.label.toLocaleLowerCase().includes(filterText.toLowerCase())
      : true));
  }, [isDisabled, options, filterText]);

  const onChange = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => {
    onSearchChange?.(e.target.value);
    setFilterText(e.target.value);
  }, isDebounceEnabled ? INPUT_ONCHANGE_DEBOUNCE : 0);

  return {
    filteredOptions,
    filterText,
    onChange,
    resetFilter: () => setFilterText(''),
  };
};
