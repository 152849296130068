import { useFragment } from '@apollo/client';
import { DocTitleFragmentDoc } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { CloseIcon, ExpandIcon } from '@cycle-app/ui/icons';
import { twJoin } from 'tailwind-merge';

import { ErrorPage, Size } from 'src/components/ErrorPage';
import { SideModal } from 'src/components/PortalModal/SideModal';
import { closeQuoteModal, useQuoteModal } from 'src/hooks/modals/useQuoteModal';
import { navigateToQuotePage } from 'src/hooks/useNavigate';

import { useQuoteId } from './QuoteContext';
import { QuoteOptions } from './QuoteOptions';
import { QuotePanelContent } from './QuotePanelContent';
import { QuotePanelLoader, QuotePanelSeleton } from './QuotePanelLoader';
import { QuotePrevNext } from './QuotePrevNext';
import { useIsScrolled } from './useIsScrolled';
import { useQuoteGroup } from './useQuoteGroup';

export const QuoteModal = () => {
  const quoteId = useQuoteModal();
  return (
    <SideModal
      id={quoteId}
      hide={closeQuoteModal}
    >
      {quoteId && (
        <QuotePanelLoader
          quoteId={quoteId}
          fallback={<QuotePanelSeleton />}
        >
          <QuoteModalContent docId={quoteId} />
        </QuotePanelLoader>
      )}
    </SideModal>
  );
};

const QuoteModalContent = ({ docId }: { docId: string }) => {
  const quoteId = useQuoteId();
  const [scrollableRef, isScrolled] = useIsScrolled();

  const group = useQuoteGroup();

  const { data } = useFragment({
    fragment: DocTitleFragmentDoc,
    from: useQuoteId(),
  });

  if (!data.id) return (
    <ErrorPage
      size={Size.SMALL}
      message="Oops, this quote no longer exists"
    />
  );

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div
        className={twJoin(
          'flex h-14 flex-none items-center justify-between gap-2 px-4',
          isScrolled && 'border-b border-grey-200 dark:border-grey-850',
        )}
      >
        <div className="flex items-center gap-1">
          <button
            className="btn-tertiary btn-square text-disabled hover:text-primary"
            onClick={closeQuoteModal}
          >
            <CloseIcon size={13} />
          </button>

          {group && (
            <QuotePrevNext
              isPrevDisabled={group.isPrevDisabled}
              isNextDisabled={group.isNextDisabled}
              openPrev={group.openPrev}
              openNext={group.openNext}
            />
          )}

          <Tooltip
            content="Expand"
            placement="top"
            withWrapper={false}
          >
            <button
              className="btn-tertiary btn-square text-disabled hover:text-primary"
              onClick={e => navigateToQuotePage(quoteId, e.metaKey)}
            >
              <ExpandIcon size={16} />
            </button>
          </Tooltip>
        </div>

        <div className="flex items-center gap-2">
          <QuoteOptions docId={docId} />
        </div>
      </div>

      <div
        ref={scrollableRef}
        className="shy-scrollbar flex grow flex-col px-6 py-4"
      >
        <QuotePanelContent
          openNext={group?.openNext}
        />
      </div>
    </div>
  );
};
