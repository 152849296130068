import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import DocAction from 'src/components/DocAction/DocAction';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;

  ${mobile} {
    flex-wrap: wrap;
  }
`;

export const AddCoverAction = styled(DocAction) <{ userColor?: boolean }>`
  display: flex;
  gap: 7px;

  ${p => p.userColor && css`
    color: hsl(var(--cycle));
  `};
`;

export const AddReleaseAction = styled(DocAction) <{ userColor?: boolean }>`
  display: flex;
  gap: 6px;
`;

interface AssigneeContainerProps {
  $showLabel?: boolean;
}

// > *: we can have whever a button or the popover div.
const AssigneeContainer = styled.div<AssigneeContainerProps>`
  ${mobile} {
    display: flex;
    flex-basis: auto;
    justify-content: flex-start;
    ${p => p.$showLabel && css`min-width: 100px;`}
    overflow: hidden;

    > * {
      overflow: hidden;
    }
  }
`;

// min-width: 30px so we can see at least the avatar.
export const CustomerContainer = styled(AssigneeContainer)`
  ${mobile} {
    flex-basis: 0px;
    flex-grow: 1;
    min-width: 30px;
  }
`;
