import { Tag, Dropdown } from '@cycle-app/ui';
import { TagIcon } from '@cycle-app/ui/icons';

import { TooltipClickToCopy } from 'src/components/TooltipClickToCopy';

import { useCustomAttributesItems } from './useCustomAttributesItems';

export const CustomAttributesDropdown = ({ companyId }: { companyId: string }) => {
  const items = useCustomAttributesItems(companyId);
  return (
    <Dropdown
      content={(
        <div className="shy-scrollbar flex max-h-96 flex-col gap-2 p-2">
          {items.map(item => (
            <TooltipClickToCopy
              key={item.id}
              title={item.title}
              value={item.value}
            >
              {props => (
                <Tag
                  color={item.color}
                  {...props}
                >
                  {item.value}
                </Tag>
              )}
            </TooltipClickToCopy>
          ))}
        </div>
      )}
    >
      <Tag
        color="grey"
        icon={<TagIcon size={12} />}
        className="flex-none! text-secondary!"
      >
        {items.length}
      </Tag>
    </Dropdown>
  );
};
