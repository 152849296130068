import { useFragment } from '@apollo/client';
import { CustomerFragmentDoc } from '@cycle-app/graphql-codegen';

export const useCustomerFragment = (customerId: string | null | undefined) => {
  const {
    data, complete,
  } = useFragment({
    fragment: CustomerFragmentDoc,
    fragmentName: 'Customer',
    from: customerId ?? '',
  });
  return complete ? data : null;
};
