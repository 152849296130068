import { DoctypeType } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { CloseIcon, ExpandIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

import DocOptions from 'src/components/DocOptions/DocOptions';
import { ExtractQuotes } from 'src/components/ExtractQuotes/ExtractQuotes';
import { FeatureDocParent } from 'src/components/FeatureDocParent';
import { useDoc } from 'src/hooks';
import { useDocId } from 'src/hooks/usePathParams';
import { useVerifyQuotesOpen, useFeatureReleaseNoteOpen } from 'src/reactives/docRightPanel.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { LeftActions, StyledActionButton, Separator, RightActions } from './DocPanelHeader.styles';
import { ToggleRightPanel } from './ToggleRightPanel';
import DocPanelRealtime from '../DocPanelRealtime/DocPanelRealtime';

interface Props {
  className?: string;
  onClose?: VoidFunction;
  onExpand?: VoidFunction;
  onDeleteDoc: VoidFunction;
  onOpenCoverInputFile: VoidFunction;
  prevNextButtons?: ReactNode;
  processButton?: ReactNode;
}

const DocPanelHeader: FC<Props> = ({
  className,
  onClose,
  onExpand,
  onDeleteDoc,
  onOpenCoverInputFile,
  prevNextButtons,
  processButton,
}) => {
  const docId = useDocId();
  const { doc: docBase } = useDoc(docId);
  const doctype = useGetDocType(docBase?.doctype.id);
  const canHaveParent = (doctype?.parents?.edges ?? []).length > 0;
  const isMobile = useIsMobile();
  const isVerifyQuotesOpen = useVerifyQuotesOpen();
  const isFeatureReleaseNoteOpen = useFeatureReleaseNoteOpen();

  if (!docId || isMobile) return null;
  const isFeedback = doctype?.type === DoctypeType.Feedback;

  return (
    <div className={twJoin('relative z-10 flex items-start gap-2 overflow-hidden p-3 @container', className)}>
      <div className="-mt-0.5 flex h-8 grow items-center gap-2 overflow-hidden">
        {(onClose || onExpand) && (
          <LeftActions>
            {onClose && (
              <StyledActionButton
                size="L"
                onClick={onClose}
                tooltip="Close"
                tooltipPlacement="bottom"
              >
                <CloseIcon />
              </StyledActionButton>
            )}
            {onExpand && (
              <StyledActionButton
                size="L"
                onClick={onExpand}
                tooltip="Open full page"
                tooltipPlacement="bottom"
              >
                <ExpandIcon />
              </StyledActionButton>
            )}
            <Separator />
          </LeftActions>
        )}

        {prevNextButtons}

        {docBase && (
          <div
            className={twJoin(
              'items-center overflow-hidden text-body text-secondary',
              isFeedback ? 'hidden @2xl:flex' : 'flex',
            )}
          >
            {canHaveParent && (
              <>
                <FeatureDocParent
                  docId={docId}
                  emojiSize={14}
                  className="m-1 h-6 gap-2 px-1 text-body"
                />
                <span className="mr-2 flex-none text-disabled">
                  /
                </span>
              </>
            )}

            <Tooltip
              content={docBase.title}
              placement="bottom"
              withPortal
              withWrapper={false}
              displayFullTitle
              disabled={!docBase.title}
            >
              <span className="shrink-[1000] truncate">
                {docBase.title ?? 'Untitled'}
              </span>
            </Tooltip>
          </div>
        )}
      </div>

      <div className="flex flex-none items-center justify-end gap-2">
        <DocPanelRealtime className="hidden @xl:flex" />

        {isFeedback && <ExtractQuotes docId={docId} />}

        <div className="flex items-center gap-2">
          {processButton}
          <RightActions className="h-7">
            {docBase && (
              <DocOptions
                doc={docBase}
                context="doc-panel"
                onAddCoverClicked={onOpenCoverInputFile}
                onDeleteDoc={onDeleteDoc}
                buttonSize="L"
              />
            )}
            {!isVerifyQuotesOpen && !isFeatureReleaseNoteOpen && <ToggleRightPanel />}
          </RightActions>
        </div>
      </div>
    </div>
  );
};

export default DocPanelHeader;
