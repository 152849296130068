import { Input } from '@cycle-app/ui';
import styled from 'styled-components';

export const InputStyled = styled(Input)`
  input {
    height: 30px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 6px;
  }
`;
