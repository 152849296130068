import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { ReactNode } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { FieldLabel, FieldButton, Caret } from './Form.styles';

export const SelectField = ({
  layer, onClearValue, label, options, value, onChange, isLoading, hasError = false,
}: {
  label: string;
  layer?: Layer;
  onClearValue?: VoidFunction;
  options: SelectOption[];
  value?: ReactNode;
  onChange: (value: string) => void;
  isLoading: boolean;
  hasError?: boolean;
}) => {
  return (
    <div>
      <FieldLabel>
        {label}
      </FieldLabel>
      <ToggleDropdown
        placement="bottom-start"
        layer={layer}
        withPortal
        withWrapper={false}
        button={props => (
          <FieldButton
            {...props}
            isLoading={isLoading}
            iconEnd={<Caret direction={props['data-active'] ? 'top' : 'bottom'} />}
            $hasError={hasError}
          >
            {value || 'Choose from list'}
          </FieldButton>
        )}
        content={props => (
          <SelectPanel
            onClearValue={onClearValue ? () => {
              props.hide();
              onClearValue();
            } : undefined}
            options={options}
            onOptionChange={option => {
              props.hide();
              onChange(option.value);
            }}
          />
        )}
      />
    </div>
  );
};
