import { ViewType } from '@cycle-app/graphql-codegen';
import { ViewCardSkeleton } from '@cycle-app/ui';

export const DocChildrenSkeletons = ({ count }: { count: number }) => (
  <>
    {[...Array(count).keys()].map(i => (
      <ViewCardSkeleton
        key={i}
        viewType={ViewType.List}
      />
    ))}
  </>
);
