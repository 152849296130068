import {
  AutopilotState,
  FindInsightState,
  DocStateDocument,
  ExtractQuotesDocument,
  FetchDocLinkedDocsDocument,
  DocQuotesDocument,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { delay } from '@cycle-app/utilities/src/utils/async.utils';
import { atom, useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useSafeMutation } from 'src/hooks';
import { useAutopilotStateSubscription } from 'src/hooks/api/useAutopilotStateSubscription';
import { useDocQuotesSubscription } from 'src/hooks/api/useDocQuotesSubscription';
import { useFindInsightStateSubscription } from 'src/hooks/api/useFindInsightStateSubscription';
import { useRequestExtractQuotes } from 'src/hooks/useRequestExtractQuotes';
import { closeVerifyQuotes, openVerifyQuotes } from 'src/reactives/docRightPanel.reactive';
import { setExtractNoQuote, useExtractNoQuote } from 'src/reactives/extractQuotes.reactive';
import client from 'src/services/apollo/client';
import { extract } from 'src/types/graphql.types';

type ExtractStatus =
  // Quotes are being extracted
  | 'loading'
  // New quotes are found and need to be verified
  | 'verify'
  // All quotes are verified
  | 'verified'
  // No quotes are found, the user can retry the extraction
  | 'retry'
  // No quotes
  | 'extract'
  // Not defined
  | null;

export const quotesVerifiedAtom = atom(false);
quotesVerifiedAtom.onMount = set => set(false);

export const useExtractQuotes = (docId: string) => {
  const [autopilotState, setAutopilotState] = useState<AutopilotState | null | undefined>(null);
  const [findInsightState, setFindInsightState] = useState<FindInsightState | null | undefined>(null);

  useAutopilotStateSubscription(docId, {
    ignoreResults: true,
    onData: options => {
      setAutopilotState(options.data.data?.autopilotState);
    },
  });

  useFindInsightStateSubscription(docId, {
    ignoreResults: true,
    onData: options => {
      setFindInsightState(options.data.data?.findInsightState);
    },
  });

  const docState = autopilotState ?? findInsightState;

  const doc = useDocPanelContext(ctx => ctx.doc);
  const quotes = nodeToArray(doc?.quotes);
  const insights = nodeToArray(doc?.docTargets);
  const aiCreatedInsights = insights.filter(insight => insight.doc?.aiState !== null);

  const [isFetchingQuotes, setIsFetchingQuotes] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  // Used to show the retry button when no quote is found
  const noQuote = useExtractNoQuote(docId);

  const [quotesVerified, setQuotesVerified] = useAtom(quotesVerifiedAtom);

  useEffect(() => {
    if (aiCreatedInsights.length === 0) {
      setQuotesVerified(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiCreatedInsights.length]);

  // Reset states when docId changes (e.g. when switching between docs with prev/next buttons)
  useEffect(() => {
    setAutopilotState(null);
    setFindInsightState(null);
    setIsFetchingQuotes(false);
    setQuotesVerified(false);

    // Refetch doc state
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.query({
      fetchPolicy: 'network-only',
      query: DocStateDocument,
      variables: { id: docId },
    }).then(result => {
      const state = extract('Doc', result.data.node);
      if (state?.findInsightState) {
        setFindInsightState(state.findInsightState);
      }
      if (state?.autopilotState) {
        setAutopilotState(state?.autopilotState);
      }
    });

    // Refetch quotes if the FullDoc fragment is in the cache
    if (doc?.id) {
      setIsRefetching(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Promise.all([
        // Refetch verified quotes (= insights, docTargets)
        client.query({
          fetchPolicy: 'network-only',
          query: FetchDocLinkedDocsDocument,
          variables: { id: docId },
        }),
        // Refetch unverified quotes
        client.query({
          fetchPolicy: 'network-only',
          query: DocQuotesDocument,
          variables: { id: docId },
        }),
      ]).finally(() => {
        setIsRefetching(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId]);

  useDocQuotesSubscription(docId, {
    ignoreResults: true,
    onData: async ({ data: { data } }) => {
      if (!isFetchingQuotes) return;

      // Wait for the quotes to be updated in the cache, to avoid flickering
      await delay(500);

      setIsFetchingQuotes(false);
      if (data && data.docQuotes.length === 0) {
        setExtractNoQuote(docId, true);
      } else {
        openVerifyQuotes();
      }
    },
  });

  const [extractQuotes, extractResult] = useSafeMutation(ExtractQuotesDocument, {
    onCompleted: () => {
      client.cache.modify({
        id: client.cache.identify({ id: docId }),
        fields: {
          findInsightState: () => FindInsightState.Unprocessed,
        },
      });
    },
  });

  const extractStatus: ExtractStatus = (() => {
    if (!!docState || extractResult.loading || isFetchingQuotes) return 'loading';
    if (quotesVerified) return 'verified';
    if (quotes.length > 0) return 'verify';
    if (aiCreatedInsights.length > 0) return 'verified';
    if (noQuote) return 'retry';
    if (!doc || isRefetching) return null;
    return 'extract';
  })();

  const requestExtractQuotes = useRequestExtractQuotes();

  // Close the verify quotes section when all quotes have been verified (e.g. from the summary of quotes)
  useEffect(() => {
    if (quotes.length === 0) {
      closeVerifyQuotes();
    }
  }, [quotes]);

  return {
    extractStatus,
    quotes,
    extractQuotes: () => {
      if (!docId) return;
      if (!requestExtractQuotes()) return;
      setIsFetchingQuotes(true);
      setExtractNoQuote(docId, false);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      extractQuotes({ variables: { docId } });
    },
  };
};
