import { Tooltip } from '@cycle-app/ui';
import { TrashIcon, PauseIcon, PlayIcon, RecordIcon } from '@cycle-app/ui/icons';
import { formatSecondsToTime } from '@cycle-app/utilities';
import {
  useState, useRef, MouseEventHandler, useEffect, useCallback, ComponentPropsWithRef, ReactNode,
} from 'react';
import { useThrottledCallback } from 'use-debounce';

import { useAudioPlayer } from 'src/components/AudioPlayer/AudioPlayer.hooks';

import { useRecorder, useCustomTimer, useMicrophonePermission } from './AudioRecorder.hooks';
import {
  Container,
  RecordingPlayerContainer,
  PlayerContainer,
  Left,
  Middle,
  Right,
  Title,
  Time,
  TrashButton,
  RecordButton,
  Stop,
} from './AudioRecorder.styles';

export { RecordButton as AudioRecorderButton };

type Status = 'stop' | 'play' | 'pause';

type PlayerState = {
  currentTime: number;
  duration: number;
  status: Status;
};

const DEFAULT_PLAYER_STATE: PlayerState = {
  currentTime: 0,
  duration: 0,
  status: 'stop',
};

type AudioRecorderProps = ComponentPropsWithRef<'div'> & {
  onAudioReady: (blobUrl: string) => void;
  onAudioRemoved?: VoidFunction;
  showDefaultButton?: boolean;
  buttons?: (props: {
    status: Status;
    removeAudio: VoidFunction;
  }) => ReactNode;
  height?: number;
  isDisabled?: boolean;
};

export const AudioRecorder = ({
  onAudioReady, onAudioRemoved, showDefaultButton = true, buttons, height = 52, isDisabled = false, ...props
}: AudioRecorderProps) => {
  const { hasMicrophonePermission } = useMicrophonePermission();
  const [recordUrl, setRecordUrl] = useState('');
  const [playerState, setPlayerState] = useState<PlayerState>(DEFAULT_PLAYER_STATE);
  const playerRecordingRef = useRef<HTMLDivElement>(null);
  const playerRecordedRef = useRef<HTMLDivElement>(null);
  const {
    startStopTimer, timer, resetTimer,
  } = useCustomTimer();
  const {
    status, startRecording, stopRecording, resetRecording,
  } = useRecorder({
    container: playerRecordingRef,
    listeners: {
      onRecordingReady: (url => {
        setRecordUrl(url);
        onAudioReady(url);
      }),
      onRecordingStart: startStopTimer,
    },
    height,
  });
  const player = useAudioPlayer({
    container: playerRecordedRef,
    url: recordUrl,
    listeners: {
      onReady: (duration) => setPlayerState(oldState => ({
        ...oldState,
        duration,
      })),
      onPlay: () => setPlayerState(oldState => ({
        ...oldState,
        status: 'play',
      })),
      onPause: () => setPlayerState(oldState => ({
        ...oldState,
        status: 'pause',
      })),
      onTimeUpdate: (time) => {
        const newTime = Number(time.toFixed(2));
        if (playerState.currentTime !== newTime) {
          updateTime(newTime);
        }
      },
    },
    options: {
      height,
    },
  });

  /**
   * Make sure the mic is stopping even if we are removing the component via
   * other method.
   * Intended empty array as dependency
   */
  useEffect(() => () => {
    stopRecording();
    if (recordUrl) {
      URL.revokeObjectURL(recordUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordUrl]);

  useEffect(() => () => {
    resetRecording();
    /**
     * We need to only perform a reset on unmount
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    if (status === null || (status === 'pause' && !recordUrl)) {
      playerRecordingRef.current?.childNodes.forEach(node => node.remove());
      startRecording();
      return;
    }
    if (status === 'recording') {
      stopRecording();
      startStopTimer();
      setPlayerState(oldState => ({
        ...oldState,
        duration: 0,
        currentTime: timer,
      }));
      playerRecordingRef.current?.childNodes.forEach(node => node.remove());
      return;
    }
    if (status === 'pause' && player && recordUrl) {
      if (player?.isPlaying()) {
        player.pause();
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        player.play();
      }
    }
  }, [player, recordUrl, startRecording, startStopTimer, status, stopRecording, timer]);

  const updateTime = useThrottledCallback((newTime: number) => {
    setPlayerState(oldState => {
      const newTimeCalculation = -(oldState.duration - newTime);
      return {
        ...oldState,
        currentTime: newTimeCalculation === 0 ? timer : newTimeCalculation,
      };
    });
  }, 300);

  const removeAudio = () => {
    URL.revokeObjectURL(recordUrl);
    setRecordUrl('');
    resetRecording();
    resetTimer();
    setPlayerState(oldState => ({
      ...oldState,
      duration: 0,
    }));
    onAudioRemoved?.();
  };

  return (
    <Container {...props}>
      <Left>
        <Title>
          {/* eslint-disable-next-line no-nested-ternary */}
          {status === 'recording'
            ? 'Recording…'
            : recordUrl
              ? 'Record'
              : 'New recording'}
        </Title>
        <Time>
          {formatSecondsToTime(recordUrl ? playerState.currentTime : timer)}
        </Time>
      </Left>
      <Middle>
        <RecordingPlayerContainer
          ref={playerRecordingRef}
          $isVisible={!recordUrl}
        />
        {!!recordUrl && (
          <PlayerContainer
            ref={playerRecordedRef}
            $isVisible={!!recordUrl}
            $hasCursor
          />
        )}
      </Middle>
      <Right>
        <TrashButton
          variant="warning"
          $isVisible={!!recordUrl}
          onClick={removeAudio}
          disabled={isDisabled}
        >
          <TrashIcon />
        </TrashButton>
        <Tooltip
          disabled={hasMicrophonePermission}
          content="Cycle does not have access to your microphone"
        >
          {showDefaultButton && (
            <RecordButton
              onClick={onClick}
              disabled={!hasMicrophonePermission || isDisabled}
            >
              {!recordUrl && status !== 'recording' && <RecordIcon />}
              {!recordUrl && status === 'recording' && <Stop />}
              {recordUrl && playerState.status === 'play' && <PauseIcon />}
              {recordUrl && playerState.status !== 'play' && <PlayIcon />}
            </RecordButton>
          )}
        </Tooltip>
        {buttons?.({
          status: playerState.status,
          removeAudio,
        })}
      </Right>
    </Container>
  );
};
