import { AnimatePresence, MotionProps, Variants } from 'framer-motion';
import { ComponentProps } from 'react';
import { twJoin } from 'tailwind-merge';

import PortalModal from 'src/components/PortalModal/PortalModal';
import { useIsLastOpenedSideModal } from 'src/hooks/modals/useSideModal';
import { Layer } from 'src/types/layers.types';

type Props = ComponentProps<typeof PortalModal> & {
  id: string | null;
  hide: VoidFunction;
};

export const SideModal = ({
  id, className, ...props
}: Props) => {
  const isOpen = !!id;
  const isOnTop = useIsLastOpenedSideModal(id);
  return (
    <AnimatePresence>
      {isOpen && (
        <PortalModal
          placement="right"
          layer={isOnTop ? Layer.ModalZ2 : Layer.Modal}
          motionVariants={motionVariants(isOnTop)}
          motionProps={motionProps}
          className={twJoin(
            'h-full max-h-full! w-[745px] max-w-full! p-0!',
            className,
          )}
          {...props}
        />
      )}
    </AnimatePresence>
  );
};

const motionProps: MotionProps = {
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
};

const motionVariants = (isOnTop: boolean): Variants => ({
  enter: {
    x: isOnTop ? 0 : -78,
    scale: isOnTop ? 1 : 0.9,
    opacity: 1,
    display: 'block',
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
  exit: {
    x: 30,
    opacity: 0,
    transition: {
      duration: 0.1,
      ease: 'easeInOut',
    },
  },
});
