import { Spinner, Icon } from '@cycle-app/ui';
import { isUrl } from '@cycle-app/utilities';
import { useState, useCallback, useMemo, forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';
import { useDebounce } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';

import { SearchContainer, Input } from './DocSearch.styles';
import { useProductAreaAttribute } from '../../hooks/api/useAttributes';
import { useProductAreasCategories } from '../../hooks/product/useProductAreasCategories';
import { Layer } from '../../types/layers.types';
import { DocSearchResult, DocSearchResultProps } from '../DocSearchDropdown/DocSearchResult';
import { ToggleDropdown } from '../DropdownLayer';
import { ProductAreasManager } from '../ProductAreasManager';

type TargetContent = 'link' | 'docId';

export interface DocSearchProps extends DocSearchResultProps {
  className?: string;
  onSubmit?: (p: SubmitParams) => void;
  placeholder?: string;
  targetContent?: TargetContent;
  initialSearch?: string;
  showLinearAutoCreate?: boolean;
  onOptionsChange?: VoidFunction;
  suggestedName?: string | null;
}

export interface SubmitParams {
  searchText: string;
  docId?: string;
}

export const DocSearch = forwardRef<HTMLDivElement, DocSearchProps>(({
  className = '',
  inheritedAttributes,
  onAdd: onAddProps,
  onSubmit,
  placeholder = 'Search...',
  targetContent = 'docId',
  initialSearch = '',
  showLinearAutoCreate,
  onOptionsChange,
  suggestedName,
  productAreaIds,
  ...docSearchProps
}, ref) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(initialSearch);
  const searchEnabled = useMemo(() => !isUrl(search, { strict: false }), [search]);
  const [productAreaFilterId, setProductAreaFilterId] = useState(productAreaIds?.[0] || null);
  const {
    areas, isEnabled: productAreaEnabled,
  } = useProductAreasCategories();
  const { attributeNamePlural } = useProductAreaAttribute();
  const [searchDebounced] = useDebounce(search, INPUT_ONCHANGE_DEBOUNCE);

  const onAdd = useCallback<NonNullable<DocSearchResultProps['onAdd']>>((docId, options) => {
    onAddProps?.(docId, options);
    onSubmit?.({
      docId,
      searchText: search,
    });
  }, [onAddProps, onSubmit, search]);

  const showProductAreaFilter = typeof productAreaIds !== 'undefined';
  const productAreaFilter = productAreaFilterId ? [productAreaFilterId] : [];
  return (
    <SearchContainer
      ref={ref}
      className={className}
      onClick={e => e.stopPropagation()}
      $isSearching={!!search}
    >
      <Input
        type="text"
        autoFocus
        iconBefore={<Icon name="search" />}
        onChange={(e) => setSearch(e.target.value)}
        onKeyUp={(e) => {
          if (!searchEnabled && e.code === 'Enter') {
            onSubmit?.({
              searchText: search,
            });
          }
        }}
        placeholder={placeholder}
        value={search}
        iconAfter={search && loading && <Spinner />}
      />
      {showProductAreaFilter && productAreaEnabled && (
        <ToggleDropdown
          layer={Layer.DialogModal}
          button={buttonProps => (
            <button
              onClick={buttonProps.toggle}
              type="button"
              className="btn btn-tertiary btn-sm bg-grey-100 dark:bg-grey-800"
            >
              <Icon name="tri-shapes" />
              {productAreaFilterId ? areas.find(area => area.id === productAreaFilterId)?.value : `Select ${attributeNamePlural}`}
              <Icon
                className={twJoin('size-4 rotate-90 text-disabled', buttonProps['data-active'] && '-rotate-90')}
                name="chevron-left"
              />
            </button>
          )}
          placement="bottom-start"
          content={contentProps => (
            <ProductAreasManager
              isMulti={false}
              compatibleIds={undefined}
              docTypeName={undefined}
              hideIncompatibleValues={false}
              isRequired={false}
              onClearValue={() => {
                setProductAreaFilterId(null);
                contentProps.hide();
              }}
              onSelect={value => {
                setProductAreaFilterId(value.productArea.id);
                contentProps.hide();
              }}
              selectedValues={productAreaFilter}
              showSettings={false}
            />
          )}
        />
      )}
      {(targetContent === 'docId' || searchEnabled) && (
        <DocSearchResult
          {...docSearchProps}
          inheritedAttributes={inheritedAttributes}
          onAdd={onAdd}
          search={searchDebounced}
          showLinearAutoCreate={showLinearAutoCreate}
          onOptionsChange={onOptionsChange}
          suggestedName={suggestedName}
          setLoading={setLoading}
          productAreaIds={showProductAreaFilter ? productAreaFilter : undefined}
        />
      )}
    </SearchContainer>
  );
});
