import { ThemeType } from '@cycle-app/graphql-codegen';

import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

interface ThemeState {
  colorTheme: ThemeType;
  syncWithSystem: boolean;
}

export const {
  hookValue: useGetThemeConfig,
  setValue: setThemeConfig,
  getValue: getThemeConfig,
} = make<ThemeState>({
  defaultState: {
    colorTheme: ThemeType.Sunrise,
    syncWithSystem: false,
  },
  localKey: LocalKey.Theme,
  crossTabSync: true,
  getInitialState: (defaultState, storageState) => {
    if (storageState?.syncWithSystem) {
      return {
        colorTheme: window.matchMedia('(prefers-color-scheme:dark)').matches
          ? ThemeType.Nightfall
          : ThemeType.Sunrise,
        syncWithSystem: true,
      };
    }
    return {
      ...defaultState,
      ...storageState,
    };
  },
});

export const toggleTheme = () => setThemeConfig({
  colorTheme: getThemeConfig().colorTheme === ThemeType.Nightfall ? ThemeType.Sunrise : ThemeType.Nightfall,
  syncWithSystem: false,
});

// Migration Eclipse -> Sunrise
if (getThemeConfig().colorTheme === ThemeType.Eclipse) {
  setThemeConfig({ colorTheme: ThemeType.Sunrise });
}
