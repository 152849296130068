import { ReactNode, ComponentProps } from 'react';

import { PenIcon, EyeIcon } from '../../icons';
import { DotsMenu } from '../DotsMenu/DotsMenu';
import { SelectOption } from '../Selects';
import { Container, Title, Count, MainButton, DotsMenuContainer } from './GroupHeader.styles';

export type GroupHeaderProps = ComponentProps<typeof Container> & {
  icon: ReactNode;
  title: ReactNode;
  count?: ReactNode;
  form?: ReactNode;
  onRename?: VoidFunction;
  onHide?: VoidFunction;
};

export const GroupHeader = ({
  icon, title, count, form, onRename, onHide, onClick, ...props
}: GroupHeaderProps) => {
  const options: SelectOption[] = [];

  if (onRename) {
    options.push({
      value: 'rename',
      label: 'Rename',
      icon: (
        <PenIcon
          style={{
            width: 20,
            height: 20,
          }}
        />
      ),
      onSelect: onRename,
    });
  }

  if (onHide) {
    options.push({
      value: 'hide',
      label: 'Hide',
      icon: (
        <EyeIcon
          style={{
            width: 20,
            height: 16,
          }}
        />
      ),
      onSelect: onHide,
    });
  }

  return (
    <Container {...props}>
      {form || (
        <>
          <MainButton
            {...props}
            role="button"
            tabIndex={0}
            onClick={onClick}
          >
            {icon}

            <Title>
              {title}
            </Title>

            {count != null && (
              <Count>
                {count}
              </Count>
            )}
          </MainButton>

          {options.length > 0 && (
            <DotsMenuContainer>
              <DotsMenu
                options={options}
                onClick={e => e.stopPropagation()}
              />
            </DotsMenuContainer>
          )}
        </>
      )}
    </Container>
  );
};
