import { SelectOption } from '@cycle-app/ui';
import { PlayIcon, PauseIcon, VolumeFullIcon, VolumeMutedIcon, VolumeLowIcon } from '@cycle-app/ui/icons';
import { formatSecondsToTime } from '@cycle-app/utilities';
import memoize from 'fast-memoize';
import { useRef, useState, useCallback, MouseEventHandler } from 'react';

import { DropdownLayer } from 'src/components/DropdownLayer';
import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { getCloudinaryWaveformUrl } from 'src/utils/cloudinary.utils';
import { getWaveSurferPeaks } from 'src/utils/wavesurfer.utils';

import { useAudioPlayer } from './AudioPlayer.hooks';
import {
  Container, PlayerContainer, PlayButton, Time,
  VolumeContainer, VolumeButton, VolumeClick, VolumeHiddenContainer,
  VolumeProgressContainer, VolumeProgress, PlayerSkeleton,
} from './AudioPlayer.styles';

const rateOptions: SelectOption[] = [
  {
    label: 'x0.5',
    value: '0.5',
  },
  {
    label: 'x1',
    value: '1',
  },
  {
    label: 'x1.25',
    value: '1.25',
  },
  {
    label: 'x1.50',
    value: '1.5',
  },
  {
    label: 'x2',
    value: '2',
  },
];

type PlayerState = {
  currentTime: number;
  duration: number | null;
  isVolumeMuted: boolean;
  isVolumeOpen: boolean;
  rate: string;
  status:'stop' | 'play' | 'pause';
  volume: number;
  volumeWidth: number;
};

const DEFAULT_PLAYER_STATE: PlayerState = {
  currentTime: 0,
  duration: null,
  isVolumeMuted: false,
  isVolumeOpen: false,
  rate: '1',
  status: 'stop',
  volume: 1,
  volumeWidth: 100,
};

type AudioPlayerProps = {
  src: string;
};

export const AudioPlayer = ({ src }: AudioPlayerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const progressContainerRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const [playerState, setPlayerState] = useState<PlayerState>(DEFAULT_PLAYER_STATE);
  const player = useAudioPlayer({
    container: containerRef,
    // webm format is not fully supported by wavesurfer (duration is not set)
    url: src.replace(/webm$/, 'm4a'),
    listeners: {
      onReady: (duration) => {
        if (!isFinite(duration)) return;
        setPlayerState(oldState => ({
          ...oldState,
          duration,
        }));
      },
      onPlay: () => setPlayerState(oldState => ({
        ...oldState,
        status: 'play',
      })),
      onPause: () => setPlayerState(oldState => ({
        ...oldState,
        status: 'pause',
      })),
      onTimeUpdate: (time) => {
        const newTime = Number(time.toFixed(2));
        if (playerState.currentTime !== newTime) {
          setPlayerState(oldState => ({
            ...oldState,
            currentTime: newTime,
          }));
        }
      },
    },
    options: {
      // set MediaElement to enable streaming
      backend: 'MediaElement',
      // set peaks and duration to avoid downloading the entire file
      getPeaks: () => getPeaksFromCloudinary(src),
      // duration will be automatically set once ready (do not works with webm format)
      duration: 0,
    },
  });

  const onPlayClick = useCallback<MouseEventHandler<HTMLButtonElement>>(async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!player) return;
    if (player.isPlaying()) {
      player.pause();
    } else {
      await player.play();
    }
  }, [player]);

  const isLoading = playerState.duration === null;

  return (
    <Container>
      <PlayButton
        onClick={onPlayClick}
      >
        {playerState.status === 'play' ? <PauseIcon /> : <PlayIcon />}
      </PlayButton>
      {isLoading && <PlayerSkeleton />}
      {playerState.duration !== null && (
        <Time>
          {formatSecondsToTime(playerState.duration)}
        </Time>
      )}
      <PlayerContainer
        ref={containerRef}
        $isLoading={isLoading}
      />
      {playerState.duration !== null && (
        <Time>
          {`-${formatSecondsToTime(Math.abs(playerState.duration - playerState.currentTime))}`}
        </Time>
      )}
      <VolumeContainer>
        <DropdownLayer
          visible={playerState.isVolumeOpen}
          animation={false}
          closingArea={false}
          content={(
            <VolumeHiddenContainer
              onMouseLeave={() => setTimeout(() => setPlayerState(oldState => ({
                ...oldState,
                isVolumeOpen: false,
              })), 1000)}
            >
              <VolumeProgressContainer
                ref={progressContainerRef}
                onClick={(e) => {
                  if (!progressContainerRef.current) return;
                  const containerWidth = progressContainerRef.current.offsetWidth;
                  const clickX = e.clientX - progressContainerRef.current.getBoundingClientRect().left;
                  const newWidth = clickX / containerWidth;
                  const newVolume = parseFloat(newWidth.toFixed(2));
                  player?.setVolume(newVolume);
                  setPlayerState(oldState => ({
                    ...oldState,
                    volume: newVolume,
                    volumeWidth: newWidth,
                  }));
                }}
              >
                <VolumeProgress
                  ref={progressRef}
                  style={{ width: `${playerState.volume * 100}%` }}
                />
              </VolumeProgressContainer>
              <VolumeClick
                onClick={() => {
                  if (playerState.volume > 0) {
                    player?.setVolume(0);
                    setPlayerState(oldState => ({
                      ...oldState,
                      volume: 0,
                      isVolumeMuted: true,
                    }));
                  } else {
                    setPlayerState(oldState => {
                      const newVolume = parseFloat(oldState.volumeWidth.toFixed(2));
                      player?.setVolume(1);
                      return {
                        ...oldState,
                        volume: newVolume,
                        isVolumeMuted: false,
                      };
                    });
                  }
                }}
              >
                <VolumeIcon
                  volume={playerState.volume}
                  isMuted={playerState.isVolumeMuted}
                />
              </VolumeClick>
            </VolumeHiddenContainer>
          )}
          hide={() => null}
          placement="left-start"
          offset={[-8, -36]}
        >
          <VolumeButton
            size="L"
            onMouseEnter={() => setPlayerState(oldState => ({
              ...oldState,
              isVolumeOpen: true,
            }))}
          >
            <VolumeIcon
              volume={playerState.volume}
              isMuted={playerState.isVolumeMuted}
            />
          </VolumeButton>
        </DropdownLayer>
      </VolumeContainer>
      <DropdownSelectLayer
        options={rateOptions}
        hideSearch
        onChange={(newOption) => {
          setPlayerState(oldState => ({
            ...oldState,
            rate: newOption.value,
          }));
          player?.setPlaybackRate(Number(newOption.value), true);
        }}
        selectedValue={playerState.rate}
      >
        <VolumeButton size="L">
          {rateOptions.find(r => r.value === playerState.rate)?.label}
        </VolumeButton>
      </DropdownSelectLayer>
    </Container>
  );
};

type VolumeIconProps = {
  isMuted: PlayerState['isVolumeMuted'];
  volume: PlayerState['volume'];
};

const VolumeIcon = ({
  volume, isMuted,
}:VolumeIconProps) => {
  if (volume < 0.1 || isMuted) return <VolumeMutedIcon />;
  if (volume < 0.6) return <VolumeLowIcon />;
  return <VolumeFullIcon />;
};

const getPeaksFromCloudinary = memoize(async (url: string) => getWaveSurferPeaks(getCloudinaryWaveformUrl(url)));
