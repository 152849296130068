import { DownIcon, UpIcon } from '@cycle-app/ui/icons';
import { getDocSlug } from '@cycle-app/utilities';

import { useReleaseNotesContext } from 'src/contexts/releaseNotesContext';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { useParams } from 'src/hooks/router/useParams';
import { navigateToReleaseNote, navigateToReleaseDoc } from 'src/hooks/useNavigate';
import { PageId, usePageId } from 'src/hooks/usePageId';

import { PrevNextContainer, Action } from './ReleaseNote.styles';

export const ReleaseNotePrevNext = () => {
  const { noteId } = useParams();
  const isReleaseDocOpen = usePageId(pageId => pageId === PageId.ReleaseDoc);
  const { releaseNote } = useReleaseNote(noteId);
  const group = releaseNote?.isOther ? 'other' : 'main';
  const releaseId = useReleaseNotesContext(ctx => ctx.releaseId);
  const list = useReleaseNotesContext(ctx => ctx[group].list);
  const hasNextPage = useReleaseNotesContext(ctx => ctx[group].hasNextPage);
  const loadMore = useReleaseNotesContext(ctx => ctx[group].loadMore);
  const index = list.findIndex(note => note.id === noteId);

  const goPrev = () => {
    const prev = list[index - 1];
    if (!prev) return;
    if (isReleaseDocOpen && prev.doc) {
      navigateToReleaseDoc(releaseId, prev.id, getDocSlug(prev.doc));
    } else {
      navigateToReleaseNote(releaseId, prev.id);
    }
  };

  const goNext = async () => {
    if (index >= list.length - 1 && hasNextPage) await loadMore();
    const next = list[index + 1];
    if (!next) return;
    if (isReleaseDocOpen && next.doc) {
      navigateToReleaseDoc(releaseId, next.id, getDocSlug(next.doc));
    } else {
      navigateToReleaseNote(releaseId, next.id);
    }
  };

  return (
    <PrevNextContainer>
      <Action
        onClick={goPrev}
        disabled={index === 0}
      >
        <UpIcon size={16} />
      </Action>

      <Action
        onClick={goNext}
        disabled={index === list.length - 1 && !hasNextPage}
      >
        <DownIcon size={16} />
      </Action>
    </PrevNextContainer>
  );
};
