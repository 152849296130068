import { Icon } from '@cycle-app/ui';
import { ComponentPropsWithRef } from 'react';
import { Placement } from 'tippy.js';

import { useBoardStar } from 'src/hooks/api/mutations/boards/useBoardStar';
import { useIsBoardStarred } from 'src/hooks/api/queries/boards/useBoardsStarred';

import { Action, StarAction } from './BoardHeader.styles';
import { HiddenAction } from './HiddenAction';

type Props = ComponentPropsWithRef<typeof Action> & {
  boardId: string;
  tooltipPlacement?: Placement;
  className?: string;
};

export const BoardStarButton = ({
  boardId,
  tooltipPlacement = 'bottom',
  ...props
}: Props) => {
  const {
    star, unStar,
  } = useBoardStar();
  const isBoardStarred = useIsBoardStarred();

  return (
    <HiddenAction isDisabled={isBoardStarred(boardId)}>
      {isBoardStarred(boardId) ? (
        <StarAction
          {...props}
          onClick={(event: MouseEvent) => {
            event.stopPropagation();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            unStar(boardId);
          }}
          tooltip="Remove from favorites"
          tooltipPlacement={tooltipPlacement}
        >
          <Icon
            name="star-fill"
            className="size-4!"
          />
        </StarAction>
      ) : (
        <Action
          {...props}
          onClick={(event: MouseEvent) => {
            event.stopPropagation();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            star(boardId);
          }}
          tooltip="Add to favorites"
          tooltipPlacement={tooltipPlacement}
        >
          <Icon
            name="star"
            className="size-4!"
          />
        </Action>
      )}
    </HiddenAction>
  );
};
