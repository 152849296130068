import { ProductBoardConfigDoctypeFilterDocument } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import client from 'src/services/apollo/client';

export const usePrefetchProductBoardConfigDoctypeFilter = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.query({
      query: ProductBoardConfigDoctypeFilterDocument,
      variables: { productId },
    });
  }, [productId]);
};
