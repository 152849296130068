import { memo, useEffect, useRef } from 'react';

import { removeDocAutomationWatch, useGetDocAutomationWatch } from 'src/reactives/docAutomationWatch.reactive';
import { getAutomationType } from 'src/utils/embeddable.util';
import { addToaster } from 'src/utils/toasters.utils';

import { useDocV2 } from '../../hooks';

const Subscribe = ({ docId }: { docId: string }) => {
  const to = useRef<ReturnType<typeof setTimeout> | null>(null);
  if (!to.current) {
    // Use case: listen for an automation update just after publishing a doc `withLinear`,
    // we dont need it to be persitent (in instance: in case the automation fails).
    to.current = setTimeout(() => {
      removeDocAutomationWatch(docId);
    }, 30_000);
  }

  useEffect(() => {
    return () => {
      if (to.current) {
        clearTimeout(to.current);
      }
    };
  }, []);

  const { doc } = useDocV2(docId);

  useEffect(() => {
    if (doc?.automationUrl) {
      const type = getAutomationType(doc.automationUrl);
      addToaster({
        id: doc.automationUrl,
        title: `Linear ${type} automatically created`,
        message: (
          <a
            target="_blank"
            href={doc.automationUrl}
            rel="noreferrer"
          >
            Click here to open in Linear
          </a>
        ),
      });
      removeDocAutomationWatch(docId);
    }
  }, [doc?.automationUrl, docId]);

  return null;
};

export const DocAutomationNotify = memo(() => {
  const { docIds } = useGetDocAutomationWatch();
  return (
    <>
      {docIds.map(docId => (
        <Subscribe
          key={docId}
          docId={docId}
        />
      ))}
    </>
  );
});
