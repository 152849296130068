import { ChangelogBuilderFormData } from '../useChangelogBuilderForm';
import { PreviewChangelogEventData } from './types';

export function changelogValueToEventData({
  backgroundColor,
  bodyColor,
  contentSection,
  dateStyle,
  dividerColor,
  headersColor,
  heroSection,
  heroStyle,
  language,
  linkColor,
  releaseTagStyle,
  shipBadgeColor,
  subscribeLabel,
  subscribeToggled,
  subtitle,
  title,
  typography,
  socialLinkText,
  socialLinkURL,
  logo,
  heroImage,
}: ChangelogBuilderFormData): PreviewChangelogEventData {
  return {
    backgroundColor,
    bodyColor,
    contentSection,
    dateStyle,
    dividerColor,
    headersColor,
    heroSection,
    heroStyle,
    language,
    linkColor,
    releaseTagStyle,
    shipBadgeColor,
    subscribeLabel,
    subscribeToggled,
    subtitle,
    title,
    typography,
    socialLinkText,
    socialLinkURL,
    logo: getFileString(logo),
    heroImage: getFileString(heroImage),

  };
}

function getFileString(file: string | File | null): string | File | null {
  /**
   * The suggestions are local files. If we send them like that, the preview will be broken.
   */
  if (typeof file === 'string' && file.startsWith('/')) {
    return `${window.origin}${file}`;
  }

  return file;
}
