import { CommandWorkspaceAction } from 'src/components/CommandBar/CommandK/CommandWorkspaceAction';
import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks';
import { useProducts } from 'src/hooks/api/useProducts';
import { useUrl } from 'src/hooks/useUrl';
import { CommandActionType } from 'src/types/commandbar.types';

import { closeCommandBar } from '../modals/useCommandBarModal';

export const useWorkspacesSearch = (search: string, enabled: boolean) => {
  const { navigate } = useNavigate();
  const getUrl = useUrl();
  const {
    products, loading, hasNextPage, fetchNextPage,
  } = useProducts({
    searchText: search,
    skip: !enabled,
  });

  const result = [{
    id: 'workspaces',
    actions: products.map(product => ({
      id: product.id,
      label: product.name,
      renderLabel: (props) => (
        <CommandWorkspaceAction
          product={product}
          searchText={props.searchTextDebounced}
        />
      ),
      linkTo: getUrl(PageId.Main, { productSlug: product.slug }),
      onClick: (e) => {
        if (!e.metaKey) closeCommandBar();
      },
      onSelect: () => {
        closeCommandBar();
        navigate(PageId.Main, { productSlug: product.slug });
      },
    })) as CommandActionType[],
  }];

  return {
    result,
    loading,
    hasNextPage,
    fetchNextPage,
  };
};
