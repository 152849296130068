import { ActionButton, typo } from '@cycle-app/ui';
import styled from 'styled-components';

import DocPanelDocAttributes from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes';
import { AddPropertyButton } from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes.styles';

import { PARENT_MAX_WIDTH } from './DocItemDraftParent.styles';
import { DocItemAvatar } from '../DocAssignee/DocAssignee.styles';

export const StyledDocPanelDocAttributes = styled(DocPanelDocAttributes)`
  gap: 4px;

  ${AddPropertyButton} {
    opacity: 1;
  }

  ${DocItemAvatar} {
    transform: scale(0.9);
  }
`;

export const StyledDropdownAction = styled(ActionButton)`
  ${typo.caption400}
  margin-top: 2px;
  min-width: 20px;
  height: 20px;
`;

export const DropdownContent = styled.div`
  display: grid;
  gap: 6px;
  padding: 8px;
  min-width: ${PARENT_MAX_WIDTH}px;
`;
