import { useMemo } from 'react';

import { ProductTourModalContent } from 'src/components/ProductTourModalContent';
import { useGetReleasesAction, resetReleasesAction } from 'src/reactives/releases.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

export const ReleasesProductTour = () => {
  const action = useGetReleasesAction();
  return action.type === 'productTour' ? <OnboardingStarted /> : null;
};

const OnboardingStarted = () => {
  const isMobile = useIsMobile();

  /* eslint-disable max-len */
  const slides = useMemo(() => [{
    id: 0,
    title: 'Create releases',
    description: 'Create releases to communicate with customers, prospects, and team. Just add your first release date and start documenting your release notes.',
    image: (
      <img
        src="/images/releases-create-note.svg"
        alt=""
        height={145}
        draggable="false"
      />
    ),
    note: isMobile ? 'Desktop only' : undefined,
  }, {
    id: 1,
    title: 'Add release notes',
    description: 'Document your release notes, feature by feature. You can use Cycle’s rich text editor, add images & GIFs, and leverage AI to get started.',
    image: (
      <img
        src="/images/releases-add-notes.svg"
        alt=""
        height={145}
        draggable="false"
      />
    ),
    note: isMobile ? 'Desktop only' : undefined,
  }, {
    id: 2,
    title: 'Close the loop',
    description: 'Close the loop at each release. With one click, send release notes to Cycle users who shared relevant feedback. They can in turn communicate with customers right where they asked.',
    image: (
      <img
        src="/images/releases-close-loop.png"
        alt=""
        height={155}
        draggable="false"
      />
    ),
    note: isMobile ? 'Desktop only' : undefined,
  }, {
    id: 3,
    title: 'Publish change log',
    description: 'Create a public change log and update it every week with the latest features. Fetch the release notes, preview, and publish. It’s that easy.',
    image: (
      <img
        src="/images/releases-publish-changelog.svg"
        alt=""
        height={145}
        draggable="false"
      />
    ),
    note: 'Coming soon',
  }], [isMobile]);
  /* eslint-enable max-len */

  return (
    <ProductTourModalContent
      slides={slides}
      stop={() => {
        resetReleasesAction();
      }}
    />
  );
};
