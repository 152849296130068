import { Skeleton, CircleSkeleton } from '@cycle-app/ui';

import { Container, Side } from './DocHierarchyItem.styles';

export const DocHierarchySkeletons = ({
  count,
  showDocId = false,
}: {
  count: number;
  showDocId?: boolean;
}) => {
  return (
    <>
      {[...Array(count).keys()].map(i => (
        <Container key={i}>
          {showDocId && (
            <Skeleton
              height={18}
              width={50}
            />
          )}
          <Skeleton
            height={18}
            width={18}
          />
          <Skeleton
            height={18}
            width={200}
          />
          <Side>
            <CircleSkeleton
              height={20}
              width={20}
            />
            <CircleSkeleton
              height={20}
              width={20}
            />
            <Skeleton
              height={18}
              width={18}
            />
          </Side>
        </Container>
      ))}
    </>
  );
};
