import { Button } from '@cycle-app/ui';
import { ComponentPropsWithRef, FC } from 'react';

import clippyErrorImage from 'src/assets/clippy-error-dark.png';
import { useLogout } from 'src/hooks/useLogout';

import { Container, Title, Img } from './ErrorPage.styles';
import { Size } from './ErrorPage.types';

type Props = ComponentPropsWithRef<'div'> & {
  showBackButton?: boolean;
  message?: string;
  size?: Size;
};

export const ErrorPage: FC<Props> = ({
  showBackButton = false, message = '', size = Size.DEFAULT, ...props
}) => {
  const logout = useLogout();

  return (
    <Container
      $size={size}
      {...props}
    >
      <Img
        src={clippyErrorImage}
        alt="Error"
      />
      <Title>
        {message || (
          <>
            Oops something went wron
            <span
              onClick={logout}
              role="button"
              tabIndex={0}
            >
              g
            </span>
          </>
        )}
      </Title>
      {showBackButton && (
        <a href="/">
          <Button size="L">
            Back to my workspace
          </Button>
        </a>
      )}
    </Container>
  );
};
