import { Button } from '@cycle-app/ui';
import { useHotkeys } from '@cycle-app/utilities';
import { useState, forwardRef } from 'react';

import { CommentEditor } from 'src/components/Editor/Editors/CommentEditor';

import {
  ButtonsGroup,
  Container,
  Error,
  InputFooter,
} from './InsightCardContextEdit.styles';

const EMPTY_EDITOR_STATE = '<p></p>';

export type InsightCardContextEditProps = {
  defaultValue?: string;
  onCancel: VoidFunction;
  onUpdate: (newContext: string) => void;
};

export const InsightCardContextEdit = forwardRef<HTMLDivElement, InsightCardContextEditProps>(({
  defaultValue,
  onCancel,
  onUpdate,
}, ref) => {
  const [value, setValue] = useState(defaultValue || '');
  const hasError = value === EMPTY_EDITOR_STATE;
  useHotkeys('enter', e => {
    e.preventDefault();
    e.stopPropagation();

    onUpdate(value);
  });
  useHotkeys('escape', e => {
    e.preventDefault();
    e.stopPropagation();

    onCancel();
  });

  return (
    <Container
      ref={ref}
      $hasError={hasError}
      onClick={e => e.stopPropagation()}
    >
      <CommentEditor
        onUpdate={(data) => {
          setValue(data.html);
        }}
        autofocus
        content={value}
      />
      <InputFooter>
        <Error>
          {hasError ? 'You need at least 1 character' : ''}
        </Error>
        <ButtonsGroup>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onCancel();
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onUpdate(value);
            }}
          >
            Save
          </Button>
        </ButtonsGroup>
      </InputFooter>
    </Container>
  );
});
