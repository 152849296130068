import styled, { css } from 'styled-components';

import { ViewType } from '../../../../graphql-codegen/generated';
import { ShyScrollbar } from '../ShyScrollbar/ShyScrollbar.styles';

// @todo viewType.types.ts should be in utilities instead of front to be reusable
interface WithViewType {
  viewType: ViewType;
}

const GroupContainerKanban = css`
  flex-direction: row;
  align-items: flex-start; /* We don't want to stretch the kanban boards */
  height: 100%;
  padding-left: calc(var(--container-spacing) - 8px);
  padding-right: calc(var(--container-spacing) - 8px);
  padding-bottom: calc(var(--container-spacing) - 8px);
`;

const GroupContainerList = css`
  flex-direction: column;
  gap: 16px;
  max-height: 100%;
  border-radius: 8px;
  padding-left: 13px;
  padding-right: 9px;
  padding-bottom: 100px;
  margin-left: -2px;
`;

export const GroupContainer = styled(ShyScrollbar)<WithViewType>`
  display: flex;
  padding-bottom: 18px;

  > * {
    max-height: 100%;
  }

  ${(props) => (props.viewType === ViewType.Kanban ? GroupContainerKanban : GroupContainerList)};
`;
