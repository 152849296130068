/* eslint-disable react/jsx-curly-brace-presence */
import {
  BillingPlan,
  MeFragment,
  UserJourney,
} from '@cycle-app/graphql-codegen';
import { CycleLoader, Icon } from '@cycle-app/ui';
import { Color } from '@cycle-app/ui/theme/baseColors';
import { useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useIntercom } from 'src/hooks/useIntercom';
import { useUpdateMe } from 'src/hooks/user/useUpdateMe';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';
import { isDevEnv } from 'src/utils/env.util';
import { openIntercom } from 'src/utils/intercom.utils';

import {
  Body,
  BodyDefault,
  Content,
  StyledButton,
  StyledButtonSecondary,
  StyledLogoLoader,
  Modal,
  CalendlyContainer,
  LoaderContainer,
  SubTitle,
  Thib,
  TooltipJoin,
} from './OnboardingStepBookCall.styles';
import thib from './thib.png';
import { Chat } from '../OnboardingLayout/Chat';
import { OnboardingAutologin } from '../OnboardingLayout/OnboardingAutologin';
import { OnboardingFooter } from '../OnboardingLayout/OnboardingFooter';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { Title } from '../OnboardingLayout/OnboardingLayout.styles';

const CALENDLY_LINK = isDevEnv()
  ? 'https://calendly.com/kevin-tassi/dogfood-cycle-onboarding'
  : 'https://calendly.com/d/dm7-jw6-44f/cycle-onboarding';
const CALENDLY_RULES = '?hide_event_type_details=1&hide_gdpr_banner=1';

type OnboardingStepBookCallProps = {
  me: MeFragment;
  productId: string;
  plan: BillingPlan;
};

export const OnboardingStepBookCall = ({
  me, productId, plan,
}: OnboardingStepBookCallProps) => {
  useIntercom();
  const history = useHistory<{ isCalendlyOpen: boolean }>();
  const isCalendlyOpen = !!history.location.state?.isCalendlyOpen;

  return (
    <OnboardingLayout
      hideLogo
      hasLogout
      isContentGrow
      footer={<OnboardingFooter />}
      main={(
        <Content>
          <StyledLogoLoader
            size={100}
            color="blue"
          />
          <Body>
            <BodyDefault initial={{ opacity: 1 }}>
              <Title>
                Book your personalized onboarding
              </Title>
              <SubTitle>
                During the call, we&apos;ll personalize your experience and help you customize your AI.
              </SubTitle>
              <StyledButton
                onClick={() => history.push({ state: { isCalendlyOpen: true } })}
                size="M"
              >
                Book onboarding
              </StyledButton>
              <StyledButtonSecondary
                onClick={() => openIntercom()}
                size="M"
              >
                Any questions? Say hi!
              </StyledButtonSecondary>
            </BodyDefault>
          </Body>
          {isCalendlyOpen && (
            <CalendlyModal
              onHide={() => history.goBack()}
              me={me}
            />
          )}

          {!isCalendlyOpen && (
            <Chat
              logo={(
                <Thib
                  src={thib}
                  alt=""
                  width={48}
                />
              )}
              tooltip={(
                <div>
                  <div>
                    Want to move faster?
                  </div>
                  <TooltipJoin>
                    <Icon
                      name="brand/google-meet"
                      className="mr-1 size-4 align-middle"
                    />
                    Join me to get immediate access
                  </TooltipJoin>
                </div>
              )}
            />
          )}
          <OnboardingAutologin
            productId={productId}
            plan={plan}
            userId={me.id}
          />
        </Content>
      )}
    />
  );
};

type CalendlyModalProps = {
  onHide: VoidFunction;
  me: MeFragment;
};

const CalendlyModal = ({
  onHide, me,
}: CalendlyModalProps) => {
  const isMobile = useIsMobile();
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const { updateMe } = useUpdateMe();
  const theme = useTheme();
  useCalendlyEventListener({
    onEventTypeViewed: () => setIsCalendarVisible(true),
    onEventScheduled: () => {
      setIsCalendarVisible(false);
      setOnboarding({ screen: LightOnboardingScreen.CallBooked });
      return updateMe({ userJourney: UserJourney.CallBooked });
    },
  });

  return (
    <Modal hide={onHide}>
      <CalendlyContainer $isVisible={isCalendarVisible}>
        <InlineWidget
          url={`${CALENDLY_LINK}${CALENDLY_RULES}`}
          styles={{
            height: isMobile ? window.innerHeight : '698px',
            width: isMobile ? window.innerWidth : '448px',
          }}
          // This works only with paid calendy account
          pageSettings={{
            backgroundColor: theme.colors.background.secondary,
            primaryColor: Color.Cycle,
            textColor: theme.colors.text.primary,
            hideEventTypeDetails: true,
            hideLandingPageDetails: true,
            hideGdprBanner: true,
          }}
          prefill={{
            email: me.email,
            firstName: me.firstName ?? '',
            lastName: me.lastName ?? '',
            name: `${me.firstName} ${me.lastName}`,
          }}
        />
      </CalendlyContainer>
      <LoaderContainer $isVisible={!isCalendarVisible}>
        <CycleLoader />
      </LoaderContainer>
    </Modal>
  );
};
