import { getOS } from '@cycle-app/utilities';
import { ReactNode, FC } from 'react';

import {
  ShortcutContainer,
  Label,
  Keys,
  Key,
  Then,
} from './Shortcut.styles';

export interface ShortcutProps {
  className?: string;
  label?: ReactNode;
  keys: string[];
  onActive?: boolean;
  colors?: 'oposite' | 'dark';
  isDark?: boolean;
  hideThen?: boolean;
}

export const Shortcut: FC<React.PropsWithChildren<ShortcutProps>> = ({
  className,
  label,
  keys,
  colors,
  onActive = false,
  hideThen,
}) => {
  return (
    <ShortcutContainer
      className={className}
      $colors={colors}
      $onActive={onActive}
    >
      {label && (
        <Label>
          {label}
        </Label>
      )}
      <Keys>
        {keys.filter(k => !hideThen || (k !== ',')).map((key) => (key === ','
          ? (
            <Then key={key}>
              then
            </Then>
          )
          : (
            <Key key={key}>
              {parseKey(key)}
            </Key>
          )
        ))}
      </Keys>
    </ShortcutContainer>
  );
};

export const parseKey = (k: string) => {
  if (k === 'mod') {
    return getOS() === 'macOS' ? '⌘' : 'Ctrl';
  }
  if (k === 'shift') {
    return '⇧';
  }
  if (k === 'escape') {
    return 'Esc';
  }
  if (k === 'enter') {
    return '↵';
  }
  if (k === 'delete') {
    return '⌫';
  }
  if (k === 'control') {
    return 'Ctrl';
  }

  return k.toUpperCase();
};

export const ShortcutComponents = {
  Label,
  Keys,
  Then,
  Key,
  ShortcutContainer,
};
