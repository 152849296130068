import { Input, Button } from '@cycle-app/ui';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';

import { useChangeMyPassword } from 'src/hooks/api/mutations/useChangePassword';
import { useMe } from 'src/hooks/api/useMe';
import { addToaster } from 'src/utils/toasters.utils';

import { Form, Inputs, FormFooter } from './SecurityTab.styles';
import { validatePassword } from '../../../utils/password';

interface FormValues {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

const SecurityTab = () => {
  const { me } = useMe();
  const {
    changeMyPassword,
    loading,
  } = useChangeMyPassword();

  const {
    handleSubmit,
    register,
    formState,
    reset,
    watch,
    setError,
  } = useForm<FormValues>();

  const currentPasswordRef = useRef({});
  const newPasswordRef = useRef({});
  currentPasswordRef.current = watch('currentPassword', '');
  newPasswordRef.current = watch('newPassword', '');

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Inputs>

        {/* For password managers to be able to link the password to the username */}
        <input
          hidden
          readOnly
          id="username"
          autoComplete="username"
          value={me.email}
        />

        <Input
          id="current-password"
          type="password"
          label="Current password"
          autoComplete="current-password"
          placeholder="Type your current password"
          error={formState.errors.currentPassword?.message}
          {...register('currentPassword', {
            required: 'Current password is required',
          })}
        />

        <Input
          id="new-password"
          type="password"
          label="New password"
          autoComplete="new-password"
          placeholder="Type your new password"
          error={formState.errors.newPassword?.message}
          {...register('newPassword', {
            validate: value => {
              if (value === currentPasswordRef.current) return 'New password must be different from current one';
              const { error } = validatePassword(value);
              return error;
            },
          })}
        />

        <Input
          id="new-password-confirm"
          type="password"
          label="New password (confirmation)"
          autoComplete="new-password-confirm"
          placeholder="Confirm your new password"
          error={formState.errors.newPasswordConfirmation?.message}
          {...register('newPasswordConfirmation', {
            required: 'New password confirmation is required',
            validate: value => value === newPasswordRef.current || 'Confirmation does not match new password',
          })}
        />
      </Inputs>

      <FormFooter>
        <Button
          type="submit"
          size="M"
          isLoading={loading}
        >
          Save
        </Button>
      </FormFooter>
    </Form>
  );

  async function onSubmit(formValues: FormValues) {
    const { data } = await changeMyPassword(formValues.currentPassword, formValues.newPassword);
    if (!data?.updateMe) {
      setError('currentPassword', { message: 'Incorrect password' });
    } else {
      addToaster({ title: 'Password changed' });
      reset();
    }
  }
};

export default SecurityTab;
