import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { Tag, Emoji, Tooltip } from '@cycle-app/ui';
import { getDocFullUrl, getDocSlug } from '@cycle-app/utilities';
import { Editor } from '@tiptap/core';
import { FC, MouseEvent, useCallback } from 'react';
import { Placement } from 'tippy.js';

import { AiStateTag } from 'src/components/AiStateTag';
import { DocLinear } from 'src/components/DocLinear';
import { DocReleaseNote } from 'src/components/DocReleaseNote';
import { DocStatus } from 'src/components/DocStatus';
import DoctypesOptions from 'src/components/DoctypesOptions/DoctypesOptions';
import { Events, Sources, Methods } from 'src/constants/analytics.constants';
import { PageId } from 'src/constants/routing.constant';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { usePageId, useParentPage } from 'src/hooks/usePageId';
import { useUrl } from 'src/hooks/useUrl';
import { useGetPermission } from 'src/reactives';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { getDocKey } from 'src/utils/doc.util';
import { isFeedback, isInsight, isCustom } from 'src/utils/docType.util';

import { DocLinearAutoCreate } from '../DocLinearAutoCreate';
import { Container, SkeletonTag, DocTypeButton, DocTypeLabel } from './DocPrimaryAttributes.styles';

interface Props {
  className?: string;
  doc: Partial<DocBaseFragment>;
  isDragging?: boolean;
  layer?: Layer;
  readOnly?: boolean;
  readOnlyStatus?: boolean;
  dropdownPlacement?: Placement;
  showDocId?: boolean;
  showDocType?: boolean;
  showStatus?: boolean;
  showRelease?: boolean;
  showLinear?: boolean;
  showAiState?: boolean;
  context?: 'doc-item' | 'doc-panel';
  isDocTypeReadOnly?: boolean;
  enableStatusShortcut?: boolean;
  hideStatusLabel?: boolean;
  compatibleStatusIds?: string[];
  showLinearAutoCreate?: boolean;
  onWithLinearChange?: (checked: boolean) => void;
  withLinearChecked?: boolean;
  editor?: Editor;
  showDocIdWithDocType?: boolean;
}

const DocPrimaryAttributes: FC<React.PropsWithChildren<Props>> = ({
  className,
  doc,
  isDragging,
  layer = Layer.Dropdown,
  readOnly = false,
  readOnlyStatus,
  dropdownPlacement = 'bottom',
  showDocId = true,
  showDocType = true,
  showStatus = true,
  showRelease = true,
  showLinear = true,
  showAiState = true,
  context = 'doc-item',
  isDocTypeReadOnly = false,
  enableStatusShortcut,
  hideStatusLabel = context === 'doc-item',
  compatibleStatusIds,
  showLinearAutoCreate,
  onWithLinearChange,
  withLinearChecked,
  editor,
  showDocIdWithDocType,
}) => {
  const docType = useGetDocType(doc.doctype?.id);
  const getUrl = useUrl();
  const pageId = usePageId();
  const { canUpdateFeedbackStatus } = useGetPermission();
  const isLinearInstalled = useWorkspaceContext(ctx => ctx.isLinearInstalled);
  const isRoadmapsEnabled = useWorkspaceContext(ctx => ctx.isRoadmapsEnabled);
  const productKey = useWorkspaceContext(ctx => ctx.productKey);
  const docKey = getDocKey(productKey, doc.publicId);

  const onTagDocIdClicked = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const docUrl = doc?.id && getUrl(PageId.DocFullPage, {
      docSlug: getDocSlug({
        id: doc.id,
        title: doc.title,
      }),
    });
    const fullDocUrl = docUrl && getDocFullUrl(docUrl);
    if (!fullDocUrl) {
      console.warn('No doc url');
      return;
    }
    copyToClipboard({
      text: fullDocUrl,
      notification: `Link to ${docKey} copied to clipboard!`,
    });
    trackAnalytics(Events.DocShared, {
      method: Methods.UI,
      source: pageId === PageId.Board ? Sources.Board : Sources.DocPanel,
    });
  }, [doc.id, doc.title, docKey, getUrl, pageId]);

  const parentPage = useParentPage();
  const showInsightParentDocType = parentPage === 'insight' && !isInsight(docType);

  const docTypeTag = (showDocType || showInsightParentDocType) && (
    <DoctypesOptions
      layer={layer}
      doc={doc}
      doctype={docType}
      tooltipDisabled={isDragging}
      dropdownPlacement={dropdownPlacement}
      disabled={readOnly || isDocTypeReadOnly}
      context={context}
    />
  );

  const docIdTag = showDocId && !doc.isDraft && (
    <Tag
      onClick={onTagDocIdClicked}
      color="grey"
      tooltip={{
        placement: 'top',
        disabled: isDragging,
        title: 'Feature ID',
        content: 'Copy link',
        withPortal: true,
      }}
    >
      {docKey ?? <SkeletonTag />}
    </Tag>
  );

  const shouldShowStatus = isFeedback(docType) ? true : isRoadmapsEnabled;
  const statusTag = showStatus && doc?.status && doc?.id && docType?.id && shouldShowStatus && (
    <DocStatus
      docId={doc.id}
      statusId={doc.status.id}
      docTypeId={docType.id}
      hideLabel={hideStatusLabel}
      enableShortcut={enableStatusShortcut}
      isDisabled={isInsight(docType) || readOnlyStatus}
      compatibleStatusIds={compatibleStatusIds}
      isAllowedToUpdate={!isFeedback(docType) || canUpdateFeedbackStatus}
    />
  );

  const releaseTag = showRelease && doc.releaseNote && (
    <DocReleaseNote
      releaseNote={doc.releaseNote}
      isPreviewEnabled
    />
  );

  const docIdWithDocType = docIdTag && docTypeTag ? (
    <Tag
      onClick={onTagDocIdClicked}
      color="grey"
      tooltip={{
        placement: 'top',
        disabled: isDragging,
        title: 'Feature ID',
        content: 'Copy link',
        withPortal: true,
      }}
      start={(
        <DoctypesOptions
          layer={layer}
          doc={doc}
          doctype={docType}
          tooltipDisabled={isDragging}
          dropdownPlacement={dropdownPlacement}
          disabled={readOnly || isDocTypeReadOnly}
          context={context}
        >
          {buttonProps => docType && (
            <Tooltip
              placement="top"
              disabled={isDragging}
              title={(
                <DocTypeLabel>
                  <Emoji
                    emoji={docType.emoji}
                    size={12}
                  />
                  {docType.name}
                </DocTypeLabel>
              )}
              content={readOnly || isDocTypeReadOnly ? undefined : 'Click to update'}
              withPortal
            >
              <DocTypeButton
                {...buttonProps}
                disabled={readOnly || isDocTypeReadOnly}
              >
                <Emoji
                  emoji={docType.emoji}
                  size={12}
                />
              </DocTypeButton>
            </Tooltip>
          )}
        </DoctypesOptions>
      )}
    >
      {docKey ?? <SkeletonTag />}
    </Tag>
  ) : (
    <>
      {docTypeTag}
      {docIdTag}
    </>
  );

  const linearTag = doc.id && isCustom(docType) && showLinear && (
    <DocLinear
      docId={doc.id}
      automationId={doc.automationId}
      automationUrl={doc.automationUrl}
      showSyncAutomationContent={context === 'doc-panel'}
      editor={editor}
    />
  );

  const aiStateTag = showAiState && doc.id && doc.doctype?.id && (
    <AiStateTag
      docId={doc.id}
      docTypeId={doc.doctype.id}
      aiState={doc.aiState}
      full={context === 'doc-panel'}
    />
  );

  return (
    <Container className={className}>
      {context === 'doc-item' ? (
        <>
          {docIdWithDocType}
          {statusTag}
          {showLinearAutoCreate && isLinearInstalled && onWithLinearChange && doc.doctype?.id && (
            <DocLinearAutoCreate
              checked={!!withLinearChecked}
              onChange={onWithLinearChange}
              doctypeId={doc.doctype.id}
            />
          )}
          {releaseTag}
          {linearTag}
          {aiStateTag}
        </>
      ) : (
        <>
          {showDocIdWithDocType ? docIdWithDocType : (
            <>
              {docIdTag}
              {docTypeTag}
            </>
          )}
          {statusTag}
          {releaseTag}
          {linearTag}
          {aiStateTag}
        </>
      )}
    </Container>
  );
};

export default DocPrimaryAttributes;
