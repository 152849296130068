import { ComponentProps, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

type Props = ComponentProps<'a'> & {
  to?: LinkProps['to'];
};

export const QuotePanelLink = forwardRef<HTMLAnchorElement, Props>(({
  className, to, ...props
}, ref) => {
  if (to) {
    return (
      <Link
        ref={ref}
        className={twJoin(
          'btn-tertiary max-w-full gap-1.5 overflow-hidden bg-grey-100 text-primary hover:bg-grey-200 dark:bg-grey-850 dark:hover:bg-grey-800',
          className,
        )}
        to={to}
        {...props}
      />
    );
  }

  return (
    <a
      ref={ref}
      className={twJoin(
        'btn-tertiary max-w-full gap-1.5 overflow-hidden bg-grey-100 text-primary hover:bg-grey-200 dark:bg-grey-850 dark:hover:bg-grey-800',
        className,
      )}
      target="_blank"
      rel="noreferrer"
      {...props}
    />
  );
});
