import { ActionButton, Emoji } from '@cycle-app/ui';
import {
  BubbleIcon,
  CloseIcon,
  NoCommentIcon,
  SlackSimpleIcon,
  TwitterIcon,
  WhatsNewIcon,
} from '@cycle-app/ui/icons';
import { twJoin } from 'tailwind-merge';

import {
  CHANGELOG_URL,
  FEEDBACK_URL,
  SLACK_URL,
  TWITTER_URL,
} from 'src/constants/contacts.constants';
import {
  HELP_URL,
  HELP_URL_ARTICLE_WHAT_IS_CYCLE,
  HELP_URL_ARTICLE_CAPTURE_FEEDBACK,
  HELP_URL_ARTICLE_DOCUMENT_INITIATIVE,
} from 'src/constants/help.constants';
import { useSidebarCollapsed } from 'src/hooks';
import { closeLearningCenter, useLearningCenter } from 'src/reactives';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { Layer } from 'src/types/layers.types';
import { openIntercom } from 'src/utils/intercom.utils';

import {
  ArticleBodyContainer,
  ArticleCard,
  ArticleDescription,
  ArticleEmojiContainer,
  ArticleTitle,
  Container,
  Flex,
  HeaderSection,
  HelpButton,
  Item,
  Link,
  List,
  MoreButton,
  MoreSection,
  PortalModal,
  SubTitle,
  Title,
} from './LearningCenterModal.styles';

export const LearningCenterModal = () => {
  const { isVisible } = useLearningCenter();
  const isMobile = useIsMobile();
  const isSidebarCollapsed = useSidebarCollapsed();
  if (!isVisible) return null;
  return (
    <PortalModal
      layer={Layer.DialogModal}
      hide={closeLearningCenter}
      placement="bottom-right"
      invisibleMask={!isMobile}
      className={twJoin(
        !isMobile && 'absolute! bottom-7! left-40!',
        !isMobile && isSidebarCollapsed && 'left-20!',
      )}
    >
      <LearningCenterContent />
    </PortalModal>
  );
};

const LearningCenterContent = () => {
  return (
    <Container>
      <HeaderSection>
        <Title>
          Learning center
        </Title>
        <ActionButton onClick={closeLearningCenter}>
          <CloseIcon />
        </ActionButton>
      </HeaderSection>

      <MoreSection>
        <Flex>
          <HelpButton onClick={() => openIntercom()}>
            <NoCommentIcon />
            Contact us
          </HelpButton>
        </Flex>
        <Flex>
          <SubTitle>
            Help articles
          </SubTitle>
          <MoreButton
            href={HELP_URL}
            target="_blank"
          >
            View more
          </MoreButton>
        </Flex>
        <ArticleCard
          href={HELP_URL_ARTICLE_WHAT_IS_CYCLE}
          target="_blank"
        >
          <ArticleEmojiContainer>
            <Emoji emoji="seedling" />
          </ArticleEmojiContainer>
          <ArticleBodyContainer>
            <ArticleTitle>
              Cycle 101
            </ArticleTitle>
            <ArticleDescription>
              Discover Cycle basics
            </ArticleDescription>
          </ArticleBodyContainer>
        </ArticleCard>
        <ArticleCard
          href={HELP_URL_ARTICLE_CAPTURE_FEEDBACK}
          target="_blank"
        >
          <ArticleEmojiContainer>
            <Emoji emoji="electric_plug" />
          </ArticleEmojiContainer>
          <ArticleBodyContainer>
            <ArticleTitle>
              Integrations
            </ArticleTitle>
            <ArticleDescription>
              Centralize feedback from multiple sources
            </ArticleDescription>
          </ArticleBodyContainer>
        </ArticleCard>
        <ArticleCard
          href={HELP_URL_ARTICLE_DOCUMENT_INITIATIVE}
          target="_blank"
        >
          <ArticleEmojiContainer>
            <Emoji emoji="gem" />
          </ArticleEmojiContainer>
          <ArticleBodyContainer>
            <ArticleTitle>
              Document initiatives
            </ArticleTitle>
            <ArticleDescription>
              Write and organize product initiatives
            </ArticleDescription>
          </ArticleBodyContainer>
        </ArticleCard>
      </MoreSection>

      {/* // TODO: Hide this by comment for now and remove properly the code later */}
      {/* <GamificationProgressList /> */}

      <MoreSection>
        <SubTitle>
          More
        </SubTitle>
        <List>
          <Item>
            <Link
              href={CHANGELOG_URL}
              target="_blank"
            >
              <WhatsNewIcon />
              What’s new
            </Link>
          </Item>
          <Item>
            <Link
              href={SLACK_URL}
              target="_blank"
            >
              <SlackSimpleIcon />
              Join our Slack community
            </Link>
          </Item>
          <Item>
            <Link
              href={TWITTER_URL}
              target="_blank"
            >
              <TwitterIcon />
              Follow @CycleProduct on Twitter
            </Link>
          </Item>
          <Item>
            <Link
              href={FEEDBACK_URL}
              target="_blank"
            >
              <BubbleIcon />
              Give us product feedback
            </Link>
          </Item>
        </List>
      </MoreSection>
    </Container>
  );
};
