import { useFragment } from '@apollo/client';
import { DocCoverFragmentDoc } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import { PropsWithChildren, useMemo } from 'react';
import { twJoin } from 'tailwind-merge';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { parseContentFiles } from './parseContentFiles';
import { QuoteCoverMenu } from './QuoteCoverMenu';
import { QuoteFile } from './QuoteFile';
import { QuoteImage } from './QuoteImage';

type Props = {
  docId: string;
  html: string;
};

export const QuoteFiles = ({
  docId, html,
}: Props) => {
  const coverUrl = useFragment({
    fragment: DocCoverFragmentDoc,
    from: docId,
  }).data.cover?.url;

  const {
    images,
    files,
  } = useMemo(() => parseContentFiles(html), [html]);

  const imageCount = images.length + (coverUrl ? 1 : 0);
  const fileCount = files.length;
  if (imageCount + files.length === 0) return null;

  const coverEditable = !coverUrl?.startsWith('blob:'); // optimistic data

  return (
    <div className="mt-1.5 flex items-center gap-2 leading-none">
      {imageCount > 0 && (
        <ToggleDropdown
          placement="bottom-start"
          layer={Layer.DropdownModalZ3}
          withWrapper={false}
          button={props => (
            <button
              onClick={props.onClick}
              className={twJoin(
                'btn-tertiary btn-sm gap-0.5 bg-grey-200 hover:bg-grey-300 hover:text-secondary dark:bg-grey-800 dark:hover:bg-grey-750',
                imageCount > 1 ? 'h-5 p-1' : 'btn-square size-5',
                props['data-active'] && 'btn-hover bg-grey-300 text-secondary dark:bg-grey-750',
              )}
            >
              <Icon name="image" />
              {imageCount > 1 ? imageCount : null}
            </button>
          )}
          content={(
            <div
              className={twJoin(
                'shy-scrollbar flex max-w-[538px] items-center gap-3',
                imageCount > 1 && 'p-3',
              )}
            >
              {coverUrl && (
                <QuoteImage src={coverUrl}>
                  {coverEditable && (
                    <QuoteCoverMenu
                      docId={docId}
                      className="absolute right-1.5 top-1.5 opacity-0 group-hover:opacity-100"
                    />
                  )}
                  <Badge className="absolute bottom-1.5 right-1.5">
                    Cover
                  </Badge>
                </QuoteImage>
              )}

              {images.map((image, index) => (
                <QuoteImage
                  key={index}
                  src={image.src}
                />
              ))}
            </div>
          )}
        />
      )}

      {fileCount > 0 && (
        <ToggleDropdown
          placement="bottom-start"
          layer={Layer.DropdownModalZ3}
          withWrapper={false}
          button={props => (
            <button
              onClick={props.onClick}
              className={twJoin(
                'btn-tertiary btn-sm gap-0.5 bg-grey-200 hover:bg-grey-300 hover:text-secondary dark:bg-grey-800 dark:hover:bg-grey-750',
                fileCount > 1 ? 'h-5 p-1' : 'btn-square size-5',
                props['data-active'] && 'btn-hover bg-grey-300 text-secondary dark:bg-grey-750',
              )}
            >
              <Icon name="paperclip" />
              {fileCount > 1 ? fileCount : null}
            </button>
          )}
          content={(
            <div className="shy-scrollbar flex max-h-96 w-96 max-w-full flex-col gap-3 p-3">
              {files.map((file, index) => (
                <QuoteFile
                  key={index}
                  {...file}
                />
              ))}
            </div>
          )}
        />
      )}
    </div>
  );
};

const Badge = ({
  className, children,
}: PropsWithChildren<{ className?: string }>) => (
  <div
    className={twJoin(
      'pointer-events-none rounded-full bg-grey-800 px-1.5 py-0.5 text-caption font-semibold text-white dark:bg-grey-900',
      className,
    )}
  >
    {children}
  </div>
);
