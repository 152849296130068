import { FC, memo, useRef, useState, useEffect } from 'react';
import { useThrottledCallback } from 'use-debounce';

import { useIsChangelogOnly } from 'src/contexts/workspaceContext';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { BoardsSection, ScrollableSectionStyled } from './SidebarBoard.styles';
import { SidebarBoardStarredSections } from './SidebarBoardSections/SidebarBoardSection/SidebarBoardStarredSections';
import SidebarBoardSections from './SidebarBoardSections/SidebarBoardSections';
import { SidebarWorkspace } from '../SidebarActions/SidebarWorkspace';

interface Props {
  collapsed: boolean;
}

export const SidebarBoard = memo(({ collapsed }: Props) => {
  const isMobile = useIsMobile();
  const isChangelogOnly = useIsChangelogOnly();
  return (
    <BoardsSection
      $collapsed={collapsed}
      $hasBorderBottom={false}
      $hasBorderTop={false}
      $grow
    >
      {!isMobile && <SidebarWorkspace />}
      {!isChangelogOnly && (
        <>
          <SidebarBoardStarredSections />
          <SidebarBoardSections />
        </>
      )}
    </BoardsSection>
  );
});

export const ScrollableSection: FC<React.PropsWithChildren<Props>> = ({
  children, collapsed,
}) => {
  const isMobile = useIsMobile();
  const sectionRef = useRef<HTMLDivElement>(null);
  const [hasBorderTop, setHasBorderTop] = useState(false);
  const [hasBorderBottom, setHasBorderBottom] = useState(false);

  const checkBorders = () => {
    const el = sectionRef.current;
    if (!el) return;
    setHasBorderTop(el.scrollTop > 0);
    setHasBorderBottom(el.scrollHeight - el.clientHeight > el.scrollTop);
  };

  const onScroll = useThrottledCallback(checkBorders, 100);

  // Check for border bottom on mount and when a board section is collapsed or expanded.
  useEffect(() => {
    const el = sectionRef.current;
    const onClick = () => {
      if (!el) return;
      setTimeout(() => setHasBorderBottom(el.scrollHeight - el.clientHeight > el.scrollTop));
    };

    onClick();
    el?.addEventListener('click', onClick);
    return () => {
      el?.removeEventListener('click', onClick);
    };
  }, []);

  return (
    <ScrollableSectionStyled
      ref={sectionRef}
      onScroll={onScroll}
      $collapsed={collapsed}
      $hasBorderTop={!isMobile && hasBorderTop}
      $hasBorderBottom={hasBorderBottom}
    >
      {children}
    </ScrollableSectionStyled>
  );
};
