import { IntegrationType, NotionMinimalDatabaseFragment, NotionMinimalPageFragment } from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import { useListNav } from '@cycle-app/utilities';
import { FC } from 'react';

import { IntegrationEmptyStateLine } from 'src/components/Integrations/EmptyStateLine/IntegrationEmptyStateLine';
import { SelectLinesWrapper } from 'src/components/Integrations/IntegrationsCommon.styles';
import { IntegrationNotInstalledLine } from 'src/components/Integrations/NotInstalledLine/IntegrationNotInstalledLine';

import { LineSpinnerContainer } from './NotionSearchList.styles';
import { NotionSelectLine } from '../NotionSelectLine/NotionSelectLine';

type Props = {
  isIntegrationActive: boolean;
  isLoading: boolean;
  onLink: (selectedPage: NotionMinimalPageFragment | NotionMinimalDatabaseFragment) => void;
  searchResult: { searchText?: string; results?: (NotionMinimalPageFragment | NotionMinimalDatabaseFragment | null | undefined)[] };
  search: string;
  docId?: string;
};

export const NotionSearchList: FC<React.PropsWithChildren<Props>> = ({
  isIntegrationActive,
  isLoading,
  onLink,
  searchResult: {
    searchText, results,
  },
  search,
}) => {
  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    optionsValues: [...(results?.map(page => page?.id ?? '').filter(Boolean) || [])],
    onSelect: onResultSelected,
  });

  return (
    <div {...listProps}>
      {renderContent()}
    </div>
  );

  function renderContent() {
    if (!isIntegrationActive) return <IntegrationNotInstalledLine integrationType={IntegrationType.Notion} />;
    if (search.length < 3) return <IntegrationEmptyStateLine label="Type to search pages… (min 3 characters)" />;
    if (!results || !searchText) return (
      <LineSpinnerContainer
        startOnlyOnHover={false}
        startSlot={<Spinner />}
      />
    );
    if (isLoading || searchText !== search) return (
      <LineSpinnerContainer
        startOnlyOnHover={false}
        startSlot={<Spinner />}
      />
    );

    return (
      <SelectLinesWrapper>
        {!results.length ? (
          <NotionSelectLine
            isSelected={false}
            extraProps={{
              label: (
                <>
                  {'No result for '}
                  &ldquo;
                  {searchText}
                  &rdquo;
                </>
              ),
            }}
          />
        ) : results.map(page => (page && (
          <NotionSelectLine
            key={page?.id}
            page={page}
            highlightText={search}
            isSelected={page.id === selected}
            extraProps={itemProps(page.id)}
            slotText={page.iconEmoji ?? ''}
          />
        )))}
      </SelectLinesWrapper>
    );
  }

  async function onResultSelected(pageId: string | null) {
    const page = results?.find(i => i?.id === pageId);
    if (page) {
      onLink(page);
    }
  }
};
