import { Skeleton, CompanyLogo } from '@cycle-app/ui';
import { CloseIcon, ExpandIcon, SummaryIcon, UsersIcon, DollarIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';

import { CompanyActionsMenu } from 'src/components/CustomersList/CompanyActionsMenu';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ErrorPage, Size } from 'src/components/ErrorPage';
import { ExportQuotesFromCompany } from 'src/components/ExportQuotesModal';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useCompany } from 'src/hooks/api/queries/customers/useCompany';

import { CompanyDomain } from './CompanyDomain';
import { CompanyName } from './CompanyName';
import { CustomAttributes } from './CustomAttributes';
import { CustomAttributesDropdown } from './CustomAttributesDropdown';
import { DocsCountCompany } from './DocsCountCompany';
import { ExternalIds } from './ExternalIds';
import {
  ProfileContainer, ProfileMain, ProfileMainContent, ProfileTopBar, ProfileTopBarContent,
  ProfileHeader, ProfileActions, CloseButton, ExpandButton,
  ProfileInfos, Summary, SummaryTitle, ImageContainer, Skeletons,
  InfosTitle, InfosSubtitle, Left, Right, BuiltInProperty, NameContainer, TopBarSubtitle,
} from './Profile.styles';
import { ProfileCompanyLogo } from './ProfileCompanyLogo';
import { CompanySummaryModal } from './SummaryModal';
import { TabsCompany } from './TabsCompany';
import { useExpandTabsSection } from './useExpandTabsSection';
import { PartialHidden } from '../PartialHidden/PartialHidden';

type Props = {
  companyId: string;
  onClose?: VoidFunction;
  onExpand?: VoidFunction;
};

export const CompanyProfile = ({
  companyId, onClose, onExpand,
}: Props) => {
  const {
    company, isLoading,
  } = useCompany(companyId);

  const {
    profileRef, tabPanelsRef, tabsExpanded, handlePan,
  } = useExpandTabsSection();

  const [attributesOverflow, setAttributesOverflow] = useState(false);

  if (!isLoading && !company) {
    return (
      <ErrorPage
        size={Size.SMALL}
        message="Oops, this company no longer exists"
      />
    );
  }

  const actions = (
    <ProfileActions>
      {onClose && (
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      )}
      {onExpand && (
        <ExpandButton
          tooltip="Open full page"
          onClick={onExpand}
        >
          <ExpandIcon size={16} />
        </ExpandButton>
      )}
    </ProfileActions>
  );

  const menu = company && !company.isDefault && (
    <CompanyActionsMenu company={company} />
  );

  const subtitle = (
    <>
      <PartialHidden>
        <CompanyDomain companyId={companyId} />
      </PartialHidden>

      <PartialHidden>
        <ExternalIds companyId={companyId} />
      </PartialHidden>
    </>
  );

  return (
    <ErrorBoundary>
      <BoardConfigContextProvider skip>
        <ProfileContainer
          ref={profileRef}
          onPan={(e, info) => {
            handlePan(e.target, info.delta.y);
          }}
        >
          <ProfileMain
            animate={{
              height: tabsExpanded ? 28 : 'auto',
            }}
          >
            <ProfileMainContent>
              <ProfileHeader>
                {actions}
                {menu}
              </ProfileHeader>

              <ImageContainer>
                <ProfileCompanyLogo companyId={companyId} />
              </ImageContainer>

              <ProfileInfos>
                {isLoading ? (
                  <Skeletons>
                    <Skeleton
                      height={16}
                      width={150}
                    />
                    <Skeleton
                      height={16}
                      width={250}
                    />
                  </Skeletons>
                ) : (company && (
                  <>
                    <InfosTitle>
                      <NameContainer>
                        <CompanyName companyId={companyId} />
                      </NameContainer>
                    </InfosTitle>
                    <InfosSubtitle>
                      <Left>
                        {subtitle}
                      </Left>
                      <Right>
                        {company.numberOfEmployees && (
                          <BuiltInProperty>
                            <UsersIcon size={18} />
                            {formatEmployees(company.numberOfEmployees)}
                          </BuiltInProperty>
                        )}
                        {company.arr && (
                          <BuiltInProperty>
                            <DollarIcon size={18} />
                            {`${formatArr(company.arr)}\u00A0ARR`}
                          </BuiltInProperty>
                        )}
                        {attributesOverflow && <CustomAttributesDropdown companyId={company.id} />}
                      </Right>
                    </InfosSubtitle>

                    <CustomAttributes
                      companyId={company.id}
                      onOverflow={setAttributesOverflow}
                    />
                  </>
                ))}
              </ProfileInfos>

              <Summary>
                <SummaryTitle>
                  <SummaryIcon />
                  Summary
                </SummaryTitle>
                <DocsCountCompany companyId={companyId} />
              </Summary>
            </ProfileMainContent>
          </ProfileMain>

          <TabsCompany
            companyId={companyId}
            tabPanelsRef={tabPanelsRef}
          />

          <ExportQuotesFromCompany companyId={companyId} />
          <CompanySummaryModal companyId={companyId} />

          <AnimatePresence>
            {tabsExpanded && (
              <ProfileTopBar
                key="topBar"
                initial={{ top: -44 }}
                animate={{ top: 0 }}
                exit={{ opacity: -44 }}
              >
                {actions}

                <ProfileTopBarContent>
                  {isLoading ? (
                    <Skeleton
                      height={16}
                      width={200}
                    />
                  ) : (
                    <>
                      <CompanyLogo
                        company={company}
                        size="S"
                      />
                      <TopBarSubtitle>
                        {company && (
                          <InfosTitle>
                            {company.name}
                          </InfosTitle>
                        )}
                        {subtitle}
                      </TopBarSubtitle>
                    </>
                  )}
                </ProfileTopBarContent>

                {menu}
              </ProfileTopBar>
            )}
          </AnimatePresence>
        </ProfileContainer>
      </BoardConfigContextProvider>
    </ErrorBoundary>
  );
};

const formatArr = (arr: number) => Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
}).format(arr);

const formatEmployees = (employees: number) => Intl.NumberFormat('en-US').format(employees);
