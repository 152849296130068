import { useState, FC } from 'react';

import { SectionSkeleton, SectionSkeletonIcon, SectionSkeletonItem, SectionSkeletonList } from './SectionsIllustrationCard.styles';

interface Props {
  className?: string;
}

const sections = Array.from(Array(4).keys());

export const SectionsIllustrationCard: FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const [actives, setActives] = useState<Record<number, boolean>>({
    0: false,
    1: false,
    2: true,
    3: false,
  });
  return (
    <SectionSkeletonList className={className}>
      {sections.map((section, i) => (
        <SectionSkeletonItem
          key={section}
          onClick={() => setActives(current => ({
            ...current,
            [i]: !current[i],
          }))}
          $isActive={actives[i]}
        >
          <SectionSkeletonIcon
            direction="right"
            $isActive={actives[i]}
          />
          <SectionSkeleton $isActive={actives[i]} />
        </SectionSkeletonItem>
      ))}
    </SectionSkeletonList>
  );
};
