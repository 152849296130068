import { QuoteCard } from 'src/components/QuoteCard';
import { useDocsSelection } from 'src/hooks/useDocsSelection';
import { setDocItemHoverId } from 'src/reactives/docItem.reactive';
import { useGetSelection } from 'src/reactives/selection.reactive';

export const FeatureDocQuote = ({ docId }: { docId: string }) => {
  const { toggleSelectDoc } = useDocsSelection();
  const { selected } = useGetSelection();
  const isSelected = selected?.includes(docId);
  return (
    <QuoteCard
      docId={docId}
      showCustomer
      showFeature={false}
      isSelected={isSelected}
      onSelect={() => toggleSelectDoc(docId)}
      onMouseEnter={() => setDocItemHoverId(docId)}
      onMouseLeave={() => setDocItemHoverId(null)}
      {...selected.length > 0 && {
        onClick: (e) => {
          e.stopPropagation();
          toggleSelectDoc(docId);
        },
      }}
    />
  );
};
