import { typo, truncate } from '@cycle-app/ui';
import styled from 'styled-components';

export const Trigger = styled.div<{ $isReadOnly: boolean }>`
  cursor: ${p => (p.$isReadOnly ? 'default' : 'pointer')};
  padding: 2px 4px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

interface LabelProps {
  $size?: 'S';
  $muted?: boolean;
}

export const Label = styled.span<LabelProps>`
  ${p => (p.$size === 'S' ? typo.caption500 : typo.body500)}
  color:  ${p => (p.$muted ? p.theme.colors.button.outlined : p.theme.colors.text.primary)};
  margin-left: 6px;
  max-width: 150px;
  ${truncate}
`;

export const CompanyLabel = styled.span<LabelProps>`
 ${p => (p.$size === 'S' ? typo.caption500 : typo.body500)}
  color:  ${p => p.theme.colors.text.primary};
`;

export const CustomerLabel = styled(Label)`
  margin: 0;
`;

export const Text = styled.span<LabelProps>`
  ${p => (p.$size === 'S' ? typo.caption400 : typo.body400)}
  white-space: nowrap;
`;
