import { useFragment } from '@apollo/client';
import { ProductMinimalFragmentDoc } from '@cycle-app/graphql-codegen';

import { getProductId, useProductId } from 'src/hooks/useProductId';
import client from 'src/services/apollo/client';

export const useProductMinimalFragment = (productId?: string) => {
  const currentProductId = useProductId();
  const {
    data, complete,
  } = useFragment({
    fragment: ProductMinimalFragmentDoc,
    fragmentName: 'ProductMinimal',
    from: productId ?? currentProductId ?? '',
  });
  if (!complete) return null;
  return data;
};

export const getProductMinimalFragment = () => {
  const productId = getProductId();
  if (!productId) return null;
  return client.readFragment({
    fragment: ProductMinimalFragmentDoc,
    fragmentName: 'ProductMinimal',
    id: productId,
  });
};
