import { DoctypeFragment, ViewType, DocChildFragment } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { TriangleIcon, AddIcon, DownArrowIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import { ReactNode, useEffect, useMemo } from 'react';

import { NewDoc } from 'src/components/NewDoc';
import { useDocContext } from 'src/contexts/docContext';
import { useDocChildren } from 'src/hooks';
import { setBoardNewDocPositionState, useBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { toggleGroup, useGroupCollapsed, setHasNextPage, getPathValue, toggleDocChildren } from 'src/reactives/docChildren.reactive';
import { SIZE_ITEMS_HIERARCHY } from 'src/utils/pagination.util';

import { DocChildrenSkeletons } from './DocChildrenSkeletons';
import {
  GroupContainer, ChildrenContainer, GroupHeader, GroupTitle, GroupCount,
  Action, GroupInner,
} from './NestedChildren.styles';
import { NestedNewDoc } from './NestedNewDoc';

type DocChildrenGroupProps = {
  docType: DoctypeFragment;
  children: (docs: DocChildFragment[]) => ReactNode;
  showHeader: boolean;
  isAnimationComplete: boolean;
  isLastGroup: boolean;
};

export const DocChildrenGroup = ({
  docType, children, showHeader, isAnimationComplete, isLastGroup,
}: DocChildrenGroupProps) => {
  const groupId = useDocContext(ctx => ctx.groupId);
  const docId = useDocContext(ctx => ctx.id);
  const childrenCount = useDocContext(ctx => ctx.childrenCount - ctx.insightsCount);
  const basePath = useDocContext(ctx => ctx.path);
  const isLastChild = useDocContext(ctx => !!ctx.isLastChild);

  const newDoc = useBoardNewDocPositionState();
  const path = useMemo(() => [...basePath, docType.id], [basePath, docType.id]);
  const isCollapsed = useGroupCollapsed(path);

  const query = useDocChildren({
    docId,
    doctypeId: docType.id,
  }, {
    skip: !isAnimationComplete || childrenCount === 0,
  });

  const hasNextPage = !!query.pageInfo?.hasNextPage;
  const isLoaded = childrenCount === 0 ? true : !!query.data;
  const isLoading = query.loading || query.isPaginationLoading;

  let count = !isLoaded || isLoading ? '' : `${query.count}`;
  if (hasNextPage) count += '+';

  const isAdding = newDoc.groupId === getPathValue(path);
  const isAddingTop = isAdding && newDoc.draftPosition === 'top';
  const isAddingBottom = isAdding && newDoc.draftPosition === 'bottom';
  const canGoUp = isLastGroup && isLastChild;
  const canGoDown = !isCollapsed && query.count > 0;
  const showAdd = newDoc.initialGroupId !== getPathValue(path);
  const showSkeleton = (isLoading || (!query.previousData && !isLoaded && !isAnimationComplete));
  const showLoadMore = isLoaded && !isLoading && !!hasNextPage;
  const showAddBottom = showAdd && isLoaded && !isLoading && !hasNextPage && !(isAdding && newDoc.draftPosition) && !canGoUp;
  const isEmpty = !showHeader && childrenCount === 0;

  // API does not yet provide childrenCount per doc type
  const skeletonCount = showHeader ? 1 : Math.min(childrenCount - query.docs.length, SIZE_ITEMS_HIERARCHY);

  // We don't want "Add doc" next to "Load more"
  useEffect(() => {
    setHasNextPage(path, hasNextPage);
  }, [path, hasNextPage]);

  // Open "Add doc" if there is nothing to display (1 doc type and no children)
  useEffect(() => {
    if (isEmpty) {
      setBoardNewDocPositionState({
        groupId: getPathValue(path),
        draftPosition: 'bottom',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastChildId = query.docs[query.docs.length - 1]?.id;

  return (
    <GroupContainer>
      <GroupInner>
        {showHeader && (
          <GroupHeader>
            <Action onClick={() => toggleGroup(path)}>
              <TriangleIcon
                direction={isCollapsed ? 'right' : 'bottom'}
                size={20}
              />
            </Action>

            <Emoji emoji={docType.emoji} />
            <GroupTitle>
              {docType.name}
            </GroupTitle>
            {count && (
              <GroupCount>
                {count}
              </GroupCount>
            )}

            {/* API does not yet provide add child on top */}

            {/* {query.data && !isLoading && !hasNextPage && (
            <AddAction
              tooltip={`Add a new ${docType.name}`}
              disabled={isAdding && !!newDoc.draftPosition}
              onClick={() => {
                if (isCollapsed) toggleGroup(path);
                setBoardNewDocPositionState({
                  groupId: getPathValue(path),
                  draftPosition: 'bottom',
                });
              }}
            >
              <AddIcon size={10} />
            </AddAction>
          )} */}
          </GroupHeader>
        )}

        <AnimatePresence initial={false}>
          {!isCollapsed && (query.count > 0 || isAddingTop || showSkeleton || showLoadMore) && (
            <ChildrenContainer>
              {isAddingTop && (
                <NewDoc
                  viewType={ViewType.List}
                  parentId={docId}
                  possibleDoctypesId={[docType.id]}
                  from="top"
                  groupId={groupId}
                />
              )}

              {children(query.docs)}

              {showSkeleton && <DocChildrenSkeletons count={Math.max(1, skeletonCount)} />}

              {showLoadMore && (
                <button
                  className="btn-tertiary btn-sm self-start"
                  onClick={() => query.loadMore(query.pageInfo?.endCursor ?? '')}
                >
                  <DownArrowIcon size={12} />
                  Load more
                </button>
              )}
            </ChildrenContainer>
          )}
        </AnimatePresence>

        {!isCollapsed && showAddBottom && (
          <button
            className="btn-tertiary btn-sm"
            onClick={() => setBoardNewDocPositionState({
              groupId: getPathValue(path),
              draftPosition: 'bottom',
            })}
          >
            <AddIcon size={10} />
            Add
          </button>
        )}

        {isAddingBottom && (
          <NestedNewDoc
            docTypeId={docType.id}
            lastChildId={lastChildId}
            canGoUp={canGoUp}
            canGoDown={canGoDown}
            onClose={isEmpty ? () => {
              toggleDocChildren(basePath);
            } : undefined}
          />
        )}
      </GroupInner>
    </GroupContainer>
  );
};
