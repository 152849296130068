import { Skeleton, InfiniteScroll } from '@cycle-app/ui';
import { AiTextIcon, DownloadIcon, DuplicateIcon, AddIcon } from '@cycle-app/ui/icons';

import { copyQuotesToClipboard } from 'src/components/InsightsList/InsightsList.utils';
import { QuoteCard } from 'src/components/QuoteCard';
import { useCustomerDocs } from 'src/hooks/api/queries/customers/useCustomerDocs';
import {
  openCustomerExportQuotes,
  openCustomerSummary,
  setCustomerStatusCategory,
  useCustomerStatusCategory,
} from 'src/reactives/customerCompanyProfile.reactive';
import { useInsightDocType } from 'src/reactives/docTypes.reactive';

import { CustomerChildrenInsights } from './CustomerChildrenInsightsAdd';
import { QuotesHeader, QuotesActions, Skeletons, QuoteAction } from './Profile.styles';
import { StatusCategorySelect } from './StatusCategorySelect';

type Props = {
  customerId: string;
};

export const QuotesListCustomer = ({ customerId }: Props) => {
  const doctype = useInsightDocType();
  const statusCategory = useCustomerStatusCategory();

  const skip = !doctype;

  const {
    docs,
    loadMore,
    pageInfo,
    isLoading,
    isPaginationLoading,
  } = useCustomerDocs({
    id: customerId,
    doctypeId: doctype?.id,
    statusCategory,
  }, skip);

  return (
    <>
      <QuotesHeader>
        <StatusCategorySelect
          value={statusCategory}
          onChange={setCustomerStatusCategory}
        />

        <QuotesActions>
          <QuoteAction
            tooltip="Generate summary"
            tooltipPlacement="top"
            onClick={openCustomerSummary}
          >
            <AiTextIcon
              hasGradient
              size={18}
            />
          </QuoteAction>

          <QuoteAction
            tooltip="Copy all quotes"
            tooltipPlacement="top"
            onClick={() => {
              copyQuotesToClipboard(docs);
            }}
          >
            <DuplicateIcon size={16} />
          </QuoteAction>

          <QuoteAction
            tooltip="Export quotes"
            tooltipPlacement="top"
            onClick={openCustomerExportQuotes}
          >
            <DownloadIcon size={16} />
          </QuoteAction>

          <CustomerChildrenInsights customerId={customerId}>
            {buttonProps => (
              <QuoteAction {...buttonProps}>
                <AddIcon size={14} />
              </QuoteAction>
            )}
          </CustomerChildrenInsights>
        </QuotesActions>
      </QuotesHeader>

      <InfiniteScroll
        loadMore={() => {
          if (!pageInfo?.hasNextPage || !pageInfo?.endCursor) return;
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          loadMore(pageInfo.endCursor);
        }}
        hasMoreData={!!pageInfo?.hasNextPage}
        isLoading={isPaginationLoading}
      >
        <div className="@container">
          <div className="mt-0.5 flex flex-col gap-4 @4xl:grid @4xl:grid-cols-2">
            {docs.map(doc => (
              <QuoteCard
                key={doc.id}
                docId={doc.id}
                showFeature
              />
            ))}

            {(isLoading || isPaginationLoading) && (
              <Skeletons>
                <Skeleton height={16} />
                <Skeleton height={16} />
                <Skeleton height={16} />
                <Skeleton height={16} />
              </Skeletons>
            )}
          </div>
        </div>
      </InfiniteScroll>
    </>
  );
};
