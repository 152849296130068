import { Popover, PopoverProps } from '@cycle-app/ui';
import { PropsWithChildren, useRef } from 'react';
import { Instance } from 'tippy.js';

import { SourcePopoverContent } from './SourcePopoverContent';

type Props = Omit<PopoverProps, 'content'> & {
  docId: string;
};

export const SourcePopover = ({
  docId,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  const tippyInstance = useRef<Instance | null>(null);
  return (
    <Popover
      placement="top"
      withWrapper={false}
      interactive
      animation
      delay={[200, 0]}
      popperOptions={{
        strategy: 'fixed',
      }}
      onMount={(instance: Instance) => {
        tippyInstance.current = instance;
      }}
      {...props}
      content={(
        <SourcePopoverContent
          docId={docId}
          hide={() => tippyInstance.current?.hide()}
        />
      )}
    >
      {children}
    </Popover>
  );
};
