import { Emoji } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { ParentPopover } from 'src/components/ParentPopover/ParentPopover';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useDocBase } from 'src/hooks/api/useDoc';
import { useGetDocType } from 'src/reactives/docTypes.reactive';

import { QuoteFeatureDropdown } from './QuoteFeatureDropdown';
import { QuoteStatus } from './QuoteStatus';

export const QuoteFeature = ({ docId }: { docId: string }) => {
  const { doc } = useDocBase(docId);
  const featureDoctype = useGetDocType(doc?.parent?.doctype?.id);
  const mainBtnRef = useRef<HTMLButtonElement>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const changeDocParent = useChangeDocParent();
  if (!doc) return null;
  const statusId = doc.parent?.status?.id;
  return (
    <>
      <button
        ref={mainBtnRef}
        onClick={e => {
          setDropdownVisible(true);
          e.stopPropagation();
        }}
        className={twJoin(
          'btn-tertiary btn-sm mr-0.5 gap-2 overflow-hidden font-normal hover:bg-grey-100 dark:hover:bg-grey-800',
          dropdownVisible && 'btn-hover',
        )}
      >
        {doc.parent ? (
          <>
            <Emoji
              size={12}
              emoji={featureDoctype?.emoji}
            />
            {statusId && <QuoteStatus statusId={statusId} />}
            <span className="truncate">
              {doc.parent.title}
            </span>
          </>
        ) : (
          <>
            <AddIcon size={10} />
            <span className="truncate">
              Link feature
            </span>
          </>
        )}
      </button>

      {doc.parent && (
        <ParentPopover
          parentDocId={doc.parent.id}
          reference={mainBtnRef}
          offset={[0, 16]}
          onClickChange={() => setDropdownVisible(true)}
          onClickUnlink={() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            changeDocParent({
              docId,
              parentId: undefined,
            });
          }}
          withPortal
        />
      )}

      <QuoteFeatureDropdown
        docId={doc.id}
        reference={mainBtnRef}
        visible={dropdownVisible}
        hide={() => setDropdownVisible(false)}
      />
    </>
  );
};
