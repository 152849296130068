import { useFragment } from '@apollo/client';
import { BoardConfigDoctypeFilterFragmentDoc } from '@cycle-app/graphql-codegen';

export function useBoardConfigDoctypesFilterFragment(boardConfigId: string | null | undefined) {
  const {
    data, complete,
  } = useFragment({
    fragment: BoardConfigDoctypeFilterFragmentDoc,
    fragmentName: 'BoardConfigDoctypeFilter',
    from: boardConfigId ?? '',
  });
  return complete ? data : null;
}
