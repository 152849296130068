import {
  DocBaseFragment, SearchDocQuery, StatusBaseFragmentDoc, StatusCategory, StatusType, UpdateDocsStatusDocument,
} from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { refetchProfileDocsCount } from 'src/hooks/api/cache/cacheProfile';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { getStatus, getSearchStatusIds } from 'src/reactives/productStatus.reactive';
import { setBulkLoading } from 'src/reactives/selection.reactive';

import { getDocFromCache } from '../../utils/cache.utils';
import { parseStoreFieldName } from '../../utils/update-cache/cache.utils';

const onDone = () => setBulkLoading({ isLoading: false });

export const useUpdateDocsStatusMutation = () => {
  const [mutate, { loading }] = useSafeMutation(UpdateDocsStatusDocument, {
    onCompleted: () => {
      onDone();
      refetchProfileDocsCount();
    },
    onError: onDone,
  });
  const updateDocsStatus = (docIds: string[], statusId: string) => {
    const status = getStatus(statusId);
    if (!status) return null;
    const newStatus: DocBaseFragment['status'] = {
      id: statusId,
      type: status.type,
      category: status.category,
      value: status.value,
    };
    const currenStatuses = docIds.map(id => ({
      id,
      status: getDocFromCache(id)?.status,
    }));
    return mutate({
      variables: {
        docIds,
        statusId,
      },
      update: (cache) => {
        docIds.forEach(docId => {
          cache.modify({
            id: docId,
            fields: {
              status: () => cache.writeFragment({
                id: statusId,
                fragment: StatusBaseFragmentDoc,
                data: newStatus,
              }),
              // Non-verified quotes will be discarded if the status is processed
              quotes: (refs: any) => ({
                ...refs,
                edges: status.type === StatusType.Processed ? [] : refs.edges,
              }),
              quotesCount: (value) => (status.type === StatusType.Processed ? 0 : value),
            },
          });

          const currentStatus = currenStatuses.find(doc => doc.id === docId);
          // Remove the doc from searches with current status.
          if (currentStatus?.status) {
            const currentCategory = currentStatus.status.category;
            const statusIds = getSearchStatusIds({
              CANCELED: currentCategory === StatusCategory.Canceled,
              COMPLETED: currentCategory === StatusCategory.Completed,
              NOT_STARTED: currentCategory === StatusCategory.NotStarted,
              STARTED: currentCategory === StatusCategory.Started,
            });
            cache.modify<SearchDocQuery>({
              fields: {
                searchDoc: (current, {
                  readField, isReference, storeFieldName,
                }) => {
                  if (isReference(current)) {
                    return current;
                  }
                  const variables = parseStoreFieldName<{ statusIds: string[] }>(storeFieldName);
                  if (variables?.statusIds.some(searchStatusId => statusIds.includes(searchStatusId))) {
                    return produce(current, draft => {
                      if (draft?.edges) {
                        draft.edges = draft.edges.filter(edge => {
                          if (isReference(edge.node.doc)) {
                            return docId !== readField<string>('id', edge.node.doc);
                          }
                          return edge;
                        });
                      }
                    });
                  }
                  return current;
                },
              },
            });
          }
        });
      },
    });
  };

  return {
    loading,
    updateDocsStatus,
  };
};
