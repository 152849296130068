import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';

import {
  Container, Toggle, ToggleDot, StyledInput, LabelText, ToggleVariant,
} from './ToggleInput.styles';
import { Label } from '../CheckboxInput/CheckboxInput.styles';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  id: string;
  label?: ReactNode;
  checked?: boolean;
  togglePosition?: 'left' | 'right';
  variant?: ToggleVariant;
  size?: 'S' | 'M';
};

export const ToggleInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    style,
    label,
    togglePosition = 'right',
    variant = 'primary',
    size = 'M',
    ...inputProps
  } = props;

  const toggle = (
    <Toggle data-toggle>
      <ToggleDot />
    </Toggle>
  );

  return (
    <Container
      className={className}
      style={style}
      $isDisabled={!!inputProps.disabled}
      $variant={variant}
      $size={size}
    >
      <StyledInput
        ref={ref}
        type="checkbox"
        $variant={variant}
        {...inputProps}
      />
      <Label htmlFor={inputProps.id}>
        {togglePosition === 'left' && toggle}
        {label && (
          <LabelText data-label>
            {label}
          </LabelText>
        )}
        {togglePosition === 'right' && toggle}
      </Label>
    </Container>
  );
});
