import { UpdateProductOnboardingDocument } from '@cycle-app/graphql-codegen';
import { Input } from '@cycle-app/ui';
import { CycleStandardIcon, ReloadIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

import { useOptimizedBooleanState, useSafeMutation } from 'src/hooks';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import { integrations, others, sliderItems as initialSliderItems } from './integrations';
import {
  Aside,
  ButtonOther,
  InputContainer,
  IntegrationSliderContainer,
  IntegrationSliderList,
  Main,
  InteBubble,
  InteLabel,
  InteLabelBadge,
  InteList,
  InteListContainer,
  InteName,
  StyledArrowsAllSidesIcon,
  StyledCheckboxInput,
  StyledSelectPanel,
} from './OnboardingStepLinear.styles';
import { DropdownLayer } from '../../../DropdownLayer';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { BackButton, Footer, NextButton } from '../OnboardingLayout/OnboardingLayout.styles';

interface Props {
  productId: string;
  shouldBookCall: boolean;
}

export const OnboardingStepLinear = ({
  productId, shouldBookCall,
} : Props) => {
  const [selected, setSelected] = useState('');
  const [hovered, setHovered] = useState('linear');
  const [sliderItems, setSliderItems] = useState(initialSliderItems);
  const [selectableList, setSelectableList] = useState(integrations.slice(0, 5));
  const [dropdownRect, setDropdownRect] = useState<DOMRect | null>(null);
  const [isInputVisible, {
    setTrueCallback: inputDisplay,
    setFalseCallback: inputHide,
  }] = useOptimizedBooleanState(false);
  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const [isOthersOpen, {
    setTrueCallback: setOpenOthers, setFalseCallback: setCloseOthers,
  }] = useOptimizedBooleanState(false);
  const [updateIntegrations, { loading: isLoading }] = useSafeMutation(UpdateProductOnboardingDocument);

  // Scroll to bottom each time we update the length of the list.
  useEffect(() => {
    listContainerRef.current?.scrollTo(0, listContainerRef.current.scrollHeight);
  }, [selectableList.length]);

  const toggleValue = (value: string) => () => {
    setSelected(selected === value ? '' : value);
    setHovered(value);
  };

  const sliderOffsetIndex = sliderItems.findIndex(item => item.id === hovered);
  const sliderOffset =
    sliderOffsetIndex *
    88 + // size of each element
    27 * // spacing
    sliderOffsetIndex;
  const selectedItem = sliderItems.find(item => item.id === selected);
  const othersSelectable = others.filter(inte => !selectableList.find(({ id }) => id === inte.id));
  return (
    <OnboardingLayout
      headline={(
        <>
          <ReloadIcon className="hidden md:block" />
          Sync customer feedback with your issue tracker
        </>
      )}
      title="Where do you currently track issues?"
      aside={(
        <Aside>
          <div className="flex h-full items-center justify-center">
            <InteBubble>
              <CycleStandardIcon variant="primary" />
            </InteBubble>
            <div className="flex w-[88px] flex-none items-center justify-center">
              <ReloadIcon
                size={24}
                className="text-primary/60"
              />
            </div>
            <IntegrationSliderContainer>
              <IntegrationSliderList
                style={{
                  transform: `translate(0, -${sliderOffset}px)`,
                }}
              >
                {sliderItems.map((item, i) => (
                  <li
                    style={{
                      ...Math.abs(sliderOffsetIndex - i) === 1 ? { opacity: 0.5 } : {},
                      ...Math.abs(sliderOffsetIndex - i) === 2 ? { opacity: 0.25 } : {},
                      ...Math.abs(sliderOffsetIndex - i) > 2 ? { opacity: 0 } : {},
                      ...(selectedItem && selectedItem.id !== item.id) ? { opacity: 0 } : {},
                    }}
                    key={item.id}
                  >
                    <InteBubble>
                      {item.icon}
                    </InteBubble>
                  </li>
                ))}
              </IntegrationSliderList>
            </IntegrationSliderContainer>
          </div>
        </Aside>
      )}
      main={(
        <>
          <Main
            onSubmit={async e => {
              e.preventDefault();
              if (isLoading) return;
              if (selectedItem?.id) {
                await updateIntegrations({
                  variables: {
                    productId,
                    integrations: [selectedItem.id],
                  },
                });
                setOnboarding({ screen: shouldBookCall ? LightOnboardingScreen.BookCall : LightOnboardingScreen.Done });
              }
            }}
          >
            <InteListContainer ref={listContainerRef}>
              <InteList>
                {selectableList.map(inte => (
                  <li
                    onMouseEnter={() => {
                      if (!selectedItem) {
                        setHovered(inte.id);
                      }
                    }}
                    onFocus={() => {
                      if (!selectedItem) {
                        setHovered(inte.id);
                      }
                    }}
                    onKeyDown={e => {
                      if (e.code === 'Enter') {
                        // Prevent submit the form if we select an item with Enter.
                        e.preventDefault();
                        e.stopPropagation();
                        toggleValue(inte.id)();
                      }
                    }}
                    key={inte.id}
                  >
                    <StyledCheckboxInput
                      $isSelected={inte.id === selected}
                      checked={inte.id === selected}
                      id={inte.id}
                      onChange={toggleValue(inte.id)}
                      label={(
                        <InteLabel>
                          {inte.icon}
                          <InteName>
                            {inte.label}
                          </InteName>
                          <AnimatePresence>
                            {inte.id === 'linear' && inte.id === selected && (
                              <InteLabelBadge
                                initial={{ opacity: 0 }}
                                exit={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                              >
                                GREAT CHOICE
                              </InteLabelBadge>
                            )}
                          </AnimatePresence>
                        </InteLabel>
                      )}
                    />
                  </li>
                ))}
                {!isInputVisible && (
                  <li>
                    <DropdownLayer
                      visible={isOthersOpen}
                      hide={() => {
                        setCloseOthers();
                      }}
                      {...dropdownRect && {
                        getReferenceClientRect: () => dropdownRect,
                      }}
                      width={212}
                      placement="right"
                      popperOptions={{
                        modifiers: [{
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['top'],
                          },
                        }],
                      }}
                      content={(
                        <StyledSelectPanel
                          options={othersSelectable.map(inte => ({
                            label: inte.label,
                            icon: inte.icon,
                            value: inte.id,
                          }))}
                          onOptionChange={({ value }) => {
                            const inte = integrations.find(({ id }) => id === value);
                            if (inte) {
                              setSelectableList(currentList => [...currentList, inte]);
                              if (!sliderItems.some(sliderItem => sliderItem.id === value)) {
                                setSliderItems(items => ([...items, inte]));
                              }
                              toggleValue(value)();
                              setHovered(value);
                            }
                          }}
                          hideSearch
                          showCreateOption
                          defaultCreateOptionLabel="Other…"
                          onCreateOption={async () => {
                            inputDisplay();
                            setCloseOthers();
                          }}
                        />
                      )}
                    >
                      <ButtonOther
                        forceFocus={isOthersOpen}
                        full
                        variant="secondary"
                        onClick={(event) => {
                          if (othersSelectable.length) {
                            setDropdownRect(event.currentTarget.getBoundingClientRect());
                            setOpenOthers();
                          } else {
                            inputDisplay();
                          }
                        }}
                      >
                        Other...
                      </ButtonOther>
                    </DropdownLayer>
                  </li>
                )}
                {isInputVisible && (
                  <li>
                    <InputContainer>
                      <Input
                        placeholder="Tell us more..."
                        autoFocus
                        onKeyDown={e => {
                          if (e.code === 'Escape') {
                            inputHide();
                          }
                          if (e.code === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                            const v = e.currentTarget.value;
                            if (selectableList.some(s => s.id === v)) return;
                            const item = {
                              id: v,
                              label: v,
                              icon: <StyledArrowsAllSidesIcon />,
                            };
                            setSelectableList(currentList => [...currentList, item]);
                            setSliderItems(items => ([...items, item]));
                            setSelected(v);
                            setHovered(v);
                            inputHide();
                          }
                        }}
                        onBlur={inputHide}
                      />
                    </InputContainer>
                  </li>
                )}
              </InteList>
            </InteListContainer>
            <Footer style={{ marginTop: '32px' }}>
              <BackButton
                size="M"
                onClick={() => setOnboarding({ screen: LightOnboardingScreen.FlowSelection })}
              >
                Back
              </BackButton>

              <NextButton
                type="submit"
                size="M"
                isLoading={isLoading}
                disabled={!selectedItem}
              >
                Next
              </NextButton>
            </Footer>
          </Main>
        </>
      )}
    />
  );
};
