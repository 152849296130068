import { DoctypeType } from '@cycle-app/graphql-codegen';
import { Emoji, Icon } from '@cycle-app/ui';
import { Size } from '@cycle-app/ui/components/Emoji/Emoji.styles';
import { QuoteIcon } from '@cycle-app/ui/icons';
import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import styled from 'styled-components';

type Props = Pick<ComponentProps<'div'>, 'className' | 'style'> & {
  doctype?: { type: DoctypeType; emoji: string };
  size?: Size;
};

export const DocTypeIcon = forwardRef<HTMLDivElement, Props>(({
  doctype, size, style, ...props
}, ref) => {
  if (!doctype) return null;

  if (doctype.type === DoctypeType.Feedback) {
    return (
      <Icon
        name="feedback"
        ref={ref as ForwardedRef<SVGSVGElement>}
        style={{
          ...style,
          width: size,
          height: size,
        }}
        {...props}
      />
    );
  }

  if (doctype.type === DoctypeType.Insight) {
    return (
      <QuoteIcon
        ref={ref as ForwardedRef<SVGSVGElement>}
        {...props}
        style={style}
        size={size}
      />
    );
  }

  return (
    <Emoji
      ref={ref}
      {...props}
      emoji={doctype.emoji}
      style={style}
      size={size}
    />
  );
});

export const DocTypeIconInline = styled(DocTypeIcon)`
  display: inline-block;
  margin-top: -2px;
  vertical-align: middle;
`;
