import { ThemeType } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import {
  Container, PreviewMain, PreviewBar, StyledPreviewDark, StyledPreviewLight, Name,
} from './ThemePicker.styles';

interface Props {
  className?: string;
  onChange: (color: ThemeType) => void;
  theme: ThemeType;
  syncWithSystem: boolean;
}

export const ThemePicker: FC<React.PropsWithChildren<Props>> = ({
  className, onChange, theme, syncWithSystem,
}) => {
  return (
    <Container className={className}>
      <div>
        <StyledPreviewLight
          $isSelected={!syncWithSystem && theme === ThemeType.Sunrise}
          onClick={() => onChange(ThemeType.Sunrise)}
        >
          <PreviewMain>
            <PreviewBar />
          </PreviewMain>
        </StyledPreviewLight>
        <Name>
          Light
        </Name>
      </div>
      <div>
        <StyledPreviewDark
          $isSelected={!syncWithSystem && theme === ThemeType.Nightfall}
          onClick={() => onChange(ThemeType.Nightfall)}
        >
          <PreviewMain>
            <PreviewBar />
          </PreviewMain>
        </StyledPreviewDark>
        <Name>
          Dark
        </Name>
      </div>
    </Container>
  );
};
