import { Color } from '@cycle-app/graphql-codegen';
import { TagProps } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';

import { useProduct } from 'src/hooks';
import { useCompany } from 'src/hooks/api/queries/customers/useCompany';
import { formatCustomAttributeDate } from 'src/utils/date.utils';

type Item = {
  id: string;
  title: string;
  value: string | number;
  color: TagProps['color'];
};

export const useCustomAttributesItems = (companyId: string) => {
  const { product } = useProduct();
  const { company } = useCompany(companyId);
  return useMemo<Item[]>(() => {
    if (!company) return [];
    const attributes = nodeToArray(company.attributes);
    if (!company.customId && attributes.length === 0) return [];
    const customAttributesDefinitions = keyBy(nodeToArray(product?.companyAttributeDefinitions), 'id');
    const result: Item[] = [];

    if (company.customId) {
      result.push({
        id: 'customId',
        title: 'Custom ID',
        value: company.customId,
        color: 'greyAlt',
      });
    }

    for (const attribute of attributes) {
      const definition = customAttributesDefinitions[attribute.definition.id];
      if (!definition || !('id' in definition)) continue;

      if (attribute.value?.__typename === 'AttributeNumberValue') {
        result.push({
          id: attribute.id,
          title: definition.name,
          value: attribute.value.numberValue,
          color: definition.color as Color,
        });
      }

      if (attribute.value?.__typename === 'AttributeDateValue') {
        const value = formatCustomAttributeDate(attribute.value.dateValue);
        if (!value) continue;
        result.push({
          id: attribute.id,
          title: definition.name,
          value,
          color: definition.color as Color,
        });
      }

      if (attribute.value?.__typename === 'AttributeCheckboxValue' && !!attribute.value.checkboxValue) {
        result.push({
          id: attribute.id,
          title: definition.name,
          value: definition.name,
          color: definition.color as Color,
        });
      }

      if (attribute.value?.__typename === 'AttributeTextValue') {
        const values = definition.__typename === 'AttributeSingleSelectDefinition'
          ? attribute.value.textValue.split(',')
          : [attribute.value.textValue];

        for (const [index, value] of values.entries()) {
          result.push({
            id: `${attribute.id}-${index}`,
            title: definition.name,
            value,
            color: definition.color as Color,
          });
        }
      }
    }

    return result;
  }, [company, product?.companyAttributeDefinitions]);
};
