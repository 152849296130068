import { ActionButton } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import { useLayoutEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { Description, DescriptionContainer } from './DocLinear.styles';

const markdownImageRegex = /!\[.*\]\(.*\)/gm;

export const IssueDescription = ({ value }: { value: string | null | undefined }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandable, setIsClamped] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const description = value?.replace(markdownImageRegex, '').trim();

  useLayoutEffect(() => {
    if (!ref.current) return;
    setIsClamped(ref.current.scrollHeight > ref.current.clientHeight);
  }, [description]);

  if (!description) return null;

  return (
    <DescriptionContainer ref={ref}>
      {isExpandable && (
        <ActionButton
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ marginTop: 2 }}
        >
          <CaretIcon
            size={11}
            direction={isExpanded ? 'bottom' : 'right'}
          />
        </ActionButton>
      )}

      <Description $isExpanded={isExpanded}>
        <ReactMarkdown>
          {description}
        </ReactMarkdown>
      </Description>
    </DescriptionContainer>
  );
};
