import type { LucideProps, LucideIcon as LucideIconType } from 'lucide-react';
import dynamicIconImports from 'lucide-react/dynamicIconImports';
import React, { lazy, Suspense, LazyExoticComponent, forwardRef } from 'react';

import type { LucideIconName } from '../../types/icon.types';

interface IconProps extends Omit<LucideProps, 'ref'> {
  name: LucideIconName;
}

export const IconNames = Object.keys(dynamicIconImports) as LucideIconName[];

type LazyIconMap = Record<LucideIconName, LazyExoticComponent<LucideIconType>>;

const lazyIconMap = Object.entries(dynamicIconImports).reduce<LazyIconMap>((previous, current) => {
  return {
    ...previous,
    [current[0] as LucideIconName]: lazy(current[1]),
  };
}, {} as LazyIconMap);

export const LucideIcon = forwardRef<SVGSVGElement, IconProps>(({
  name,
  strokeWidth = 2,
  ...props
}, ref) => {
  const LucideIconComponent = lazyIconMap[name];

  return (
    <Suspense fallback={<div className="size-4 bg-secondary" />}>
      <LucideIconComponent
        strokeWidth={strokeWidth}
        {...props}
        ref={ref}
      />
    </Suspense>
  );
});
