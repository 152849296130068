import { IntegrationType } from '@cycle-app/graphql-codegen';
import { ActionButton } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import { IntegrationItem } from 'src/app/Main/Settings/SettingsIntegrations/IntegrationItem/IntegrationItem';
import { ACTIVE_INTEGRATIONS } from 'src/constants/integrations.constants';
import { useProductIntegrations } from 'src/hooks';
import { closeSourcesModal, useGetIntegrations } from 'src/reactives/integrations.reactive';
import { openIntercom } from 'src/utils/intercom.utils';

import {
  StyledPortalModal, ModalHeader, ModalTitle, ModalIntegrationsGrid, StyledButton, ModalFooter,
} from './SourcesModal.styles';

export const SourcesModal = () => {
  const { sourcesByStatus } = useProductIntegrations();
  const { isSourcesModalVisible } = useGetIntegrations();

  const uninstalledSources = useMemo(() => sortBy(
    sourcesByStatus.uninstalled.filter(item => item.integrationType !== IntegrationType.Slack),
    i => !ACTIVE_INTEGRATIONS.includes(i.integrationType),
  ), [sourcesByStatus.uninstalled]);

  if (!isSourcesModalVisible) return null;

  return (
    <StyledPortalModal hide={closeSourcesModal}>
      <ModalHeader>
        <ModalTitle>
          Add sources
        </ModalTitle>
        <ActionButton onClick={closeSourcesModal}>
          <CloseIcon />
        </ActionButton>
      </ModalHeader>
      <ModalIntegrationsGrid>
        {uninstalledSources.map((item) => (
          <IntegrationItem
            key={item.integrationType}
            integration={item.integration}
            integrationType={item.integrationType}
            viewType="block"
          />
        ))}
      </ModalIntegrationsGrid>
      <ModalFooter>
        Need more sources?
        {' '}
        <StyledButton
          variant="secondary"
          size="M"
          id="contact-sales"
          onClick={() => openIntercom()}
        >
          Let us know
        </StyledButton>
      </ModalFooter>
    </StyledPortalModal>
  );
};
