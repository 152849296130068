import { CreateCompanyMutation, CompanyLogoInput } from '@cycle-app/graphql-codegen';
import { Button, CompanyLogo } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { emailRegex, ERROR_CODE, isUrl } from '@cycle-app/utilities';
import { FC, useCallback, useEffect, useState } from 'react';
import type { UseFormWatch, UseFormSetValue } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

import { Header, Title, CloseButtonStyled, Actions } from 'src/components/DialogModal/DialogModal.styles';
import { ImageInput } from 'src/components/ImageInput';
import { useFetchCompanyLogo } from 'src/hooks';
import { useCompanyCreate } from 'src/hooks/api/mutations/customers/useCompanyCreate';
import type { CreateCompanyParams } from 'src/hooks/api/mutations/customers/useCompanyCreate';
import { ErrorMap, useEnhancedForm } from 'src/hooks/form/useEnhancedForm';
import { usePrevious } from 'src/hooks/usePrevious';
import { Layer } from 'src/types/layers.types';

import {
  Form, FormInput,
  InputContent,
  UploadNewBtn,
  RemoveBtn,
  CustomerModal,
} from './Customers.styles';

type FormData = CreateCompanyParams;

interface Props {
  onClose: () => void;
  layer?: Layer;
  onCreated?: (data: CreateCompanyMutation) => void;
  defaultValues?: {
    name: FormData['name'];
  };
  productId: string | undefined;
}

const emailValidationOptions = {
  pattern: {
    value: emailRegex,
    message: 'Email format is incorrect',
  },
};

const mutationErrorsMap: ErrorMap<FormData>[] = [
  {
    fieldName: 'name',
    code: ERROR_CODE.COMPANY_ALREADY_EXISTS,
    renderMessage: () => 'A company with this name already exists',
  },
];

export const CompanyAddModal: FC<React.PropsWithChildren<Props>> = ({
  onClose,
  layer = Layer.Modal,
  onCreated,
  defaultValues,
  productId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    displayFieldsErrors,
    watch,
    setValue,
    trigger,
  } = useEnhancedForm<FormData>({
    defaultValues: {
      name: defaultValues?.name || '',
      domain: '',
      customerName: 'Main contact',
      customerEmail: '',
    },
  });
  const {
    createCompany, isLoading,
  } = useCompanyCreate(productId);

  const onSubmit = useCallback(async (data: FormData) => {
    if (isLoading) return;

    const result = await createCompany(data);
    if (result?.errors) {
      displayFieldsErrors(result.errors, mutationErrorsMap, true);
      return;
    }
    if (result?.data) {
      onCreated?.(result.data);
    }
    onClose();
  }, [createCompany, displayFieldsErrors, isLoading, onClose, onCreated]);

  return (
    <CustomerModal
      hide={onClose}
      layer={layer}
    >
      <Header>
        <Title>
          Add new company
        </Title>
        <CloseButtonStyled onClick={onClose}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>

      <Form onSubmit={e => {
        e.stopPropagation();
        return handleSubmit(onSubmit)(e);
      }}
      >
        <FormInput
          autoFocus
          type="text"
          label="Name"
          placeholder="Company name"
          error={errors.name?.message}
          {...register('name', {
            required: 'You must have at least 3 characters.',
            min: 3,
          })}
        />
        <FormInput
          type="text"
          placeholder="https://www.figma.com/"
          label="Domain URL"
          error={errors.domain?.message}
          helper="Adding a domain URL will automatically fetch the company logo"
          helperSize="S"
          {...register('domain', {
            setValueAs: value => value.trim(),
            validate: value => !value || isUrl(value, { strict: false }) || 'URL format is incorrect',
            onChange: () => trigger('domain'),
          })}
        />
        <FormInput
          type="text"
          label="Main contact name"
          error={errors.customerName?.message}
          {...register('customerName', {
            required: 'You must provide a name',
          })}
        />
        <FormInput
          type="email"
          label="Main contact email"
          error={errors.customerEmail?.message}
          {...register('customerEmail', emailValidationOptions)}
        />
        <LogoField
          watch={watch}
          setValue={setValue}
        />
        <Actions>
          <Button
            size="M"
            type="button"
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="M"
            type="submit"
            isLoading={isLoading}
            disabled={!!Object.keys(errors).length}
          >
            Create
          </Button>
        </Actions>
      </Form>
    </CustomerModal>
  );
};

interface LogoFieldProps {
  watch: UseFormWatch<FormData>;
  setValue: UseFormSetValue<FormData>;
}

const LogoField = ({
  watch, setValue,
}: LogoFieldProps) => {
  const [hasManuallyUpload, setHasManuallyUpload] = useState(false);
  const [companyAvatar, setCompanyAvatar] = useState<FormData['logo']>(null);
  const name = watch('name');
  const domain = watch('domain');
  const {
    fetch, isLoading,
  } = useFetchCompanyLogo();

  const retrieveLogo = useDebouncedCallback((data: CompanyLogoInput) => {
    fetch(data)
      .then((response) => {
        if (response?.companyLogo) {
          setCompanyAvatar(response.companyLogo);
        }
      })
      .catch(console.warn);
  }, 800);

  const previousDomain = usePrevious(domain);
  useEffect(() => {
    if (hasManuallyUpload) return;

    // We don't fetch the logo based on the name if the domain is set
    // And we don't refetch the logo if the domain hasn't changed
    if (domain && domain === previousDomain) return;

    if (domain) {
      retrieveLogo({ domain });
    } else if (name) {
      retrieveLogo({ name });
    } else if (!name.length && !domain?.length) {
      setCompanyAvatar(null);
    }
    /**
     * We don't want to watch for `hasManuallyUpload` since we do not want
     * to update the file state based on naming when user upload a file
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, domain]);

  useEffect(() => {
    setValue('logo', companyAvatar);
  }, [companyAvatar, setValue]);

  return (
    <ImageInput
      label="Logo"
      previewModalTitle="New company logo"
      previewModalSubmitLabel="Set new company logo"
      onChange={(file) => {
        setHasManuallyUpload(true);
        setCompanyAvatar(file);
      }}
    >
      {(inputRef) => (
        <InputContent>
          <CompanyLogo
            isEditable
            isLoading={isLoading}
            company={name.length
              ? {
                name,
                logo: '',
              }
              : undefined}
            src={companyAvatar instanceof File
              ? window.URL.createObjectURL(companyAvatar)
              : companyAvatar ?? ''}
            onClick={() => inputRef.current?.click()}
            size="S-M"
          />
          <UploadNewBtn onClick={() => inputRef.current?.click()}>
            Upload new
          </UploadNewBtn>
          {!!companyAvatar && (
            <RemoveBtn onClick={() => {
              setCompanyAvatar(null);
              setHasManuallyUpload(false);
            }}
            >
              Remove
            </RemoveBtn>
          )}
        </InputContent>
      )}
    </ImageInput>
  );
};
