import { AutopilotState, DocQuotesCountDocument, DoctypeType, FindInsightState } from '@cycle-app/graphql-codegen';
import { delay } from '@cycle-app/utilities/src/utils/async.utils';
import { useRef } from 'react';

import { useDocDoctypeFragment } from 'src/hooks/api/fragments/doctype';
import { useAutopilotStateSubscription } from 'src/hooks/api/useAutopilotStateSubscription';
import { useFindInsightStateSubscription } from 'src/hooks/api/useFindInsightStateSubscription';
import client from 'src/services/apollo/client';

/**
 * These 2 subscriptions are used to update the state of the quote extraction of each feedback.
 * A loader is displayed in the doc item when the state is not null.
 *
 * Extraction can be triggered by creating a doc with autopilot enabled (autopilotState)
 * or by clicking on “Extract quotes” in the doc panel (findInsightState).
 *
 * The state returned by the back-end is sometimes briefly null in case of error
 * To avoid loader flashing, we wait 1 second to be sure we've received the final null
 *
 * When we receive the last null, we refetch the extracted quotes count before updating cache and hide the loader
 */
export const useDocStateSubscriptions = (docId: string) => {
  const doctype = useDocDoctypeFragment(docId);
  const skip = doctype?.type !== DoctypeType.Feedback;
  const state = useRef<AutopilotState | FindInsightState | null>(null);

  useFindInsightStateSubscription(docId, {
    skip,
    ignoreResults: true,
    onData: async ({
      client: { cache },
      data: { data },
    }) => {
      if (data?.findInsightState === undefined) return;
      state.current = data.findInsightState;

      if (data.findInsightState === null) {
        await delay(1000);
        if (state.current !== null) return;
        await fetchDocQuotesCount(docId);
      }

      cache.modify({
        id: docId,
        fields: {
          findInsightState: () => data.findInsightState,
        },
      });
    },
  });

  useAutopilotStateSubscription(docId, {
    skip,
    ignoreResults: true,
    onData: async ({
      client: { cache },
      data: { data },
    }) => {
      if (data?.autopilotState === undefined) return;
      state.current = data.autopilotState;

      if (data.autopilotState === null) {
        await delay(1000);
        if (state.current !== null) return;
        await fetchDocQuotesCount(docId);
      }

      cache.modify({
        id: docId,
        fields: {
          autopilotState: () => data.autopilotState,
        },
      });
    },
  });
};

const fetchDocQuotesCount = (docId: string) => client.query({
  fetchPolicy: 'network-only',
  query: DocQuotesCountDocument,
  variables: { id: docId },
});
