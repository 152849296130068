import { MateFragment } from '@cycle-app/graphql-codegen';

import { useBoard } from 'src/hooks/api/useBoard';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useDoc } from 'src/hooks/api/useDoc';

import { EmojiStyled } from './RealtimeUsers.styles';

export const UserLocation = ({ user }: { user: MateFragment }) => {
  const { sections } = useBoardSections();
  const board = useBoard(user._boardId, !user._boardId);
  const { doc } = useDoc(user._docId, !user._docId);

  if (doc) return (
    <>
      {doc.title}
    </>
  );

  const showEmoji = sections.flatMap(section => section.boards.edges).some(edge => edge.node.id === board?.id);

  if (board) {
    return (
      <>
        {showEmoji && board.emoji && <EmojiStyled emoji={board.emoji} />}
        {board.name}
      </>
    );
  }

  return (
    <>
      Home
    </>
  );
};
