import { DocBaseFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useState, ReactNode, FC } from 'react';

import { InsightDocSearchDropdown } from 'src/components/DocSearchDropdown/InsightDocSearchDropdown';
import { FeedbackQuoteCreateModal, InsightCreateModal } from 'src/components/InsightCreateModal';
import { InsightsSearch } from 'src/components/InsightsSearch';
import { useDocTypeInsight, useOptimizedBooleanState, useDocV2 } from 'src/hooks';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocTypeNames } from 'src/utils/docType.util';

export const OpenInsightCreateModal: FC<React.PropsWithChildren<{
  layer?: Layer;
  doc: DocBaseFragment;
  button: (props: {
    onClick: VoidFunction;
    isOpen: boolean;
  }) => ReactNode;
}>> = ({
  layer = Layer.DropdownModalZ2,
  button,
  doc,
}) => {
  const [isOpen, actions] = useOptimizedBooleanState(false);
  const docType = useGetDocType(doc.doctype.id);

  if (docType?.type === DoctypeType.Feedback) {
    return (
      <OpenFeedbackQuoteCreateModal
        layer={layer}
        feedbackDoc={doc}
        button={button}
      />
    );
  }

  return (
    <InsightsSearch
      parentId={doc.id}
      visible={isOpen}
      hide={actions.setFalseCallback}
      layer={layer}
      createModal={modalProps => (
        <InsightCreateModal
          hide={modalProps.hide}
          hideParentDoc
          isOpen={modalProps.visible}
          parentDoc={doc}
          dropdownProps={{
            placement: 'bottom-start',
            layer,
          }}
        >
          {button({
            onClick: actions.setTrueCallback,
            isOpen: isOpen || modalProps.visible,
          })}
        </InsightCreateModal>
      )}
    />
  );
};

const OpenFeedbackQuoteCreateModal: FC<React.PropsWithChildren<{
  layer?: Layer;
  feedbackDoc: DocBaseFragment;
  button: (props: {
    onClick: VoidFunction;
    isOpen: boolean;
  }) => ReactNode;
}>> = ({
  layer = Layer.DropdownModalZ2,
  button,
  feedbackDoc,
}) => {
  const [isOpen, actions] = useOptimizedBooleanState(false);

  const [insightDocParentId, setInsightDocParentId] = useState('');

  const {
    doc, isLoading: isParentLoading,
  } = useDocV2(insightDocParentId);

  const { insight } = useDocTypeInsight();
  const possibleDocTypes = nodeToArray(insight?.parents);

  return (
    <FeedbackQuoteCreateModal
      isOpen={!!doc?.id}
      isLoading={isParentLoading}
      hide={() => setInsightDocParentId('')}
      parentDocId={doc?.id}
      feedbackDoc={feedbackDoc}
      defaultContent={feedbackDoc.title}
      dropdownProps={{
        placement: 'bottom-start',
        layer,
      }}
    >
      <InsightDocSearchDropdown
        productAreaIds={undefined}
        hideSearchDropdown={actions.setFalseCallback}
        isSearchDropdownVisible={isOpen}
        onAdd={(docId) => {
          setInsightDocParentId(docId);
          actions.setFalseCallback();
        }}
        childDoctypeId={insight?.id}
        possibleDoctypes={possibleDocTypes}
        placeholder={getDocTypeNames(possibleDocTypes, {
          prefix: 'Link to',
          suffix: '',
        })}
        layer={Layer.DropdownZ1}
      >
        {button({
          onClick: actions.setTrueCallback,
          isOpen,
        })}
      </InsightDocSearchDropdown>
    </FeedbackQuoteCreateModal>
  );
};
