import { AiState } from '@cycle-app/graphql-codegen';
import { Tag, Tooltip } from '@cycle-app/ui';
import { AiIcon, CheckIcon } from '@cycle-app/ui/icons';

import { AiStatePopover } from 'src/components/AiStatePopover';
import { useDocBase } from 'src/hooks/api/useDoc';

type Props = {
  docId: string;
  readOnly?: boolean;
};

export const QuoteAiState = ({
  docId, readOnly = false,
}: Props) => {
  const { doc } = useDocBase(docId);

  if (doc?.aiState === AiState.UserValidated) {
    return (
      <Tooltip
        content="AI-generated · user-verified"
        placement="top"
        withWrapper={false}
        withPortal
      >
        <Tag color="grey">
          <div className="flex items-center gap-1 text-secondary">
            <AiIcon size={14} />
            <CheckIcon size={11} />
          </div>
        </Tag>
      </Tooltip>
    );
  }

  if (doc?.aiState === AiState.AiCreated) {
    return (
      <AiStatePopover
        docId={docId}
        disabled={readOnly}
      >
        <Tag
          color="grey"
          className="flex items-center gap-1 text-secondary"
        >
          <div className="flex items-center gap-1 text-secondary">
            <AiIcon size={14} />
          </div>
        </Tag>
      </AiStatePopover>
    );
  }

  return null;
};
