import type { Editor } from '@tiptap/core';
import { useEditor, EditorContent, Content } from '@tiptap/react';
import React, { FC, useEffect, ReactNode, ComponentProps } from 'react';
import { Doc } from 'yjs';

import { EditorContextProvider, EditorFeature } from 'src/contexts/editorContext';
import { displayExtensions } from 'src/editorExtensions/editorExtensions';
import { useProduct } from 'src/hooks';
import { EditorPreview } from 'src/styles';

import { ReadOnlyEditorContainer, ReadOnlyEditorContainer2 } from './Editor.styles';

const disabledFeatures: EditorFeature[] = [
  'image-resizable',
  'cover',
  'transcript',
];

type Props = ComponentProps<typeof ReadOnlyEditorContainer> & {
  key?: string;
  content?: Content;
  interactive?: boolean;
  onEditorReady?: (e: Editor) => void;
  collaboration?: {
    document: Doc;
  };
  fallback?: ReactNode;
  mode?: 'default' | 'preview';
  hasWiderWidth?: boolean;
};

export const ReadOnlyEditor: FC<React.PropsWithChildren<Props>> = ({
  content,
  interactive = true,
  collaboration,
  onEditorReady,
  fallback = null,
  mode = 'default',
  hasWiderWidth = false,
  ...otherProps
}) => {
  const editor = useEditor({
    immediatelyRender: true,
    extensions: displayExtensions({
      ...(collaboration ? { collaboration } : {}),
    }),
    content,
    editable: false,
    autofocus: false,
  }, [collaboration?.document.clientID]);

  useEffect(() => {
    if (!content || !editor) return;
    queueMicrotask(() => {
      editor.chain().setContent(content).run();
    });
  }, [content, editor]);

  useEffect(() => {
    if (editor && onEditorReady) {
      onEditorReady(editor);
    }
  }, [editor, onEditorReady]);

  const { product } = useProduct();

  if (!editor) {
    return (
      <>
        {fallback}
      </>
    );
  }

  if (mode === 'preview') {
    return (
      <EditorContextProvider
        editor={editor}
        isReadOnly
        nbAiQueries={product?.nbAiQueries}
        disabledFeatures={disabledFeatures}
      >
        <EditorPreview>
          <EditorContent editor={editor} />
        </EditorPreview>
      </EditorContextProvider>
    );
  }

  /**
   * This is a dirty overwrite
   * It was the easiest solution to avoid complete refactor of the current
   * implementation
   */
  const ContainerEditorComponent = hasWiderWidth ? ReadOnlyEditorContainer2 : ReadOnlyEditorContainer;

  return (
    <EditorContextProvider
      editor={editor}
      isReadOnly
      nbAiQueries={product?.nbAiQueries}
      disabledFeatures={disabledFeatures}
    >
      <ContainerEditorComponent
        interactive={interactive}
        {...otherProps}
      >
        <EditorContent editor={editor} />
      </ContainerEditorComponent>
    </EditorContextProvider>
  );
};
