import { IntegrationType } from '@cycle-app/graphql-codegen';
import { ActionButton, Spinner, Input } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { isDomain } from '@cycle-app/utilities';
import { useForm } from 'react-hook-form';

import { closeSubdomainModal, useGetSubdomain } from 'src/reactives/subdomain.reactive';

import { Modal, Title, Form } from './SubdomainModal.styles';

export const SubdomainModal = () => {
  const { isModalOpen } = useGetSubdomain();
  if (!isModalOpen) return null;
  return <FinalizeModalOpen />;
};

const FinalizeModalOpen = () => {
  const {
    install, type,
  } = useGetSubdomain();

  const {
    formState,
    handleSubmit,
    register,
    setFocus,
  } = useForm({
    defaultValues: {
      baseUrl: '',
    },
  });

  if (!install) return null;

  return (
    <Modal
      hide={closeSubdomainModal}
    >
      <header>
        <Title>
          Add
          {' '}
          { type === IntegrationType.Zendesk ? 'Zendesk' : 'Salesforce' }
          {' '}
          integration
        </Title>
        <ActionButton onClick={closeSubdomainModal}>
          <CloseIcon />
        </ActionButton>
      </header>

      <p>
        Enter your sub-domain to start the installation.
      </p>

      <Form
        onSubmit={handleSubmit(async (values) => {
          if (formState.isSubmitting) return;
          window.subdomain = values.baseUrl;
          install();
          closeSubdomainModal();
        })}
        onReset={() => {
          setFocus('baseUrl');
        }}
      >
        <Input
          label="Sub-domain"
          prefix="https://"
          suffix={`.${type === IntegrationType.Zendesk ? 'zendesk.com' : 'my.salesforce.com'}/...`}
          placeholder="123-abc"
          autoFocus
          error={formState.errors.baseUrl?.message}
          {...register('baseUrl', {
            validate: (value) => {
              if (!value.trim()) return 'Required field';
              if (!isDomain(`${value}.${type === IntegrationType.Zendesk ? 'zendesk.com' : 'my.salesforce.com'}`)) return 'Invalid sub-domain';
              return true;
            },
          })}
        />

        <footer>
          <button
            type="reset"
            className="btn-tertiary"
            disabled={formState.isSubmitting || !formState.isDirty}
          >
            Cancel
          </button>
          <button
            className="btn-primary"
            disabled={!formState.isDirty}
          >
            {formState.isSubmitting && <Spinner />}
            Submit
          </button>
        </footer>
      </Form>
    </Modal>
  );
};
