import { Mark } from '@tiptap/core'
import { SPEAKER_MARK_EXTENSION_NAME, HIGHLIGHT_DATA_TYPE, HIGHLIGHT_TAG_NAME } from '../constants'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    speakerMarkExtension: {
      setSpeakerMark: () => ReturnType
    }
  }
}

export const speakerMarkExtension = Mark.create({
  name: SPEAKER_MARK_EXTENSION_NAME,
  inclusive: false,
  exitable: true,
  addAttributes() {
    return {
      [HIGHLIGHT_DATA_TYPE]: {
        default: 'speaker',
      },
    };
  },
  renderHTML(props) {
    return [HIGHLIGHT_TAG_NAME, props.HTMLAttributes, 0];
  },
  parseHTML() {
    return [{ tag: HIGHLIGHT_TAG_NAME }];
  },
  addCommands() {
    return {
      setSpeakerMark: () => (props) => {
        return props.commands.setMark(this.name, {
          [HIGHLIGHT_DATA_TYPE]: 'speaker',
        });
      },
    };
  },

  // Debug: uncomment to add speaker marks manually

  // addKeyboardShortcuts() {
  //   return {
  //     'Mod-s': () => this.editor.commands.setSpeakerMark(),
  //   }
  // },
});
