/**
 * This file is generated by running `yarn workspace @cycle-app/ui build:icons`
 */
import brandAirtableIcon from './icons/brand.airtable.icon.svg?no-inline';
import brandAttioIcon from './icons/brand.attio.icon.svg?no-inline';
import brandChromeIcon from './icons/brand.chrome.icon.svg?no-inline';
import brandFigmaIcon from './icons/brand.figma.icon.svg?no-inline';
import brandGithubIcon from './icons/brand.github.icon.svg?no-inline';
import brandGoogleDocIcon from './icons/brand.google-doc.icon.svg?no-inline';
import brandGoogleFormIcon from './icons/brand.google-form.icon.svg?no-inline';
import brandGoogleMeetIcon from './icons/brand.google-meet.icon.svg?no-inline';
import brandGoogleSheetIcon from './icons/brand.google-sheet.icon.svg?no-inline';
import brandGoogleIcon from './icons/brand.google.icon.svg?no-inline';
import brandHotjarIcon from './icons/brand.hotjar.icon.svg?no-inline';
import brandHubspotIcon from './icons/brand.hubspot.icon.svg?no-inline';
import brandIntercomIcon from './icons/brand.intercom.icon.svg?no-inline';
import brandNotionIcon from './icons/brand.notion.icon.svg?no-inline';
import brandSlackIcon from './icons/brand.slack.icon.svg?no-inline';
import brandZapierIcon from './icons/brand.zapier.icon.svg?no-inline';
import colorPlaygroundIcon from './icons/color.playground.icon.svg?no-inline';
import addUserIcon from './icons/add-user.icon.svg?no-inline';
import arrowCornerDownRightIcon from './icons/arrow-corner-down-right.icon.svg?no-inline';
import arrowRightUpIcon from './icons/arrow-right-up.icon.svg?no-inline';
import bellActiveIcon from './icons/bell-active.icon.svg?no-inline';
import bellIcon from './icons/bell.icon.svg?no-inline';
import cameraPlusFillIcon from './icons/camera-plus-fill.icon.svg?no-inline';
import chevronLeftIcon from './icons/chevron-left.icon.svg?no-inline';
import closeIcon from './icons/close.icon.svg?no-inline';
import colorsFillIcon from './icons/colors-fill.icon.svg?no-inline';
import compassIcon from './icons/compass.icon.svg?no-inline';
import copyIcon from './icons/copy.icon.svg?no-inline';
import deleteFillIcon from './icons/delete-fill.icon.svg?no-inline';
import desktopIcon from './icons/desktop.icon.svg?no-inline';
import editFillIcon from './icons/edit-fill.icon.svg?no-inline';
import editIcon from './icons/edit.icon.svg?no-inline';
import expandIcon from './icons/expand.icon.svg?no-inline';
import featureIcon from './icons/feature.icon.svg?no-inline';
import feedbackIcon from './icons/feedback.icon.svg?no-inline';
import homeIcon from './icons/home.icon.svg?no-inline';
import imageIcon from './icons/image.icon.svg?no-inline';
import infoCircleIcon from './icons/info-circle.icon.svg?no-inline';
import languageFillIcon from './icons/language-fill.icon.svg?no-inline';
import loadingIcon from './icons/loading.icon.svg?no-inline';
import logoutIcon from './icons/logout.icon.svg?no-inline';
import paperclipIcon from './icons/paperclip.icon.svg?no-inline';
import penFillIcon from './icons/pen-fill.icon.svg?no-inline';
import phoneIcon from './icons/phone.icon.svg?no-inline';
import playFillIcon from './icons/play-fill.icon.svg?no-inline';
import plusIcon from './icons/plus.icon.svg?no-inline';
import releaseIcon from './icons/release.icon.svg?no-inline';
import searchIcon from './icons/search.icon.svg?no-inline';
import settingsFillIcon from './icons/settings-fill.icon.svg?no-inline';
import settingsIcon from './icons/settings.icon.svg?no-inline';
import starFillIcon from './icons/star-fill.icon.svg?no-inline';
import starIcon from './icons/star.icon.svg?no-inline';
import tableFillIcon from './icons/table-fill.icon.svg?no-inline';
import tabletIcon from './icons/tablet.icon.svg?no-inline';
import ticketIcon from './icons/ticket.icon.svg?no-inline';
import trashIcon from './icons/trash.icon.svg?no-inline';
import triShapesIcon from './icons/tri-shapes.icon.svg?no-inline';
import typeIcon from './icons/type.icon.svg?no-inline';
import unlinkIcon from './icons/unlink.icon.svg?no-inline';
import userEditIcon from './icons/user-edit.icon.svg?no-inline';
import userIcon from './icons/user.icon.svg?no-inline';

export const allIconsMap = {
  'brand/airtable': brandAirtableIcon,
  'brand/attio': brandAttioIcon,
  'brand/chrome': brandChromeIcon,
  'brand/figma': brandFigmaIcon,
  'brand/github': brandGithubIcon,
  'brand/google-doc': brandGoogleDocIcon,
  'brand/google-form': brandGoogleFormIcon,
  'brand/google-meet': brandGoogleMeetIcon,
  'brand/google-sheet': brandGoogleSheetIcon,
  'brand/google': brandGoogleIcon,
  'brand/hotjar': brandHotjarIcon,
  'brand/hubspot': brandHubspotIcon,
  'brand/intercom': brandIntercomIcon,
  'brand/notion': brandNotionIcon,
  'brand/slack': brandSlackIcon,
  'brand/zapier': brandZapierIcon,
  'color/playground': colorPlaygroundIcon,
  'add-user': addUserIcon,
  'arrow-corner-down-right': arrowCornerDownRightIcon,
  'arrow-right-up': arrowRightUpIcon,
  'bell-active': bellActiveIcon,
  'bell': bellIcon,
  'camera-plus-fill': cameraPlusFillIcon,
  'chevron-left': chevronLeftIcon,
  'close': closeIcon,
  'colors-fill': colorsFillIcon,
  'compass': compassIcon,
  'copy': copyIcon,
  'delete-fill': deleteFillIcon,
  'desktop': desktopIcon,
  'edit-fill': editFillIcon,
  'edit': editIcon,
  'expand': expandIcon,
  'feature': featureIcon,
  'feedback': feedbackIcon,
  'home': homeIcon,
  'image': imageIcon,
  'info-circle': infoCircleIcon,
  'language-fill': languageFillIcon,
  'loading': loadingIcon,
  'logout': logoutIcon,
  'paperclip': paperclipIcon,
  'pen-fill': penFillIcon,
  'phone': phoneIcon,
  'play-fill': playFillIcon,
  'plus': plusIcon,
  'release': releaseIcon,
  'search': searchIcon,
  'settings-fill': settingsFillIcon,
  'settings': settingsIcon,
  'star-fill': starFillIcon,
  'star': starIcon,
  'table-fill': tableFillIcon,
  'tablet': tabletIcon,
  'ticket': ticketIcon,
  'trash': trashIcon,
  'tri-shapes': triShapesIcon,
  'type': typeIcon,
  'unlink': unlinkIcon,
  'user-edit': userEditIcon,
  'user': userIcon,
} as const;
