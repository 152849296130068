import { truncate, textGradient, TEXT_GRADIENT_COLORS } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { Color } from '@cycle-app/ui/theme/baseColors';
import { darken } from 'polished';
import styled from 'styled-components';

export const Quote = styled.div`
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  .ProseMirror {
    padding: 0 !important;
    p {
      line-height: 24px;
    }
  }
`;

export const Content = styled.div`
  padding-left: 12px;
  border-left: 2px solid ${p => p.theme.colors.border.primary};
  word-break: break-word;
  &, * {
    cursor: pointer !important;
  }
  :hover {
    background: ${p => (p.theme.isDark ? darken(0.02, Color.Grey800) : Color.Grey100)};
  }
`;

export const ParentText = styled.div<{ $gradient?: boolean }>`
  ${truncate}
  ${p => p.$gradient && textGradient}
`;

export const StyledAddIcon = styled(AddIcon)`
  color: ${TEXT_GRADIENT_COLORS[0]};
`;
