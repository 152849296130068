import { SourceFragment } from '@cycle-app/graphql-codegen';
import { Tag, TagProps, CycleLogo, Icon, type IconName } from '@cycle-app/ui';
import {
  EmailIcon, MicrosoftTeamsIcon, ZoomIcon, CannyIcon, RedditIcon, TwitterIcon,
  LoomIcon, MakeIcon, MiroIcon, ModjoIcon, MondayIcon, OutlookIcon,
  AsanaIcon, GainsightIcon, GongIcon, GrainIcon, ProductBoardIcon, ShortcutIcon,
  ZendeskIcon, GitLabIcon, PitchIcon, PinterestIcon, SnowflakeIcon,
  SalesforceIcon, FrontIcon, FullStoryIcon, SkypeIcon, SliteIcon, DescriptIcon,
  DiscordIcon, DovetailIcon, DropboxIcon, FreshDeskIcon,
  ClickUpIcon, ClaapIcon, ConfluenceIcon,
  G2Icon, TrustpilotIcon, CapterraIcon,
} from '@cycle-app/ui/icons';
import vitallyImageUrl from '@cycle-app/ui/icons/social/vitally.png';
import { forwardRef } from 'react';

import { isCycleFileSource } from 'src/utils/doc.util';

import { CycleLogoContainer, StyledImportFileIcon } from './DocSource.styles';
import { getLabel } from './DocSource.utils';

export type DocSourceProps = Pick<TagProps, 'color'> & {
  source?: SourceFragment | null;
  showName?: boolean;
};

export const DocSource = forwardRef<HTMLDivElement, DocSourceProps>(({
  source, showName = true, color = 'grey',
}, ref) => {
  if (!source) return null;
  return (
    <Tag
      ref={ref}
      color={color}
      icon={(
        <SourceIcon
          source={source}
          size={12}
        />
      )}
    >
      {showName && getLabel(source)}
    </Tag>
  );
});

export const getIconBasedOnUrl = (url: string, size: number, defaultIcon: JSX.Element) => {
  // handles refactored icons
  const iconName = getIconNameFromUrl(url);
  if (iconName) {
    return (
      <Icon
        name={iconName}
        style={{
          width: size,
          height: size,
        }}
      />
    );
  }

  if (url.includes('clickup.com')) return <ClickUpIcon size={size} />;
  if (url.includes('claap.io')) return <ClaapIcon size={size} />; // e.g. https://app.claap.io/cycle/share-your-claaps-c-aZ6f2FkC_j-RZJEfe8crKKx
  if (url.includes('atlassian.net')) return <ConfluenceIcon size={size} />; // e.g. https://cycle-team.atlassian.net/l/cp/mBniDYfb
  // if (url.includes('jira.com')) return < size={size} />; // COnflictual with confluence e.g. https://cycle-team.atlassian.net/browse/KAN-3?atlOrigin=eyJpIjoiYzIxOGIzOTQ2NjdhNGQ3Yzk5MjUyYzYyNGFlOGI4ZGQiLCJwIjoiaiJ9
  if (url.includes('descript.com')) return <DescriptIcon size={size} />; // e.g. https://share.descript.com/view/lcEZpDJoXPa
  if (url.includes('discord.com')) return <DiscordIcon size={size} />;
  if (url.includes('dovetail.com')) return <DovetailIcon size={size} />; // e.g. https://cycle-yyv4.dovetail.com/data/3jPeauRlJ2IihgVGy2LxID
  if (url.includes('dropbox.com')) return <DropboxIcon size={size} />; // e.g. https://www.dropbox.com/s/luyfzjww5uokmsf/Enregistrement%202024-03-05%20%C3%A0%2013.13.40.webm?dl=0
  if (url.includes('freshdesk.com')) return <FreshDeskIcon size={size} />; // e.g. https://cycle.freshdesk.com/a/tickets/2
  if (url.includes('front.com')) return <FrontIcon size={size} />;
  if (url.includes('fullstory.com') || url.includes('fsty.io')) return <FullStoryIcon size={size} />;
  if (url.includes('gainsight.com')) return <GainsightIcon size={size} />;
  if (url.includes('gitlab.com')) return <GitLabIcon size={size} />;
  if (url.includes('gong.io')) return <GongIcon size={size} />;
  if (url.includes('grain.com')) return <GrainIcon size={size} />;
  if (url.includes('make.com')) return <MakeIcon size={size} />;
  if (url.includes('microsoft.com')) return <MicrosoftTeamsIcon size={size} />;
  if (url.includes('miro.com')) return <MiroIcon size={size} />; // e.g.https://miro.com/welcomeonboard/NlpVV0FrNkdJb0hFc0pYMW1DR1ZzVlNNSW5DYmdLU3B0Nk1abnFlam1tZmlVQnZ1TTdPaVh3eldPS0hKdDAyOXwzNDU4NzY0NTI2MTQ5Mzc0MjIyfDI=?share_link_id=935171778387
  if (url.includes('modjo.com') || url.includes('modjo.ai')) return <ModjoIcon size={size} />;
  if (url.includes('monday.com')) return <MondayIcon size={size} />;
  if (url.includes('outlook.com')) return <OutlookIcon size={size} />;
  if (url.includes('productboard.com')) return <ProductBoardIcon size={size} />;
  if (url.includes('pitch.com')) return <PitchIcon size={size} />;
  if (url.includes('pinterest.com')) return <PinterestIcon size={size} />;
  if (url.includes('shortcut.com')) return <ShortcutIcon size={size} />;
  if (url.includes('skype.com')) return <SkypeIcon size={size} />;
  if (url.includes('slite.com')) return <SliteIcon size={size} />;
  if (url.includes('snowflake.com')) return <SnowflakeIcon size={size} />;
  if (url.includes('zoom.com')) return <ZoomIcon size={size} />;
  if (url.includes('salesforce.com')) return <SalesforceIcon size={size} />;
  if (url.includes('zendesk.com')) return <ZendeskIcon size={size} />;
  if (url.includes('asana.com')) return <AsanaIcon size={size} />;
  if (url.includes('canny.io')) return <CannyIcon size={size} />;
  if (url.includes('reddit.com')) return <RedditIcon size={size} />;
  if (url.includes('vitally.io')) return (
    <img
      width={`${size}px`}
      src={vitallyImageUrl}
      alt="vitally-logo"
    />
  );
  if (url.includes('twitter.com') || url.includes('https://x.com')) return <TwitterIcon size={size} />;
  if (url.includes('g2.com')) return <G2Icon size={size} />;
  if (url.includes('trustpilot.com')) return <TrustpilotIcon size={size} />;
  if (url.includes('capterra')) return <CapterraIcon size={size} />;
  return defaultIcon;
};

const UrlIconMap = {
  'brand/hubspot': ['hubspot.com'],
  'brand/slack': ['slack.com'],
  'brand/notion': ['notion.com', 'notion.so'],
  'brand/attio': ['attio.com'],
  'brand/github': ['github.com'],
  'brand/airtable': ['airtable.com'],
  'brand/intercom': ['intercom.com'],
  'brand/zapier': ['zapier.com'],
  'brand/google-doc': ['docs.google.com/document'],
  'brand/google-sheet': ['docs.google.com/spreadsheets'],
  'brand/hotjar': ['hotjar.com'],
} satisfies Partial<Record<IconName, string[]>>;

function getIconNameFromUrl(url: string) {
  for (const [iconName, urls] of Object.entries(UrlIconMap)) {
    if (urls.some((urlPattern) => url.includes(urlPattern))) {
      return iconName as keyof typeof UrlIconMap;
    }
  }
  return null;
}

export const SourceIcon = ({
  size, source, defaultIcon,
}: {
  source: Partial<SourceFragment> | null;
  size?: number;
  defaultIcon?: JSX.Element;
}) => {
  switch (source?.__typename) {
    case 'SourceSlack':
      return (
        <Icon
          name="brand/slack"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceIntercom':
      return (
        <Icon
          name="brand/intercom"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceMail':
      return <EmailIcon size={size} />;
    case 'SourceZapier':
      return (
        <Icon
          name="brand/zapier"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceHubspot':
      return (
        <Icon
          name="brand/hubspot"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceZoom':
      return <ZoomIcon size={size} />;
    case 'SourceMicrosoftTeams':
      return <MicrosoftTeamsIcon size={size} />;
    case 'SourceGoogleMeet':
      return (
        <Icon
          name="brand/google-meet"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceLoom':
      return <LoomIcon size={size} />;
    case 'SourceNotion':
      return (
        <Icon
          name="brand/notion"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceZendesk':
      return <ZendeskIcon size={size} />;
    case 'SourceSalesforce':
      return <SalesforceIcon size={size} />;
    case 'SourceModjo':
      return <ModjoIcon size={size} />;
    case 'SourceWeb':
      return getIconBasedOnUrl(
        source?.url || '',
        size ?? 12,
        <Icon
          name="brand/chrome"
          style={{
            width: size,
            height: size,
          }}
        />,
      );
    case 'SourceCycle':
    default:
      if (isCycleFileSource(source)) {
        const extension = source?.url?.split('.').at(-1);
        return (
          <StyledImportFileIcon
            extension={extension}
            size="S"
          />
        );
      }
      return getIconBasedOnUrl(
        source?.url || '',
        size ?? 12,
        defaultIcon ?? (
          <CycleLogoContainer>
            <CycleLogo size={8} />
          </CycleLogoContainer>
        ),
      );
  }
};
