import { Skeleton } from '@cycle-app/ui';

import { FeatureCardContainer } from './QuoteCards.styles';

export const QuoteCardSkeleton = () => {
  return (
    <FeatureCardContainer className="overflow-hidden border-none! p-0!">
      <Skeleton height={70} />
    </FeatureCardContainer>
  );
};
