import { DocParentFragment } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import { CommentAltIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import DocComments from 'src/components/DocComments/DocComments';
import { DocQuotes } from 'src/components/DocInsights';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getCommentsCount } from 'src/utils/comments.utils';

import { Footer } from './QuoteCards.styles';

type Props = {
  doc: DocParentFragment;
};

export const FeatureCardFooter = ({ doc }: Props) => {
  const docType = useGetDocType(doc?.doctype.id);
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);

  return (
    <Footer>
      <DocComments
        docId={doc.id}
        nbComments={doc ? getCommentsCount(doc) : 0}
        buttonIcon={<CommentAltIcon size={16} />}
        isDropdownVisible={isCommentsVisible}
        onHideDropdown={() => setIsCommentsVisible(false)}
        onToggleDropdown={() => setIsCommentsVisible(prev => !prev)}
      />

      <DocQuotes
        doc={doc}
        context="doc-item"
        layer={Layer.DropdownZ2}
      />

      <div style={{ flex: 1 }} />

      {docType && (
        <DocAssignee
          assignee={doc.assignee}
          tooltipPlacement="top"
          docId={doc.id}
          context="doc-item"
          docTypeName={docType.name}
          docTypeType={docType.type}
          icon={(
            <Icon
              name="add-user"
              className="size-4!"
            />
          )}
        />
      )}
    </Footer>
  );
};
