import { FC, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import { DocSearchResult } from 'src/components/DocSearchDropdown/DocSearchResult';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useGetDocType } from 'src/reactives/docTypes.reactive';

import { Container } from './MentionDocDropdown.styles';
import { CategoryTitle } from '../../components/Editor/SlashDropdown/SlashDropdown.styles';
import { useEditorContext } from '../../contexts/editorContext';

interface Props {
  query: string;
  command: (p: { id: string }) => void;
  hide: () => void;
}

const MentionDocDropdown: FC<React.PropsWithChildren<Props>> = ({
  query,
  command,
  hide,
}) => {
  const [toSearch] = useDebounce(query ?? '', INPUT_ONCHANGE_DEBOUNCE);
  const doc = useEditorContext(ctx => ctx.doc);
  const doctype = useGetDocType(doc?.doctype.id);

  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      e.stopPropagation();
      hide();
    };
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, [hide]);

  return (
    <Container onClick={e => e.stopPropagation()}>
      <CategoryTitle>
        Docs
      </CategoryTitle>
      <DocSearchResult
        productAreaIds={undefined}
        search={toSearch}
        onAdd={onSelect}
        sourceId={doc?.source?.id}
        customerId={doctype?.customer ? doc?.customer?.id : undefined}
      />
    </Container>
  );

  function onSelect(itemId: string | null) {
    if (itemId) {
      command({ id: itemId });
    }
  }
};

export default MentionDocDropdown;
