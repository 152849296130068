import { CaretIcon } from '@cycle-app/ui/icons';
import { ReactNode } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { useGithubProject } from 'src/hooks/api/queries/integrations/useGithubProject';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';

import { AddProjectButton, LoadingState, ProjectsList, ProjectItem } from './GithubProjectDropdown.styles';

export const GithubProjectDropdown = ({ children }: { children?: (toggle: VoidFunction) => ReactNode }) => {
  const [isModalOpen, { toggleCallback: toggleModal }] = useOptimizedBooleanState(false);
  const {
    projects, isLoading,
  } = useGithubProject();

  return (
    <DropdownLayer
      layer={Layer.DropdownModalZ2}
      visible={isModalOpen}
      placement="bottom-start"
      hide={toggleModal}
      content={(
        <ProjectsList>
          {projects?.map(p => (
            <ProjectItem key={p?.id}>
              {p?.title}
            </ProjectItem>
          ))}
        </ProjectsList>
      )}
    >
      {!children && (
        <AddProjectButton
          $isMuted={!projects?.length}
          onClick={toggleModal}
        >
          {isLoading ? <LoadingState /> : (
            <>
              <span>
                See projects
              </span>
              <CaretIcon />
            </>
          )}
        </AddProjectButton>
      )}
      {!!children && isLoading && <LoadingState />}
      {!!children && !isLoading && children(toggleModal)}
    </DropdownLayer>
  );
};
