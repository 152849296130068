import { LinearIssueFullFragment, LinearIssueBaseFragment, IntegrationType, LinearTeamBaseFragment } from '@cycle-app/graphql-codegen';
import { SelectLine, Spinner } from '@cycle-app/ui';
import { useListNav } from '@cycle-app/utilities';
import { FC, useState } from 'react';

import { IntegrationEmptyStateLine } from 'src/components/Integrations/EmptyStateLine/IntegrationEmptyStateLine';
import { LineSpinnerContainer, SelectLinesWrapper, SelectWrapper } from 'src/components/Integrations/IntegrationsCommon.styles';
import { IntegrationNotInstalledLine } from 'src/components/Integrations/NotInstalledLine/IntegrationNotInstalledLine';
import { LinearIssueSelectLine } from 'src/components/LinearIssueSelectLine/LinearIssueSelectLine';
import { LinearTeamsSearchDropdown } from 'src/components/LinearTeamsSearchDropdown/LinearTeamsSearchDropdown';
import { Events, Methods } from 'src/constants/analytics.constants';
import { useCreateLinearIssue } from 'src/hooks/api/mutations/integrations/useCreateLinearIssue';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { logError } from 'src/utils/errors.utils';

import { ShortcutsContainer, ShortcutStyled } from './LinearIssuesSearchList.styles';
import useAppHotkeys from '../../hooks/useAppHotkeys';
import useOptimizedBooleanState from '../../hooks/useOptimizedBooleanState';
import { LinearIssueCreationModal } from '../LinearIssueCreationModal/LinearIssueCreationModal';

const CREATE_VALUE = 'create-linear-issue-value';
const LINEAR_URL_RE = /^https:\/\/[\w.]+\/[\w.]+\/issue\/([\w.-]+)\/.+$/;

const getLinearIssueKey = (issue: LinearIssueBaseFragment) => {
  if (!issue?.url) return '';

  const [, issueKey] = LINEAR_URL_RE.exec(issue.url) || [];

  return issueKey;
};

type Props = {
  isIntegrationActive: boolean;
  isLoading: boolean;
  onLink: (selectedIssue: LinearIssueFullFragment) => void;
  onCreated: (selectedIssue: LinearIssueFullFragment) => void;
  results: (LinearIssueFullFragment | null | undefined)[];
  search: string;
  docId?: string;
};

export const LinearIssuesSearchList: FC<React.PropsWithChildren<Props>> = ({
  isIntegrationActive,
  isLoading,
  onLink,
  onCreated,
  results,
  search,
  docId,
}) => {
  const [selectedTeam, setSelectedTeam] = useState<LinearTeamBaseFragment | null>(null);
  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    optionsValues: [...results.map((r) => r?.id ?? ''), CREATE_VALUE],
    onSelect: onResultSelected,
  });
  const {
    createIssue, isLoading: isCreateLoading,
  } = useCreateLinearIssue();

  const [isModalOpen, {
    setTrueCallback: openModal,
    setFalseCallback: closeModal,
  }] = useOptimizedBooleanState(false);

  useAppHotkeys('command+enter', (e) => {
    e.stopPropagation();
    openModal();
  }, {
    enabled: () => !!search.length && !isModalOpen,
    capture: true,
  });

  return (
    <div {...listProps}>
      {renderIssuesContent()}
      {renderCreateIssue()}
    </div>
  );

  function renderIssuesContent() {
    if (!isIntegrationActive) return <IntegrationNotInstalledLine integrationType={IntegrationType.Linear} />;

    if (isLoading) return (
      <LineSpinnerContainer>
        <Spinner />
      </LineSpinnerContainer>
    );

    if (!results.length && search.length < 3) return <IntegrationEmptyStateLine />;

    return (
      <>
        <SelectLinesWrapper>
          {results.map(item => (item ? (
            <LinearIssueSelectLine
              key={item?.id}
              issue={item}
              isSelected={item.id === selected}
              extraProps={itemProps(item.id)}
              slotText={getLinearIssueKey(item)}
              search={search}
            />
          ) : null))}
        </SelectLinesWrapper>
      </>
    );
  }

  function renderCreateIssue() {
    return isIntegrationActive && !!search ? (
      <>
        <SelectLine
          startSlot={(
            <SelectWrapper>
              {isCreateLoading ? <Spinner /> : '+ New'}
              <LinearTeamsSearchDropdown
                isDisabled={isCreateLoading}
                selectedTeamId={selectedTeam?.id ?? ''}
                onSelect={setSelectedTeam}
              />
            </SelectWrapper>
          )}
          label={search}
          isSelected={CREATE_VALUE === selected}
          endSlot={(
            <ShortcutsContainer>
              <ShortcutStyled keys={['mod']} />
              +
              <ShortcutStyled keys={['enter']} />
              to add details
            </ShortcutsContainer>
          )}
          {...itemProps(CREATE_VALUE)}
        />
        {isModalOpen && (
          <LinearIssueCreationModal
            onHide={closeModal}
            defaultValues={{
              title: search,
              teamId: selectedTeam?.id,
              statusId: selectedTeam?.availableStatus[0]?.id,
            }}
            onIssuesCreated={onIssuesCreated}
          />
        )}
      </>
    ) : null;
  }

  async function onResultSelected(issueId: string | null) {
    if (issueId === CREATE_VALUE) {
      await onCreateLinearIssue();
    } else {
      const issue = results.find(i => i?.id === issueId);

      if (!issue) return;

      onLink(issue);
    }
  }

  function onIssuesCreated(issues: LinearIssueFullFragment[]) {
    if (issues[0]) onCreated(issues[0]);
    trackAnalytics(Events.IntegrationIssueCreated, {
      type: IntegrationType.Linear,
      method: Methods.SlashCommand,
    });
  }

  async function onCreateLinearIssue() {
    if (!search.trim() || !docId || !selectedTeam) return;

    const result = await createIssue({
      title: search,
      docId,
      teamId: selectedTeam.id,
    });
    if (result.data?.createLinearIssue) {
      onCreated(result.data.createLinearIssue);
      trackAnalytics(Events.IntegrationIssueCreated, {
        type: IntegrationType.Linear,
        method: Methods.SlashCommand,
      });
      return;
    }

    logError(new Error('Error during linear issue creation'));
  }
};
