import { SubscriptionHookOptions, useSubscription } from '@apollo/client';
import { FindInsightStateDocument, FindInsightStateSubscription, FindInsightStateSubscriptionVariables } from '@cycle-app/graphql-codegen';

type Options = SubscriptionHookOptions<FindInsightStateSubscription, FindInsightStateSubscriptionVariables>;

export const useFindInsightStateSubscription = (docId: string, options: Omit<Options, 'variables'> = {}) => {
  return useSubscription(FindInsightStateDocument, {
    variables: { docId },
    ...options,
  });
};
