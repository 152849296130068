import { Skeleton } from '@cycle-app/ui';
import { QuoteIcon } from '@cycle-app/ui/icons';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';

import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { useCompany } from 'src/hooks/api/queries/customers/useCompany';
import { useCompanyDocsCount } from 'src/hooks/api/queries/customers/useCompanyDocsCount';
import { setCompanyTabId } from 'src/reactives/customerCompanyProfile.reactive';
import { insightName } from 'src/utils/doc.util';
import { getDocTypeName } from 'src/utils/docType.util';

import { CountDonutChart } from './CountDonutChart';
import { Skeletons, CountLine, CountButton, CountsContainer } from './Profile.styles';
import { TooltipCount } from './TooltipCount';

type Props = {
  companyId: string;
};

export const DocsCountCompany = ({ companyId }: Props) => {
  const { docsCount } = useCompanyDocsCount(companyId);

  const {
    company, isLoading: isCompanyLoading,
  } = useCompany(companyId);

  const isLoading = !docsCount || isCompanyLoading;

  if (isLoading) {
    return (
      <Skeletons>
        <Skeleton
          height={16}
          width={300}
        />
        <Skeleton
          height={16}
          width={300}
        />
      </Skeletons>
    );
  }

  if (!company?.name) return null;

  const quotesCount = sumBy(docsCount, 'totalInsights');

  if (quotesCount === 0) {
    return (
      <CountLine>
        {`No quotes were collected for ${company.name}`}
      </CountLine>
    );
  }

  const sortedDocTypes = sortBy(docsCount, 'doctype.name');

  return (
    <CountsContainer>
      <CountLine>
        <CountButton
          onClick={() => setCompanyTabId('quotes')}
        >
          <QuoteIcon size={14} />
          {`${quotesCount} ${insightName({ plural: quotesCount > 1 })}`}
        </CountButton>
        {quotesCount === 1
          ? `was collected for ${company.name} and linked to`
          : `were collected for ${company.name} and linked to`}
      </CountLine>

      <CountLine>
        {sortedDocTypes
          .map(count => {
            const { doctype } = count;
            return (doctype ? (
              <TooltipCount
                key={doctype.id}
                count={count}
              >
                <CountButton
                  onClick={() => setCompanyTabId(doctype.id)}
                >
                  <DocTypeIcon
                    doctype={doctype}
                    size={14}
                  />
                  {`${count.total} ${getDocTypeName(doctype, count.total > 1).toLowerCase()}`}
                  <CountDonutChart count={count} />
                </CountButton>
              </TooltipCount>
            ) : (
              <CountButton key="unlinked">
                {`${count.toLink} unlinked`}
              </CountButton>
            ));
          })}
      </CountLine>
    </CountsContainer>
  );
};
