import { Popover, PopoverProps } from '@cycle-app/ui';
import { AiIcon, CloseIcon, EditIcon, PenIcon, AddIcon } from '@cycle-app/ui/icons';
import { ComponentProps, ReactNode } from 'react';

import { CommentEditor } from 'src/components/Editor';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useDiscardQuote } from 'src/hooks/api/mutations/quotes/useDiscardQuote';
import { Layer } from 'src/types/layers.types';
import { requestInsightDelete } from 'src/utils/requestInsightDelete';
import { requestInsightUpdate } from 'src/utils/requestInsightUpdate';

import { PopoverContainer, ButtonsList, PopoverContent, QuoteContent, FeatureContent } from './QuotePopover.styles';

export type QuotePopoverProps = Pick<PopoverProps, 'onMount' | 'onHide' | 'disabled' | 'placement' | 'offset'> & {
  children: ReactNode;
  buttons: ReactNode;
  quoteContent?: string | null;
  featureContent?: ReactNode;
};

export const QuotePopover = ({
  quoteContent, featureContent, buttons, ...props
}: QuotePopoverProps) => {
  return (
    <Popover
      placement="top-start"
      {...props}
      controlled
      interactive
      withPortal
      withWrapper
      zIndex={mappingZindex[Layer.Dropdown]}
      animation
      delay={[200, 0]}
      content={(
        <PopoverContainer>
          <PopoverContent>
            {quoteContent && (
              <QuoteContent>
                <CommentEditor
                  isReadOnly
                  content={quoteContent}
                />
              </QuoteContent>
            )}
            <ButtonsList>
              {buttons}
            </ButtonsList>
          </PopoverContent>

          {featureContent && (
            <FeatureContent>
              {featureContent}
            </FeatureContent>
          )}
        </PopoverContainer>
      )}
    />
  );
};

export const VerifyQuote = ({ onClick }: { onClick: VoidFunction }) => {
  return (
    <ButtonItem
      onClick={() => {
        if (!requestInsightUpdate()) return;
        onClick();
      }}
    >
      <AiIcon
        hasGradient
        size={14}
      />
      Verify
    </ButtonItem>
  );
};

export const DiscardQuote = ({
  docId, quoteId,
}: { docId: string; quoteId: string }) => {
  const { discard } = useDiscardQuote();
  return (
    <ButtonItem
      onClick={() => {
        if (!requestInsightDelete()) return;
        discard(docId, quoteId);
      }}
    >
      <CloseIcon size={10} />
      Discard quote
    </ButtonItem>
  );
};

export const EditQuote = ({ onClick }: { onClick: VoidFunction }) => {
  return (
    <ButtonItem
      onClick={() => {
        if (!requestInsightUpdate()) return;
        onClick();
      }}
    >
      <EditIcon size={14} />
      Edit quote
    </ButtonItem>
  );
};

export const ChangeFeature = ({
  onClick, insightId,
}: {
  onClick: VoidFunction;
  insightId: string;
}) => {
  const insight = useDocPanelContext(ctx => ctx.doc?.docTargets.edges.find(edge => edge.node?.id === insightId)?.node);
  const hasParent = !!insight?.doc?.parent;
  return (
    <ButtonItem
      onClick={() => {
        if (!requestInsightUpdate()) return;
        onClick();
      }}
    >
      {hasParent ? <PenIcon size={14} /> : <AddIcon size={12} />}
      {hasParent ? 'Change feature' : 'Link feature'}
    </ButtonItem>
  );
};

export const RemoveQuote = ({ onClick }: { onClick: VoidFunction }) => {
  return (
    <>
      <ButtonItem
        onClick={() => {
          if (!requestInsightDelete()) return;
          onClick();
        }}
      >
        <CloseIcon size={10} />
        Remove quote
      </ButtonItem>
    </>
  );
};

const ButtonItem = (props: ComponentProps<'button'>) => (
  <button
    // eslint-disable-next-line max-len
    className="relative flex h-7 flex-none cursor-pointer items-center gap-1.5 px-2 py-0.5 text-caption font-medium outline-hidden hover:bg-grey-100 focus-visible:bg-blue-400/20 dark:hover:bg-grey-800"
    {...props}
  />
);
