import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, RefObject, useCallback, useSyncExternalStore } from 'react';
import { twJoin } from 'tailwind-merge';

import { RIGHT_PANEL_WIDTH, VERIFY_QUOTES_WIDTH } from 'src/constants/docPanel.constants';
import { useGetDocPanel, useVerifyQuotesOpen } from 'src/reactives/docRightPanel.reactive';

export const VerifyQuotesContainer = ({
  children, docPanelRef,
}: PropsWithChildren<{
  docPanelRef: RefObject<HTMLDivElement>;
}>) => {
  const isVerifyQuotesOpen = useVerifyQuotesOpen();
  const docPanel = useGetDocPanel();
  const layout = useLayout(docPanelRef);
  return (
    <AnimatePresence key={layout}>
      {isVerifyQuotesOpen && (
        <motion.div
          className="z-10 overflow-hidden bg-white dark:bg-grey-900"
          {...(layout === 'full' ? {
            style: {
              position: 'absolute',
              inset: 0,
              width: '100%',
            },
            variants: {
              visible: {
                opacity: 1,
              },
              hidden: {
                opacity: 0,
              },
            },
          } : {
            style: {
              marginRight: docPanel.isExpanded ? -RIGHT_PANEL_WIDTH : 0,
            },
            variants: {
              visible: {
                opacity: 1,
                width: VERIFY_QUOTES_WIDTH,
              },
              hidden: {
                opacity: 0,
                width: docPanel.isExpanded ? RIGHT_PANEL_WIDTH : 0,
              },
            },
          })}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <div
            className={twJoin(
              'sticky top-0 flex h-full flex-col',
              layout === 'right' && 'border-l border-grey-300 dark:border-grey-850',
            )}
            style={{
              width: layout === 'full' ? '100%' : VERIFY_QUOTES_WIDTH,
            }}
          >
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

// The quotes panel must be full-width if the doc panel is too small
const useLayout = (docPanelRef: RefObject<HTMLDivElement>) => {
  const subscribe = useCallback((next: VoidFunction) => {
    const observer = new ResizeObserver(next);
    if (docPanelRef.current) observer.observe(docPanelRef.current);
    return () => observer.disconnect();
  }, [docPanelRef]);

  return useSyncExternalStore(subscribe, () => {
    const docPanelWidth = docPanelRef.current?.clientWidth;
    return !!docPanelWidth && docPanelWidth < 850 ? 'full' : 'right';
  });
};
