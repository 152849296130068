import { CycleLogo } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks/useNavigate';

import {
  Container,
  Centered,
  Main,
  Title,
  Desc,
  StyledScrollableContent,
  LogoContainer,
} from './BillingUpdateLayout.styles';
import dark from './gradient-dark.webp';
import light from './gradient-light.png';
import { ImgGradient } from './Gradients.styles';

interface BillingUpdateLayoutProps {
  main: ReactNode;
  features: ReactNode;
  title: string;
  description: string;
  logo?: ReactNode;
  gradients?: ReactNode;
}

export const BillingUpdateLayout = ({
  main,
  features,
  title,
  description,
  logo,
  gradients,
}: BillingUpdateLayoutProps) => {
  const { getUrl } = useNavigate();
  const theme = useTheme();
  const backToBoardUrl = getUrl(PageId.Main);
  return (
    <StyledScrollableContent>
      {gradients || (
        <>
          <Helmet>
            <link
              rel="prefetch"
              as="image"
              href={dark}
            />
            <link
              rel="prefetch"
              as="image"
              href={light}
            />
          </Helmet>
          <ImgGradient
            src={theme.isDark ? dark : light}
            alt=""
          />
        </>
      )}
      <Container>
        <Centered>
          <Link
            className="btn-tertiary text-body font-normal"
            to={backToBoardUrl}
          >
            <CaretIcon
              direction="left"
              size={10}
            />
            Back to my workspace
          </Link>
          <Main>
            <div>
              <LogoContainer>
                {logo || (
                  <CycleLogo
                    size={64}
                    color={theme.isDark ? 'metal' : 'blue'}
                  />
                )}
              </LogoContainer>
              <Title>
                {title}
              </Title>
              <Desc>
                {description}
              </Desc>
            </div>
            {main}
          </Main>
          {features}
        </Centered>
      </Container>
    </StyledScrollableContent>
  );
};
