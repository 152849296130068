/* eslint-disable no-nested-ternary */
import { HIGHLIGHT_DATA_ID } from '@cycle-app/editor-extensions';
import { CustomerAvatar, Icon } from '@cycle-app/ui';
import type { Editor } from '@tiptap/core';
import { useCallback, useEffect, useRef, FC } from 'react';

import DocParent from 'src/components/DocParent/DocParent';
import { Events } from 'src/constants/analytics.constants';
import { useFullDoc, useDoc } from 'src/hooks/api/useDoc';
import useRealtimeEditorConfig from 'src/hooks/doc/useRealtimeEditorConfig';
import { trackAnalytics } from 'src/utils/analytics/analytics';

import { isFeedback } from '../../utils/docType.util';
import { ReadOnlyEditor } from '../Editor';
import {
  Container,
  Header,
  Title,
  StyledDocAttributes,
  Assignee,
  Fullname,
  Content,
  EditorSkeleton,
} from './DocPreview.styles';

interface Props {
  className?: string;
  id: string;
  blockId?: string | null;
  hideAttributes?: boolean;
}
const DocPreview: FC<Props> = ({
  id,
  blockId,
  hideAttributes,
  ...otherProps
}) => {
  const { doc: partialDocFromCache } = useDoc(id, false, 'cache-only');
  const { doc } = useFullDoc({ docId: id });
  const {
    yDoc, isSync,
  } = useRealtimeEditorConfig({ docId: id });
  const bodyRef = useRef<HTMLDivElement>(null);

  const preventKeyPress = useCallback((e: KeyboardEvent) => e.preventDefault(), []);

  useEffect(() => {
    trackAnalytics(Events.DocPreview);
    window.addEventListener('keydown', preventKeyPress);
    return () => {
      window.removeEventListener('keydown', preventKeyPress);
    };
  }, [preventKeyPress]);

  const handleEditorScroll = (editor: Editor) => {
    if (!blockId) return;

    const block = editor.view.dom.querySelector<HTMLElement>(`[${HIGHLIGHT_DATA_ID}="${blockId}"]`);

    if (bodyRef.current && block) {
      // scrollIntoView scrolls the whole viwport.
      bodyRef.current.scrollTop = block.offsetTop - bodyRef.current.offsetTop - bodyRef.current.offsetHeight / 2;
    }
  };

  if (!partialDocFromCache) return null;

  const showHeader = !!partialDocFromCache.parent || !!doc?.doctype.customer;

  return (
    <Container {...otherProps}>
      <div>
        {!hideAttributes && showHeader && (
          <Header>
            {partialDocFromCache.parent && (
              <DocParent
                doc={partialDocFromCache.parent}
                minimal
                readonly
              />
            )}

            <Assignee>
              {// eslint-disable-next-line no-nested-ternary
                doc?.doctype.customer
                  ? doc.customer?.id
                    ? (
                      <>
                        <CustomerAvatar
                          customer={doc.customer}
                          size="S"
                        />
                        <Fullname>
                          {doc.customer.name || doc.customer.email}
                        </Fullname>
                      </>
                    )
                    : (
                      <>
                        <Icon name="user" />
                        ,
                        No customer
                      </>
                    )
                  : null
              }
            </Assignee>
          </Header>
        )}

        <Title>
          {partialDocFromCache.title}
        </Title>
        {!hideAttributes && doc && (
          <StyledDocAttributes
            doc={doc}
            readOnly
            context="doc-panel"
            showDocId={!isFeedback(doc.doctype)}
            showDocType={!isFeedback(doc.doctype)}
            showAssignee={!!doc.assignee}
            showLinear={!!doc.automationId}
            showAiState={!!doc.aiState}
          />
        )}
      </div>

      <Content
        ref={bodyRef}
        $blockId={blockId}
      >
        {!isSync && (
          <EditorSkeleton nbLines={11} />
        )}
        {yDoc && isSync && (
          <ReadOnlyEditor
            interactive={false}
            collaboration={{ document: yDoc }}
            onEditorReady={handleEditorScroll}
            mode="preview"
          />
        )}
      </Content>
    </Container>
  );
};

export default DocPreview;
