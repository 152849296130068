import { CycleLogo } from '@cycle-app/ui';

import { SourcePopover } from 'src/components/SourcePopover/SourcePopover';
import { useDocSourceFragment } from 'src/hooks/api/fragments/doc';

import { useQuoteId } from './QuoteContext';
import { SourceIcon } from '../DocSource';
import { QuotePanelLink } from './QuotePanelLink';
import { getLabel } from '../DocSource/DocSource.utils';

export const QuotePanelSource = () => {
  const quoteId = useQuoteId();
  const doc = useDocSourceFragment(quoteId);
  if (!doc?.source) return null;
  const linkTo = getLinkTo(doc.source.url);
  return (
    <SourcePopover
      docId={doc.id}
      placement="top-start"
    >
      <QuotePanelLink
        {...linkTo ? {
          to: linkTo,
        } : {
          href: doc.source.__typename === 'SourceMail' ? `mailto:${doc.source.url}` : doc.source.url,
        }}
      >
        <SourceIcon
          source={doc.source}
          size={16}
          defaultIcon={(
            <CycleLogo
              size={16}
              color="blue"
            />
          )}
        />
        {getLabel(doc.source)}
      </QuotePanelLink>
    </SourcePopover>
  );
};

/**
 * Get link to the source URL if it's internal
 */
export const getLinkTo = (url: string) => {
  try {
    const sourceUrl = new URL(url);
    return sourceUrl.origin === location.origin ? sourceUrl.pathname : null;
  } catch {
    // not valid URL (e.g. mailto)
    return null;
  }
};
