import { SwimlaneDocFragment } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { TriangleIcon, EyeClosedIcon, ArrowRightUpIcon } from '@cycle-app/ui/icons';
import { HTMLAttributes, forwardRef, useState, useCallback } from 'react';

import { AiStateTag } from 'src/components/AiStateTag';
import { DocLinear } from 'src/components/DocLinear';
import { BULK_PREVENT_CLASSNAME } from 'src/constants/bulkSelection.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import useManageHiddenSwimlanes from 'src/hooks/api/mutations/boardConfig/useManageHiddenSwimlanes';
import { useLoader } from 'src/hooks/useLoader';
import { useGetDocTypeEmoji } from 'src/reactives/docTypes.reactive';

import {
  Menu,
  Container,
  IndicatorBtn,
  SwimlaneGroupName,
  DocTitle,
  LinkTag,
} from './SwimlaneDoc.styles';

interface Props extends HTMLAttributes<HTMLDivElement> {
  id: string;
  boardConfigId: string | null | undefined;
  doc?: SwimlaneDocFragment;
  docUrl?: string;
  name?: string;
  collapsed: boolean;
  onToggleCollapsed?: (id: string) => void;
  asPlaceholder?: boolean;
}
const SwimlaneDoc = forwardRef<HTMLInputElement, Props>(({
  id,
  boardConfigId,
  doc,
  docUrl,
  name = '',
  collapsed,
  onToggleCollapsed,
  asPlaceholder,
  className = '',
  ...containerProps
}, ref) => {
  const {
    hideSwimlane,
    loading,
  } = useManageHiddenSwimlanes({
    boardConfigId,
    swimlaneConfigId: null,
  });

  useLoader({ loading });

  const [isMenuVisible, setMenuVisible] = useState(false);
  const onHideSwimlane = useCallback(() => hideSwimlane(id, { updateSwimlanes: true }), [hideSwimlane, id]);
  const emoji = useGetDocTypeEmoji(doc?.doctype.id);
  const builtInDisplay = useBoardConfig(ctx => ctx.builtInDisplay);

  return (
    <Container
      className={`${className} ${BULK_PREVENT_CLASSNAME}`}
      ref={ref}
      asPlaceholder={asPlaceholder}
      noSwimlaneDoc={!!name}
      {...containerProps}
    >
      <span className="content">
        <IndicatorBtn
          $collapsed={collapsed}
          onClick={(e) => {
            e.preventDefault();
            onToggleCollapsed?.(id);
          }}
        >
          <TriangleIcon direction="bottom" />
        </IndicatorBtn>

        <SwimlaneGroupName>
          {doc && (
            <Emoji
              size={12}
              emoji={emoji}
            />
          )}
          <DocTitle>
            {doc?.title ?? name}
          </DocTitle>

          {builtInDisplay?.linear && doc && (
            <DocLinear
              docId={doc.id}
              automationId={doc.automationId}
              automationUrl={doc.automationUrl}
            />
          )}

          {builtInDisplay?.aiState && doc && (
            <AiStateTag
              docId={doc.id}
              docTypeId={doc.doctype.id}
              aiState={doc.aiState}
            />
          )}

          {docUrl && (
            <a
              href={docUrl}
              target="_blank"
              rel="noreferrer"
            >
              <LinkTag
                color="grey"
                icon={<ArrowRightUpIcon size={10} />}
              >
                Open
              </LinkTag>
            </a>
          )}
        </SwimlaneGroupName>

        <Menu
          visible={isMenuVisible}
          setVisible={setMenuVisible}
          forceFocus={isMenuVisible}
          options={[
            // TODO: waiting for the UX
            // {
            //   label: 'Rename',
            //   value: 'rename',
            //   icon: <PenIcon />,
            // },
            {
              label: 'Hide',
              value: 'hide',
              icon: <EyeClosedIcon />,
              disabled: loading,
              onSelect: onHideSwimlane,
            },
          ]}
        />
      </span>
    </Container>
  );
});

export default SwimlaneDoc;
