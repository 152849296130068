import { useEffect } from 'react';

import { useBoardConfigGroups } from 'src/hooks/api/useBoardGroups';
import { useMoreDocsInGroup } from 'src/hooks/api/useMoreDocsInGroup';
import { openQuoteModal } from 'src/hooks/modals/useQuoteModal';
import { useIsLastOpenedSideModal } from 'src/hooks/modals/useSideModal';
import { useLocationSelector } from 'src/hooks/router/useLocation';
import { getPublishedBoardConfig } from 'src/utils/boardConfig/boardConfig.util';
import { getPathParams } from 'src/utils/routing.utils';

import { useQuoteId } from './QuoteContext';

export const useQuoteGroup = () => {
  const groupId = useLocationSelector(location => location.state?.groupId);
  const docId = useQuoteId();
  const isModalOnTop = useIsLastOpenedSideModal(docId);

  const { boardId } = getPathParams(location.pathname);
  const boardConfig = getPublishedBoardConfig(boardId);
  const {
    groups, withGroupBy,
  } = useBoardConfigGroups(boardConfig);

  const group = groupId ? groups?.[groupId] : null;
  const list = group ? Object.values(group.docs) : [];
  const index = list.findIndex(item => item.id === docId);
  const prevId = index === 0 ? undefined : list[index - 1]?.id;
  const nextId = index === list.length - 1 ? undefined : list[index + 1]?.id;

  const isPrevDisabled = !isModalOnTop || !prevId;
  const isNextDisabled = !isModalOnTop || !nextId;

  const hasNextPage = !!group?.pageInfo?.hasNextPage;
  const endCursor = group?.pageInfo?.endCursor;

  const moreDocsInGroup = useMoreDocsInGroup(groupId ?? '');
  const loading = !!group?.more?.loading || moreDocsInGroup.loading;

  useEffect(() => {
    if (hasNextPage && !nextId && !loading) {
      loadMore();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, loading, nextId]);

  if (!groupId) return null;

  const loadMore = () => {
    if (loading) return;
    if (withGroupBy) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      moreDocsInGroup.moreDocs(endCursor ?? '');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      group?.more?.exec?.();
    }
  };

  const openPrev = () => {
    if (!isPrevDisabled) {
      openQuoteModal(prevId, groupId);
    }
  };

  const openNext = () => {
    if (hasNextPage && index >= 0 && index >= list.length - 2) {
      loadMore();
    }
    if (!isNextDisabled) {
      openQuoteModal(nextId, groupId);
    }
  };

  return {
    openPrev,
    openNext,
    isPrevDisabled,
    isNextDisabled,
  };
};
