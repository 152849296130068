import { DocBaseFragment, ThemeType } from '@cycle-app/graphql-codegen';
import { useTheme } from 'styled-components';

import { DocChat } from 'src/components/DocComments/DocChat';
import useDocComments from 'src/hooks/api/queries/useDocComments';
import { Content, useGetDocPanel } from 'src/reactives/docRightPanel.reactive';
import { getCommentsCount } from 'src/utils/comments.utils';

import { NoComment, Hint } from './DocPanelRightPanel.styles';

export const DocRightPanelEmptyState = ({ doc }: {
  doc: DocBaseFragment;
}) => {
  const theme = useTheme();
  const commentsCount = getCommentsCount(doc);
  const docPanel = useGetDocPanel();
  const {
    comments, loading: isCommentsLoading, previousData: previousCommentsData,
  } = useDocComments({
    docId: doc.id,
    blockId: null,
    skip: !docPanel.isExpanded || docPanel.content !== Content.Comments,
    resolved: false,
  });
  return (
    <>
      {commentsCount === 0 && (
        <NoComment>
          <img
            src={`/images/empty-comments-${theme.themeType === ThemeType.Nightfall ? 'dark' : 'light'}.png`}
            alt="empty-comments"
          />
          <p>
            No comment yet
          </p>
        </NoComment>
      )}
      <DocChat
        docId={doc.id}
        blockId={null}
        comments={comments}
        isCommentsLoading={commentsCount > 0 && !previousCommentsData && isCommentsLoading}
        commentsCount={commentsCount}
        isResolved={false}
      />
      {commentsCount === 0 && (
        <Hint>
          use @ to mention someone
        </Hint>
      )}
    </>
  );
};
