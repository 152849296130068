import { Button } from '@cycle-app/ui';
import { TrashIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { useDeleteComment, useResolveThread } from 'src/hooks/api/mutations/comments';
import { useUnresolveThread } from 'src/hooks/api/mutations/comments/useUnresolveThread';
import { useShowResolveThreadButton } from 'src/hooks/useShowResolveThreadButton';
import { useThreadsPanel } from 'src/reactives/comments.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { Layer } from 'src/types/layers.types';

import { DocChatNav } from './DocChatNav';
import { Header, Nav, Actions, CloseButton } from './DocComments.styles';

export const DocChatHeader = ({
  threadId, docId, blockId = null, firstCommentId, isDeletable, hide, isResolved,
}: {
  threadId?: string;
  docId: string;
  blockId: string | null;
  firstCommentId: string;
  isDeletable: boolean;
  hide?: VoidFunction;
  isResolved: boolean;
}) => {
  const isMobile = useIsMobile();
  const {
    onDelete, onResolve,
  } = useThreadsPanel();
  const {
    resolveThread, isResolvingThread,
  } = useResolveThread();
  const {
    unresolveThread, isUnresolvingThread,
  } = useUnresolveThread();
  const {
    deleteComment, isDeletingComment,
  } = useDeleteComment({ isFirstComment: true });
  const [isDialogVisible, setDialogVisible] = useState(false);

  const showResolveButton = useShowResolveThreadButton(docId, blockId, threadId);

  return (
    <Header>
      {isMobile && (
        <CloseButton onClick={hide}>
          Close
        </CloseButton>
      )}

      <Nav>
        <DocChatNav
          threadId={threadId}
          docId={docId}
          blockId={blockId}
          hide={hide}
        />
      </Nav>

      <Actions>
        {showResolveButton && (
          <Button
            isLoading={isResolvingThread || isUnresolvingThread}
            onClick={async () => {
              if (isResolved) {
                await unresolveThread(docId, { commentId: firstCommentId });
              } else {
                await resolveThread(docId, blockId, { commentId: firstCommentId });
              }
              onResolve?.(blockId, !isResolved);
              hide?.();
            }}
          >
            {isResolved ? 'Unresolve' : 'Resolve'}
          </Button>
        )}

        {isDeletable && (
          <DotsMenuLayer
            layer={Layer.DropdownModalZ1}
            options={[{
              label: 'Delete thread',
              value: 'delete',
              icon: <TrashIcon />,
              variant: 'danger',
              onSelect: () => setDialogVisible(true),
            }]}
          />
        )}
      </Actions>

      {isDeletable && isDialogVisible && (
        <DialogModal
          title="Delete thread"
          info="Are you sure you want to delete this thread? This will delete all of its replies."
          confirmLabel="Delete"
          hide={() => setDialogVisible(false)}
          loading={isDeletingComment}
          autoHide={false}
          onConfirm={async () => {
            await deleteComment(docId, blockId, { commentId: firstCommentId });
            onDelete?.(blockId);
            hide?.();
          }}
        />
      )}
    </Header>
  );
};
