import { Spinner } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { FC, useRef, useState, useEffect } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useAddAttributeSelectOption } from 'src/hooks/api/mutations/useAttributesMutations';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { ViewType } from 'src/types/viewType.types';

import { Container, StyledInput } from './BoardContentNewGroup.styles';

interface BoardContentNewGroupProps {
  viewType: ViewType;
}

const BoardContentNewGroup: FC<React.PropsWithChildren<BoardContentNewGroupProps>> = ({ viewType }) => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [name, setName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    addSelectOption, loading,
  } = useAddAttributeSelectOption();

  useAppHotkeys('enter', handleSubmit);
  useAppHotkeys('escape', handleEscape);

  useEffect(() => {
    if (isCreatingGroup) {
      inputRef.current?.focus();
    } else {
      setName('');
    }
  }, [isCreatingGroup]);

  return (
    <Container viewType={viewType}>
      {isCreatingGroup ? (
        <StyledInput
          ref={inputRef}
          value={name}
          placeholder="Your group name"
          onChange={e => setName(e.target.value)}
          iconAfter={loading && <Spinner />}
        />
      ) : (
        <button
          className="btn-tertiary btn-sm w-full justify-start font-normal"
          onClick={() => setIsCreatingGroup(true)}
        >
          <AddIcon size={9} />
          Add group
        </button>
      )}

    </Container>
  );

  async function handleSubmit() {
    if (!isCreatingGroup || !name) return;
    if (boardConfig?.docQuery.__typename !== 'BoardQueryWithGroupBy') return;
    const attributeDefinitionId = boardConfig?.docQuery.groupbyConfig.property.id;
    await addSelectOption(attributeDefinitionId, name);
    setIsCreatingGroup(false);
  }

  function handleEscape() {
    setIsCreatingGroup(false);
  }
};

export default BoardContentNewGroup;
