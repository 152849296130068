import styled from 'styled-components';

import { DocItem } from 'src/components/DocItem';

export const DocsList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 7px;
`;

export const StyledDocItem = styled(DocItem)`
  & + & {
    margin-top: 8px;
  }
`;
