import { Icon } from '@cycle-app/ui';
import { ChangeEvent } from 'react';
import { twJoin } from 'tailwind-merge';

export function ChangelogInputImage({
  className, accept, value, onChange,
}: { className?: string; accept?: string; value: string | File | null; onChange: (value: File) => void }) {
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      onChange(file);
    }
    e.target.value = '';
  };

  const logoUrl = getImageUrl(value);

  return (
    <label
      // eslint-disable-next-line max-len
      className={twJoin('group relative flex cursor-pointer items-center justify-center overflow-hidden rounded-lg border border-primary p-4 has-[:focus-visible]:outline', className)}
    >
      {logoUrl && (
        <img
          src={logoUrl}
          className="max-h-full max-w-full object-contain"
        />
      )}

      <div
        // eslint-disable-next-line max-len
        className="absolute inset-0 size-full rounded-inherit bg-black/10 opacity-0 backdrop-blur-xs transition group-hover:opacity-100 dark:bg-white/5"
      />

      <div
        className={twJoin(
          'absolute flex size-12 items-center justify-center rounded-full bg-grey-100 transition dark:bg-grey-800',
          // eslint-disable-next-line max-len
          logoUrl && 'scale-50 opacity-0 group-hover:scale-100 group-hover:opacity-100',
        )}
      >
        <Icon
          name="camera-plus-fill"
          className="size-6 text-secondary"
        />
      </div>

      <input
        type="file"
        className="sr-only"
        onChange={handleFileChange}
        accept={accept}
      />
    </label>
  );
}

export function getImageUrl(value: string | File | null) {
  return value instanceof File ? URL.createObjectURL(value) : value;
}
