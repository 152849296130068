import { makeVar, useReactiveVar } from '@apollo/client';
import { useFeature, useReload } from '@unrevealed/react';
import type { FeatureKey } from '@unrevealed/react';
import { useEffect } from 'react';

const statusVar = makeVar<'idle' | 'loading' | 'loaded'>('idle');

const featuresEnabled = import.meta.env.VITE_FEATURES_ENABLED || '';

export const useFeatureFlag = (key: FeatureKey) => {
  const status = useReactiveVar(statusVar);
  const flag = useFeature(key);

  useEffect(() => {
    if (statusVar() === 'idle' && flag.loading) {
      statusVar('loading');
      return;
    }
    if (statusVar() === 'loading' && !flag.loading) {
      statusVar('loaded');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag.loading]);

  return {
    isEnabled: flag.enabled || featuresEnabled.includes(key),
    isLoading: flag.loading,
    isLoaded: status === 'loaded',
  };
};

export const useFeatureFlagReload = () => {
  const { reload } = useReload();
  return reload;
};
