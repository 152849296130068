import { UpdateMeDocument } from '@cycle-app/graphql-codegen';
import { Input, Button } from '@cycle-app/ui';
import { useForm } from 'react-hook-form';

import ImageInput from 'src/components/ImageInput/ImageInput';
import { Events } from 'src/constants/analytics.constants';
import { useMe } from 'src/hooks/api/useMe';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { jobsLabel } from 'src/utils/jobs.util';

import {
  Form,
  Inputs,
  StyledAvatar,
  InputContent,
  UploadNewAvatarBtn,
  Buttons,
} from './AccountTab.styles';

interface FormValues {
  firstName: string;
  lastName: string;
  avatar?: File;
  avatarPreviewUrl?: string;
  jobTitle: string;
}

const AccountTab = () => {
  const { me } = useMe();
  const [updateMe, { loading }] = useSafeMutation(UpdateMeDocument, {
    onCompleted: () => trackAnalytics(Events.UserAccountUpdated),
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      firstName: me.firstName ?? '',
      lastName: me.lastName ?? '',
      avatarPreviewUrl: me.avatar?.url,
      jobTitle: me.jobTitle ? jobsLabel[me.jobTitle] : '',
    },
  });

  const avatarUrl = watch('avatarPreviewUrl');

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Inputs>
        <Input
          id="firstName"
          type="text"
          label="First name"
          error={errors.firstName?.message}
          {...register('firstName', {
            setValueAs: value => value.trim(),
            required: 'First name is required',
          })}
        />
        <Input
          id="lastName"
          type="text"
          label="Last name"
          error={errors.lastName?.message}
          {...register('lastName', {
            setValueAs: value => value.trim(),
            required: 'Last name is required',
          })}
        />
        <Input
          id="email"
          type="email"
          label="Email"
          defaultValue={me.email}
          readOnly
        />
        <Input
          id="job"
          type="text"
          label="Your job"
          {...register('jobTitle')}
          readOnly
        />

        <ImageInput
          label="Avatar"
          previewModalTitle="New profile picture"
          previewModalSubmitLabel="Set new profile picture"
          onChange={onAvatarChanged}
        >
          {(inputRef) => (
            <InputContent>
              <StyledAvatar
                src={avatarUrl}
                user={me}
                size={64}
                onClick={() => inputRef.current?.click()}
              />
              <UploadNewAvatarBtn onClick={() => inputRef.current?.click()}>
                Upload new
              </UploadNewAvatarBtn>
            </InputContent>
          )}
        </ImageInput>
      </Inputs>

      <Buttons>
        <Button
          size="M"
          type="submit"
          isLoading={loading}
        >
          Save
        </Button>
      </Buttons>
    </Form>
  );

  async function onSubmit({
    firstName, lastName, avatar,
  }: FormValues) {
    return updateMe({
      variables: {
        firstName,
        lastName,
        avatarInput: !avatar ? undefined : {
          avatar,
        },
      },
    });
  }

  async function onAvatarChanged(avatar: File) {
    setValue('avatar', avatar);
    setValue('avatarPreviewUrl', URL.createObjectURL(avatar));
  }
};

export default AccountTab;
