/**
 * 🚨 Important : Keep this file in sync with the customer facing equivalent
 * changelog-poc/src/features/changelog/Preview/types.ts
 */

import { ChangelogBaseFragment } from '@cycle-app/graphql-codegen';

export enum CustomEvents {
  CLIENT_APP_READY = 'CLIENT_APP_READY',
  PREVIEW_CHANGE = 'PREVIEW_CHANGE',
}

type Changelog = ChangelogBaseFragment;

export type PreviewChangelogEventData = Pick<
Changelog,
| 'backgroundColor'
| 'bodyColor'
| 'contentSection'
| 'dateStyle'
| 'dividerColor'
| 'favicon'
| 'headersColor'
| 'heroSection'
| 'heroStyle'
| 'language'
| 'linkColor'
| 'releaseTagStyle'
| 'shipBadgeColor'
| 'socialLinkText'
| 'socialLinkURL'
| 'subscribeLabel'
| 'subscribeToggled'
| 'subtitle'
| 'title'
| 'typography'
> & {
  heroImage: string | null | File;
  logo: string | null | File;
};

export enum PreviewType {
  DESKTOP = 'DESKTOP',
  TABLET = 'TABLET',
  MOBILE = 'MOBILE',
}
