import { DependencyList, useLayoutEffect, useRef, useState } from 'react';

const emptyList: DependencyList = [];

/**
 * Check if the content of an element is overflowing
 * Useful for displaying a tooltip on an element whose content is truncated
 */
export const useOverflow = <T extends HTMLElement>(deps = emptyList) => {
  const ref = useRef<T>(null);
  const [overflow, setOverflow] = useState(false);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) return;

    const observer = new ResizeObserver(() => {
      setOverflow(element.clientWidth < element.scrollWidth);
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  return [ref, overflow] as const;
};
