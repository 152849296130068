import { ReactNode, useCallback, useRef, FC } from 'react';
import { DropzoneRootProps } from 'react-dropzone';

import { DocProcessButton } from 'src/components/DocProcessButton';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ErrorPage } from 'src/components/ErrorPage';
import { Size } from 'src/components/ErrorPage/ErrorPage.types';
import { VerifyQuotes, VerifyQuotesContainer } from 'src/components/VerifyQuotes';
import { PageId } from 'src/constants/routing.constant';
import { useDoc } from 'src/hooks';
import { useNavigate } from 'src/hooks/useNavigate';
import { useDocId } from 'src/hooks/usePathParams';
import { isFeedback } from 'src/utils/docType.util';

import { DocPanelBaseProps } from '../DocPanel.types';
import {
  Container,
  MainSection,
} from './DocPanelFullPage.styles';
import { PanelsContainer } from '../DocPanelBoardPage/DocPanelBoardPage.styles';
import { DocPanelContent } from '../DocPanelContent/DocPanelContent';
import DocPanelHeader from '../DocPanelHeader/DocPanelHeader';
import DocPanelRightPanel from '../DocPanelRightPanel/DocPanelRightPanel';
import { FeatureDocPanelContent, FeatureDocReleaseNotePanel, FeatureDocReleaseNotePanelContent } from '../FeatureDocPanelContent';

interface Props extends Omit<DocPanelBaseProps, 'doc'> {
  widthSidebar: number;
  getDropzonePropsTopBar: () => DropzoneRootProps;
  children?: ReactNode;
}

const DocPanelFullPage: FC<Props> = ({
  getDropzonePropsCover,
  isDraggingCover,
  isUploadingCover,
  onOpenCoverInputFile,
  onTitleUpdated,
  widthSidebar,
  children,
}) => {
  const docId = useDocId();
  const {
    doc: docBase, loading: isLoadingDocbase,
  } = useDoc(docId);
  const { navigate } = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);

  const onDeleteDoc = useCallback(() => {
    navigate(PageId.Main);
  }, [navigate]);

  if (!docId) return null;

  const noDoc = !docBase && !isLoadingDocbase;
  const isFeedbackDoctype = isFeedback(docBase?.doctype);

  return (
    <Container
      ref={containerRef}
      $widthSidebar={widthSidebar}
    >
      {noDoc ? (
        <ErrorPage
          size={Size.SMALL}
          message="Oops, this doc no longer exists"
        />
      ) : (
        <ErrorBoundary>
          <MainSection>
            <DocPanelHeader
              onDeleteDoc={onDeleteDoc}
              onOpenCoverInputFile={onOpenCoverInputFile}
              {...docBase && isFeedbackDoctype && docBase.status && {
                processButton: (
                  <DocProcessButton
                    docId={docBase.id}
                    docStatusCategory={docBase.status.category}
                    parent="doc-panel"
                    quotesCount={docBase.quotesCount ?? 0}
                  />
                ),
              }}
            />

            {isFeedbackDoctype ? (
              <DocPanelContent
                containerRef={containerRef}
                getDropzonePropsCover={getDropzonePropsCover}
                isDraggingCover={isDraggingCover}
                isUploadingCover={isUploadingCover}
                onTitleUpdated={onTitleUpdated}
                onOpenCoverInputFile={onOpenCoverInputFile}
                focusOnMount={false}
              />
            ) : (
              <FeatureDocPanelContent
                docId={docId}
                containerRef={containerRef}
                getDropzonePropsCover={getDropzonePropsCover}
                isDraggingCover={isDraggingCover}
                isUploadingCover={isUploadingCover}
                onTitleUpdated={onTitleUpdated}
                onOpenCoverInputFile={onOpenCoverInputFile}
                focusOnMount={false}
              />
            )}
          </MainSection>

          {docBase && (
            <PanelsContainer>
              {isFeedbackDoctype && (
                <VerifyQuotesContainer docPanelRef={containerRef}>
                  <VerifyQuotes doc={docBase} />
                </VerifyQuotesContainer>
              )}

              {!isFeedbackDoctype && docBase.releaseNote &&  (
                <FeatureDocReleaseNotePanel
                  containerRef={containerRef}
                >
                  <FeatureDocReleaseNotePanelContent
                    releaseNote={docBase.releaseNote}
                  />
                </FeatureDocReleaseNotePanel>
              )}

              <DocPanelRightPanel doc={docBase} />
            </PanelsContainer>
          )}

          {children}
        </ErrorBoundary>
      )}
    </Container>
  );
};

export default DocPanelFullPage;
