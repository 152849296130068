import { Skeleton, ActionButton } from '@cycle-app/ui';
import { CaretIcon, LinearIcon, PenIcon, UnlinkIcon, ArrowRightUpIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { IssueAssignee } from 'src/components/LinearEditorMentionView/IssueAssignee';
import { IssueStatus } from 'src/components/LinearEditorMentionView/IssueStatus';
import { LinearStatus } from 'src/components/LinearStatus/LinearStatus';
import { useDocLinearContext } from 'src/contexts/docLinearContext';
import { useLinearIssue } from 'src/hooks/api/queries/integrations/useLinearIssue';
import { Layer } from 'src/types/layers.types';
import { issueInfos } from 'src/utils/linear.utils';

import {
  PreviewContainer, PreviewHeader, HeaderLeft, HeaderRight, IssueTitle, Infos, IssueDetails,
} from './DocLinear.styles';
import { IssueDescription } from './IssueDescription';
import { IssueEstimate } from './IssueEstimate';
import { IssuePriority } from './IssuePriority';
import { IssueProject } from './IssueProject';
import { PreviewAlert } from './PreviewAlert';

export const PreviewIssue = ({
  onClickChange, onUnlink, hide,
}: {
  onClickChange: VoidFunction;
  onUnlink: VoidFunction;
  hide: VoidFunction;
}) => {
  const query = useLinearIssue({
    issueId: useDocLinearContext(ctx => ctx.automationId),
    integrationId: useDocLinearContext(ctx => ctx.integrationId),
  });
  const automationUrl = useDocLinearContext(ctx => ctx.automationUrl);
  const [isExpanded, setIsExpanded] = useState(false);

  const menu = (
    <DotsMenuLayer
      layer={Layer.DropdownModalZ4}
      options={[...automationUrl ? [{
        value: 'open',
        label: 'Open in Linear',
        keepDropdownOnSelect: false,
        onSelect: () => {
          hide();
          window.open(automationUrl, '_blank');
        },
        icon: <ArrowRightUpIcon />,
      }] : [], {
        value: 'change',
        label: 'Change issue',
        icon: <PenIcon style={iconStyle(18)} />,
        keepDropdownOnSelect: false,
        onSelect: () => {
          hide();
          onClickChange();
        },
      }, {
        value: 'unlink',
        label: 'Unlink issue',
        icon: <UnlinkIcon style={iconStyle(15)} />,
        keepDropdownOnSelect: false,
        onSelect: () => {
          hide();
          onUnlink();
        },
      }]}
    />
  );

  if (query.isLoading && !query.issue) {
    return (
      <PreviewContainer>
        <PreviewHeader>
          <Skeleton height={20} />
          {menu}
        </PreviewHeader>
      </PreviewContainer>
    );
  }

  if (!query.issue) {
    return (
      <PreviewContainer>
        <PreviewHeader>
          <PreviewAlert message="It seems that this issue no longer exists." />
          {menu}
        </PreviewHeader>
      </PreviewContainer>
    );
  }

  return (
    <PreviewContainer>
      <PreviewHeader>
        <HeaderLeft>
          <ActionButton onClick={() => setIsExpanded(!isExpanded)}>
            <CaretIcon
              size={11}
              direction={isExpanded ? 'bottom' : 'right'}
            />
          </ActionButton>
          <LinearIcon size={16} />
          <Infos>
            {issueInfos(query.issue.url, query.issue.team?.name)}
          </Infos>
          {query.issue.status && (
            <LinearStatus
              status={query.issue.status}
              size={14}
            />
          )}
          <IssueTitle>
            {query.issue.title}
          </IssueTitle>
        </HeaderLeft>

        <HeaderRight>
          <IssueAssignee
            issue={query.issue}
            layer={Layer.DropdownModalZ4}
          />
          {menu}
        </HeaderRight>
      </PreviewHeader>

      {isExpanded && (
        <>
          <IssueDescription value={query.issue.description} />
          <IssueDetails>
            <IssueStatus issue={query.issue} />
            <IssuePriority issue={query.issue} />
            <IssueEstimate issue={query.issue} />
            <IssueProject issue={query.issue} />
          </IssueDetails>
        </>
      )}
    </PreviewContainer>
  );
};

const iconStyle = (height: number) => ({
  height,
  width: 18,
});
