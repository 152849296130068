import { StatusFragment, StatusCategory } from '@cycle-app/graphql-codegen';
import { StatusIcon, Shortcut, SelectOption, TagProps } from '@cycle-app/ui';
import { useHasParentContext } from '@fluentui/react-context-selector';
import { useCallback, FC, useState, useEffect, ReactNode } from 'react';

import { shortcuts, ShortcutStatus } from 'src/constants/shortcuts.constants';
import { BoardConfigContext } from 'src/contexts/boardConfigContext';
import { useUpdateDocsStatus } from 'src/hooks/doc/useUpdateDocsStatus';
import { useUpdateDocStatus } from 'src/hooks/doc/useUpdateDocStatus';
import { useHotkeyListener, UseHotkeyParams } from 'src/hooks/useHotkeyListener';
import { getDocItemHoverId } from 'src/reactives/docItem.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useStatusByDocTypes } from 'src/reactives/productStatus.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutDocPanel } from 'src/types/shortcuts.types';

import { ToggleDropdown } from '../DropdownLayer';
import { Label, StyledTag, StyledSelectPanel, DocStatusText } from './DocStatus.styles';

export type DocStatusProps = {
  className?: string;
  docId?: string;
  docTypeId: string;
  hideLabel?: boolean;
  isDisabled?: boolean;
  statusId: string;
  enableShortcut?: boolean;
  compatibleStatusIds?: string[];
  onStatusUpdated?: (statusId?: string) => void;
  isAllowedToUpdate?: boolean;
};

export const DocStatus: FC<React.PropsWithChildren<DocStatusProps>> = ({
  className,
  docId,
  docTypeId,
  hideLabel,
  isDisabled = false,
  statusId,
  compatibleStatusIds,
  onStatusUpdated,
  isAllowedToUpdate,
  ...props
}) => {
  const statusList = useStatusByDocTypes(
    [docTypeId],
    status => (!compatibleStatusIds || compatibleStatusIds.includes(status.id)),
  );
  const [value, setValue] = useState<StatusFragment>();

  useEffect(() => {
    setValue(statusList.find(status => status?.id === statusId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId, statusId]);

  const options = statusList
    .map((status, i) => ({
      label: status.value ?? '',
      value: status.id ?? '',
      disabled: !status.id,
      icon: (
        <StatusIcon
          category={status.category}
          withBackground
        />
      ),
      shortcut: status.id && i < 9 ? shortcuts[ShortcutStatus[`Status${i + 1}` as ShortcutStatus]] : undefined,
    }));

  if (!value) return null;

  if (isDisabled) {
    return (
      <StatusTag
        className={className}
        category={value.category}
        value={value.value}
        hideLabel={hideLabel}
        role="button"
        isReadOnly
      />
    );
  }

  return (
    <ToggleDropdown
      placement="bottom-start"
      layer={Layer.DropdownModalZ3}
      shortcutEnabled={() => getDocItemHoverId() === docId || !!props.enableShortcut}
      shortcut={ShortcutDocPanel.Status}
      button={buttonProps => (
        <StatusTag
          role="button"
          className={className}
          category={value.category}
          value={value.value}
          hideLabel={hideLabel}
          onClick={e => (isAllowedToUpdate || isAllowedToUpdate === undefined ? buttonProps.onClick(e) : setLimitationsModal({ action: 'FEEDBACK_STATUS_UPDATE' }))}
          data-active={buttonProps['data-active']}
        />
      )}
      content={contentProps => (
        <StatusPanel
          onDone={contentProps.hide}
          options={options}
          docId={docId}
          value={value}
          setValue={setValue}
          onStatusUpdated={onStatusUpdated}
          statusList={statusList}
        />
      )}
    />
  );
};

type StatusTagProps = TagProps & {
  category: StatusCategory;
  isReadOnly?: boolean;
  value: string;
  hideLabel?: boolean;
};

export const StatusTag: FC<React.PropsWithChildren<StatusTagProps>> = ({
  category,
  isReadOnly = false,
  value,
  hideLabel,
  ...props
}) => {
  const statusLabel = (
    <Label>
      <StatusIcon
        category={category}
        style={{ color: 'currentcolor' }}
      />
      {value}
    </Label>
  );

  return (
    <StyledTag
      $category={category}
      color="grey"
      squared={hideLabel}
      tooltip={{
        ...(isReadOnly ? {
          content: statusLabel,
        } : {
          title: statusLabel,
          content: (
            <Label>
              Change status
              <Shortcut keys={shortcuts[ShortcutDocPanel.Status]} />
            </Label>
          ),
        }),
        placement: 'top',
        withPortal: true,
        disabled: isReadOnly && !hideLabel,
      }}
      {...props}
    >
      <Label>
        <StatusIcon category={category} />
        {!hideLabel && (
          <DocStatusText>
            {value}
          </DocStatusText>
        )}
      </Label>
    </StyledTag>
  );
};

type StatusPanelProps = {
  docId?: string;
  onDone: VoidFunction;
  options: SelectOption[];
  value?: StatusFragment;
  setValue: (status?: StatusFragment) => void;
  statusList: StatusFragment[];
  onStatusUpdated?: (statusId?: string) => void;
};

type UpdateDocsStatus = (docId: string, statusId: string, undoable?: boolean) => Promise<void>;

type WithUpdateStatusProps = {
  children: (callback: UpdateDocsStatus) => ReactNode;
};

const StatusPanel: FC<React.PropsWithChildren<StatusPanelProps>> = (props) => {
  const isWrappedWithContext = useHasParentContext(BoardConfigContext);
  const Wrapper = isWrappedWithContext ? WithUpdateStatusInBoard : WithUpdateStatus;
  return (
    <Wrapper>
      {updateDocStatus => (
        <StatusPanelWithUpdate
          {...props}
          updateDocStatus={updateDocStatus}
        />
      )}
    </Wrapper>
  );
};

const WithUpdateStatusInBoard = ({ children }: WithUpdateStatusProps) => {
  const { updateDocsStatus } = useUpdateDocsStatus();
  const updateDocStatus = (docId: string, statusId: string, undoable?: boolean) => {
    return updateDocsStatus([docId], statusId, undoable);
  };
  return (
    <>
      {children(updateDocStatus)}
    </>
  );
};

const WithUpdateStatus = ({ children }: WithUpdateStatusProps) => {
  const mutation = useUpdateDocStatus();

  const updateDocStatus = async (docId: string, statusId: string) => {
    await mutation.updateDocStatus(docId, statusId);
  };

  return (
    <>
      {children(updateDocStatus)}
    </>
  );
};

const StatusPanelWithUpdate = ({
  docId, onDone, options, value, setValue, statusList, updateDocStatus, onStatusUpdated,
}: StatusPanelProps & {
  updateDocStatus: UpdateDocsStatus;
}) => {
  const handleChange = useCallback((id: string) => {
    const newStatus = statusList.find(status => status?.id === id);
    if (!newStatus || !docId) return;
    onDone();
    setValue(newStatus);
    // The mutation updateDocStatus causes a lot of re-rendering
    // Delaying it improves performance
    setTimeout(() => {
      updateDocStatus(docId, id, false)
        .then(() => onStatusUpdated?.(id))
        .catch(() => setValue(value));
    }, 200);
  }, [onStatusUpdated]);

  useHotkeyListener(statusList.slice(0, 9).reduce<UseHotkeyParams<ShortcutStatus>>((acc, s, index) => {
    const key = ShortcutStatus[`Status${index + 1}` as ShortcutStatus];
    acc.shortcuts.push(key);
    acc.callbacks[key] = () => handleChange(s.id);
    return acc;
  }, {
    callbacks: {},
    shortcuts: [],
  }));

  return (
    <StyledSelectPanel
      options={options}
      onOptionChange={option => handleChange(option.value)}
    />
  );
};
