import { NotificationFragment, NotificationType } from '@cycle-app/graphql-codegen';
import { Avatar, Skeleton, StatusIcon, Emoji } from '@cycle-app/ui';
import { CycleIcon } from '@cycle-app/ui/icons';
import { getDocSlug, getHighlightHash } from '@cycle-app/utilities';
import { STATUS_CATEGORY } from '@cycle-app/utilities/src/constants/statusCategory.constants';
import { toNotificationDateTime } from '@cycle-app/utilities/src/utils/date.utils';
import range from 'lodash/range';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { AssigneeContent } from 'src/components/DocAssignee/AssigneeContent';
import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { DocSource } from 'src/components/DocSource';
import { SourcePopover } from 'src/components/SourcePopover/SourcePopover';
import { PageId } from 'src/constants/routing.constant';
import { useDocChildren, useMe, useNavigate } from 'src/hooks';
import { useUrl } from 'src/hooks/useUrl';
import { openCommentsPanel } from 'src/reactives/docRightPanel.reactive';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { closeNotifications } from 'src/reactives/notifications.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { openSettingsNotifications } from 'src/reactives/settingsModal.reactive';
import { getInsightChildren, isInsight } from 'src/utils/docType.util';
import {
  COMMENT_SEARCH_PARAM,
  getNotificationTitleProps,
  shouldLinkToAComment,
  welcomeNotificationText,
} from 'src/utils/notifications.util';

import {
  ChildContainer,
  Container,
  Content,
  Date,
  GreyPart,
  ImageContainer,
  MainArea,
  SettingsButton,
  StatusContainer,
  Title,
  TitleEmoji,
  UnreadDot,
} from './NotificationCard.styles';

const openSettings = () => {
  closeNotifications();
  openSettingsNotifications();
};

interface NotificationCardProps {
  notification: NotificationFragment;
  onClickLink?: VoidFunction;
}

export const NotificationCard: FC<React.PropsWithChildren<NotificationCardProps>> = ({
  notification, onClickLink,
}) => {
  const {
    creator, comment, doc, createdAt, read, type,
  } = notification;

  const history = useHistory();
  const dateString = createdAt && toNotificationDateTime(createdAt);
  const getUrl = useUrl();
  const {
    navigateToDocFullPage, navigateToReleaseNote,
  } = useNavigate();
  const isMobile = useIsMobile();

  const isDocInsight = isInsight(getDocType(doc?.doctype.id));

  const docUrl = doc ? getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) }) : null;

  const image = creator
    ? (
      <ImageContainer>
        <Avatar
          user={creator}
          size={20}
        />
      </ImageContainer>
    )
    : (
      <ImageContainer>
        <CycleIcon
          rounded
          size={20}
        />
      </ImageContainer>
    );

  const isWelcomeNotification = type === NotificationType.WelcomeNotification;

  const titleProps = getNotificationTitleProps(notification);
  const content = isWelcomeNotification ? welcomeNotificationText : comment?.content;
  const isContentBoxed = !!comment?.content;
  const searchParams = shouldLinkToAComment(type) ? `${COMMENT_SEARCH_PARAM}=${comment?.id}` : '';

  const dateContent = (!isWelcomeNotification && dateString) ? (
    <Date>
      {dateString}
    </Date>
  ) : undefined;
  const dotContent = !read ? <UnreadDot /> : undefined;

  let titleContent: JSX.Element;

  if (notification.type === NotificationType.DocStatusChanged) {
    titleContent = (
      <Title>
        <TitleEmoji emoji={titleProps.emoji} />
        {titleProps.docTitle}
        {' '}
        <GreyPart>
          {titleProps.label}
        </GreyPart>
        {' '}
        {titleProps.prev?.category && (
          <StatusContainer $statusCategory={titleProps.prev?.category}>
            <StatusIcon category={titleProps.prev.category} />
            {STATUS_CATEGORY[titleProps.prev.category]}
          </StatusContainer>
        )}
        {titleProps.labelSecond && (
          <GreyPart>
            {titleProps.labelSecond}
          </GreyPart>
        )}
        {titleProps.next?.category && (
          <StatusContainer $statusCategory={titleProps.next?.category}>
            <StatusIcon category={titleProps.next.category} />
            {STATUS_CATEGORY[titleProps.next.category]}
          </StatusContainer>
        )}
      </Title>
    );
  } else if (notification.type === NotificationType.LoopClosed) {
    titleContent = (
      <Title>
        {`${doc?.releaseNote?.title || 'A release note'} was just released `}
        <Emoji
          emoji=":tada:"
          inline
        />
      </Title>
    );
  } else {
    titleContent = (
      <Title>
        {titleProps.name}
        {' '}
        <GreyPart>
          {titleProps.label}
        </GreyPart>
        {' '}
        {titleProps.docTitle}
      </Title>
    );
  }

  const cardContent = [
    NotificationType.DocStatusChanged,
    NotificationType.LoopClosed,
  ].includes(notification.type)
    ? (
      <NotificationContentDocStatusChange
        date={dateContent}
        doc={doc}
        dot={dotContent}
        image={image}
        title={titleContent}
      />
    )
    : (
      <Container
        isWelcomeNotification={isWelcomeNotification}
        isContentBoxed={isContentBoxed}
      >
        {image}
        <MainArea>
          {dotContent}
          {titleContent}
          {content && (
            <Content
              content={content}
              isContentBoxed={isContentBoxed}
              fallback={<Skeleton style={{ height: 24 }} />}
            />
          )}
          {dateContent}
          {isWelcomeNotification && (
            <p>
              {'You can '}
              <SettingsButton onClick={openSettings}>
                manage your notifications preferences here
              </SettingsButton>
            </p>
          )}
        </MainArea>
      </Container>
    );

  if (docUrl && doc) {
    return (
      <div
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (shouldLinkToAComment(type)) {
            history.push({
              pathname: docUrl,
              search: searchParams,
            });
          } else if (notification.type === NotificationType.LoopClosed) {
            const releaseId = doc.releaseNote?.release.id;
            const releaseNoteId = doc.releaseNote?.id;
            if (releaseId && releaseNoteId) navigateToReleaseNote(releaseId, releaseNoteId);
          } else {
            navigateToDocFullPage(isDocInsight && !!doc.docSource?.doc ? {
              // Navigates to the feedback and highlight the insight
              title: doc.docSource.doc.title,
              id: doc.docSource.doc.id,
              hash: getHighlightHash({
                docId: doc.id,
                blockId: doc.docSource.blockId,
              }),
            } : {
              title: doc.title,
              id: doc.id,
            });
          }
          closeNotifications();
          onClickLink?.();
          if (isMobile && shouldLinkToAComment(type)) {
            // Open the comments panel once the doc is open
            setTimeout(openCommentsPanel);
          }
        }}
      >
        {cardContent}
      </div>
    );
  }
  return (
    <>
      {cardContent}
    </>
  );
};

interface NotificationContentDocStatusChangeProps {
  date?: JSX.Element;
  dot?: JSX.Element;
  image: JSX.Element;
  title: JSX.Element;
  doc: NotificationFragment['doc'];
}

const NotificationContentDocStatusChange: FC<React.PropsWithChildren<NotificationContentDocStatusChangeProps>> = ({
  date,
  dot,
  image,
  title,
  doc,
}) => {
  const { me } = useMe();
  const insightChildrenDocType = useMemo(() => getInsightChildren(doc?.doctype), [doc?.doctype]);
  const {
    loading, docs,
  } = useDocChildren({
    docId: doc?.id,
    doctypeId: insightChildrenDocType?.id,
    userId: me.id,
  });

  return (
    <Container
      isWelcomeNotification={false}
      isContentBoxed={false}
    >
      {image}
      <MainArea>
        {dot}
        {title}
        {loading
          ? range(3).map((n) => (
            <Skeleton
              key={n}
              style={{ height: 24 }}
            />
          ))
          : docs.map(d => (
            <ChildContainer key={d.id}>
              <div>
                {d.docSource?.content || d.title || ''}
              </div>
              <SourcePopover docId={d.id}>
                <DocSource
                  source={d.source}
                  showName={false}
                />
              </SourcePopover>
              <AssigneeContent
                assignee={d.assignee}
                tooltipPlacement="top"
                isDisabled
                inheritCursor
              />
              {d.customer && (
                <DocCompanyCustomer
                  doc={d}
                  isCompact
                  isReadOnly
                />
              )}
            </ChildContainer>
          ))}
        {date}
      </MainArea>
    </Container>
  );
};
