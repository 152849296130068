import { StatusCategory } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';
import { twJoin } from 'tailwind-merge';

import { useDocStatusFragment } from 'src/hooks/api/fragments/doc';
import { openVerifyQuotes, useVerifyQuotesOpen } from 'src/reactives/docRightPanel.reactive';

import { useExtractQuotes } from './useExtractQuotes';

export const ExtractQuotes = ({ docId }: { docId: string  }) => {
  const docStatus = useDocStatusFragment(docId)?.status;

  const {
    extractStatus, extractQuotes, quotes,
  } = useExtractQuotes(docId);
  const isVerifyQuotesOpen = useVerifyQuotesOpen();

  if (isVerifyQuotesOpen || !docStatus) return null;

  const isExtractDisabled = [StatusCategory.Completed, StatusCategory.Canceled].includes(docStatus.category);

  if (extractStatus === 'extract' || extractStatus === null) {
    return (
      <Tooltip
        withPortal
        withWrapper={false}
        placement="top"
        content={getExtractDisabledMessage(docStatus.category)}
        disabled={!isExtractDisabled}
      >
        <button
          className={twJoin(
            'btn-tertiary flex-none text-cycle dark:text-primary',
            isExtractDisabled && 'btn-disabled',
          )}
          onClick={isExtractDisabled ? undefined : extractQuotes}
          disabled={extractStatus === null}
        >
          <AiIcon size={13} />
          Extract quotes
        </button>
      </Tooltip>
    );
  }

  if (extractStatus === 'loading') {
    return (
      <button
        className="bg-ai btn-tertiary flex-none cursor-default bg-clip-text text-transparent"
        onClick={e => { e.stopPropagation(); }}
      >
        <AiIcon
          isAnimated
          hasGradient
          size={14}
        />
        Looking for quotes…
      </button>
    );
  }

  if (extractStatus === 'retry') {
    return (
      <div className="relative flex flex-none items-center gap-0.5">
        <button
          className="btn-tertiary px-0 text-cycle dark:text-primary"
          disabled
        >
          <AiIcon size={13} />
          No quote to verify
        </button>
        <button
          className="btn-tertiary text-cycle dark:text-primary"
          onClick={extractQuotes}
        >
          Retry
        </button>
      </div>
    );
  }

  if (extractStatus === 'verified') {
    return (
      <Tooltip
        withPortal
        withWrapper={false}
        placement="top"
        content={isExtractDisabled
          ? getExtractDisabledMessage(docStatus.category)
          : 'Remove existing AI-generated quotes if you want to extract new ones'}
      >
        <button
          className="btn-disabled btn-tertiary flex-none text-cycle dark:text-primary"
          onClick={e => { e.stopPropagation(); }}
        >
          <AiIcon size={13} />
          {verifyQuotesLabel()}
        </button>
      </Tooltip>
    );
  }

  if (extractStatus === 'verify' && quotes.length > 0) {
    return (
      <button
        className="btn-tertiary flex-none text-cycle dark:text-primary"
        onClick={e => {
          e.stopPropagation();
          openVerifyQuotes();
        }}
      >
        {verifyQuotesLabel(quotes.length)}
      </button>
    );
  }

  return null;
};

export const verifyQuotesLabel = (count = 0) => {
  if (count === 0) return 'No quote to verify';
  if (count === 1) return '1 quote to verify';
  return `${count} quotes to verify`;
};

export const getExtractDisabledMessage = (docStatusCategory?: StatusCategory) => {
  if (docStatusCategory === StatusCategory.Completed) return 'Quote extraction is disabled when feedback is processed';
  if (docStatusCategory === StatusCategory.Canceled) return 'Quote extraction is disabled when feedback is canceled';
  return '';
};
