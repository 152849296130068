import { CompanyLogo, CustomerAvatar } from '@cycle-app/ui';

import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { useDocBase } from 'src/hooks/api/useDoc';
import { Layer } from 'src/types/layers.types';

export const QuoteCustomer = ({
  docId, showLabel = false,
}: {
  docId: string;
  showLabel?: boolean;
}) => {
  const { doc } = useDocBase(docId);
  const customer = doc?.customer;
  if (!customer) return null;
  const singleCustomer = customer.company && customer.company.countCustomers === 1;
  return (
    <DocCompanyCustomer
      doc={doc}
      layer={Layer.DropdownModalZ3}
      isDisabled
      className="flex-none cursor-pointer! gap-1.5! overflow-hidden p-0!"
      withWrapper={false}
    >
      {customer.company?.logo
        ? (
          <CompanyLogo
            company={customer.company}
            size="XS"
          />
        )
        : (
          <CustomerAvatar
            customer={customer}
            hideCompany
            size="XS"
          />
        )}

      {showLabel && (
        <div className="truncate text-caption text-secondary">
          {singleCustomer && customer.company?.name
            ? customer.company.name
            : (
              <>
                <span className="font-medium text-primary">
                  {customer.displayName}
                </span>
                {customer.company && (
                  <span>
                    {` · ${customer.company.name}`}
                  </span>
                )}
              </>
            )}
        </div>
      )}
    </DocCompanyCustomer>
  );
};
