import { Icon, Spinner } from '@cycle-app/ui';
import { CalendarIcon, CalendarEditIcon } from '@cycle-app/ui/icons';
import { search } from 'fast-fuzzy';
import { useMemo, useState } from 'react';
import { isPresent } from 'ts-is-present';

import { useMeetingsEvents } from 'src/hooks/integration/useMeetingsEvents';

import { MeetingEvent } from './MeetingEvent';
import { MeetingEventOngoing } from './MeetingEventOngoing';
import { MeetingEventSkeleton } from './MeetingEventSkeleton';
import { MeetingsEmpty } from './MeetingsEmpty';
import {
  Counter,
  Heading,
  SearchInput,
  Section,
} from './MeetingsTab.styles';

const loaders = (
  <>
    <MeetingEventSkeleton />
    <MeetingEventSkeleton />
    <MeetingEventSkeleton />
  </>
);

export const MeetingsTab = () => {
  const {
    events, isInitialLoading, isPastLoading, isUpcomingLoading,
  } = useMeetingsEvents();
  const [searchText, setSearchText] = useState('');

  const passed = useMemo(() => (
    searchText.trim() ? search(searchText, events.passed, {
      keySelector: a => [a.name, a.url].filter(isPresent),
      threshold: 1,
    }) : events.passed), [events.passed, searchText]);

  const ongoing = useMemo(() => (
    searchText.trim() ? search(searchText, events.ongoing, {
      keySelector: a => [a.name, a.url].filter(isPresent),
      threshold: 1,
    }) : events.ongoing), [events.ongoing, searchText]);

  const upcoming = useMemo(() => (
    searchText.trim() ? search(searchText, events.upcoming, {
      keySelector: a => [a.name, a.url].filter(isPresent),
      threshold: 1,
    }) : events.upcoming), [events.upcoming, searchText]);

  return (
    <div>
      <SearchInput
        iconBefore={<Icon name="search"  />}
        placeholder="Search for a meeting..."
        value={searchText}
        onChange={e => setSearchText(e.currentTarget.value)}
      />
      <Section>
        <Heading>
          Ongoing meetings
          {!!ongoing.length && (
            <Counter>
              {ongoing.length}
            </Counter>
          )}
          {isPastLoading && !isInitialLoading && <Spinner className="opacity-50" />}
        </Heading>
        {isInitialLoading && loaders}
        {!isInitialLoading && ongoing.map(event => (
          <MeetingEventOngoing
            key={event.id}
            botScheduled={event.botScheduled}
            id={event.id}
            name={event.name}
            url={event.url}
          />
        ))}
        {!isInitialLoading && !events.ongoing.length && (
          <MeetingsEmpty
            body="We automatically add your Google Calendar meetings."
            icon={(
              <CalendarIcon
                width="24"
                height="24"
              />
            )}
            title="No ongoing meeting"
          />
        )}
      </Section>
      <Section>
        <Heading>
          Upcoming meetings
          {!!upcoming.length && (
            <Counter>
              {upcoming.length}
            </Counter>
          )}
          {isUpcomingLoading && !isInitialLoading && <Spinner className="opacity-50" />}
        </Heading>
        {isInitialLoading && loaders}
        {!isInitialLoading && upcoming.map(event => (
          <MeetingEvent
            key={event.id}
            botScheduled={event.botScheduled}
            endTime={event.endTime}
            id={event.id}
            name={event.name}
            startTime={event.startTime}
            url={event.url}
          />
        ))}
        {!isInitialLoading && !events.upcoming.length && (
          <MeetingsEmpty
            body="We automatically add your Google Calendar meetings."
            icon={(
              <CalendarIcon
                width="24"
                height="24"
              />
            )}
            title="No upcoming meeting"
          />
        )}
      </Section>
      <Section>
        <Heading>
          Previous meetings
          {!!passed.length && (
            <Counter>
              {passed.length}
            </Counter>
          )}
          {isPastLoading && !isInitialLoading && <Spinner className="opacity-50" />}
        </Heading>
        {isInitialLoading && loaders}
        {!isInitialLoading && passed.map(event => (
          <MeetingEvent
            key={event.id}
            botScheduled={event.botScheduled}
            docId={event.doc?.id}
            docTitle={event.doc?.title}
            endTime={event.endTime}
            id={event.id}
            isPast
            name={event.name}
            startTime={event.startTime}
            url={event.url}
          />
        ))}
        {!isInitialLoading && !events.passed.length && (
          <MeetingsEmpty
            body="You haven't recorded any meeting yet."
            icon={(
              <CalendarEditIcon
                width="20"
                height="20"
              />
            )}
            title="No previous meeting"
          />
        )}
      </Section>
    </div>
  );
};
