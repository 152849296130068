import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { RightIcon } from '@cycle-app/ui/icons';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { search } from 'fast-fuzzy';
import { useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { routing, PageId } from 'src/constants/routing.constant';
import { ReleaseNotesProvider } from 'src/contexts/releaseNotesContext';
import { useReleasesContext } from 'src/contexts/releasesContext';
import { useRouteMatch } from 'src/hooks';
import { openCreateRelease } from 'src/reactives/releases.reactive';

import { ReleaseItem } from './ReleaseItem';
import { ReleaseItemSkeleton } from './ReleaseItemSkeleton';
import { List, EmptyStateContent, EmptyStateContentLink } from './ReleasesList.styles';
import { getPermission, setLimitationsModal } from '../../reactives';
import { ReleaseEmptyState } from '../ReleaseEmptyState';
import { ReleaseShareModal } from '../ReleaseModals';

type Props = {
  searchText: string;
};

export const ReleasesList = ({
  children, searchText,
}: React.PropsWithChildren<Props>) => {
  const matchRelease = useRouteMatch(routing[PageId.Release]);
  const isLoadingMore = useReleasesContext(ctx => ctx.isLoadingMore);
  const releases = useReleasesContext(ctx => ctx.releasesList);
  const releaseNoValue = useReleasesContext(ctx => ctx.releaseNoValue);
  const hasNextPage = useReleasesContext(ctx => ctx.hasNextPage);
  const loadMore = useReleasesContext(ctx => ctx.loadMore);

  const releasesValue = releases.filter(r => r.id !== releaseNoValue?.id);

  const releasesList = useMemo(() => (
    searchText.trim() ? search(searchText, releasesValue, {
      keySelector: r => {
        const date = r.date ? toShortLocaleDateString(r.date) : null;
        const fields = [
          date,
          r.title,
          `${date || ''} ${r.title || ''}`,
        ];
        return fields.filter(isPresent);
      },
      threshold: 1,
    }) : releasesValue), [searchText, releasesValue]);

  return (
    <List
      hasMoreData={hasNextPage}
      loadMore={loadMore}
      isLoading={isLoadingMore}
      loader={<ReleaseItemSkeleton />}
    >
      {releaseNoValue && (
        <ReleaseItem
          key={releaseNoValue.id}
          releaseId={releaseNoValue.id}
          isActive={matchRelease?.params.releaseId === releaseNoValue.id}
        >
          <ReleaseNotesProvider
            releaseId={releaseNoValue.id}
            isReadonly={false}
            publicStatus={null}
            showBugAndImprovements={null}
            isNoValue={!!releaseNoValue}
          >
            {children}
          </ReleaseNotesProvider>
        </ReleaseItem>
      )}
      {releasesList.map(release => (
        <ReleaseItem
          key={release.id}
          releaseId={release.id}
          isActive={matchRelease?.params.releaseId === release.id}
        >
          <ReleaseNotesProvider
            isReadonly={release.publicStatus === ReleasePublicStatus.Published}
            releaseId={release.id}
            publicStatus={release.publicStatus}
            showBugAndImprovements={release.showBugAndImprovements}
            isNoValue={false}
          >
            {children}
          </ReleaseNotesProvider>
        </ReleaseItem>
      ))}

      {releasesList.length === 0 && (
        <ReleaseEmptyState withHeight>
          <EmptyStateContent>
            <button
              className="btn-primary btn-lg"
              onClick={() => {
                if (getPermission().canCreateRelease) {
                  openCreateRelease();
                } else {
                  setLimitationsModal({ action: 'RELEASE_UPDATE' });
                }
              }}
            >
              Create release
            </button>
            <EmptyStateContentLink
              href="https://help.cycle.app/core-concepts/releases"
              target="_blank"
              rel="noreferrer"
            >
              Learn more about Releases
              <RightIcon />
            </EmptyStateContentLink>
          </EmptyStateContent>
        </ReleaseEmptyState>
      )}
      <ReleaseShareModal />
    </List>
  );
};
