import { useMemo } from 'react';

import productTourFeedbackImageStep1 from 'src/assets/product-tour-feedback-step-1.svg';
import productTourFeedbackImageStep2 from 'src/assets/product-tour-feedback-step-2.svg';
import productTourFeedbackImageStep3 from 'src/assets/product-tour-feedback-step-3.svg';
import productTourFeedbackImageStep4 from 'src/assets/product-tour-feedback-step-4.svg';
import productTourFeedbackImageStep5 from 'src/assets/product-tour-feedback-step-5.svg';
import { ProductTourModalContent } from 'src/components/ProductTourModalContent';
import { useIsFeedbackTourActive, useFeedbackTour } from 'src/hooks/useFeedbackTour';

export const FeedbackProductTour = () => {
  const { isActive } = useIsFeedbackTourActive();
  return isActive ? <OnboardingStarted /> : null;
};

const OnboardingStarted = () => {
  const { close } = useFeedbackTour();

  /* eslint-disable max-len */
  const slides = useMemo(() => [
    {
      id: 0,
      title: 'Capture feedback from anywhere',
      description: 'All of your feedback, all in one place. Slack messages, user interviews, surveys, support requests, etc. Bring them all into Cycle and make sure no product feedback gets unnoticed.',
      image: (
        <img
          src={productTourFeedbackImageStep1}
          alt=""
          height={145}
          draggable="false"
        />
      ),
    },
    {
      id: 1,
      title: 'Create new feedback',
      description: 'Create feedback from Cycle or any connected source. Pick the right customer and add some rich content with all you expect from a modern rich text editor with markdown and media embeds.',
      image: (
        <img
          src={productTourFeedbackImageStep2}
          alt=""
          height={145}
          draggable="false"
        />
      ),
    },
    {
      id: 2,
      title: 'Bring it all in your inbox',
      description: 'All of your feedback gets into your inbox. Make sure no feedback gets unnoticed. Go through your feedback docs, mark them each as “processed” & hit inbox zero in no time.',
      image: (
        <img
          src={productTourFeedbackImageStep3}
          alt=""
          height={155}
          draggable="false"
        />
      ),
    },
    {
      id: 3,
      title: 'Add feedback views',
      description: "Feedback isn't all the same. Add as many feedback views as you want so you can explore feedback docs in your own way. Add filters, groups & displayed properties.",
      image: (
        <img
          src={productTourFeedbackImageStep4}
          alt=""
          height={145}
          draggable="false"
        />
      ),
    },
    {
      id: 4,
      title: 'Customize feedback forms',
      description: 'Configure the content template and properties you want people to fill in when they send you feedback. They will be available whenever you create new feedback in Cycle or elsewhere.',
      image: (
        <img
          src={productTourFeedbackImageStep5}
          alt=""
          height={145}
          draggable="false"
        />
      ),
    },
  ], []);
  /* eslint-enable max-len */

  return (
    <ProductTourModalContent
      slides={slides}
      stop={close}
    />
  );
};
