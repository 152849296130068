import { DeleteDocCommentDocument, DeleteDocCommentMutationVariables, DocThreadsDocument } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';
import { useCallback } from 'react';

import useSafeMutation from 'src/hooks/useSafeMutation';
import client from 'src/services/apollo/client';
import { RefConnection } from 'src/types/apollo.types';
import { parseStoreFieldName } from 'src/utils/update-cache/cache.utils';

/**
 * This hook is used to delete a comment from a doc.
 * Side effects:
 * - Optimistically update comments count and comments list in cache
 * - Garbage collect the cache
 * - Deleting the first comment also delete the thread
 */
export const useDeleteComment = ({ isFirstComment }: { isFirstComment: boolean }) => {
  const [mutate, { loading }] = useSafeMutation(DeleteDocCommentDocument);

  const deleteComment = useCallback((
    docId: string,
    blockId: string | null,
    variables: DeleteDocCommentMutationVariables,
  ) => mutate({
    variables,
    refetchQueries: [{
      query: DocThreadsDocument,
      variables: {
        id: docId,
        resolved: false,
      },
    }, {
      query: DocThreadsDocument,
      variables: {
        id: docId,
        resolved: true,
      },
    }],
    // We disable optimistic response when deleting the first comment,
    // because it has unrevertable side effects (removing the mark in the editor)
    optimisticResponse: isFirstComment ? undefined : {
      deleteComment: {
        __typename: 'Comment',
        id: variables.commentId,
        doc: {
          id: docId,
        },
      },
    },
    update: (_, { data }) => {
      const comment = data?.deleteComment;
      if (!comment) return;
      client.cache.modify({
        id: client.cache.identify(comment.doc),
        fields: {
          threads: (threads, {
            toReference, storeFieldName,
          }) => {
            // We only want to update the cache for the thread with the given blockId
            const storeVariables = parseStoreFieldName<{ blockId: string }>(storeFieldName);
            // eslint-disable-next-line eqeqeq -- for the main thread, undefined != null must be false
            if (storeVariables?.blockId != blockId) return threads;

            const commentsCount = threads.edges[0]?.node.commentsCount ?? 0;
            const comments = threads.edges[0]?.node.comments ?? { edges: [] };
            return {
              edges: [{
                node: {
                  commentsCount: Math.max(0, commentsCount - 1),
                  comments: produce(comments, (draft: RefConnection) => {
                    const ref = toReference(comment)?.__ref;
                    const index = draft.edges.findIndex(edge => edge.node?.__ref === ref);
                    if (index !== -1) draft.edges.splice(index, 1);
                  }),
                },
              }],
            };
          },
        },
      });
      client.cache.gc();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return {
    deleteComment,
    isDeletingComment: loading,
  };
};
