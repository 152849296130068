/* eslint-disable @typescript-eslint/indent */
import { DETAILS_EXTENSION_CLASS_NAME, DRAG_HANDLE_CLASS, PARAGRAPH_CLASS } from '@cycle-app/editor-extensions';
import { ThemeType } from '@cycle-app/graphql-codegen/generated';
import { small } from '@cycle-app/utilities';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { EditorContainer as ReleaseEditorContainer } from 'src/components/ReleaseNote/ReleaseNote.styles';
import { EDITOR_MARGIN, EDITOR_WIDTH_DESKTOP, LIST_NESTING_SPACE } from 'src/constants/editor.constants';

type GetCssParams = {
  common: FlattenSimpleInterpolation;
  overwrites?: FlattenSimpleInterpolation;
};

export const IMAGE_NODE_CLASS_NAME = 'node-image';
export const VIDEO_NODE_CLASS_NAME = 'node-video';
export const AUDIO_NODE_CLASS_NAME = 'node-audio';
export const IFRAMELY_NODE_CLASS_NAME = 'node-iframely';
export const CODE_BLOCK_NODE_CLASS_NAME = 'code-block-node-class';
export const COLLABORATION_CURSOR_CARET_CLASS = 'collaboration-cursor__caret';
export const COLLABORATION_CURSOR_LABEL_CLASS = 'collaboration-cursor__label';

export const nodeCommonCss = css`
  width: ${EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 2}px;
  margin: auto;
  max-width: 100%;
  padding: 0 ${EDITOR_MARGIN}px;
  box-sizing: border-box;
`;

const nodeCommonReadOnlyCss = css`
  width: ${EDITOR_WIDTH_DESKTOP}px;
  margin: 0;
  max-width: 100%;
  padding: 0;
  box-sizing: border-box;
`;

const baseEditorCss = css`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: text;

  /* Fix the emoji selection from the right when emoji is the last char of the line */
  .ProseMirror-separator {
    width: 0 !important;
    height: 0 !important;
  }

  &[contenteditable="true"] {
    img.ProseMirror-selectednode {
      outline: 3px solid ${(p) => p.theme.userColors.main};
    }
  }
`;

const emptyPlaceholderCss = css`
  *.is-empty {
    ${small} {
      height: 46px;
    }
  }

  *.is-empty::before {
    content: attr(data-placeholder);
    float: left;
    pointer-events: none;
    height: 0;
    font-weight: 400;
    color: ${(p) => p.theme.colors.text.disabled};
  }

  blockquote.is-empty::before {
    position: relative;
    left: 20px;
    top: 4px;
  }

  *.is-empty.node-codeBlock::before {
    display: none;
  }
`;

const gapCursorCss = css`
  .ProseMirror-gapcursor {
    ${nodeCommonCss}
    position: relative;
    height: 24px;
  }

  .ProseMirror-gapcursor:after {
    top: 4px;
    width: 2px;
    height: 16px;
    border-top: none;
    border-left: 1px solid black;
  }
`;

export const boldCss = css`
  strong {
    font-weight: 700;
  }
`;

const italicCss = css`
  em {
    font-style: italic;
  }
`;

const underlineCss = css`
  u {
    text-decoration: underline;
    text-underline-offset: 2px;
    span {
      text-decoration: underline;
    }
  }
`;

const strikeCss = css`
  s {
    text-decoration: line-through;
  }
`;

const inlineCodeCss = css`
  code {
    font-family: monospace;
    display: inline-block;
    border-radius: 4px;
    padding: 0px 3px;
    word-wrap: break-word;
    color: ${p => p.theme.colors.editor.code.text};
    background-color: ${p => p.theme.colors.editor.code.bg};
    border: 1px solid ${p => p.theme.colors.editor.code.border};
  }

  p code {
    font-size: 12px;
    font-weight: 500;
    margin: 2px 0;
  }

  h1 code {
    font-size: 22px;
    margin: 2px 0;
  }
  h2 code {
    font-size: 16px;
    margin: 2px 0;
  }
  h3 code {
    font-size: 14px;
    margin: 2px 0;
  }
  h4 code {
    font-size: 13px;
    margin: 2px 0;
  }
  h5 code {
    font-size: 12.5px;
    margin: 2px 0;
  }
  h6 code {
    font-size: 12px;
    margin: 2px 0;
  }
`;

const dragIconCss = css`
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: 6px;
  left: 8px;
  height: 22px;
  width: 22px;
  cursor: grab;
  color: ${p => p.theme.colors.text.secondary};
  border-radius: 4px;
  &:hover { background-color: ${p => p.theme.colors.button.light.bg} }
  > svg {
    width: 16px;
    height: 16px;
  }
`;

const dragHandleCss = css`
  > .${DRAG_HANDLE_CLASS} { ${dragIconCss} }
  &:hover > .${DRAG_HANDLE_CLASS} { display: flex }
`;
const dragHandleHideOnHover = css`
  &:hover > .${DRAG_HANDLE_CLASS} { display: none }
`;

export const paragraphCss = css`
  /* Need 12px between all paragraphs */
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  font-style: normal;
  color: ${p => p.theme.colors.editor.text};
`;

const getParagraphCss = ({ common }: GetCssParams) => css`
  .${PARAGRAPH_CLASS} {
    ${common}
    ${paragraphCss}
    position: relative;
    ${dragHandleCss}
  }

  li:not([data-checked]) .${PARAGRAPH_CLASS} {
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    padding-left: 0;
    padding-right: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    ${dragHandleHideOnHover}
  }

  li[data-checked] .${PARAGRAPH_CLASS} {
    padding-left: 0;
    padding-right: 0;
    ${dragHandleHideOnHover}
  }

  blockquote {
    position: relative;

    & > * {
      margin-left: 24px !important;
    }
  }

  blockquote::before {
    content: "";
    position: absolute;
    height: 100%;
    border-color: ${(p) => p.theme.colors.border.primary};
    border-width: 0 0 0 4px;
    border-style: solid;
  }

  blockquote .${PARAGRAPH_CLASS} {
    font-style: italic;
    padding-left: 0;
    padding-right: 0;
    ${dragHandleHideOnHover}
  }

  /* Do not put padding when is inside detail summary */
  div[data-type="detailsContent"] .${PARAGRAPH_CLASS} {
    margin-left: -${EDITOR_MARGIN}px;
    padding-left: ${EDITOR_MARGIN}px;
    padding-right: 0;
  }

  td .${PARAGRAPH_CLASS} {
    padding: 3px 8px;
    ${dragHandleHideOnHover}
  }
  th .${PARAGRAPH_CLASS} {
    padding: 3px 8px;
    font-weight: 500;
    ${dragHandleHideOnHover}
  }
`;

const getHeadingCommonCss = ({ common }: GetCssParams) => css`
  ${common}
  font-style: normal;
  color: ${p => p.theme.colors.editor.text};
  font-weight: 600;
  /* Need 16px between headings */
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1.5;
  position: relative;
  ${dragHandleCss}
`;

export const h1Css = css`font-size: 24px;`;
export const h2Css = css`font-size: 18px;`;
export const h3Css = css`font-size: 16px;`;
export const h4Css = css`font-size: 15px;`;
export const h5Css = css`font-size: 14.5px;`;
export const h6Css = css`font-size: 14px;`;

const getHeadingsCss = (params: GetCssParams) => css`
  h1 {
    ${getHeadingCommonCss(params)}
    ${h1Css}
    > .${DRAG_HANDLE_CLASS} { top: 15px }
  }

  h2 {
    ${getHeadingCommonCss(params)}
    ${h2Css}
    > .${DRAG_HANDLE_CLASS} { top: 11px }
  }

  h3 {
    ${getHeadingCommonCss(params)}
    ${h3Css}
    > .${DRAG_HANDLE_CLASS} { top: 9px }
  }

  h4 {
    ${getHeadingCommonCss(params)}
    ${h4Css}
    > .${DRAG_HANDLE_CLASS} { top: 8px }
  }

  h5 {
    ${getHeadingCommonCss(params)}
    ${h5Css}
    > .${DRAG_HANDLE_CLASS} { top: 8px }
  }

  h6 {
    ${getHeadingCommonCss(params)}
    ${h6Css}
    > .${DRAG_HANDLE_CLASS} { top: 7px }
  }

  /* Title should have bigger space when following a paragraph */
  p + h1 {
    padding-top: 26px;
    > .${DRAG_HANDLE_CLASS} { top: 33px }
  }
  p + h2 {
    padding-top: 26px;
    > .${DRAG_HANDLE_CLASS} { top: 29px }
  }
  p + h3 {
    padding-top: 26px;
    > .${DRAG_HANDLE_CLASS} { top: 27px }
  }
  p + h4 {
    padding-top: 26px;
    > .${DRAG_HANDLE_CLASS} { top: 27px }
  }
  p + h5,
  p + h6 {
    padding-top: 26px;
    > .${DRAG_HANDLE_CLASS} { top: 26px }
  }
`;

export const linkCss = css`
  a {
    cursor: pointer;
    color: ${p => p.theme.colors.editor.link};
    font-weight: 500;
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;

const getLineBreakCss = ({
 common, overwrites,
}: GetCssParams) => css`
  hr {
    ${common}

    position: relative;
    overflow: visible;

    border: none;
    border-top: 1px solid ${(p) => p.theme.colors.editor.hr};

    &::before {
      background-color: hsl(var(--cycle));
      border-radius: 2px;
      bottom: -4px;
      content: " ";
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: -5px;
      transition: opacity 0.1s;
    }

    &:hover::before {
      opacity: 0.1;
    }

    &.ProseMirror-selectednode::before {
      opacity: 0.2;
    }

    ${overwrites}
  }
`;

const lineBreakOverwritesCss = css`
  width: ${EDITOR_WIDTH_DESKTOP}px;
  max-width: calc(100% - ${EDITOR_MARGIN * 2}px);
`;

const getBulletListCss = ({
  common, overwrites,
}: GetCssParams) => css`
  ul {
    ${common}
    padding-left: calc(var(--editor-margin, ${EDITOR_MARGIN}px) + ${LIST_NESTING_SPACE}px);
    list-style-type: disc;
    box-sizing: border-box;

    /* Support changing type for 10 level of nesting */
    ul {
      padding-left: ${LIST_NESTING_SPACE}px;
      padding-right: 0;
      list-style-type: circle;
      ul {
        list-style-type: square;
        ul {
          list-style-type: disc;
          ul {
            list-style-type: circle;
            ul {
              list-style-type: square;
              ul {
                list-style-type: disc;
                ul {
                  list-style-type: circle;
                  ul {
                    list-style-type: square;
                    ul {
                      list-style-type: disc;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    ${overwrites}
  }
`;

const getNumberedListCss = ({
  common, overwrites,
}: GetCssParams) => css`
  ol {
    ${common}
    padding-left: calc(var(--editor-margin, ${EDITOR_MARGIN}px) + ${LIST_NESTING_SPACE}px);
    list-style-type: decimal;
    box-sizing: border-box;

    ol {
      padding-left: ${LIST_NESTING_SPACE}px;
      list-style-type: lower-alpha;
      ol {
        list-style-type: lower-roman;
        ol {
          list-style-type: decimal;
          ol {
            list-style-type: lower-alpha;
            ol {
              list-style-type: lower-roman;
              ol {
                list-style-type: decimal;
                ol {
                  list-style-type: lower-alpha;
                  ol {
                    list-style-type: lower-roman;
                    ol {
                      list-style-type: decimal;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    ${overwrites}
  }
`;

const getTaskListCss = ({ common }: GetCssParams) => css`
  ul[data-type="taskList"] {
    ${common}
    list-style: none;

    li {
      display: flex;

      label {
        display: flex;
        align-items: flex-start;
        padding-top: 10px;
        padding-right: 6px;

        span {
          display: none;
        }
      }

      > div {
        flex: 1;
        box-sizing: border-box;
        max-width: calc(100% - (19px + 4px));
      }
    }

    ul[data-type="taskList"] {
      padding: 0;
    }
  }
`;

const listItemCss = css`
  li {
    box-sizing: border-box;
  }
`;

const getImageCss = ({ common }: GetCssParams) => css`
  .${IMAGE_NODE_CLASS_NAME} {
    ${common}
  }
`;

const getIframelyCss = ({ common }: GetCssParams) => css`
  .${IFRAMELY_NODE_CLASS_NAME} {
    ${common}
  }
`;

const getVideoCss = ({ common }: GetCssParams) => css`
  .${VIDEO_NODE_CLASS_NAME} {
    ${common}
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

const getAudioCss = ({ common }: GetCssParams) => css`
  .${AUDIO_NODE_CLASS_NAME} {
    ${common}
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

const docMentionCss = css`
  [data-type="doc-mention"] {
    max-width: 100%;
    a {
      color: inherit;
      text-decoration: inherit;
      max-width: 100%;
    }
  }
`;

const lowLightCss = css`
  .hljs-comment,
  .hljs-quote {
    color: #616161;
  }

  .hljs-variable,
  .hljs-template-variable,
  .hljs-attribute,
  .hljs-tag,
  .hljs-regexp,
  .hljs-link,
  .hljs-name,
  .hljs-selector-id,
  .hljs-selector-class {
    color: #f98181;
  }

  .hljs-number,
  .hljs-meta,
  .hljs-built_in,
  .hljs-builtin-name,
  .hljs-literal,
  .hljs-type,
  .hljs-params {
    color: #fbbc88;
  }

  .hljs-string,
  .hljs-symbol,
  .hljs-bullet {
    color: #b9f18d;
  }

  .hljs-title,
  .hljs-section {
    color: #faf594;
  }

  .hljs-keyword,
  .hljs-selector-tag {
    color: #70cff8;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: 700;
  }
`;

const getCodeBlockCss = ({ common }: GetCssParams) => css`
  .${CODE_BLOCK_NODE_CLASS_NAME} {
    ${common}
  }

  pre {
    ${lowLightCss}

    border-radius: 4px;
    color: white;
    background: black;
    padding-top: 24px;
    padding-bottom: 24px;

    [data-node-view-content] {
      padding-left: 24px;
      padding-right: 24px;
    }

    code {
      padding: 0;
      background: transparent;
      color: inherit;
    }
  }
`;

const getQuoteCss = ({ common }: GetCssParams) => css`
  /* Style is inside paragraph */
  blockquote {
    ${common}
  }
`;

const emojiCss = css`
  span[data-type="emoji"] {
    user-select: text;
    font-size: 17px;
    > img {
      height: 1em;
    }
  }
`;

export const collaborationCursorCss = css`
  p > .${COLLABORATION_CURSOR_CARET_CLASS},
  p > [data-content=true] > .${COLLABORATION_CURSOR_CARET_CLASS} {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid;
    border-right: 1px solid;
    word-break: normal;
    display: inline-block;
    width: 1px;
    height: 16px;
    cursor: default;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -3px;
      left: -3px;
      border-radius: 50%;
      border: 3px solid;
      border-color: inherit;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover {
      .${COLLABORATION_CURSOR_LABEL_CLASS} {
        opacity: 1;
        pointer-events: auto;
      }

      &::before {
        opacity: 0;
      }
    }
  }

  > .${COLLABORATION_CURSOR_CARET_CLASS},
  > [data-content=true] > .${COLLABORATION_CURSOR_CARET_CLASS} {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid;
    border-right: 1px solid;
    word-break: normal;
    display: inline-block;
    width: 100%;
    height: 2px;
    cursor: default;
  }

  .${COLLABORATION_CURSOR_LABEL_CLASS} {
    position: absolute;
    top: -28px;
    left: -1px;
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    user-select: none;
    color: white;
    padding: 8px;
    border-radius: 4px 4px 4px 0;
    white-space: nowrap;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }
`;

const getDetailsCss = ({ common }: GetCssParams) => css`
  .${DETAILS_EXTENSION_CLASS_NAME} {
    ${common}
    display: flex;
    align-items: flex-start;
    padding-top: 6px;
    padding-bottom: 6px;

    > button {
      display: flex;
      cursor: pointer;
      background: transparent;
      border: none;
      padding: 0;
      border-radius: 4px;
      margin-top: 2px;
      margin-right: 4px;

      &::before {
        content: "\\25B6";
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5em;
        height: 1.5em;
        font-size: 10px;
        margin-top: 2px;
        margin-right: 2px;
        margin-left: 2px;
        margin-bottom: 1px;
        transition: transform 0.2s ease;
      }
    }

    > button:hover {
      background-color: ${p => p.theme.colors.background.hoverSoft};
    }

    &.is-open > button::before {
      transform: rotate(90deg);
    }

    > div {
      flex: 1;
      box-sizing: border-box;
      max-width: calc(100% - (19px + 4px));

      summary {
        width: 100%;
        color: ${(p) => p.theme.colors.text.primary};
        font-size: 14px;
        line-height: 1.5;
        font-weight: 500;
        font-style: normal;
      }

      div[data-type="detailsContent"] {
        padding: 8px 0;
      }
    }
  }
`;

export const markColor = css`
  background-color: ${(p) => (p.theme.themeType === ThemeType.Nightfall
    ? 'hsla(var(--cycle300), 0.4)'
    : 'hsla(var(--cycle), 0.15)')};
`;

const highlightCss = css`
  /* Backward compatibility: marks without type are insights */
  mark:not([data-mark-type]),
  mark[data-mark-type="insight"] {
    background-color: ${(p) => p.theme.colors.editor.textHighlight};
    border-radius: 4px;
    padding: 2px 0px;

    &:hover,
    &:focus,
    &:active {
      ${markColor}
    }
  }

  mark[data-mark-type="comment"] {
    --bg: unset;
    background: var(--bg);
    box-shadow: 0 0 0 2px var(--bg);
    border-radius: 4px;

    [data-thread-section="open"] &[isresolved="false"],
    [data-thread-section="closed"] &[isresolved="true"],
    [data-thread-section="all"] & {
      cursor: pointer;
      text-decoration-line: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 5px;

      text-decoration-color: hsl(var(--cycle300));
      &:hover, &:focus, &:active {
        text-decoration-color: hsl(var(--cycle400));
        --bg: hsl(var(--cycle100));
      }

      ${p => p.theme.themeType === ThemeType.Nightfall && `
        text-decoration-color: hsl(var(--cycle500));
        &:hover, &:focus, &:active {
          --bg: hsla(var(--cycle300), 0.4);
        }
      `}
    }
  }

  mark[data-mark-type="linear-synced-content"] {
    background: transparent;
    color: ${p => p.theme.colors.text.secondary};
  }

  mark[data-mark-type="speaker"] {
    background: unset;
    color: unset;
  }
`;

const tableCss = css`
  table,
  tr,
  td,
  th {
    border-color: ${p => p.theme.colors.border.primary};
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-width: 0px;
    border-style: solid;
    padding: 0;
    table-layout: fixed;
  }

  td,
  th {
    border-width: 1px;
    border-style: solid;
    text-align: left;
    padding: .25rem;
    position: relative;
  }

  th {
    background-color: ${p => p.theme.colors.background.hoverSoft};
  }

  .column-resize-handle {
    position: absolute;
    right: -1px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: ${p => p.theme.colors.background.blue};
    z-index: 2;
    cursor: col-resize;
  }

  &.resize-cursor {
    cursor: col-resize;
  }
`;

const tableNormalCss = css`
  ${tableCss}

  div.tableWrapper {
   ${nodeCommonCss}
   ${tableCss}
  }
`;

const tableReadOnlyCss = css`
  ${tableCss}

  table {
    ${nodeCommonReadOnlyCss}
    width: calc(${EDITOR_WIDTH_DESKTOP}px);
  }
`;

const suggestionCss = css`
  .suggestion {
    background: ${p => p.theme.colors.background.secondaryElevated};
    color: ${p => p.theme.colors.text.disabled};
    padding: 2px 4px;
    border-radius: 4px;
    &[data-doc-mention-empty="true"] {
      :after {
        content: " Title of your feature";
      }
    }
  }
`;

/**
 * Some overwrite here
 * /packages/front/src/components/Editor/Editors/Editor.styles.ts
 * /packages/ui/src/components/InsightCard/InsightCard.styles.ts
 */
export const EditorContentCss = css`
  ${getParagraphCss({ common: nodeCommonCss })}

  .ProseMirror {
    ${baseEditorCss}

    ${emptyPlaceholderCss}
    ${gapCursorCss}
    ${boldCss}
    ${italicCss}
    ${underlineCss}
    ${strikeCss}
    ${inlineCodeCss}
    ${getHeadingsCss({ common: nodeCommonCss })}
    ${linkCss}
    ${getLineBreakCss({
      common: nodeCommonCss,
      overwrites: lineBreakOverwritesCss,
    })}
    ${getBulletListCss({ common: nodeCommonCss })}
    ${getNumberedListCss({ common: nodeCommonCss })}
    ${getTaskListCss({ common: nodeCommonCss })}
    ${listItemCss}
    ${getImageCss({ common: nodeCommonCss })}
    ${getVideoCss({ common: nodeCommonCss })}
    ${getAudioCss({ common: nodeCommonCss })}
    ${getIframelyCss({ common: nodeCommonCss })}
    ${docMentionCss}
    ${getCodeBlockCss({ common: nodeCommonCss })}
    ${getQuoteCss({ common: nodeCommonCss })}
    ${emojiCss}
    ${collaborationCursorCss}
    ${getDetailsCss({ common: nodeCommonCss })}
    ${highlightCss}
    ${tableNormalCss}
    ${suggestionCss}
  }
`;

const EditorReadOnlyOverwrites = css`
  padding-left: 22px;
`;

export const EditorPreview = styled.div`
  overflow: hidden;

  /* stylelint-disable-next-line no-duplicate-selectors */
  ${ReleaseEditorContainer} & {
    overflow: visible;
  }

  .ProseMirror {
    ${baseEditorCss}
    padding-top: 0;
    padding-bottom: 0;

    ${boldCss}
    ${italicCss}
    ${underlineCss}
    ${strikeCss}
    ${inlineCodeCss}
    ${getParagraphCss({ common: nodeCommonReadOnlyCss })}
    ${getHeadingsCss({ common: nodeCommonReadOnlyCss })}
    ${linkCss}
    ${getLineBreakCss({ common: nodeCommonReadOnlyCss })}
    ${getBulletListCss({
      common: nodeCommonReadOnlyCss,
      overwrites: EditorReadOnlyOverwrites,
    })}
    ${getNumberedListCss({
      common: nodeCommonReadOnlyCss,
      overwrites: EditorReadOnlyOverwrites,
    })}
    ${getTaskListCss({ common: nodeCommonReadOnlyCss })}
    ${listItemCss}
    ${getImageCss({ common: nodeCommonReadOnlyCss })}
    ${getVideoCss({ common: nodeCommonReadOnlyCss })}
    ${getAudioCss({ common: nodeCommonReadOnlyCss })}
    ${getIframelyCss({ common: nodeCommonReadOnlyCss })}
    ${docMentionCss}
    ${getCodeBlockCss({ common: nodeCommonReadOnlyCss })}
    ${getQuoteCss({ common: nodeCommonReadOnlyCss })}
    ${emojiCss}
    ${getDetailsCss({ common: nodeCommonReadOnlyCss })}
    ${highlightCss}
    ${tableReadOnlyCss}
  }
`;
